import * as yup from 'yup'; // https://github.com/jquense/yup#string

const ImportServiceCentersUtilProcessLogs = {
	
	name: 'ImportServiceCentersUtilProcessLogs',
	
	model: {

		idProperty: 'import_service_centers_util_process_logs__id',
		displayProperty: 'import_service_centers_util_process_logs__id',
		sortProperty: 'import_service_centers_util_process_logs__datetime',
		sortDir: 'DESC',
		
		sorters: null,

		validator: yup.object({
			import_service_centers_util_process_logs__util_process_log_id: yup.number().integer().required(),
			import_service_centers_util_process_logs__datetime: yup.date().nullable(),
			import_service_centers_util_process_logs__success: yup.boolean().required(),
			import_service_centers_util_process_logs__status: yup.number().integer().required(),
			import_service_centers_util_process_logs__json: yup.string().nullable(),
			import_service_centers_util_process_logs__import_service_center_id: yup.number().integer().nullable(),
			import_service_centers_util_process_logs__service_center_id: yup.number().integer().nullable()
		}),
		
		properties: [
			{ name: 'import_service_centers_util_process_logs__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'import_service_centers_util_process_logs__util_process_log_id', mapping: 'util_process_log_id', title: 'Util Process Log', type: 'int', isFk: true, fkIdField: 'util_process_logs__id', fkDisplayField: 'util_process_logs__process', filterType: {"type":"UtilProcessLogsCombo","loadAfterRender":!1}, editorType: {"type":"UtilProcessLogsCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'import_service_centers_util_process_logs__datetime', mapping: 'datetime', title: 'Datetime', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', },
			{ name: 'import_service_centers_util_process_logs__success', mapping: 'success', title: 'Success', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'import_service_centers_util_process_logs__status', mapping: 'status', title: 'Status', type: 'int', filterType: {"type":"ImportStatusesCombo"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_service_centers_util_process_logs__json', mapping: 'json', title: 'Json', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'import_service_centers_util_process_logs__import_service_center_id', mapping: 'import_service_center_id', title: 'Import Service Center', type: 'int', isFk: true, fkIdField: 'import_service_centers__id', fkDisplayField: 'import_service_centers__name', filterType: {"type":"ImportServiceCentersCombo","loadAfterRender":!1}, editorType: {"type":"ImportServiceCentersComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'import_service_centers_util_process_logs__service_center_id', mapping: 'service_center_id', title: 'Service Center', type: 'int', isFk: true, fkIdField: 'service_centers__id', fkDisplayField: 'service_centers__name', filterType: {"type":"ServiceCentersCombo","loadAfterRender":!1}, editorType: {"type":"ServiceCentersComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'import_service_centers__id', mapping: 'import_service_center.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_service_centers__name', mapping: 'import_service_center.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_service_centers__code', mapping: 'import_service_center.code', title: 'Code', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_service_centers__is_imported', mapping: 'import_service_center.is_imported', title: 'Imported?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_service_centers__imported_date', mapping: 'import_service_center.imported_date', title: 'Imported Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'service_centers__id', mapping: 'service_center.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'service_centers__name', mapping: 'service_center.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'service_centers__code', mapping: 'service_center.code', title: 'Code', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__id', mapping: 'util_process_log.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__datetime', mapping: 'util_process_log.datetime', title: 'Datetime', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__process', mapping: 'util_process_log.process', title: 'Process', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__success', mapping: 'util_process_log.success', title: 'Success', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__count', mapping: 'util_process_log.count', title: 'Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__time', mapping: 'util_process_log.time', title: 'Time', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__json', mapping: 'util_process_log.json', title: 'Json', type: 'json', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'ImportServiceCenters',
				'ServiceCenters',
				'UtilProcessLogs'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'import_service_centers_util_process_logs__util_process_log_id',
			// 'import_service_centers_util_process_logs__datetime',
			'import_service_centers_util_process_logs__success',
			'import_service_centers_util_process_logs__status',
			// 'import_service_centers_util_process_logs__import_service_center_id',
			// 'import_service_centers_util_process_logs__service_center_id'
		],

	},

	entity: {
		methods: {

			getImportServiceCenter: async function() {
				const ImportServiceCenters = this.getAssociatedRepository('ImportServiceCenters');
				let entity = ImportServiceCenters.getById(this.import_service_centers_util_process_logs__import_service_center_id);
				if (!entity) {
					entity = await ImportServiceCenters.getSingleEntityFromServer(this.import_service_centers_util_process_logs__import_service_center_id);
				}
				return entity;
			},

			getServiceCenter: async function() {
				const ServiceCenters = this.getAssociatedRepository('ServiceCenters');
				let entity = ServiceCenters.getById(this.import_service_centers_util_process_logs__service_center_id);
				if (!entity) {
					entity = await ServiceCenters.getSingleEntityFromServer(this.import_service_centers_util_process_logs__service_center_id);
				}
				return entity;
			},

			getUtilProcessLog: async function() {
				const UtilProcessLogs = this.getAssociatedRepository('UtilProcessLogs');
				let entity = UtilProcessLogs.getById(this.import_service_centers_util_process_logs__util_process_log_id);
				if (!entity) {
					entity = await UtilProcessLogs.getSingleEntityFromServer(this.import_service_centers_util_process_logs__util_process_log_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default ImportServiceCentersUtilProcessLogs;
