import ImportStatusesViewer from '../../Viewer/ImportStatusesViewer.js';
import TimeViewer from '../../Viewer/TimeViewer.js';

export default [
	    {
	        "id": "import_manufacturers_util_process_logs__datetime",
	        "header": "Time", // MOD
	        "fieldName": "import_manufacturers_util_process_logs__datetime",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100, // MOD
			renderer: (entity) => {
				return <TimeViewer moment={entity.properties.import_manufacturers_util_process_logs__datetime?.getMoment()} />;
			},
	    },
		{
			id: "import_manufacturers__name",
			header: "Name",
			fieldName: "import_manufacturers__name",
			isSortable: false,
			isEditable: false,
			isReorderable: true,
			isResizable: true,
			w: 200
		},
		{
			id: "import_manufacturers__abbr",
			header: "Abbr",
			fieldName: "import_manufacturers__abbr",
			isSortable: false,
			isEditable: false,
			isReorderable: true,
			isResizable: true,
			w: 100
		},
	    {
	        "id": "import_manufacturers_util_process_logs__success",
	        "header": "Success",
	        "fieldName": "import_manufacturers_util_process_logs__success",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_manufacturers_util_process_logs__status",
	        "header": "Status",
	        "fieldName": "import_manufacturers_util_process_logs__status",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
			renderer: (entity) => {
				return <ImportStatusesViewer id={entity.import_manufacturers_util_process_logs__status} />;
			},
	    },
	    {
	        "id": "import_manufacturers_util_process_logs__json",
	        "header": "Json",
	        "fieldName": "import_manufacturers_util_process_logs__json",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    // {
	    //     "id": "import_manufacturers_util_process_logs__import_manufacturer_id",
	    //     "header": "Import Manufacturer",
	    //     "fieldName": "import_manufacturers__name",
	    //     "isSortable": true,
	    //     "isEditable": true,
	    //     "isReorderable": true,
	    //     "isResizable": true,
	    //     "editField": "import_manufacturers_util_process_logs__import_manufacturer_id",
	    //     "flex": 1,
	    //     "minWidth": 150
	    // },
	    // {
	    //     "id": "import_manufacturers_util_process_logs__manufacturer_id",
	    //     "header": "Manufacturer",
	    //     "fieldName": "manufacturers__name",
	    //     "isSortable": true,
	    //     "isEditable": true,
	    //     "isReorderable": true,
	    //     "isResizable": true,
	    //     "w": 100,
	    //     "editField": "import_manufacturers_util_process_logs__manufacturer_id"
	    // }
	];