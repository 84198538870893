/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function EquipmentEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "Equipment Details",
		                "reference": "equipment_details",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "equipment__eq_status_id"
		                    },
		                    {
		                        "name": "equipment__inactive_date",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "equipment__year"
		                    },
		                    {
		                        "name": "equipment__manufacturer_id"
		                    },
		                    {
		                        "name": "equipment__model"
		                    },
		                    {
		                        "name": "equipment__serial_number"
		                    },
		                    {
		                        "name": "equipment__unit_number"
		                    },
		                    {
		                        "name": "equipment__asset_number"
		                    },
		                    {
		                        "name": "equipment__dept_number"
		                    },
		                    {
		                        "name": "equipment__business_partner_id"
		                    },
		                    {
		                        "name": "equipment__eq_type_id"
		                    },
		                    {
		                        "name": "equipment__tel_manufacturer_id"
		                    },
		                    {
		                        "name": "equipment__tel_pid"
		                    },
		                    {
		                        "name": "equipment__comments"
		                    },
		                    {
		                        "name": "equipment__is_misc"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "PM Setup",
		                "reference": "p_m_setup",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "equipment__is_pm_enable"
		                    },
		                    {
		                        "name": "equipment__pm_schedules"
		                    },
		                    {
		                        "name": "equipment__expected_meter_per_month"
		                    },
		                    {
		                        "name": "equipment__available_meter_per_week"
		                    },
		                    {
		                        "name": "equipment__in_service_date"
		                    },
		                    {
		                        "name": "equipment__in_service_meter"
		                    }
		                ]
		            }
		        ]
		    },
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": []
		    }
		],
		ancillaryItems = [
		    // {
		    //     "title": "Eq Monthly Stats",
		    //     "type": "EqMonthlyStatsGridEditor",
		    //     "selectorId": "eq_monthly_stats__equipment_id"
		    // },
		    // {
		    //     "title": "Attachments",
		    //     "type": "Attachments",
		    //     "selectorId": "attachments__modelid"
		    // }
		    {
		        "title": "Meter Readings",
		        "type": "MeterReadingsGridEditor",
		        "selectorId": "meter_readings__equipment_id"
		    },
		    {
		        "title": "Pm Events",
		        "type": "PmEventsGridEditor",
		        "selectorId": "pm_events__equipment_id"
		    },
		    {
		        "title": "Work Orders",
		        "type": "WorkOrdersGridEditor",
		        "selectorId": "work_orders__equipment_id"
		    },
		    {
		        "title": "Tel Usages",
		        "type": "TelUsagesGridEditor",
		        "selectorId": "tel_usages__equipment_id"
		    },
		],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="EquipmentEditor"
				title="Equipment"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

