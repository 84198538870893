/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportServiceCentersInlineGridEditor from './ImportServiceCentersInlineGridEditor.js';

export default function ImportServiceCentersFilteredInlineGridEditor(props) {
	return <ImportServiceCentersInlineGridEditor
				reference="ImportServiceCentersFilteredInlineGridEditor"
				useFilters={true}

				{...props}
			/>;
}