export default [
	    {
	        "id": "pm_schedules__name",
	        "header": "Name",
	        "fieldName": "pm_schedules__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "pm_schedules__interval_meter",
	        "header": "Interval Meter",
	        "fieldName": "pm_schedules__interval_meter",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "pm_schedules__interval_days",
	        "header": "Interval Days",
	        "fieldName": "pm_schedules__interval_days",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "pm_schedules__pm_schedule_mode_id",
	        "header": "PM Schedule Mode",
	        "fieldName": "pm_schedule_modes__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "pm_schedules__pm_schedule_mode_id"
	    },
	    {
	        "id": "pm_schedules__repeats",
	        "header": "Repeats",
	        "fieldName": "pm_schedules__repeats",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "pm_schedules__job_code",
	        "header": "Job Code",
	        "fieldName": "pm_schedules__job_code",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    }
	];