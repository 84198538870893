/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportWoOtherChargesInlineGridEditor from './ImportWoOtherChargesInlineGridEditor.js';

export default function ImportWoOtherChargesFilteredInlineGridEditor(props) {
	return <ImportWoOtherChargesInlineGridEditor
				reference="ImportWoOtherChargesFilteredInlineGridEditor"
				useFilters={true}

				{...props}
			/>;
}