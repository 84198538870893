export default [
	    {
	        "id": "parts_work_orders__work_order_id",
	        "header": "Work Order",
	        "fieldName": "work_orders__service_order",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "editField": "parts_work_orders__work_order_id",
	        "flex": 1,
	        "minWidth": 150
	    },
	    {
	        "id": "parts_work_orders__qty_ordered",
	        "header": "Qty Ordered",
	        "fieldName": "parts_work_orders__qty_ordered",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "parts_work_orders__qty_shipped",
	        "header": "Qty Shipped",
	        "fieldName": "parts_work_orders__qty_shipped",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "parts_work_orders__unit_cost",
	        "header": "Unit Cost",
	        "fieldName": "parts_work_orders__unit_cost",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "parts_work_orders__unit_price",
	        "header": "Unit Price",
	        "fieldName": "parts_work_orders__unit_price",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "parts_work_orders__ext_cost",
	        "header": "Ext Cost",
	        "fieldName": "parts_work_orders__ext_cost",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "parts_work_orders__ext_price",
	        "header": "Ext Price",
	        "fieldName": "parts_work_orders__ext_price",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];