export default [
	    {
	        "id": "wo_other_charges__amount",
	        "header": "Amount",
	        "fieldName": "wo_other_charges__amount",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "wo_other_charges__description",
	        "header": "Description",
	        "fieldName": "wo_other_charges__description",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "wo_other_charges__work_order_id",
	        "header": "Work Order",
	        "fieldName": "work_orders__service_order",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "wo_other_charges__work_order_id"
	    },
	    {
	        "id": "wo_other_charges__wo_other_charge_type_id",
	        "header": "Wo Other Charge Type",
	        "fieldName": "wo_other_charge_types__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "wo_other_charges__wo_other_charge_type_id"
	    },
	    {
	        "id": "wo_other_charges__qty_ordered",
	        "header": "Qty Ordered",
	        "fieldName": "wo_other_charges__qty_ordered",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "wo_other_charges__qty_shipped",
	        "header": "Qty Shipped",
	        "fieldName": "wo_other_charges__qty_shipped",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];