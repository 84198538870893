/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportWorkOrdersUtilProcessLogsGridColumns from './Columns/ImportWorkOrdersUtilProcessLogsGridColumns.js';

export default function ImportWorkOrdersUtilProcessLogsGrid(props) {
	return <Grid
				reference="ImportWorkOrdersUtilProcessLogsGrid"
				model="ImportWorkOrdersUtilProcessLogs"
				usePermissions={true}
				columnsConfig={ImportWorkOrdersUtilProcessLogsGridColumns}

				{...props}
			/>;
}