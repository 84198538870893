/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { TagEditor } from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import ImportWoPartsEditorWindow from '../../../Window/ImportWoPartsEditorWindow.js';

function ImportWoPartsTagEditor(props) {
	return <TagEditor
				reference="ImportWoPartsTagEditor"
				model="ImportWoParts"
				uniqueRepository={true}
				Editor={ImportWoPartsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default ImportWoPartsTagEditor;