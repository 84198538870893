/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function PermissionsEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "Custom Permissions",
		                "reference": "custom_permissions",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_reports"
		                    },
		                    {
		                        "name": "permissions__view_quick_reports"
		                    },
		                    {
		                        "name": "permissions__access_fleet_app"
		                    },
		                    {
		                        "name": "permissions__access_pms_app"
		                    },
		                    {
		                        "name": "permissions__access_users_app"
		                    },
		                    {
		                        "name": "permissions__access_audit_app"
		                    },
		                    {
		                        "name": "permissions__access_data_management_app"
		                    },
		                    {
		                        "name": "permissions__access_lease_app"
		                    },
		                    {
		                        "name": "permissions__access_rentals_app"
		                    },
		                    {
		                        "name": "permissions__access_all_enterprises"
		                    },
		                    {
		                        "name": "permissions__access_whole_enterprise"
		                    },
		                    {
		                        "name": "permissions__drag_drop"
		                    },
		                    {
		                        "name": "permissions__view_charts"
		                    },
		                    {
		                        "name": "permissions__receive_alerts"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Addresses",
		                "reference": "addresses",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_addresses"
		                    },
		                    {
		                        "name": "permissions__add_addresses"
		                    },
		                    {
		                        "name": "permissions__edit_addresses"
		                    },
		                    {
		                        "name": "permissions__delete_addresses"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Chromes",
		                "reference": "chromes",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_chromes"
		                    },
		                    {
		                        "name": "permissions__add_chromes"
		                    },
		                    {
		                        "name": "permissions__edit_chromes"
		                    },
		                    {
		                        "name": "permissions__delete_chromes"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Attachments",
		                "reference": "attachments",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_attachments"
		                    },
		                    {
		                        "name": "permissions__add_attachments"
		                    },
		                    {
		                        "name": "permissions__edit_attachments"
		                    },
		                    {
		                        "name": "permissions__delete_attachments"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "BusinessPartners",
		                "reference": "business_partners",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_business_partners"
		                    },
		                    {
		                        "name": "permissions__add_business_partners"
		                    },
		                    {
		                        "name": "permissions__edit_business_partners"
		                    },
		                    {
		                        "name": "permissions__delete_business_partners"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "BusinessPartnersFleets",
		                "reference": "business_partners_fleets",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_business_partners_fleets"
		                    },
		                    {
		                        "name": "permissions__add_business_partners_fleets"
		                    },
		                    {
		                        "name": "permissions__edit_business_partners_fleets"
		                    },
		                    {
		                        "name": "permissions__delete_business_partners_fleets"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Enterprises",
		                "reference": "enterprises",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_enterprises"
		                    },
		                    {
		                        "name": "permissions__add_enterprises"
		                    },
		                    {
		                        "name": "permissions__edit_enterprises"
		                    },
		                    {
		                        "name": "permissions__delete_enterprises"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "EqForkLengths",
		                "reference": "eq_fork_lengths",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_eq_fork_lengths"
		                    },
		                    {
		                        "name": "permissions__add_eq_fork_lengths"
		                    },
		                    {
		                        "name": "permissions__edit_eq_fork_lengths"
		                    },
		                    {
		                        "name": "permissions__delete_eq_fork_lengths"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "EqPowerTypes",
		                "reference": "eq_power_types",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_eq_power_types"
		                    },
		                    {
		                        "name": "permissions__add_eq_power_types"
		                    },
		                    {
		                        "name": "permissions__edit_eq_power_types"
		                    },
		                    {
		                        "name": "permissions__delete_eq_power_types"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Manufacturers",
		                "reference": "manufacturers",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_manufacturers"
		                    },
		                    {
		                        "name": "permissions__add_manufacturers"
		                    },
		                    {
		                        "name": "permissions__edit_manufacturers"
		                    },
		                    {
		                        "name": "permissions__delete_manufacturers"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "EqTireTypes",
		                "reference": "eq_tire_types",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_eq_tire_types"
		                    },
		                    {
		                        "name": "permissions__add_eq_tire_types"
		                    },
		                    {
		                        "name": "permissions__edit_eq_tire_types"
		                    },
		                    {
		                        "name": "permissions__delete_eq_tire_types"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "EqTypes",
		                "reference": "eq_types",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_eq_types"
		                    },
		                    {
		                        "name": "permissions__add_eq_types"
		                    },
		                    {
		                        "name": "permissions__edit_eq_types"
		                    },
		                    {
		                        "name": "permissions__delete_eq_types"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "EqUprightTypes",
		                "reference": "eq_upright_types",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_eq_upright_types"
		                    },
		                    {
		                        "name": "permissions__add_eq_upright_types"
		                    },
		                    {
		                        "name": "permissions__edit_eq_upright_types"
		                    },
		                    {
		                        "name": "permissions__delete_eq_upright_types"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "EqValveTypes",
		                "reference": "eq_valve_types",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_eq_valve_types"
		                    },
		                    {
		                        "name": "permissions__add_eq_valve_types"
		                    },
		                    {
		                        "name": "permissions__edit_eq_valve_types"
		                    },
		                    {
		                        "name": "permissions__delete_eq_valve_types"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "EqVoltages",
		                "reference": "eq_voltages",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_eq_voltages"
		                    },
		                    {
		                        "name": "permissions__add_eq_voltages"
		                    },
		                    {
		                        "name": "permissions__edit_eq_voltages"
		                    },
		                    {
		                        "name": "permissions__delete_eq_voltages"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Equipment",
		                "reference": "equipment",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_equipment"
		                    },
		                    {
		                        "name": "permissions__add_equipment"
		                    },
		                    {
		                        "name": "permissions__edit_equipment"
		                    },
		                    {
		                        "name": "permissions__delete_equipment"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "EquipmentPmSchedules",
		                "reference": "equipment_pm_schedules",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_equipment_pm_schedules"
		                    },
		                    {
		                        "name": "permissions__add_equipment_pm_schedules"
		                    },
		                    {
		                        "name": "permissions__edit_equipment_pm_schedules"
		                    },
		                    {
		                        "name": "permissions__delete_equipment_pm_schedules"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Fleets",
		                "reference": "fleets",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_fleets"
		                    },
		                    {
		                        "name": "permissions__add_fleets"
		                    },
		                    {
		                        "name": "permissions__edit_fleets"
		                    },
		                    {
		                        "name": "permissions__delete_fleets"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Groups",
		                "reference": "groups",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_groups"
		                    },
		                    {
		                        "name": "permissions__add_groups"
		                    },
		                    {
		                        "name": "permissions__edit_groups"
		                    },
		                    {
		                        "name": "permissions__delete_groups"
		                    }
		                ]
		            }
		        ]
		    },
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "GroupsUsers",
		                "reference": "groups_users",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_groups_users"
		                    },
		                    {
		                        "name": "permissions__add_groups_users"
		                    },
		                    {
		                        "name": "permissions__edit_groups_users"
		                    },
		                    {
		                        "name": "permissions__delete_groups_users"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "ImportManufacturers",
		                "reference": "import_manufacturers",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_import_manufacturers"
		                    },
		                    {
		                        "name": "permissions__add_import_manufacturers"
		                    },
		                    {
		                        "name": "permissions__edit_import_manufacturers"
		                    },
		                    {
		                        "name": "permissions__delete_import_manufacturers"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "ImportManufacturersUtilProcessLogs",
		                "reference": "import_manufacturers_util_process_logs",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_import_manufacturers_util_process_logs"
		                    },
		                    {
		                        "name": "permissions__add_import_manufacturers_util_process_logs"
		                    },
		                    {
		                        "name": "permissions__edit_import_manufacturers_util_process_logs"
		                    },
		                    {
		                        "name": "permissions__delete_import_manufacturers_util_process_logs"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "ImportPmEquipment",
		                "reference": "import_pm_equipment",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_import_pm_equipment"
		                    },
		                    {
		                        "name": "permissions__add_import_pm_equipment"
		                    },
		                    {
		                        "name": "permissions__edit_import_pm_equipment"
		                    },
		                    {
		                        "name": "permissions__delete_import_pm_equipment"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "ImportPmEquipmentUtilProcessLogs",
		                "reference": "import_pm_equipment_util_process_logs",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_import_pm_equipment_util_process_logs"
		                    },
		                    {
		                        "name": "permissions__add_import_pm_equipment_util_process_logs"
		                    },
		                    {
		                        "name": "permissions__edit_import_pm_equipment_util_process_logs"
		                    },
		                    {
		                        "name": "permissions__delete_import_pm_equipment_util_process_logs"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "ImportServiceCenters",
		                "reference": "import_service_centers",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_import_service_centers"
		                    },
		                    {
		                        "name": "permissions__add_import_service_centers"
		                    },
		                    {
		                        "name": "permissions__edit_import_service_centers"
		                    },
		                    {
		                        "name": "permissions__delete_import_service_centers"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "ImportServiceCentersUtilProcessLogs",
		                "reference": "import_service_centers_util_process_logs",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_import_service_centers_util_process_logs"
		                    },
		                    {
		                        "name": "permissions__add_import_service_centers_util_process_logs"
		                    },
		                    {
		                        "name": "permissions__edit_import_service_centers_util_process_logs"
		                    },
		                    {
		                        "name": "permissions__delete_import_service_centers_util_process_logs"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "ImportTechnicians",
		                "reference": "import_technicians",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_import_technicians"
		                    },
		                    {
		                        "name": "permissions__add_import_technicians"
		                    },
		                    {
		                        "name": "permissions__edit_import_technicians"
		                    },
		                    {
		                        "name": "permissions__delete_import_technicians"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "MeterReadings",
		                "reference": "meter_readings",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_meter_readings"
		                    },
		                    {
		                        "name": "permissions__add_meter_readings"
		                    },
		                    {
		                        "name": "permissions__edit_meter_readings"
		                    },
		                    {
		                        "name": "permissions__delete_meter_readings"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "ImportTechniciansUtilProcessLogs",
		                "reference": "import_technicians_util_process_logs",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_import_technicians_util_process_logs"
		                    },
		                    {
		                        "name": "permissions__add_import_technicians_util_process_logs"
		                    },
		                    {
		                        "name": "permissions__edit_import_technicians_util_process_logs"
		                    },
		                    {
		                        "name": "permissions__delete_import_technicians_util_process_logs"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "ImportWoLabors",
		                "reference": "import_wo_labors",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_import_wo_labors"
		                    },
		                    {
		                        "name": "permissions__add_import_wo_labors"
		                    },
		                    {
		                        "name": "permissions__edit_import_wo_labors"
		                    },
		                    {
		                        "name": "permissions__delete_import_wo_labors"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "ImportWoOtherChargeTypes",
		                "reference": "import_wo_other_charge_types",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_import_wo_other_charge_types"
		                    },
		                    {
		                        "name": "permissions__add_import_wo_other_charge_types"
		                    },
		                    {
		                        "name": "permissions__edit_import_wo_other_charge_types"
		                    },
		                    {
		                        "name": "permissions__delete_import_wo_other_charge_types"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "ImportWoOtherChargeTypesUtilProcessLogs",
		                "reference": "import_wo_other_charge_types_util_process_logs",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_import_wo_other_charge_types_util_process_logs"
		                    },
		                    {
		                        "name": "permissions__add_import_wo_other_charge_types_util_process_logs"
		                    },
		                    {
		                        "name": "permissions__edit_import_wo_other_charge_types_util_process_logs"
		                    },
		                    {
		                        "name": "permissions__delete_import_wo_other_charge_types_util_process_logs"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "PartTypes",
		                "reference": "part_types",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_part_types"
		                    },
		                    {
		                        "name": "permissions__add_part_types"
		                    },
		                    {
		                        "name": "permissions__edit_part_types"
		                    },
		                    {
		                        "name": "permissions__delete_part_types"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Parts",
		                "reference": "parts",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_parts"
		                    },
		                    {
		                        "name": "permissions__add_parts"
		                    },
		                    {
		                        "name": "permissions__edit_parts"
		                    },
		                    {
		                        "name": "permissions__delete_parts"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "PartsPartTypes",
		                "reference": "parts_part_types",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_parts_part_types"
		                    },
		                    {
		                        "name": "permissions__add_parts_part_types"
		                    },
		                    {
		                        "name": "permissions__edit_parts_part_types"
		                    },
		                    {
		                        "name": "permissions__delete_parts_part_types"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "ImportWoOtherCharges",
		                "reference": "import_wo_other_charges",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_import_wo_other_charges"
		                    },
		                    {
		                        "name": "permissions__add_import_wo_other_charges"
		                    },
		                    {
		                        "name": "permissions__edit_import_wo_other_charges"
		                    },
		                    {
		                        "name": "permissions__delete_import_wo_other_charges"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "PartsWorkOrders",
		                "reference": "parts_work_orders",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_parts_work_orders"
		                    },
		                    {
		                        "name": "permissions__add_parts_work_orders"
		                    },
		                    {
		                        "name": "permissions__edit_parts_work_orders"
		                    },
		                    {
		                        "name": "permissions__delete_parts_work_orders"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "ImportWoParts",
		                "reference": "import_wo_parts",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_import_wo_parts"
		                    },
		                    {
		                        "name": "permissions__add_import_wo_parts"
		                    },
		                    {
		                        "name": "permissions__edit_import_wo_parts"
		                    },
		                    {
		                        "name": "permissions__delete_import_wo_parts"
		                    }
		                ]
		            }
		        ]
		    },
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "ImportWorkOrders",
		                "reference": "import_work_orders",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_import_work_orders"
		                    },
		                    {
		                        "name": "permissions__add_import_work_orders"
		                    },
		                    {
		                        "name": "permissions__edit_import_work_orders"
		                    },
		                    {
		                        "name": "permissions__delete_import_work_orders"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "PmEvents",
		                "reference": "pm_events",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_pm_events"
		                    },
		                    {
		                        "name": "permissions__add_pm_events"
		                    },
		                    {
		                        "name": "permissions__edit_pm_events"
		                    },
		                    {
		                        "name": "permissions__delete_pm_events"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "PmScheduleModes",
		                "reference": "pm_schedule_modes",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_pm_schedule_modes"
		                    },
		                    {
		                        "name": "permissions__add_pm_schedule_modes"
		                    },
		                    {
		                        "name": "permissions__edit_pm_schedule_modes"
		                    },
		                    {
		                        "name": "permissions__delete_pm_schedule_modes"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "PmSchedules",
		                "reference": "pm_schedules",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_pm_schedules"
		                    },
		                    {
		                        "name": "permissions__add_pm_schedules"
		                    },
		                    {
		                        "name": "permissions__edit_pm_schedules"
		                    },
		                    {
		                        "name": "permissions__delete_pm_schedules"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "ImportWorkOrdersUtilProcessLogs",
		                "reference": "import_work_orders_util_process_logs",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_import_work_orders_util_process_logs"
		                    },
		                    {
		                        "name": "permissions__add_import_work_orders_util_process_logs"
		                    },
		                    {
		                        "name": "permissions__edit_import_work_orders_util_process_logs"
		                    },
		                    {
		                        "name": "permissions__delete_import_work_orders_util_process_logs"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Permissions",
		                "reference": "permissions",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_permissions"
		                    },
		                    {
		                        "name": "permissions__add_permissions"
		                    },
		                    {
		                        "name": "permissions__edit_permissions"
		                    },
		                    {
		                        "name": "permissions__delete_permissions"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "ServiceCenters",
		                "reference": "service_centers",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_service_centers"
		                    },
		                    {
		                        "name": "permissions__add_service_centers"
		                    },
		                    {
		                        "name": "permissions__edit_service_centers"
		                    },
		                    {
		                        "name": "permissions__delete_service_centers"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "TelOperators",
		                "reference": "tel_operators",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_tel_operators"
		                    },
		                    {
		                        "name": "permissions__add_tel_operators"
		                    },
		                    {
		                        "name": "permissions__edit_tel_operators"
		                    },
		                    {
		                        "name": "permissions__delete_tel_operators"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "TelUsages",
		                "reference": "tel_usages",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_tel_usages"
		                    },
		                    {
		                        "name": "permissions__add_tel_usages"
		                    },
		                    {
		                        "name": "permissions__edit_tel_usages"
		                    },
		                    {
		                        "name": "permissions__delete_tel_usages"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "Users",
		                "reference": "users",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_users"
		                    },
		                    {
		                        "name": "permissions__add_users"
		                    },
		                    {
		                        "name": "permissions__edit_users"
		                    },
		                    {
		                        "name": "permissions__delete_users"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "UtilCrudLogs",
		                "reference": "util_crud_logs",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_util_crud_logs"
		                    },
		                    {
		                        "name": "permissions__add_util_crud_logs"
		                    },
		                    {
		                        "name": "permissions__edit_util_crud_logs"
		                    },
		                    {
		                        "name": "permissions__delete_util_crud_logs"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "WoClasses",
		                "reference": "wo_classes",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_wo_classes"
		                    },
		                    {
		                        "name": "permissions__add_wo_classes"
		                    },
		                    {
		                        "name": "permissions__edit_wo_classes"
		                    },
		                    {
		                        "name": "permissions__delete_wo_classes"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "WoLaborTypes",
		                "reference": "wo_labor_types",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_wo_labor_types"
		                    },
		                    {
		                        "name": "permissions__add_wo_labor_types"
		                    },
		                    {
		                        "name": "permissions__edit_wo_labor_types"
		                    },
		                    {
		                        "name": "permissions__delete_wo_labor_types"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "WoLabors",
		                "reference": "wo_labors",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_wo_labors"
		                    },
		                    {
		                        "name": "permissions__add_wo_labors"
		                    },
		                    {
		                        "name": "permissions__edit_wo_labors"
		                    },
		                    {
		                        "name": "permissions__delete_wo_labors"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "UtilErrorCodes",
		                "reference": "util_error_codes",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_util_error_codes"
		                    },
		                    {
		                        "name": "permissions__add_util_error_codes"
		                    },
		                    {
		                        "name": "permissions__edit_util_error_codes"
		                    },
		                    {
		                        "name": "permissions__delete_util_error_codes"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "UtilErrorLogs",
		                "reference": "util_error_logs",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_util_error_logs"
		                    },
		                    {
		                        "name": "permissions__add_util_error_logs"
		                    },
		                    {
		                        "name": "permissions__edit_util_error_logs"
		                    },
		                    {
		                        "name": "permissions__delete_util_error_logs"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "WoOtherCharges",
		                "reference": "wo_other_charges",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_wo_other_charges"
		                    },
		                    {
		                        "name": "permissions__add_wo_other_charges"
		                    },
		                    {
		                        "name": "permissions__edit_wo_other_charges"
		                    },
		                    {
		                        "name": "permissions__delete_wo_other_charges"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "UtilProcessLogs",
		                "reference": "util_process_logs",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_util_process_logs"
		                    },
		                    {
		                        "name": "permissions__add_util_process_logs"
		                    },
		                    {
		                        "name": "permissions__edit_util_process_logs"
		                    },
		                    {
		                        "name": "permissions__delete_util_process_logs"
		                    }
		                ]
		            },
		            {
		                "type": "FieldSet",
		                "title": "WoOtherChargeTypes",
		                "reference": "wo_other_charge_types",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_wo_other_charge_types"
		                    },
		                    {
		                        "name": "permissions__add_wo_other_charge_types"
		                    },
		                    {
		                        "name": "permissions__edit_wo_other_charge_types"
		                    },
		                    {
		                        "name": "permissions__delete_wo_other_charge_types"
		                    }
		                ]
		            }
		        ]
		    },
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "WorkOrders",
		                "reference": "work_orders",
		                "showToggleAllCheckbox": true,
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "permissions__view_work_orders"
		                    },
		                    {
		                        "name": "permissions__add_work_orders"
		                    },
		                    {
		                        "name": "permissions__edit_work_orders"
		                    },
		                    {
		                        "name": "permissions__delete_work_orders"
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="PermissionsEditor"
				title="Permissions"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

