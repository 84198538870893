import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import UtilCrudLogsFilteredGridEditor from '../components/Grid/UtilCrudLogsFilteredGridEditor.js';
import UtilCrudLogsFilteredSideGridEditor from '../components/Grid/UtilCrudLogsFilteredSideGridEditor.js';
import UtilErrorLogsFilteredGridEditor from '../components/Grid/UtilErrorLogsFilteredGridEditor.js';
import UtilErrorLogsFilteredSideGridEditor from '../components/Grid/UtilErrorLogsFilteredSideGridEditor.js';
import UtilProcessLogsFilteredGridEditor from '../components/Grid/UtilProcessLogsFilteredGridEditor.js';
import UtilProcessLogsFilteredSideGridEditor from '../components/Grid/UtilProcessLogsFilteredSideGridEditor.js';
import TabBar from '@onehat/ui/src/Components/Tab/TabBar.js';
import Bug from '@onehat/ui/src/Components/Icons/Bug.js';
import Edit from '@onehat/ui/src/Components/Icons/Edit.js';
import Microchip from '../components/Icons/Microchip.js';
import _ from 'lodash';

export default function Logs(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'Logs';

	if (!isActive) {
		return null;
	}

	return <TabBar
				tabs={[
					{
						title: 'Process Logs',
						_icon: { as: Microchip },
						content: <ManagerScreen
									title="Process Logs"
									reference="UtilProcessLogs"
									fullModeComponent={<UtilProcessLogsFilteredGridEditor
															reference="UtilProcessLogsFilteredGridEditor"
														/>}
									sideModeComponent={<UtilProcessLogsFilteredSideGridEditor
															reference="UtilProcessLogsFilteredGridEditor"
														/>}
								/>,
					},
					{
						title: 'CRUD Logs',
						_icon: { as: Edit },
						content: <ManagerScreen
									title="CRUD Logs"
									reference="UtilCrudLogs"
									fullModeComponent={<UtilCrudLogsFilteredGridEditor
															reference="UtilCrudLogsFilteredGridEditor"
														/>}
									sideModeComponent={<UtilCrudLogsFilteredSideGridEditor
															reference="UtilCrudLogsFilteredGridEditor"
														/>}
								/>,
					},
					{
						title: 'Error Logs',
						_icon: { as: Bug },
						content: <ManagerScreen
									title="Error Logs"
									reference="UtilErrorLogs"
									fullModeComponent={<UtilErrorLogsFilteredGridEditor
															reference="UtilErrorLogsFilteredGridEditor"
														/>}
									sideModeComponent={<UtilErrorLogsFilteredSideGridEditor
															reference="UtilErrorLogsFilteredGridEditor"
														/>}
								/>,
					},
				]}
			/>;
}
