/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Tag from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import ImportTechniciansUtilProcessLogsEditorWindow from '../../../Window/ImportTechniciansUtilProcessLogsEditorWindow.js';

function ImportTechniciansUtilProcessLogsTag(props) {
	return <Tag
				reference="ImportTechniciansUtilProcessLogsTag"
				model="ImportTechniciansUtilProcessLogs"
				uniqueRepository={true}
				Editor={ImportTechniciansUtilProcessLogsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default ImportTechniciansUtilProcessLogsTag;