/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import EquipmentEditor from '../Editor/EquipmentEditor.js';
import EquipmentGridColumns from './Columns/EquipmentGridColumns.js';
import { useSelector } from 'react-redux';
import {
	selectEnterpriseId,
	selectTreeSelection,
} from '../../models/Slices/AppSlice';
import _ from 'lodash';

export default function EquipmentSideGridEditor(props) {
	const
		enterpriseId = useSelector(selectEnterpriseId),
		treeSelection = useSelector(selectTreeSelection),
		fleetId = treeSelection[0].id;

	return <SideGridEditor
				reference="EquipmentSideGridEditor"
				model="Equipment"
				usePermissions={true}
				isCollapsible={false}
				Editor={EquipmentEditor}
				columnsConfig={EquipmentGridColumns}

				selectorId="equipment__fleet_id"
				selectorSelected={treeSelection[0]}
				defaultValues={{ 
					equipment__enterprise_id: enterpriseId,
					equipment__fleet_id: fleetId,
				}}
				
				{...props}
			/>;
}