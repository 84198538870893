// from https://www.flaticon.com/free-icon/kick_1928
import * as React from "react"
import Svg, { Defs, G, Path } from "react-native-svg"
import { Icon } from 'native-base';
/* SVGR has dropped some elements not supported by react-native-svg: style */

function SvgComponent(props) {
  return (
    <Icon
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 415.89 249.77"
      {...props}
    >
      <Defs></Defs>
      <G id="Layer_1-2">
        <Path
          d="M112.65 231.72c-.66-6.8-1.84-12.86-1.72-18.89.33-16.05 14.69-27.22 30.49-24.08 16.26 3.24 31.22 10.07 46.06 17.13 2.67 1.27 4.91 1.38 7.7.51 27.09-8.43 54.23-16.72 81.35-25.06 18.68-5.75 37.38-11.47 56.04-17.3 6.36-1.99 6.65-2.74 4.74-8.92-.6-1.95-1.16-3.9-1.81-5.84-3.21-9.63-5.67-19.61-9.84-28.8-6.8-14.96-20.99-21.74-37.12-19.8-13.07 1.57-26.23 2.48-39.35 3.65-2.91.26-5.84.4-9.18.62v31.62h-17.24v-32c-5.36-.35-10.43-.67-16.02-1.04v28.89h-17.41v-31.17c-5.27-1.02-10.13-1.95-15.52-2.99v26.04h-17.14v-21.87c0-9.08.1-9.38-8.62-12.19-10.91-3.51-19.56-9.1-22.74-20.89-.49-1.82-1.44-3.51-2.18-5.26-8.4-19.74-16.75-39.5-25.25-59.2C95.44-.8 95.24-.7 89.56 1.09 61.6 9.89 33.62 18.7 5.66 27.51c-1.89.6-3.75 1.27-5.66 1.92 4.29 26.31 8.49 52.19 12.75 78.06.67 4.05 1.17 7.71-1.81 11.42-1.94 2.41-2.86 5.85-3.62 8.98-2.48 10.16-1.27 20.26 1.25 30.23 4.98 19.73 14.07 37.71 25.15 54.52 6.63 10.05 11.26 20.62 14.48 32.04 1.51 5.36 2.32 5.86 7.86 4.36 6.94-1.88 13.79-4.1 20.67-6.21 12.17-3.74 24.34-7.52 35.94-11.11zm265.05-54.59c-3.46-6.2-6.98-11.72-9.73-17.6-9.46-20.18-4.21-36.18 15.57-46.3 6.45-3.3 13.71-5.05 20.63-7.43 3.88-1.33 7.81-2.5 11.72-3.74-3.25-1.48-6.41-2.02-9.5-2.8-11.7-2.92-23.75-4.93-34.98-9.13-16.55-6.19-25.42-18.84-25.73-36.94-.16-9.73 2.56-18.76 6.39-27.57 1.74-4 3.24-8.11 5.26-13.23-3.27 2.05-5.42 3.46-7.62 4.77-7.11 4.24-13.98 8.99-21.42 12.58-19.72 9.5-38.29 5.49-52.49-11.15-4.47-5.23-8.15-11.13-12.33-16.91-4.01 1.74-8.35 3.63-12.9 5.61 7.09 14.48 16.55 26.54 30.26 34.76 15.95 9.57 32.7 9.59 50.24 2.79-2.22 29.1 9.78 49.09 36.23 59.96-.26.63-.29.94-.46 1.09-.9.83-1.84 1.61-2.75 2.43-8.16 7.33-13.65 16.18-14.99 27.22-2.33 19.23 6.48 34.69 17.05 50.05 4.05-2.98 7.85-5.76 11.54-8.48z"
          fill="#fff"
          strokeWidth={0}
        />
        <Path
          className="cls-1"
          d="M112.65 231.72c-11.6 3.59-23.76 7.36-35.94 11.11-6.88 2.11-13.73 4.33-20.67 6.21-5.53 1.5-6.34 1-7.86-4.36-3.22-11.42-7.85-21.99-14.48-32.04-11.08-16.81-20.17-34.79-25.15-54.52-2.52-9.98-3.73-20.07-1.25-30.23.76-3.13 1.68-6.56 3.62-8.98 2.98-3.71 2.48-7.37 1.81-11.42C8.49 81.62 4.29 55.75 0 29.43c1.9-.65 3.77-1.33 5.66-1.92C33.62 18.7 61.6 9.89 89.56 1.09 95.24-.7 95.44-.8 97.89 4.88c8.5 19.7 16.84 39.46 25.25 59.2.74 1.75 1.69 3.45 2.18 5.26 3.18 11.8 11.83 17.38 22.74 20.89 8.71 2.81 8.62 3.11 8.62 12.19v21.87h17.14V98.25c5.39 1.04 10.25 1.97 15.52 2.99v31.17h17.41v-28.89c5.59.36 10.66.69 16.02 1.04v32h17.24v-31.62c3.34-.22 6.27-.36 9.18-.62 13.12-1.17 26.27-2.08 39.35-3.65 16.13-1.94 30.32 4.84 37.12 19.8 4.18 9.19 6.63 19.17 9.84 28.8.64 1.93 1.21 3.89 1.81 5.84 1.91 6.18 1.62 6.93-4.74 8.92-18.66 5.83-37.35 11.55-56.04 17.3-27.12 8.34-54.25 16.63-81.35 25.06-2.79.87-5.03.76-7.7-.51-14.84-7.06-29.8-13.89-46.06-17.13-15.8-3.14-30.16 8.03-30.49 24.08-.12 6.03 1.06 12.09 1.72 18.89z"
        />
        <Path
          className="cls-1"
          d="M377.7 177.13c-3.69 2.71-7.49 5.5-11.54 8.48-10.57-15.37-19.38-30.82-17.05-50.05 1.34-11.04 6.83-19.89 14.99-27.22.91-.82 1.85-1.6 2.75-2.43.17-.15.2-.46.46-1.09-26.44-10.87-38.45-30.86-36.23-59.96-17.54 6.8-34.29 6.78-50.24-2.79-13.71-8.22-23.17-20.28-30.26-34.76 4.55-1.98 8.89-3.87 12.9-5.61 4.18 5.78 7.86 11.68 12.33 16.91 14.2 16.64 32.76 20.65 52.49 11.15 7.43-3.58 14.3-8.33 21.42-12.58 2.21-1.32 4.36-2.72 7.62-4.77-2.03 5.12-3.52 9.23-5.26 13.23-3.83 8.81-6.55 17.84-6.39 27.57.3 18.09 9.17 30.74 25.73 36.94 11.23 4.2 23.28 6.21 34.98 9.13 3.1.77 6.25 1.31 9.5 2.8-3.91 1.24-7.84 2.4-11.72 3.74-6.92 2.38-14.17 4.13-20.63 7.43-19.78 10.12-25.03 26.13-15.57 46.3 2.76 5.88 6.28 11.4 9.73 17.6z"
        />
      </G>
    </Icon>
  )
}

export default SvgComponent
