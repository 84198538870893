
import {
	Row,
	Text,
} from 'native-base';
import { useSelector, useDispatch } from 'react-redux';
import {
	setEnterpriseIdThunk,
	selectEnterpriseId,
} from '../../../models/Slices/AppSlice';
import EnterprisesCombo from '../../Form/Field/Combo/EnterprisesCombo';

export default function EnterpriseSelector(props) {
	const {
			isMinimized = false,
		} = props,
		dispatch = useDispatch(),
		enterprise_id = useSelector(selectEnterpriseId),
		onChangeEnterprise = (enterprise_id) => {
			dispatch(setEnterpriseIdThunk(enterprise_id));
		};

	return <Row
				alignItems="center"
				flex={1}
				ml={isMinimized ? 4 : 10}
				mr={isMinimized ? 0 : 2}
				maxWidth={isMinimized ? 180 : 250}
			>
				<EnterprisesCombo
					showXButton={true}
					value={enterprise_id}
					onChangeValue={onChangeEnterprise}
					placeholder="Enterprise"
					tooltip="Select Enterprise"
				/>
			</Row>;
}
