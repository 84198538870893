export default [
	    {
	        "id": "import_wo_other_charge_types__code",
	        "header": "Code",
	        "fieldName": "import_wo_other_charge_types__code",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_wo_other_charge_types__name",
	        "header": "Name",
	        "fieldName": "import_wo_other_charge_types__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_wo_other_charge_types__is_imported",
	        "header": "Imported?",
	        "fieldName": "import_wo_other_charge_types__is_imported",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_other_charge_types__imported_date",
	        "header": "Imported Date",
	        "fieldName": "import_wo_other_charge_types__imported_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    }
	];