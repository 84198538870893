import * as yup from 'yup'; // https://github.com/jquense/yup#string

const ImportServiceCenters = {
	
	name: 'ImportServiceCenters',
	
	model: {

		idProperty: 'import_service_centers__id',
		displayProperty: 'import_service_centers__name',
		sortProperty: 'import_service_centers__name',
		
		sorters: null,

		validator: yup.object({
			import_service_centers__name: yup.string().max(30).nullable(),
			import_service_centers__code: yup.string().max(6).nullable(),
			import_service_centers__is_imported: yup.boolean().nullable(),
			import_service_centers__imported_date: yup.date().nullable()
		}),
		
		properties: [
			{ name: 'import_service_centers__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'import_service_centers__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_service_centers__code', mapping: 'code', title: 'Code', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_service_centers__is_imported', mapping: 'is_imported', title: 'Imported?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'import_service_centers__imported_date', mapping: 'imported_date', title: 'Imported Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', }
		],

		associations: {

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'import_service_centers__is_imported',
			// 'import_service_centers__imported_date'
		],

	},



	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default ImportServiceCenters;
