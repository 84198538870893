export default [
	    {
	        "id": "equipment_pm_schedules__pm_schedule_id",
	        "header": "PM Schedule",
	        "fieldName": "pm_schedules__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "editField": "equipment_pm_schedules__pm_schedule_id",
	        "flex": 1,
	        "minWidth": 150
	    },
	    {
	        "id": "equipment_pm_schedules__pm_status_id",
	        "header": "PM Status",
	        "fieldName": "pm_statuses__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "equipment_pm_schedules__pm_status_id"
	    },
	    {
	        "id": "equipment_pm_schedules__next_pm_due",
	        "header": "Next Pm Due",
	        "fieldName": "equipment_pm_schedules__next_pm_due",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "equipment_pm_schedules__latest_pm_date",
	        "header": "Latest Pm Date",
	        "fieldName": "equipment_pm_schedules__latest_pm_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "equipment_pm_schedules__pm_technician_id",
	        "header": "PM Technician",
	        "fieldName": "users__username",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "equipment_pm_schedules__pm_technician_id"
	    },
	    {
	        "id": "equipment_pm_schedules__service_plan",
	        "header": "Service Plan",
	        "fieldName": "equipment_pm_schedules__service_plan",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "equipment_pm_schedules__comments",
	        "header": "Comments",
	        "fieldName": "equipment_pm_schedules__comments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "equipment_pm_schedules__is_triggered",
	        "header": "Triggered?",
	        "fieldName": "equipment_pm_schedules__is_triggered",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];