/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { useState, useEffect, useRef, } from 'react';
import {
	Column,
	Text,
} from 'native-base';
import { useSelector, useDispatch } from 'react-redux';
import {
	selectEnterpriseId,
	selectTreeSelection,
	setTreeSelection,
} from '../../models/Slices/AppSlice';
import oneHatData from '@onehat/data';
import withComponent from '@onehat/ui/src/Components/Hoc/withComponent.js';
import withAlert from '@onehat/ui/src/Components/Hoc/withAlert.js';
import Panel from '@onehat/ui/src/Components/Panel/Panel.js';
import Loading from '@onehat/ui/src/Components/Messages/Loading.js';
import Tree from '@onehat/ui/src/Components/Tree/Tree.js';
import Envelope from '@onehat/ui/src/Components/Icons/Envelope.js';
import EnterpriseSpecific from '../Layout/EnterpriseSpecific.js';
import HelpMessage from '../Layout/HelpMessage';
import getNodeIcon from '../../functions/getNodeIcon.js';
import getNodeText from '../../functions/getNodeText.js';
import getDisplayTextFromFleetTreeSearchResults from '../../functions/getDisplayTextFromFleetTreeSearchResults.js';

// The EquipmentLitesTree is a tree of both Fleets and Equipment, 
// optimized to only get the necessary information to display
// (not edit) the tree.

function EquipmentLitesTree(props) {

	const {
			self,
			alert,
		} = props,
		enterpriseId = useSelector(selectEnterpriseId),
		treeSelection = useSelector(selectTreeSelection),
		helpMessage = useSelector(selectTreeSelection),
		dispatch = useDispatch(),
		[isReady, setIsReady] = useState(false),
		needsBuild = useRef(false),
		getNeedsBuild = () => {
			return needsBuild.current;
		},
		setNeedsBuild = (bool) => {
			needsBuild.current = bool;
		},
		FleetLites = oneHatData.getRepository('FleetLites'),
		onChangeSelection = (selection) => {
			dispatch(setTreeSelection(selection));
		},
		clearTreeSelection = () => {
			dispatch(setTreeSelection([]));
		},
		onLayout = (e) => {
			if (getNeedsBuild()) {
				const tree = self.children.Tree;
				tree.buildAndSetTreeNodeData();
			}
		},
		onTreeLoad = () => {
			const rootNode = FleetLites.getRootNodes()[0];
			if (rootNode) {
				self.children.Tree.setSelection([rootNode]);
			}
		},
		onEmailBusinessPartner = () => {
			if (!treeSelection.length) {
				alert('No fleet selected');
				return;
			}
			if (treeSelection.length > 1) {
				alert('Please select only one equipment');
				return;
			}
			// debugger;
			// if (treeSelection[0] > 1) {
				alert('Not yet implemented. EquipmentLitesTree.js');
				return;
			// }
			const fleet = treeSelection[0];
			if (fleet) {
				const businessPartner = fleet.fleets__business_partner;
				if (businessPartner) {
					const email = businessPartner.email;
					if (email) {
						window.location.href = `mailto:${email}`;
					}
				}
			}
		};

	useEffect(() => {
		
		if (enterpriseId) {
			let needsLoad = false;
			if (FleetLites.getBaseParam('conditions[fleets__enterprise_id]') !== enterpriseId) {
				FleetLites.setBaseParam('conditions[fleets__enterprise_id]', enterpriseId);
				needsLoad = true;
			}
			if (FleetLites.getBaseParam('getEquipment') !== true) {
				FleetLites.setBaseParam('getEquipment', true);
				needsLoad = true;
			}
			if (FleetLites.timeout !== 35000) {
				FleetLites.timeout = 35000;
			}
			if (!FleetLites.areRootNodesLoaded) {
				needsLoad = true;
			}
			if (needsLoad) {
				FleetLites.loadRootNodes(1);
				setNeedsBuild(false);
			} else {
				setNeedsBuild(true);
			}
		}
	
		setIsReady(true);

	}, [enterpriseId]);
	
	if (!isReady) {
		return <Loading {...props} />;
	}

	return <Panel
				{...props}
				title="Equipment Tree"
				disableTitleChange={true}
			>
				<EnterpriseSpecific>
					<Tree
						parent={self}
						reference="Tree"
						disableWithData={true}
						Repository={FleetLites}
						defaultSelection={treeSelection}

						autoLoadRootNodes={false}
						getNodeIcon={getNodeIcon}
						getNodeText={getNodeText}
						onChangeSelection={onChangeSelection}
						onTreeLoad={onTreeLoad}
						getDisplayTextFromSearchResults={getDisplayTextFromFleetTreeSearchResults}
						onLayout={onLayout}
						additionalToolbarButtons={[
							{
								key: 'emailBusinessPartnerBtn',
								icon: Envelope,
								handler: onEmailBusinessPartner,
								tooltip: 'Email to business partner',
							},
						]}
					/>
				</EnterpriseSpecific>
				{/* <HelpMessage /> */}
			</Panel>;
}


function withAdditionalProps(WrappedComponent) {
	return (props) => {
		return <WrappedComponent
					reference="EquipmentLitesTree"
					{...props}
				/>;
	};
}

export default withAdditionalProps(withComponent(withAlert(EquipmentLitesTree)));