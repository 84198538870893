/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportServiceCentersEditorWindow from '../Window/ImportServiceCentersEditorWindow.js';
import ImportServiceCentersGridColumns from './Columns/ImportServiceCentersGridColumns.js';

export default function ImportServiceCentersGridEditor(props) {
	return <WindowedGridEditor
				reference="ImportServiceCentersGridEditor"
				model="ImportServiceCenters"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={ImportServiceCentersEditorWindow}
				columnsConfig={ImportServiceCentersGridColumns}
				
				
				{...props}
			/>;
}