/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportPmEquipmentEditor from '../Editor/ImportPmEquipmentEditor.js';
import ImportPmEquipmentGridColumns from './Columns/ImportPmEquipmentGridColumns.js';

export default function ImportPmEquipmentSideGridEditor(props) {
	return <SideGridEditor
				reference="ImportPmEquipmentSideGridEditor"
				model="ImportPmEquipment"
				usePermissions={true}
				isCollapsible={false}
				Editor={ImportPmEquipmentEditor}
				columnsConfig={ImportPmEquipmentGridColumns}
				
				
				{...props}
			/>;
}