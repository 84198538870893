/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportServiceCentersUtilProcessLogsEditorWindowSideA from '../Window/ImportServiceCentersUtilProcessLogsEditorWindowSideA.js';
import ImportServiceCentersUtilProcessLogsGridColumnsSideA from './Columns/ImportServiceCentersUtilProcessLogsGridColumnsSideA.js';

export default function ImportServiceCentersUtilProcessLogsGridEditorSideA(props) {
	return <WindowedGridEditor
				reference="ImportServiceCentersUtilProcessLogsGridEditorSideA"
				model="ImportServiceCentersUtilProcessLogs"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={ImportServiceCentersUtilProcessLogsEditorWindowSideA}
				columnsConfig={ImportServiceCentersUtilProcessLogsGridColumnsSideA}
				canColumnsReorder={false}
				canColumnsResize={false}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}