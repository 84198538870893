import * as yup from 'yup'; // https://github.com/jquense/yup#string

const WoLaborTypes = {
	
	name: 'WoLaborTypes',
	
	model: {

		idProperty: 'wo_labor_types__id',
		displayProperty: 'wo_labor_types__name',
		sortProperty: 'wo_labor_types__name',
		
		sorters: null,

		validator: yup.object({
			wo_labor_types__name: yup.string().required(),
			wo_labor_types__unit_price: yup.number().required(),
			wo_labor_types__code: yup.string().max(8).required()
		}),
		
		properties: [
			{ name: 'wo_labor_types__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'wo_labor_types__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'wo_labor_types__unit_price', mapping: 'unit_price', title: 'Unit Price', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'wo_labor_types__code', mapping: 'code', title: 'Code', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', }
		],

		associations: {
			hasMany: [
				'WoLabors'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'wo_labor_types__unit_price'
		],

	},

	entity: {
		methods: {

			getWoLabors: function() {
				const WoLabors = this.getAssociatedRepository('WoLabors');
				return WoLabors.getBy((entity) => {
					return entity.wo_labors__wo_labor_type_id === this.wo_labor_types__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default WoLaborTypes;
