export default [
	    {
	        "id": "eq_stats__equipment_id",
	        "header": "Equipment",
	        "fieldName": "equipment__unit_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__is_stale",
	        "header": "Stale?",
	        "fieldName": "eq_stats__is_stale",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__path",
	        "header": "Path",
	        "fieldName": "eq_stats__path",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "eq_stats__latest_meter_reading",
	        "header": "Latest Meter Reading",
	        "fieldName": "eq_stats__latest_meter_reading",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__latest_meter_date",
	        "header": "Latest Meter Date",
	        "fieldName": "eq_stats__latest_meter_date",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__latest_meter_source",
	        "header": "Latest Meter Source",
	        "fieldName": "eq_stats__latest_meter_source",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__latest_repair_date",
	        "header": "Latest Repair Date",
	        "fieldName": "eq_stats__latest_repair_date",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__latest_pm_data",
	        "header": "Latest Pm Data",
	        "fieldName": "eq_stats__latest_pm_data",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "eq_stats__due_pms",
	        "header": "Due Pms",
	        "fieldName": "eq_stats__due_pms",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__next_pm_due_data",
	        "header": "Next Pm Due Data",
	        "fieldName": "eq_stats__next_pm_due_data",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "eq_stats__overdue_pms",
	        "header": "Overdue Pms",
	        "fieldName": "eq_stats__overdue_pms",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "eq_stats__assigned_technician_data",
	        "header": "Assigned Technician Data",
	        "fieldName": "eq_stats__assigned_technician_data",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    }
	];