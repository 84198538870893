/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import WoServiceTypesEditor from '../../Editor/WoServiceTypesEditor.js';
import WoServiceTypesGridColumns from '../../Grid/Columns/WoServiceTypesGridColumns.js';

export default function WoServiceTypesSideGridEditorPanel(props) {
	return <GridPanel
				reference="WoServiceTypesSideGridEditorPanel"
				model="WoServiceTypes"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={WoServiceTypesEditor}
				columnsConfig={WoServiceTypesGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}