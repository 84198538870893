import * as yup from 'yup'; // https://github.com/jquense/yup#string
import json from '@onehat/ui/src/Functions/jsonValidator';

const UtilProcessLogs = {
	
	name: 'UtilProcessLogs',
	
	model: {

		idProperty: 'util_process_logs__id',
		displayProperty: 'util_process_logs__process',
		sortProperty: 'util_process_logs__datetime',
		sortDir: 'DESC',
		
		sorters: null,

		validator: yup.object({

		}),
		
		properties: [
			{ name: 'util_process_logs__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'util_process_logs__datetime', mapping: 'datetime', title: 'Datetime', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'util_process_logs__process', mapping: 'process', title: 'Process', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'util_process_logs__success', mapping: 'success', title: 'Success', type: 'bool', filterType: {"type":"Toggle"}, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'util_process_logs__count', mapping: 'count', title: 'Count', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'util_process_logs__time', mapping: 'time', title: 'Time', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'util_process_logs__json', mapping: 'json', title: 'Json', type: 'json', isFilteringDisabled: true, viewerType: {"type":"Json"}, isEditingDisabled: true, fieldGroup: 'General', }
		],

		associations: {
			hasMany: [
				'ImportManufacturersUtilProcessLogs',
				'ImportPmEquipmentUtilProcessLogs',
				'ImportServiceCentersUtilProcessLogs',
				'ImportTechniciansUtilProcessLogs',
				'ImportWoOtherChargeTypesUtilProcessLogs',
				'ImportWorkOrdersUtilProcessLogs',
				'ImportWoServiceTypesUtilProcessLogs'
			],
			belongsToMany: [
				'ImportManufacturers',
				'ImportPmEquipment',
				'ImportServiceCenters',
				'ImportTechnicians',
				'ImportWoOtherChargeTypes',
				'ImportWorkOrders'
			],

		},

		ancillaryFilters: {
		    "import_manufacturers": "ImportManufacturer",
		    "import_pm_equipment": "ImportPmEquipment",
		    "import_service_centers": "ImportServiceCenter",
		    "import_technicians": "ImportTechnician",
		    "import_wo_other_charge_types": "ImportWoOtherChargeType",
		    "import_work_orders": "ImportWorkOrder"
		},

		defaultFilters: [
			'util_process_logs__datetime',
			'util_process_logs__success',
			// 'util_process_logs__count'
		],

	},

	entity: {
		methods: {

			getImportManufacturersUtilProcessLogs: function() {
				const ImportManufacturersUtilProcessLogs = this.getAssociatedRepository('ImportManufacturersUtilProcessLogs');
				return ImportManufacturersUtilProcessLogs.getBy((entity) => {
					return entity.import_manufacturers_util_process_logs__util_process_log_id === this.util_process_logs__id;
				});
			},

			getImportPmEquipmentUtilProcessLogs: function() {
				const ImportPmEquipmentUtilProcessLogs = this.getAssociatedRepository('ImportPmEquipmentUtilProcessLogs');
				return ImportPmEquipmentUtilProcessLogs.getBy((entity) => {
					return entity.import_pm_equipment_util_process_logs__util_process_log_id === this.util_process_logs__id;
				});
			},

			getImportServiceCentersUtilProcessLogs: function() {
				const ImportServiceCentersUtilProcessLogs = this.getAssociatedRepository('ImportServiceCentersUtilProcessLogs');
				return ImportServiceCentersUtilProcessLogs.getBy((entity) => {
					return entity.import_service_centers_util_process_logs__util_process_log_id === this.util_process_logs__id;
				});
			},

			getImportTechniciansUtilProcessLogs: function() {
				const ImportTechniciansUtilProcessLogs = this.getAssociatedRepository('ImportTechniciansUtilProcessLogs');
				return ImportTechniciansUtilProcessLogs.getBy((entity) => {
					return entity.import_technicians_util_process_logs__util_process_log_id === this.util_process_logs__id;
				});
			},

			getImportWoOtherChargeTypesUtilProcessLogs: function() {
				const ImportWoOtherChargeTypesUtilProcessLogs = this.getAssociatedRepository('ImportWoOtherChargeTypesUtilProcessLogs');
				return ImportWoOtherChargeTypesUtilProcessLogs.getBy((entity) => {
					return entity.import_wo_other_charge_types_util_process_logs__util_process_log_id === this.util_process_logs__id;
				});
			},

			getImportWorkOrdersUtilProcessLogs: function() {
				const ImportWorkOrdersUtilProcessLogs = this.getAssociatedRepository('ImportWorkOrdersUtilProcessLogs');
				return ImportWorkOrdersUtilProcessLogs.getBy((entity) => {
					return entity.import_work_orders_util_process_logs__util_process_log_id === this.util_process_logs__id;
				});
			},

			getImportWoServiceTypesUtilProcessLogs: function() {
				const ImportWoServiceTypesUtilProcessLogs = this.getAssociatedRepository('ImportWoServiceTypesUtilProcessLogs');
				return ImportWoServiceTypesUtilProcessLogs.getBy((entity) => {
					return entity.import_wo_service_types_util_process_logs__util_process_log_id === this.util_process_logs__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": true,
	    "isAutoLoad": false
	},

};

export default UtilProcessLogs;
