/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportWoOtherChargeTypesInlineGridEditorPanel from './ImportWoOtherChargeTypesInlineGridEditor.js';

export default function ImportWoOtherChargeTypesFilteredInlineGridEditorPanel(props) {
	return <ImportWoOtherChargeTypesInlineGridEditorPanel
				reference="ImportWoOtherChargeTypesFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}