/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function ImportWorkOrdersEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "General",
		                "reference": "general",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "import_work_orders__woid"
		                    },
		                    {
		                        "name": "import_work_orders__business_partner"
		                    },
		                    {
		                        "name": "import_work_orders__service_order"
		                    },
		                    {
		                        "name": "import_work_orders__segment"
		                    },
		                    {
		                        "name": "import_work_orders__service_center"
		                    },
		                    {
		                        "name": "import_work_orders__po_number"
		                    },
		                    {
		                        "name": "import_work_orders__service_type"
		                    },
		                    {
		                        "name": "import_work_orders__open_date"
		                    },
		                    {
		                        "name": "import_work_orders__invoice_date"
		                    },
		                    {
		                        "name": "import_work_orders__wo_class"
		                    },
		                    {
		                        "name": "import_work_orders__data_source"
		                    },
		                    {
		                        "name": "import_work_orders__segment_type"
		                    },
		                    {
		                        "name": "import_work_orders__manufacturer"
		                    },
		                    {
		                        "name": "import_work_orders__job_code"
		                    },
		                    {
		                        "name": "import_work_orders__model"
		                    },
		                    {
		                        "name": "import_work_orders__serial_number"
		                    },
		                    {
		                        "name": "import_work_orders__unit_number"
		                    },
		                    {
		                        "name": "import_work_orders__asset_number"
		                    },
		                    {
		                        "name": "import_work_orders__meter_reading"
		                    },
		                    {
		                        "name": "import_work_orders__complaint"
		                    },
		                    {
		                        "name": "import_work_orders__cause"
		                    },
		                    {
		                        "name": "import_work_orders__correction"
		                    },
		                    {
		                        "name": "import_work_orders__coverage"
		                    },
		                    {
		                        "name": "import_work_orders__comments"
		                    },
		                    {
		                        "name": "import_work_orders__total_parts"
		                    },
		                    {
		                        "name": "import_work_orders__total_labor"
		                    },
		                    {
		                        "name": "import_work_orders__total_other"
		                    },
		                    {
		                        "name": "import_work_orders__tax"
		                    },
		                    {
		                        "name": "import_work_orders__total"
		                    },
		                    {
		                        "name": "import_work_orders__sold_to_customer"
		                    },
		                    {
		                        "name": "import_work_orders__sold_to_name"
		                    },
		                    {
		                        "name": "import_work_orders__sold_to_address"
		                    },
		                    {
		                        "name": "import_work_orders__sold_to_city"
		                    },
		                    {
		                        "name": "import_work_orders__sold_to_state_abbr"
		                    },
		                    {
		                        "name": "import_work_orders__sold_to_zip"
		                    },
		                    {
		                        "name": "import_work_orders__bill_to_customer"
		                    },
		                    {
		                        "name": "import_work_orders__bill_to_name"
		                    },
		                    {
		                        "name": "import_work_orders__ship_to_customer"
		                    },
		                    {
		                        "name": "import_work_orders__ship_to_name"
		                    },
		                    {
		                        "name": "import_work_orders__ship_to_address"
		                    },
		                    {
		                        "name": "import_work_orders__ship_to_city"
		                    },
		                    {
		                        "name": "import_work_orders__ship_to_state_abbr"
		                    },
		                    {
		                        "name": "import_work_orders__ship_to_zip"
		                    },
		                    {
		                        "name": "import_work_orders__is_imported"
		                    },
		                    {
		                        "name": "import_work_orders__imported_date"
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="ImportWorkOrdersEditor"
				title="ImportWorkOrders"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

