import * as yup from 'yup'; // https://github.com/jquense/yup#string

const PartsWorkOrders = {
	
	name: 'PartsWorkOrders',
	
	model: {

		idProperty: 'parts_work_orders__id',
		displayProperty: 'parts_work_orders__id',
		sortProperty: 'parts_work_orders__id',
		
		sorters: null,

		validator: yup.object({
			parts_work_orders__part_id: yup.number().integer().nullable(),
			parts_work_orders__work_order_id: yup.number().integer().required(),
			parts_work_orders__qty_ordered: yup.number().nullable(),
			parts_work_orders__qty: yup.number().nullable(),
			parts_work_orders__qty_shipped: yup.number().nullable(),
			parts_work_orders__unit_cost: yup.number().nullable(),
			parts_work_orders__unit_price: yup.number().nullable(),
			parts_work_orders__ext_cost: yup.number().nullable(),
			parts_work_orders__ext_price: yup.number().nullable()
		}),
		
		properties: [
			{ name: 'parts_work_orders__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'parts_work_orders__part_id', mapping: 'part_id', title: 'Part', type: 'int', isFk: true, fkIdField: 'parts__id', fkDisplayField: 'parts__part_number', filterType: {"type":"PartsCombo","loadAfterRender":!1}, editorType: {"type":"PartsComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'parts_work_orders__work_order_id', mapping: 'work_order_id', title: 'Work Order', type: 'int', isFk: true, fkIdField: 'work_orders__id', fkDisplayField: 'work_orders__service_order', filterType: {"type":"WorkOrdersCombo","loadAfterRender":!1}, editorType: {"type":"WorkOrdersComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'parts_work_orders__qty_ordered', mapping: 'qty_ordered', title: 'Qty Ordered', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', defaultValue: 1, },
			{ name: 'parts_work_orders__qty', mapping: 'qty', title: 'Qty', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', defaultValue: 1, },
			{ name: 'parts_work_orders__qty_shipped', mapping: 'qty_shipped', title: 'Qty Shipped', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'parts_work_orders__unit_cost', mapping: 'unit_cost', title: 'Unit Cost', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'parts_work_orders__unit_price', mapping: 'unit_price', title: 'Unit Price', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'parts_work_orders__ext_cost', mapping: 'ext_cost', title: 'Ext Cost', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'parts_work_orders__ext_price', mapping: 'ext_price', title: 'Ext Price', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'parts__id', mapping: 'part.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__part_number', mapping: 'part.part_number', title: 'Part Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__description', mapping: 'part.description', title: 'Description', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__manufacturer_id', mapping: 'part.manufacturer_id', title: 'Manufacturer', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__unit_cost', mapping: 'part.unit_cost', title: 'Unit Cost', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__unit_price', mapping: 'part.unit_price', title: 'Unit Price', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__id', mapping: 'work_order.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__enterprise_id', mapping: 'work_order.enterprise_id', title: 'Enterprise', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__equipment_id', mapping: 'work_order.equipment_id', title: 'Equipment', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__business_partner_id', mapping: 'work_order.business_partner_id', title: 'Business Partner', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__service_order', mapping: 'work_order.service_order', title: 'Service Order', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__segment', mapping: 'work_order.segment', title: 'Segment', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__invoice_number', mapping: 'work_order.invoice_number', title: 'Invoice Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__segment_type', mapping: 'work_order.segment_type', title: 'Segment Type', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__wo_number', mapping: 'work_order.wo_number', title: 'Work Order Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__po_number', mapping: 'work_order.po_number', title: 'PO Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__service_center_id', mapping: 'work_order.service_center_id', title: 'Service Center', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__open_date', mapping: 'work_order.open_date', title: 'Open Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__wo_service_type_id', mapping: 'work_order.wo_service_type_id', title: 'Wo Service Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__close_date', mapping: 'work_order.close_date', title: 'Close Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__invoice_date', mapping: 'work_order.invoice_date', title: 'Invoice Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__complete_date', mapping: 'work_order.complete_date', title: 'Complete Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__time_out_of_service', mapping: 'work_order.time_out_of_service', title: 'Time Out Of Service', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__wo_mode_id', mapping: 'work_order.wo_mode_id', title: 'Mode', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__wo_class_id', mapping: 'work_order.wo_class_id', title: 'Class', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__pm_schedule_id', mapping: 'work_order.pm_schedule_id', title: 'PM Schedule', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__complaint', mapping: 'work_order.complaint', title: 'Complaint', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__cause', mapping: 'work_order.cause', title: 'Cause', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__correction', mapping: 'work_order.correction', title: 'Correction', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__coverage', mapping: 'work_order.coverage', title: 'Coverage', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__comments', mapping: 'work_order.comments', title: 'Comments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__meter_reading', mapping: 'work_order.meter_reading', title: 'Meter Reading', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__original_destination', mapping: 'work_order.original_destination', title: 'Original Destination', type: 'json', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__total_parts', mapping: 'work_order.total_parts', title: 'Total Parts', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__total_labor', mapping: 'work_order.total_labor', title: 'Total Labor', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__total_other', mapping: 'work_order.total_other', title: 'Total Other', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__tax', mapping: 'work_order.tax', title: 'Tax', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__total', mapping: 'work_order.total', title: 'Total', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__is_ltm', mapping: 'work_order.is_ltm', title: 'Ltm?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__is_scheduled', mapping: 'work_order.is_scheduled', title: 'Scheduled?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__is_verified', mapping: 'work_order.is_verified', title: 'Verified?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__repaired_by_branch', mapping: 'work_order.repaired_by_branch', title: 'Repaired By Branch', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__repaired_by_company', mapping: 'work_order.repaired_by_company', title: 'Repaired By Company', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__sold_to_business_partner_id', mapping: 'work_order.sold_to_business_partner_id', title: 'Sold To Business Partner', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__sold_to_name', mapping: 'work_order.sold_to_name', title: 'Sold To Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__sold_to_address', mapping: 'work_order.sold_to_address', title: 'Sold To Address', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__sold_to_city', mapping: 'work_order.sold_to_city', title: 'Sold To City', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__sold_to_state_abbr', mapping: 'work_order.sold_to_state_abbr', title: 'Sold To State Abbr', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__sold_to_zip', mapping: 'work_order.sold_to_zip', title: 'Sold To Zip', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__bill_to_business_partner_id', mapping: 'work_order.bill_to_business_partner_id', title: 'Bill To Business Partner', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__bill_to_name', mapping: 'work_order.bill_to_name', title: 'Bill To Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_business_partner_id', mapping: 'work_order.ship_to_business_partner_id', title: 'Ship To Business Partner', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_name', mapping: 'work_order.ship_to_name', title: 'Ship To Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_address', mapping: 'work_order.ship_to_address', title: 'Ship To Address', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_city', mapping: 'work_order.ship_to_city', title: 'Ship To City', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_state_abbr', mapping: 'work_order.ship_to_state_abbr', title: 'Ship To State', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_zip', mapping: 'work_order.ship_to_zip', title: 'Ship To Zip', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Parts',
				'WorkOrders'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'parts_work_orders__part_id',
			// 'parts_work_orders__work_order_id',
			// 'parts_work_orders__qty_ordered',
			// 'parts_work_orders__qty',
			// 'parts_work_orders__qty_shipped',
			// 'parts_work_orders__unit_cost',
			// 'parts_work_orders__unit_price',
			// 'parts_work_orders__ext_cost',
			// 'parts_work_orders__ext_price'
		],

	},

	entity: {
		methods: {

			getPart: async function() {
				const Parts = this.getAssociatedRepository('Parts');
				let entity = Parts.getById(this.parts_work_orders__part_id);
				if (!entity) {
					entity = await Parts.getSingleEntityFromServer(this.parts_work_orders__part_id);
				}
				return entity;
			},

			getWorkOrder: async function() {
				const WorkOrders = this.getAssociatedRepository('WorkOrders');
				let entity = WorkOrders.getById(this.parts_work_orders__work_order_id);
				if (!entity) {
					entity = await WorkOrders.getSingleEntityFromServer(this.parts_work_orders__work_order_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": true,
	    "isAutoLoad": false
	},

};

export default PartsWorkOrders;
