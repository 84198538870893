/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';


export default function ImportWoOtherChargeTypesUtilProcessLogsEditorSideA(props) {
	const {
			selection,
		} = props,
		record = selection[0],
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "Import Log", // MOD
		                "reference": "general",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "import_wo_other_charge_types_util_process_logs__util_process_log_id"
		                    },
		                    {
		                        "name": "import_wo_other_charge_types_util_process_logs__datetime"
		                    },
		                    {
		                        "name": "import_wo_other_charge_types_util_process_logs__success"
		                    },
		                    {
		                        "name": "import_wo_other_charge_types_util_process_logs__status",
								type: 'ImportStatusesViewer',
								id: record.import_wo_other_charge_types_util_process_logs__status,
		                    },
		                    {
		                        "name": "import_wo_other_charge_types_util_process_logs__json"
		                    },
		                    // {
		                    //     "name": "import_wo_other_charge_types_util_process_logs__import_wo_other_charge_type_id"
		                    // }
		                ]
		            },
		            { // added these
		                "type": "FieldSet",
		                "title": "Import Details",
		                "reference": "general",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "import_wo_other_charge_types__code"
		                    },
		                    {
		                        "name": "import_wo_other_charge_types__name"
		                    },
		                    // {
		                    //     "name": "import_wo_other_charge_types__is_imported"
		                    // },
		                    // {
		                    //     "name": "import_wo_other_charge_types__imported_date"
		                    // }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="ImportWoOtherChargeTypesUtilProcessLogsEditorSideA"
				title="ImportWoOtherChargeTypesUtilProcessLogs"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

