/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportManufacturersUtilProcessLogsInlineGridEditorPanel from './ImportManufacturersUtilProcessLogsInlineGridEditor.js';

export default function ImportManufacturersUtilProcessLogsFilteredInlineGridEditorPanel(props) {
	return <ImportManufacturersUtilProcessLogsInlineGridEditorPanel
				reference="ImportManufacturersUtilProcessLogsFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}