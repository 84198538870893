/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportManufacturersGridPanel from './ImportManufacturersGrid.js';

export default function ImportManufacturersFilteredGridPanel(props) {
	return <ImportManufacturersGridPanel
				reference="ImportManufacturersFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}