/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportTechniciansEditorWindow from '../Window/ImportTechniciansEditorWindow.js';
import ImportTechniciansGridColumns from './Columns/ImportTechniciansGridColumns.js';

export default function ImportTechniciansGridEditor(props) {
	return <WindowedGridEditor
				reference="ImportTechniciansGridEditor"
				model="ImportTechnicians"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={ImportTechniciansEditorWindow}
				columnsConfig={ImportTechniciansGridColumns}
				
				
				{...props}
			/>;
}