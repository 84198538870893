/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import WorkOrdersEditor from '../Editor/WorkOrdersEditor.js';
import WorkOrdersGridColumns from './Columns/WorkOrdersGridColumns.js';
import { useSelector } from 'react-redux';
import {
	selectEnterpriseId,
	selectTreeSelection,
} from '../../models/Slices/AppSlice';
import _ from 'lodash';

export default function WorkOrdersSideGridEditor(props) {
	const
		enterpriseId = useSelector(selectEnterpriseId),
		treeSelection = useSelector(selectTreeSelection),
		fleetId = treeSelection[0].id;

	return <SideGridEditor
				reference="WorkOrdersSideGridEditor"
				model="WorkOrders"
				usePermissions={true}
				isCollapsible={false}
				Editor={WorkOrdersEditor}
				columnsConfig={WorkOrdersGridColumns}
				
				selectorId="equipment__fleet_id"
				selectorSelected={treeSelection[0]}
				defaultValues={{ 
					equipment__enterprise_id: enterpriseId,
					equipment__fleet_id: fleetId,
				}}
				
				{...props}
			/>;
}