import {
	Column,
	Text,
} from 'native-base';
import {
	PM_EVENT_TYPES__DELAY_BY_DAYS,
} from '../../constants/PmEventTypes.js';
import {
	PM_STATUSES__OK,
	PM_STATUSES__PM_DUE,
	PM_STATUSES__DELAYED,
	PM_STATUSES__WILL_CALL,
	PM_STATUSES__SCHEDULED,
	PM_STATUSES__OVERDUE,
	PM_STATUSES__COMPLETED,
} from '../../constants/PmStatuses.js';
import {
	WO_CLASSES__PM,
} from '../../constants/WoClasses.js';
import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import oneHatData from '@onehat/data';
import IconButton from '@onehat/ui/src/Components/Buttons/IconButton.js';
import UiGlobals from '@onehat/ui/src/UiGlobals.js';
import Clipboard from '@onehat/ui/src/Components/Icons/Clipboard.js';
import Bump from '../Icons/Bump.js';
import withAlert from '@onehat/ui/src/Components/Hoc/withAlert.js';
import withComponent from '@onehat/ui/src/Components/Hoc/withComponent.js';
import withData from '@onehat/ui/src/Components/Hoc/withData.js';
import withSelection from '@onehat/ui/src/Components/Hoc/withSelection.js';
import withWindowedEditor from '@onehat/ui/src/Components/Hoc/withWindowedEditor.js';
import BumpPmsEditorWindow from '../Window/BumpPmsEditorWindow.js';
import WorkOrdersEditorWindow from '../Window/WorkOrdersEditorWindow.js';
import AppGlobals from '../../AppGlobals.js';
import _ from 'lodash';

// NOTE: This is based on the EquipmentPmSchedulesGrid, but uses the modded
// schema of UpcomingPms, so that we can keep these two repositories distinct.
// This one will always sort by next_pm_due


function UpcomingPmsGrid(props) {
	const {
			onAdd: onAddBumper,
			onAddWorkOrder,
		} = props,
		styles = UiGlobals.styles,
		onBump = async (upcomingPm) => {
			onAddBumper(null, {
				pm_events__equipment_id: upcomingPm.equipment_pm_schedules__equipment_id,
				pm_events__pm_schedule_id: upcomingPm.equipment_pm_schedules__pm_schedule_id,
				pm_events__pm_event_type_id: PM_EVENT_TYPES__DELAY_BY_DAYS,
				pm_events__interval: 30,
			});
		},
		onNewWorkOrder = async (upcomingPm) => {
			onAddWorkOrder(null, {
				work_orders__wo_class_id: WO_CLASSES__PM,
				work_orders__equipment: JSON.stringify([{ // to add one single equipment among many
					id: upcomingPm.equipment_pm_schedules__equipment_id,
					text: upcomingPm.equipment__nickname,
				}]),
				work_orders__pm_schedule_id: upcomingPm.equipment_pm_schedules__pm_schedule_id,
			});
		},
		textProps = {};
	if (styles.MANAGER_SCREEN_TITLE) {
		textProps.style = {
			fontFamily: styles.MANAGER_SCREEN_TITLE,
		};
	}
	return <Column flex={1} w="100%">
				<Text p={4} fontSize="26" fontWeight={700} {...textProps}>Upcoming PMs</Text>
				<Grid
					reference="upcomingPmsGrid"
					model="UpcomingPms"
					useFilters={true}
					searchAllText={false}
					forceLoadOnRender={true}

					baseParams={{
						forUpcomingPms: true
					}}
					showClearFiltersButton={false}
					customFilters={[
						{
							id: 'showThru',
							title: 'Show Thru',
							tooltip: 'This is the latest date to display',
							field: 'showTrue',
							type: 'Date',
							value: AppGlobals.dates.oneMonthFromNow,
							getRepoFilters: (value) => {
								return [
									{
										name: 'nextPmDue',
										value,
									},
								];
							},
						},
						{
							id: 'showOverdue',
							title: 'Overdue?',
							tooltip: 'Should we include overdue PMs?',
							field: 'showOverdue',
							type: 'Toggle',
							value: true,
							getRepoFilters: (value) => {
								return [
									{
										name: 'showOverdue',
										value,
									},
								];
							},
						},
						{
							id: 'showDue',
							title: 'Due?',
							tooltip: 'Should we include due PMs?',
							field: 'showDue',
							type: 'Toggle',
							value: true,
							getRepoFilters: (value) => {
								return [
									{
										name: 'showDue',
										value,
									},
								];
							},
						},
						{
							id: 'showOk',
							title: 'OK?',
							tooltip: 'Should we include OK PMs?',
							field: 'showOk',
							type: 'Toggle',
							value: false,
							getRepoFilters: (value) => {
								return [
									{
										name: 'showOk',
										value,
									},
								];
							},
						},
						{
							id: 'showWillCall',
							title: 'Will Call?',
							tooltip: 'Should we include "will call" PMs?',
							field: 'showWillCall',
							type: 'Toggle',
							value: false,
							getRepoFilters: (value) => {
								return [
									{
										name: 'showWillCall',
										value,
									},
								];
							},
						},
					]}
					columnsConfig={[
						{
							id: 'bump',
							header: 'Bump',
							w: 60,
							isSortable: false,
							isEditable: false,
							isReorderable: false,
							isResizable: false,
							isHidable: false,
							renderer: (item) => {
								return <IconButton
											icon={Bump}
											_icon={{
												size: 'xl',
											}}
											onPress={() => onBump(item)}
											toolTip="Bump"
										/>;
							},
						},
						{
							id: 'wo',
							header: '+WO',
							w: 60,
							isSortable: false,
							isEditable: false,
							isReorderable: false,
							isResizable: false,
							isHidable: false,
							renderer: (item) => {
								return <IconButton
											icon={Clipboard}
											_icon={{
												size: 'xl',
											}}
											onPress={() => onNewWorkOrder(item)}
											toolTip="Create a new work order"
										/>;
							},
						},
						{
							id: 'equipment_pm_schedules__next_pm_due',
							"header": "Next PM Due",
							"fieldName": "equipment_pm_schedules__next_pm_due",
							"isSortable": false,
							"isEditable": false,
							"isReorderable": false,
							"isResizable": true,
							"w": 140 // MOD
						},
						{
							id: 'pm_statuses__name',
							"header": "PM Status",
							"fieldName": "pm_statuses__name",
							"isSortable": false,
							"isEditable": false,
							"isReorderable": false,
							"isResizable": true,
							"w": 100
						},
						{
							id: 'equipment_pm_schedules__latest_pm_date',
							"header": "Last PM",
							"fieldName": "equipment_pm_schedules__latest_pm_date",
							"isSortable": false,
							"isEditable": false,
							"isReorderable": false,
							"isResizable": true,
							"w": 140 // MOD
						},
						{
							id: 'equipment__nickname',
							"header": "Equipment",
							"fieldName": "equipment__nickname",
							"isSortable": false,
							"isEditable": false,
							"isReorderable": false,
							"isResizable": true,
							"w": 200 // MOD
						},
						{
							id: 'pm_schedules__name',
							"header": "Pm Schedule",
							"fieldName": "pm_schedules__name",
							"isSortable": false,
							"isEditable": false,
							"isReorderable": false,
							"isResizable": true,
							"w": 350 // MOD
						},
					]}
					getRowProps={(item) => {
						const props = {
							borderBottomWidth: 1,
							borderBottomColor: 'trueGray.500',
						};
						if (item.equipment_pm_schedules__pm_status_id === PM_STATUSES__PM_DUE) {
							props.bg = '#f9eabb';
						}
						if (item.equipment_pm_schedules__pm_status_id === PM_STATUSES__DELAYED) {
							props.bg = '#d4fed2';
						}
						if (item.equipment_pm_schedules__pm_status_id === PM_STATUSES__WILL_CALL) {
							props.bg = '#cdf1ff';
						}
						if (item.equipment_pm_schedules__pm_status_id === PM_STATUSES__OVERDUE) {
							props.bg = '#ffd1d1';
						}
						return props;
					}}

				/>
			</Column>;
}


function withBumper(WrappedComponent) {
	const Component = withAlert(withData(withSelection(withWindowedEditor(WrappedComponent))));
	return (props) => {
		const {
				onAdd,
			} = props,
			UpcomingPms = oneHatData.getRepository('UpcomingPms'),
			onAddPmEvent = () => {
				UpcomingPms.reload();
			};
			
		return <Component
					reference="bumper"
					Editor={BumpPmsEditorWindow}
					model="PmEvents"
					onAdd={onAddPmEvent}
					onAddWorkOrder={onAdd}
				/>;
	};
}

function withWorkOrdersAdder(WrappedComponent) {
	const Component = withAlert(withData(withSelection(withWindowedEditor(WrappedComponent))));
	return (props) => {
		const
			UpcomingPms = oneHatData.getRepository('UpcomingPms'),
			onAddWorkOrder = () => {
				UpcomingPms.reload();
			};

		return <Component
					reference="workOrdersAdder"
					Editor={WorkOrdersEditorWindow}
					model="WorkOrders"
					onAdd={onAddWorkOrder}
				/>;
	};
}

function withReference(WrappedComponent) {
	return (props) => {
		return <WrappedComponent
					reference="UpcomingPmsGrid"
					{...props}
				/>;
	};
}

export default withReference(withComponent(withWorkOrdersAdder(withBumper(UpcomingPmsGrid))));
