import * as yup from 'yup'; // https://github.com/jquense/yup#string

const ImportTechniciansUtilProcessLogs = {
	
	name: 'ImportTechniciansUtilProcessLogs',
	
	model: {

		idProperty: 'import_technicians_util_process_logs__id',
		displayProperty: 'import_technicians_util_process_logs__id',
		sortProperty: 'import_technicians_util_process_logs__datetime',
		sortDir: 'DESC',
		
		sorters: null,

		validator: yup.object({
			import_technicians_util_process_logs__util_process_log_id: yup.number().integer().required(),
			import_technicians_util_process_logs__datetime: yup.date().nullable(),
			import_technicians_util_process_logs__success: yup.boolean().required(),
			import_technicians_util_process_logs__status: yup.number().integer().required(),
			import_technicians_util_process_logs__json: yup.string().nullable(),
			import_technicians_util_process_logs__import_technician_id: yup.number().integer().nullable(),
			import_technicians_util_process_logs__pm_technician_id: yup.number().integer().nullable()
		}),
		
		properties: [
			{ name: 'import_technicians_util_process_logs__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'import_technicians_util_process_logs__util_process_log_id', mapping: 'util_process_log_id', title: 'Util Process Log', type: 'int', isFk: true, fkIdField: 'util_process_logs__id', fkDisplayField: 'util_process_logs__process', filterType: {"type":"UtilProcessLogsCombo","loadAfterRender":!1}, editorType: {"type":"UtilProcessLogsCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'import_technicians_util_process_logs__datetime', mapping: 'datetime', title: 'Datetime', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', },
			{ name: 'import_technicians_util_process_logs__success', mapping: 'success', title: 'Success', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'import_technicians_util_process_logs__status', mapping: 'status', title: 'Status', type: 'int', filterType: {"type":"ImportStatusesCombo"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_technicians_util_process_logs__json', mapping: 'json', title: 'Json', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'import_technicians_util_process_logs__import_technician_id', mapping: 'import_technician_id', title: 'Import Technician', type: 'int', isFk: true, fkIdField: 'import_technicians__id', fkDisplayField: 'import_technicians__name', filterType: {"type":"ImportTechniciansCombo","loadAfterRender":!1}, editorType: {"type":"ImportTechniciansComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'import_technicians_util_process_logs__pm_technician_id', mapping: 'pm_technician_id', title: 'PM Technician', type: 'int', isFk: true, fkIdField: 'users__id', fkDisplayField: 'users__username', filterType: {"type":"UsersCombo","loadAfterRender":!1}, editorType: {"type":"UsersComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'import_technicians__id', mapping: 'import_technician.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_technicians__name', mapping: 'import_technician.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_technicians__employee_number', mapping: 'import_technician.employee_number', title: 'Employee Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_technicians__service_center', mapping: 'import_technician.service_center', title: 'Service Center', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_technicians__is_imported', mapping: 'import_technician.is_imported', title: 'Imported?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_technicians__imported_date', mapping: 'import_technician.imported_date', title: 'Imported Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__id', mapping: 'user.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__username', mapping: 'user.username', title: 'Username', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__email', mapping: 'user.email', title: 'Email', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password', mapping: 'user.password', title: 'Password', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__first_name', mapping: 'user.first_name', title: 'First Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_name', mapping: 'user.last_name', title: 'Last Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__employee_number', mapping: 'user.employee_number', title: 'Employee Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token', mapping: 'user.token', title: 'Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token_generated', mapping: 'user.token_generated', title: 'Token Generated', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__expo_token', mapping: 'user.expo_token', title: 'Expo Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password_reset', mapping: 'user.password_reset', title: 'Password Reset', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__full_name', mapping: 'user.full_name', title: 'Full Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_login', mapping: 'user.last_login', title: 'Last Login', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__login_count', mapping: 'user.login_count', title: 'Login Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__groups', mapping: 'user.groups', title: 'Groups', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__id', mapping: 'util_process_log.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__datetime', mapping: 'util_process_log.datetime', title: 'Datetime', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__process', mapping: 'util_process_log.process', title: 'Process', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__success', mapping: 'util_process_log.success', title: 'Success', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__count', mapping: 'util_process_log.count', title: 'Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__time', mapping: 'util_process_log.time', title: 'Time', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__json', mapping: 'util_process_log.json', title: 'Json', type: 'json', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'ImportTechnicians',
				'Users',
				'UtilProcessLogs'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'import_technicians_util_process_logs__util_process_log_id',
			// 'import_technicians_util_process_logs__datetime',
			'import_technicians_util_process_logs__success',
			'import_technicians_util_process_logs__status',
			// 'import_technicians_util_process_logs__import_technician_id',
			// 'import_technicians_util_process_logs__pm_technician_id'
		],

	},

	entity: {
		methods: {

			getImportTechnician: async function() {
				const ImportTechnicians = this.getAssociatedRepository('ImportTechnicians');
				let entity = ImportTechnicians.getById(this.import_technicians_util_process_logs__import_technician_id);
				if (!entity) {
					entity = await ImportTechnicians.getSingleEntityFromServer(this.import_technicians_util_process_logs__import_technician_id);
				}
				return entity;
			},

			getUser: async function() {
				const Users = this.getAssociatedRepository('Users');
				let entity = Users.getById(this.import_technicians_util_process_logs__pm_technician_id);
				if (!entity) {
					entity = await Users.getSingleEntityFromServer(this.import_technicians_util_process_logs__pm_technician_id);
				}
				return entity;
			},

			getUtilProcessLog: async function() {
				const UtilProcessLogs = this.getAssociatedRepository('UtilProcessLogs');
				let entity = UtilProcessLogs.getById(this.import_technicians_util_process_logs__util_process_log_id);
				if (!entity) {
					entity = await UtilProcessLogs.getSingleEntityFromServer(this.import_technicians_util_process_logs__util_process_log_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default ImportTechniciansUtilProcessLogs;
