/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import ImportWoOtherChargesEditor from '../../Editor/ImportWoOtherChargesEditor.js';
import ImportWoOtherChargesGridColumns from '../../Grid/Columns/ImportWoOtherChargesGridColumns.js';

export default function ImportWoOtherChargesSideGridEditorPanel(props) {
	return <GridPanel
				reference="ImportWoOtherChargesSideGridEditorPanel"
				model="ImportWoOtherCharges"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={ImportWoOtherChargesEditor}
				columnsConfig={ImportWoOtherChargesGridColumns}
				
				
				{...props}
			/>;
}