/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportWoOtherChargeTypesUtilProcessLogsEditor from '../Editor/ImportWoOtherChargeTypesUtilProcessLogsEditor.js';
import ImportWoOtherChargeTypesUtilProcessLogsGridColumns from './Columns/ImportWoOtherChargeTypesUtilProcessLogsGridColumns.js';

export default function ImportWoOtherChargeTypesUtilProcessLogsSideGridEditor(props) {
	return <SideGridEditor
				reference="ImportWoOtherChargeTypesUtilProcessLogsSideGridEditor"
				model="ImportWoOtherChargeTypesUtilProcessLogs"
				usePermissions={true}
				isCollapsible={false}
				Editor={ImportWoOtherChargeTypesUtilProcessLogsEditor}
				columnsConfig={ImportWoOtherChargeTypesUtilProcessLogsGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}