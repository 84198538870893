/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportPmEquipmentUtilProcessLogsGridColumnsSideA from './Columns/ImportPmEquipmentUtilProcessLogsGridColumnsSideA.js';

export default function ImportPmEquipmentUtilProcessLogsGridSideA(props) {
	return <Grid
				reference="ImportPmEquipmentUtilProcessLogsGridSideA"
				model="ImportPmEquipmentUtilProcessLogs"
				usePermissions={true}
				columnsConfig={ImportPmEquipmentUtilProcessLogsGridColumnsSideA}
				canColumnsReorder={false}
				canColumnsResize={false}

				{...props}
			/>;
}