import * as yup from 'yup'; // https://github.com/jquense/yup#string

const ImportWoServiceTypes = {
	
	name: 'ImportWoServiceTypes',
	
	model: {

		idProperty: 'import_wo_service_types__id',
		displayProperty: 'import_wo_service_types__name',
		sortProperty: 'import_wo_service_types__name',
		
		sorters: null,

		validator: yup.object({
			import_wo_service_types__name: yup.string().nullable(),
			import_wo_service_types__comments: yup.string().nullable(),
			import_wo_service_types__is_imported: yup.boolean().nullable(),
			import_wo_service_types__imported_date: yup.date().nullable()
		}),
		
		properties: [
			{ name: 'import_wo_service_types__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'import_wo_service_types__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_wo_service_types__comments', mapping: 'comments', title: 'Comments', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'import_wo_service_types__is_imported', mapping: 'is_imported', title: 'Imported?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'import_wo_service_types__imported_date', mapping: 'imported_date', title: 'Imported Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', }
		],

		associations: {
			hasMany: [
				'ImportWoServiceTypesUtilProcessLogs'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'import_wo_service_types__is_imported',
			// 'import_wo_service_types__imported_date'
		],

	},

	entity: {
		methods: {

			getImportWoServiceTypesUtilProcessLogs: function() {
				const ImportWoServiceTypesUtilProcessLogs = this.getAssociatedRepository('ImportWoServiceTypesUtilProcessLogs');
				return ImportWoServiceTypesUtilProcessLogs.getBy((entity) => {
					return entity.import_wo_service_types_util_process_logs__import_wo_service_type_id === this.import_wo_service_types__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default ImportWoServiceTypes;
