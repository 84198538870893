import * as React from "react"
import Svg, { Defs, G, Path } from "react-native-svg"
import { Icon } from 'native-base';
/* SVGR has dropped some elements not supported by react-native-svg: style */

function SvgComponent(props) {
  return (
    <Icon
      id="Layer_2"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 269.94 167.5"
      {...props}
    >
      <Defs></Defs>
      <G id="Layer_1-2">
        <Path
          className="cls-1"
          d="M59.96 102.33c1.16-5.59 6.07-9.59 11.63-9.42 2.35-.03 4.7-.02 7-.02V75.88c-4.26 0-8.52.02-12.79.02-.96 0-.96-.03-.96-.98V23.78c-.03-.74.77-.57 1.26-.57 4.16 0 8.32 0 12.48.02v-1.08c0-4.38 2.72-7.09 7.09-7.09h.22c7.32.27 14.91.11 22.25-.04 3.24-.07 6.51-.13 9.8-.16a25.657 25.657 0 00-6.16-3.25c-19.24-2.43-39.11-.25-58.54-.96-1.09 0-1.09.03-1.09 1.09v75.72c0 .69-.35 1.04-1.04 1.04-4.02 0-8.01-.05-12.02 0-2.95-.14-5.27 2.1-5.74 4.89-1.37 4.18-.93 8.72-.63 13.06.96 10.08 3.12 20 5.6 29.81h6.45c.89-8.97 6.49-16.55 14.3-20.23-.3-4.23-.58-8.96.87-13.7zM144.97 16.05c.44.06.88.16 1.3.32 1.89.69 3.65 1.54 5.29 2.52-.06-.17-.11-.34-.17-.52-1.01-3.01-1.91-7.73-5.93-7.35l.03.03c-3.39.42-4.29 2.32-4.17 4.61 1.22.12 2.44.24 3.65.4zM222.46 125.9c1.29-.43 2.57-.85 3.86-1.27 4.1-1.75 13.01-2.24 10.88-8.66-1.78-4.18-6.18-2.46-9.43-1.34-2.72.89-5.44 1.79-8.16 2.68.95 2.87 1.9 5.73 2.85 8.6zM78.06 152.59c-.28.04-.56.08-.86.08h-6.45c-2.55 0-4.78-1.62-5.63-3.98a10.907 10.907 0 01-6.35-9.92c0-2.64.93-5.06 2.49-6.95-.52-2.75-.99-5.59-1.39-8.48-5.09 3.25-8.48 8.94-8.48 15.43 0 10.11 8.2 18.31 18.31 18.31 3.18 0 6.18-.82 8.78-2.25-.18-.74-.32-1.49-.43-2.25zM152.67 152.59c-.29.04-.58.08-.88.08h-19.37c3.2 2.74 7.35 4.41 11.9 4.41 3.18 0 6.18-.82 8.78-2.25-.18-.74-.32-1.49-.43-2.25z"
        />
        <Path
          className="cls-1"
          d="M27.54 91.92c1.16-5.59 6.07-9.59 11.63-9.42 2.35-.03 4.7-.02 7-.02V65.47c-4.26 0-8.52.02-12.79.02-.96 0-.96-.03-.96-.98V13.37c-.03-.74.77-.57 1.26-.57 4.16 0 8.32 0 12.48.02v-1.08c0-4.38 2.72-7.09 7.09-7.09h.22c7.32.27 14.91.11 22.25-.04 3.24-.07 6.51-.13 9.8-.16a25.657 25.657 0 00-6.16-3.25C60.13-1.25 40.27.94 20.84.23c-1.09 0-1.09.03-1.09 1.09v75.72c0 .69-.35 1.04-1.04 1.04-4.02 0-8.01-.05-12.02 0-2.95-.14-5.27 2.1-5.74 4.89-1.37 4.18-.93 8.72-.63 13.06.96 10.08 3.12 20 5.6 29.81h6.45c.89-8.97 6.49-16.55 14.3-20.23-.3-4.23-.58-8.96.87-13.7zM112.55 5.64c.44.06.88.16 1.3.32 1.89.69 3.65 1.54 5.29 2.52-.06-.17-.11-.34-.17-.52-1.01-3.01-1.91-7.73-5.93-7.35l.03.03c-3.39.42-4.29 2.32-4.17 4.61 1.22.12 2.44.24 3.65.4zM45.64 142.18c-.28.04-.56.08-.86.08h-6.45c-2.55 0-4.78-1.62-5.63-3.98a10.907 10.907 0 01-6.35-9.92c0-2.64.93-5.06 2.49-6.95-.52-2.75-.99-5.59-1.39-8.48-5.09 3.25-8.48 8.94-8.48 15.43 0 10.11 8.2 18.31 18.31 18.31 3.18 0 6.18-.82 8.78-2.25-.18-.74-.32-1.49-.43-2.25z"
        />
        <G id="Layer_1-2">
          <Path
            className="cls-1"
            d="M177.91 21.46c-6.37.79-3.99 6.78-2.49 10.82 12.76 37.9 25 75.97 38.07 113.76 1.53 3.31 5.49 2.35 8.17 1.23 3.66-1.18 7.3-2.4 10.93-3.61 8.72-2.87 17.43-5.74 26.15-8.64 4.1-1.75 13.01-2.24 10.88-8.66-1.78-4.18-6.18-2.46-9.43-1.34-12.98 4.26-25.99 8.53-38.97 12.84-.87.3-1.04.22-1.34-.68-11.97-36.15-24.05-72.25-36.07-108.41-1.01-3.01-1.91-7.73-5.93-7.35l.03.03zm-57.47 127.73c0 10.11-8.2 18.31-18.31 18.31s-18.31-8.2-18.31-18.31 8.2-18.31 18.31-18.31 18.31 8.2 18.31 18.31zm-18.31-10.93c-6.04 0-10.93 4.89-10.93 10.93s4.89 10.93 10.93 10.93 10.93-4.89 10.93-10.93-4.89-10.93-10.93-10.93zm92.91 10.93c0 10.11-8.2 18.31-18.31 18.31s-18.31-8.2-18.31-18.31 8.2-18.31 18.31-18.31 18.31 8.2 18.31 18.31zm-18.31-10.93c-6.04 0-10.93 4.89-10.93 10.93s4.89 10.93 10.93 10.93 10.93-4.89 10.93-10.93-4.89-10.93-10.93-10.93z"
          />
          <Path
            className="cls-1"
            d="M97.27 59.97v25.36c0 .96 0 .98.96.98 8.66 0 17.33-.05 25.96 0 .25.08.57.22.68.44 1.2 2.05 2.4 4.15 3.58 6.23 2.79 4.89 5.6 9.76 8.36 14.67.36.6.74.77 1.37.77h27.05c.38 0 .52-.16.46-.55-.49-3.17-.98-6.31-1.53-9.48-2.51-13.06-5.08-26.15-8.36-39.05-3.22-10.66-8.12-26.04-21.62-25.74-11.89.11-23.77.03-35.66.03-.49 0-1.28-.16-1.26.57v25.77zm-20.14 86.7h-6.37c-2.49-9.81-4.65-19.73-5.6-29.81-.3-4.35-.74-8.88.63-13.06.46-2.79 2.79-5.03 5.74-4.89 4.02-.05 8.01 0 12.02 0 .69 0 1.04-.35 1.04-1.04V22.14c0-1.07 0-1.09 1.09-1.09 19.43.71 39.3-1.48 58.54.96 12.08 4.4 18.15 17 22.9 28.07 7.11 17.3 10.68 35.91 13.17 54.38.3 6.18 7.24 6.15 11.48 3.8.74-.16 3.06-2.02 3.33-.9 1.04 3.28 12.3 39.32 12.3 39.32h-5.6c-1.26-12.71-11.97-22.63-25-22.63s-23.75 9.92-25 22.63h-24.57c-1.26-12.71-11.97-22.63-25-22.63s-23.75 9.92-25 22.63h-.08z"
          />
        </G>
      </G>
    </Icon>
  )
}

export default SvgComponent
