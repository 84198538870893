/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import WoServiceTypesSideGridEditorPanel from './WoServiceTypesSideGridEditor.js';


export default function WoServiceTypesFilteredSideGridEditorPanel(props) {
	return <WoServiceTypesSideGridEditorPanel
				reference="WoServiceTypesFilteredSideGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}