/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportWorkOrdersGridColumns from './Columns/ImportWorkOrdersGridColumns.js';

export default function ImportWorkOrdersGrid(props) {
	return <Grid
				reference="ImportWorkOrdersGrid"
				model="ImportWorkOrders"
				usePermissions={true}
				columnsConfig={ImportWorkOrdersGridColumns}

				{...props}
			/>;
}