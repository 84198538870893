/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import ImportWoOtherChargesEditorWindow from '../../Window/ImportWoOtherChargesEditorWindow.js';
import ImportWoOtherChargesGridColumns from '../../Grid/Columns/ImportWoOtherChargesGridColumns.js';

export default function ImportWoOtherChargesGridEditorPanel(props) {
	return <GridPanel
				reference="ImportWoOtherChargesGridEditorPanel"
				model="ImportWoOtherCharges"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={ImportWoOtherChargesEditorWindow}
				columnsConfig={ImportWoOtherChargesGridColumns}
				
				
				{...props}
			/>;
}