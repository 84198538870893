import * as yup from 'yup'; // https://github.com/jquense/yup#string

const ImportWorkOrders = {
	
	name: 'ImportWorkOrders',
	
	model: {

		idProperty: 'import_work_orders__id',
		displayProperty: 'import_work_orders__service_order',
		sortProperty: 'import_work_orders__service_order',
		
		sorters: null,

		validator: yup.object({
			import_work_orders__woid: yup.string().max(50).required(),
			import_work_orders__business_partner: yup.string().nullable(),
			import_work_orders__service_order: yup.string().max(9).nullable(),
			import_work_orders__segment: yup.number().integer().nullable(),
			import_work_orders__service_center: yup.string().max(6).nullable(),
			import_work_orders__po_number: yup.string().max(25).nullable(),
			import_work_orders__service_type: yup.string().nullable(),
			import_work_orders__open_date: yup.date().nullable(),
			import_work_orders__invoice_date: yup.date().nullable(),
			import_work_orders__wo_class: yup.string().max(3).nullable(),
			import_work_orders__data_source: yup.string().max(2).nullable(),
			import_work_orders__segment_type: yup.number().integer().nullable(),
			import_work_orders__manufacturer: yup.string().max(25).nullable(),
			import_work_orders__job_code: yup.string().max(25).nullable(),
			import_work_orders__model: yup.string().max(25).nullable(),
			import_work_orders__serial_number: yup.string().max(50).nullable(),
			import_work_orders__unit_number: yup.string().max(25).nullable(),
			import_work_orders__asset_number: yup.string().max(25).nullable(),
			import_work_orders__meter_reading: yup.number().integer().nullable(),
			import_work_orders__complaint: yup.string().nullable(),
			import_work_orders__cause: yup.string().nullable(),
			import_work_orders__correction: yup.string().nullable(),
			import_work_orders__coverage: yup.string().nullable(),
			import_work_orders__comments: yup.string().nullable(),
			import_work_orders__total_parts: yup.number().nullable(),
			import_work_orders__total_labor: yup.number().nullable(),
			import_work_orders__total_other: yup.number().nullable(),
			import_work_orders__tax: yup.number().nullable(),
			import_work_orders__total: yup.number().nullable(),
			import_work_orders__sold_to_customer: yup.string().max(50).nullable(),
			import_work_orders__sold_to_name: yup.string().max(50).nullable(),
			import_work_orders__sold_to_address: yup.string().nullable(),
			import_work_orders__sold_to_city: yup.string().max(50).nullable(),
			import_work_orders__sold_to_state_abbr: yup.string().max(2).nullable(),
			import_work_orders__sold_to_zip: yup.string().max(10).nullable(),
			import_work_orders__bill_to_customer: yup.string().max(50).nullable(),
			import_work_orders__bill_to_name: yup.string().max(50).nullable(),
			import_work_orders__ship_to_customer: yup.string().max(50).nullable(),
			import_work_orders__ship_to_name: yup.string().max(50).nullable(),
			import_work_orders__ship_to_address: yup.string().nullable(),
			import_work_orders__ship_to_city: yup.string().max(50).nullable(),
			import_work_orders__ship_to_state_abbr: yup.string().max(2).nullable(),
			import_work_orders__ship_to_zip: yup.string().max(10).nullable(),
			import_work_orders__is_imported: yup.boolean().nullable(),
			import_work_orders__imported_date: yup.date().nullable()
		}),
		
		properties: [
			{ name: 'import_work_orders__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'import_work_orders__woid', mapping: 'woid', title: 'WOID', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__business_partner', mapping: 'business_partner', title: 'Business Partner', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__service_order', mapping: 'service_order', title: 'Service Order', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__segment', mapping: 'segment', title: 'Segment', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__service_center', mapping: 'service_center', title: 'Service Center', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__po_number', mapping: 'po_number', title: 'Po Number', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__service_type', mapping: 'service_type', title: 'Service Type', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__open_date', mapping: 'open_date', title: 'Open Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__invoice_date', mapping: 'invoice_date', title: 'Invoice Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__wo_class', mapping: 'wo_class', title: 'Wo Class', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__data_source', mapping: 'data_source', title: 'Data Source', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__segment_type', mapping: 'segment_type', title: 'Segment Type', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__manufacturer', mapping: 'manufacturer', title: 'Manufacturer', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__job_code', mapping: 'job_code', title: 'Job Code', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__model', mapping: 'model', title: 'Model', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__serial_number', mapping: 'serial_number', title: 'Serial Number', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__unit_number', mapping: 'unit_number', title: 'Unit Number', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__asset_number', mapping: 'asset_number', title: 'Asset Number', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__meter_reading', mapping: 'meter_reading', title: 'Meter Reading', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__complaint', mapping: 'complaint', title: 'Complaint', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__cause', mapping: 'cause', title: 'Cause', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__correction', mapping: 'correction', title: 'Correction', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__coverage', mapping: 'coverage', title: 'Coverage', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__comments', mapping: 'comments', title: 'Comments', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__total_parts', mapping: 'total_parts', title: 'Total Parts', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__total_labor', mapping: 'total_labor', title: 'Total Labor', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__total_other', mapping: 'total_other', title: 'Total Other', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__tax', mapping: 'tax', title: 'Tax', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__total', mapping: 'total', title: 'Total', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__sold_to_customer', mapping: 'sold_to_customer', title: 'Sold To Customer', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__sold_to_name', mapping: 'sold_to_name', title: 'Sold To Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__sold_to_address', mapping: 'sold_to_address', title: 'Sold To Address', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__sold_to_city', mapping: 'sold_to_city', title: 'Sold To City', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__sold_to_state_abbr', mapping: 'sold_to_state_abbr', title: 'Sold To State Abbr', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__sold_to_zip', mapping: 'sold_to_zip', title: 'Sold To Zip', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__bill_to_customer', mapping: 'bill_to_customer', title: 'Bill To Customer', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__bill_to_name', mapping: 'bill_to_name', title: 'Bill To Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__ship_to_customer', mapping: 'ship_to_customer', title: 'Ship To Customer', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__ship_to_name', mapping: 'ship_to_name', title: 'Ship To Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__ship_to_address', mapping: 'ship_to_address', title: 'Ship To Address', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__ship_to_city', mapping: 'ship_to_city', title: 'Ship To City', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__ship_to_state_abbr', mapping: 'ship_to_state_abbr', title: 'Ship To State Abbr', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__ship_to_zip', mapping: 'ship_to_zip', title: 'Ship To Zip', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__is_imported', mapping: 'is_imported', title: 'Imported?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__imported_date', mapping: 'imported_date', title: 'Imported Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', }
		],

		associations: {

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'import_work_orders__segment',
			// 'import_work_orders__open_date',
			// 'import_work_orders__invoice_date',
			// 'import_work_orders__segment_type',
			// 'import_work_orders__meter_reading',
			// 'import_work_orders__total_parts',
			// 'import_work_orders__total_labor',
			// 'import_work_orders__total_other',
			// 'import_work_orders__tax',
			// 'import_work_orders__total',
			// 'import_work_orders__is_imported',
			// 'import_work_orders__imported_date'
		],

	},



	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default ImportWorkOrders;
