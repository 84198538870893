import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Linking } from 'react-native';
import {
	setDebugMessage,
	setAlertMessage,
	setInfoMessage,
	setIsWaitModalShown,
} from './DebugSlice.js';
import { logout, replace, } from '../../RootNavigation.js';
import {
	calculateTabVisibility,
	visibleNormalTabs,
	visibleSetupTabs,
} from '../../navigators/Tabs.js';
import oneHatData from '@onehat/data';
import getTokenHeaders from '@onehat/ui/src/Functions/getTokenHeaders.js';
import inArray from '@onehat/ui/src/Functions/inArray';
import setSecure from '@onehat/ui/src/Functions/setSecure';
import getSecure from '@onehat/ui/src/Functions/getSecure';
import setSaved from '@onehat/ui/src/Functions/setSaved';
import deleteSecure from '@onehat/ui/src/Functions/deleteSecure';
import applyEnterpriseIdToRepositories from '../../functions/applyEnterpriseIdToRepositories';
import AppGlobals from '../../AppGlobals.js';
import moment from 'moment';
import _ from 'lodash';


const USER_CREDS = 'USER_CREDS-';

// async thunks
export const loginThunk = createAsyncThunk(
	'auth/login',
	async (args, { dispatch }) => {

		try {
			const {
					loginId,
					password,
					isValid,
				} = args;
			
			if (!isValid) {
				dispatch(setAlertMessage('Please completely fill out the form first.'));
				return;
			}

			dispatch(setIsWaitModalShown(true));

			let loginResult,
				savedCreds,
				isError = false;

			setSecure('loginId', loginId);
			const
				Users = oneHatData.getRepository('Users'),
				secureCredsKey = USER_CREDS + loginId;

			// Login user
			const params = {};
			params[AppGlobals.loginIdField] = loginId;
			params.password = password;
			loginResult = await Users.login(params)
				.catch(function (error) {
					dispatch(setAlertMessage(error.message));
					isError = true;
				});
			if (isError || !loginResult) {
				dispatch(setIsWaitModalShown(false));
				return;
			}

			// Login successful (otherwise would have thrown an Error)

			const {
					user,
					groups,
					permissions,
				} = loginResult;

			// Save user creds to secure store
			const creds = JSON.stringify({
				loginId,
				password,
				user,
				groups,
				permissions,
			});
			savedCreds = await setSecure(secureCredsKey, creds);

			const userEntity = await Users.createStandaloneEntity(user);

			await dispatch(setUserThunk(userEntity));
			await dispatch(setGroupsThunk(groups));
			await dispatch(setPermissionsThunk(permissions));
			
			dispatch(setAlertMessage(null));

			calculateTabVisibility();

			replace('AppNavigator'); // navigation

		} catch(error) {
			dispatch(setAlertMessage(error?.message));
		}
		dispatch(setIsWaitModalShown(false));

	}
);
export const setUserThunk = createAsyncThunk(
	'auth/setUser',
	async (user, { dispatch }) => {
		dispatch(setUser(user?.getOriginalData()));
		
		let headers;
		if (user) {
			await setSecure('user', user);
			headers = getTokenHeaders();
		} else {
			await deleteSecure('user');
			headers = getTokenHeaders(true); // true for clearAll
		}
		if (!_.isEmpty(headers)) {
			oneHatData.setOptionsOnAllRepositories({ headers }); // already created
			oneHatData.setRepositoryGlobals({ headers }); // not yet created
		}
	}
);
export const setGroupsThunk = createAsyncThunk(
	'auth/setGroups',
	async (groups, { dispatch }) => {

		dispatch(setGroups(groups));

		if (!_.isEmpty(groups)) {
			await setSecure('groups', groups);
		} else {
			await deleteSecure('groups');
		}
		
	}
);
export const setPermissionsThunk = createAsyncThunk(
	'auth/setPermissions',
	async (permissions, { dispatch }) => {

		if (_.isPlainObject(permissions)) {
			// login returns permissions as an object from the server,
			// so we need to convert it to an array
			permissions = _.map(permissions, (value, key) => {
				if (!parseInt(value)) {
					return null;
				}
				return key;
			});
		}
		dispatch(setPermissions(permissions));

		if (!_.isEmpty(permissions)) {
			await setSecure('permissions', permissions);
		} else {
			await deleteSecure('permissions');
		}
		
	}
);
export const logoutThunk = createAsyncThunk(
	'auth/logout',
	async (arg, { dispatch }) => {

		dispatch(setDebugMessage(null));
		dispatch(setAlertMessage(null));
		dispatch(setInfoMessage(null));
		await dispatch(setUserThunk(null));

		const Users = oneHatData.getRepository('Users');
		await Users.logout();
		
		// router.replace('/');
		logout();
	}
);
export const setEnterpriseIdThunk = createAsyncThunk(
	'enterprises/set',
	async (enterprise_id, { dispatch }) => {
		applyEnterpriseIdToRepositories(enterprise_id);
		dispatch(setEnterpriseId(enterprise_id));
		setSaved('enterprise_id', enterprise_id);

		// Set the EnterpriseType
		const enterprise = await oneHatData.getRepository('Enterprises')
											.getSingleEntityFromServer(enterprise_id);
		dispatch(setEnterpriseTypeId(enterprise.enterprises__enterprise_type_id));
	}
);
export const toggleSetupModeThunk = createAsyncThunk(
	'navigation/toggleSetupMode',
	async (args, { dispatch, getState }) => {
		const
			state = getState(),
			isSetupMode = selectIsSetupMode(state),
			currentNormalTabIx = selectCurrentNormalTabIx(state),
			currentSetupTabIx = selectCurrentSetupTabIx(state),
			newSetupMode = !isSetupMode;

		dispatch(setIsSetupMode(newSetupMode));
		dispatch(navigateTo({ tabIx: newSetupMode ? currentSetupTabIx : currentNormalTabIx }));
	}
);
export const navigateTo = createAsyncThunk(
	'navigation/navigateTo',
	async (args, { dispatch, getState, }) => {

		let {
				tabIx,
				app,
				tab,
			} = args;

		if (typeof tabIx === 'undefined' && !app && !tab) {
			throw new Error('navigateTo args must contain either tabIx or app or tab');
		}
		
		if (app) {
			Linking.openURL(app.path); // open a new tab in browser
			return;
		}

		const
			state = getState(),
			currentScreen = selectCurrentScreen(state),
			isSetupMode = selectIsSetupMode(state);
		
		if (tab) {
			tabIx = isSetupMode ? visibleSetupTabs.indexOf(tab) : visibleNormalTabs.indexOf(tab);
		} else {
			tab = isSetupMode ? visibleSetupTabs[tabIx] : visibleNormalTabs[tabIx];
		}
		
		if (tab.action) {
			tab.action();
			return;
		}

		// update state
		if (isSetupMode) {
			dispatch(setCurrentSetupTabIx(tabIx));
		} else {
			dispatch(setCurrentNormalTabIx(tabIx));
		}
		dispatch(setIsEnterpriseSelectorShown(!!tab.usesEnterpriseSelector));
		dispatch(setIsEquipmentLitesTreeShown(!!tab.usesEquipmentLitesTree));

		// navigate
		if (tab.screenName !== currentScreen) {
			replace(tab.screenName);
			dispatch(setCurrentScreen(tab.screenName));
		}
	}
);


// LEFT OFF HERE
// The FleetTree is not correctly loading, especially after
// minimizing it and them maximizing it again.
// Then have manager screens hide when no enterprise is selected.


// slice
export const appSlice = createSlice({
	name: 'app',
	initialState: {
		appState: 'active',
		user: null,
		groups: null,
		permissions: null,
		enterprise_id: null,
		enterprise_type_id: null,
		isEnterpriseSelectorShown: false,
		isCustomerPerspective: false,
		isSetupMode: false,
		isEquipmentLitesTreeShown: false,
		treeSelection: [],
		helpMessage: '',
		currentScreen: null,
		currentNormalTabIx: 0,
		currentSetupTabIx: 0,
	},
	reducers: {
		setAppState: (state, action) => {
			state.appState = action.payload
		},
		setUser: (state, action) => {
			state.user = action.payload
		},
		setGroups: (state, action) => {
			state.groups = action.payload
		},
		setPermissions: (state, action) => {
			state.permissions = action.payload
		},
		setEnterpriseId: (state, action) => {
			state.enterprise_id = action.payload
		},
		setEnterpriseTypeId: (state, action) => {
			state.enterprise_type_id = action.payload
		},
		setIsEnterpriseSelectorShown: (state, action) => {
			state.isEnterpriseSelectorShown = action.payload
		},
		setIsCustomerPerspective: (state, action) => {
			state.isCustomerPerspective = action.payload
		},
		setIsSetupMode: (state, action) => {
			state.isSetupMode = action.payload
		},
		setIsEquipmentLitesTreeShown: (state, action) => {
			state.isEquipmentLitesTreeShown = action.payload
		},
		setTreeSelection: (state, action) => {
			state.treeSelection = action.payload
		},
		setHelpMessage: (state, action) => {
			state.helpMessage = action.payload
		},
		setCurrentScreen: (state, action) => {
			state.currentScreen = action.payload
		},
		setCurrentNormalTabIx: (state, action) => {
			state.currentNormalTabIx = action.payload
		},
		setCurrentSetupTabIx: (state, action) => {
			state.currentSetupTabIx = action.payload
		},
	}
});


// action definitions
export const {
	setAppState,
	setUser,
	setGroups,
	setPermissions,
	setEnterpriseId,
	setEnterpriseTypeId,
	setIsEnterpriseSelectorShown,
	setIsCustomerPerspective,
	setIsSetupMode,
	setIsEquipmentLitesTreeShown,
	setTreeSelection,
	setHelpMessage,
	setCurrentScreen,
	setCurrentNormalTabIx,
	setCurrentSetupTabIx
} = appSlice.actions;


// selectors
export const selectAppState = state => state.app.appState;
export const selectUser = state => state.app.user;
export const selectGroups = state => state.app.groups;
export const selectPermissions = state => state.app.permissions;
export const selectEnterpriseId = state => state.app.enterprise_id;
export const selectEnterpriseTypeId = state => state.app.enterprise_type_id;
export const selectIsEnterpriseSelectorShown = state => state.app.isEnterpriseSelectorShown;
export const selectIsCustomerPerspective = state => state.app.isCustomerPerspective;
export const selectIsSetupMode = state => state.app.isSetupMode;
export const selectIsEquipmentLitesTreeShown = state => state.app.isEquipmentLitesTreeShown;
export const selectTreeSelection = state => state.app.treeSelection;
export const selectHelpMessage = state => state.app.helpMessage;
export const selectCurrentScreen = state => state.app.currentScreen;
export const selectCurrentNormalTabIx = state => state.app.currentNormalTabIx;
export const selectCurrentSetupTabIx = state => state.app.currentSetupTabIx;

export default appSlice.reducer;