/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import WorkOrdersEditorWindow from '../Window/WorkOrdersEditorWindow.js';
import WorkOrdersGridColumns from './Columns/WorkOrdersGridColumns.js';
import { useSelector } from 'react-redux';
import {
	selectEnterpriseId,
	selectTreeSelection,
} from '../../models/Slices/AppSlice';
import _ from 'lodash';

export default function WorkOrdersGridEditor(props) {
	const
		enterpriseId = useSelector(selectEnterpriseId),
		treeSelection = useSelector(selectTreeSelection);
		// fleetId = treeSelection[0].id;

	return <WindowedGridEditor
				reference="WorkOrdersGridEditor"
				model="WorkOrders"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={WorkOrdersEditorWindow}
				columnsConfig={WorkOrdersGridColumns}
				
				// selectorId="equipment__fleet_id"
				// selectorSelected={treeSelection[0]}
				defaultValues={{ 
					equipment__enterprise_id: enterpriseId,
					// equipment__fleet_id: fleetId,
				}}
				
				
				{...props}
			/>;
}