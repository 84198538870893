/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportTechniciansGridEditor from './ImportTechniciansGridEditor.js';

export default function ImportTechniciansFilteredGridEditor(props) {
	return <ImportTechniciansGridEditor
				reference="ImportTechniciansFilteredGridEditor"
				useFilters={true}

				{...props}
			/>;
}