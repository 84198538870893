import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Permissions = {
	
	name: 'Permissions',
	
	model: {

		idProperty: 'permissions__id',
		displayProperty: 'permissions__id',
		sortProperty: 'permissions__id',
		
		sorters: null,

		validator: yup.object({
			permissions__view_reports: yup.boolean().nullable(),
			permissions__view_quick_reports: yup.boolean().nullable(),
			permissions__access_fleet_app: yup.boolean().nullable(),
			permissions__access_pms_app: yup.boolean().nullable(),
			permissions__access_users_app: yup.boolean().nullable(),
			permissions__access_audit_app: yup.boolean().nullable(),
			permissions__access_data_management_app: yup.boolean().nullable(),
			permissions__access_lease_app: yup.boolean().nullable(),
			permissions__access_rentals_app: yup.boolean().nullable(),
			permissions__access_all_enterprises: yup.boolean().nullable(),
			permissions__access_whole_enterprise: yup.boolean().nullable(),
			permissions__drag_drop: yup.boolean().nullable(),
			permissions__access_setup_mode: yup.boolean().nullable(),
			permissions__view_charts: yup.boolean().nullable(),
			permissions__receive_alerts: yup.boolean().nullable(),
			permissions__view_addresses: yup.boolean().nullable(),
			permissions__add_addresses: yup.boolean().nullable(),
			permissions__edit_addresses: yup.boolean().nullable(),
			permissions__delete_addresses: yup.boolean().nullable(),
			permissions__view_attachments: yup.boolean().nullable(),
			permissions__add_attachments: yup.boolean().nullable(),
			permissions__edit_attachments: yup.boolean().nullable(),
			permissions__delete_attachments: yup.boolean().nullable(),
			permissions__view_business_partners: yup.boolean().nullable(),
			permissions__add_business_partners: yup.boolean().nullable(),
			permissions__edit_business_partners: yup.boolean().nullable(),
			permissions__delete_business_partners: yup.boolean().nullable(),
			permissions__view_business_partners_fleets: yup.boolean().nullable(),
			permissions__add_business_partners_fleets: yup.boolean().nullable(),
			permissions__edit_business_partners_fleets: yup.boolean().nullable(),
			permissions__delete_business_partners_fleets: yup.boolean().nullable(),
			permissions__view_chromes: yup.boolean().nullable(),
			permissions__add_chromes: yup.boolean().nullable(),
			permissions__edit_chromes: yup.boolean().nullable(),
			permissions__delete_chromes: yup.boolean().nullable(),
			permissions__view_enterprises: yup.boolean().nullable(),
			permissions__add_enterprises: yup.boolean().nullable(),
			permissions__edit_enterprises: yup.boolean().nullable(),
			permissions__delete_enterprises: yup.boolean().nullable(),
			permissions__view_eq_fork_lengths: yup.boolean().nullable(),
			permissions__add_eq_fork_lengths: yup.boolean().nullable(),
			permissions__edit_eq_fork_lengths: yup.boolean().nullable(),
			permissions__delete_eq_fork_lengths: yup.boolean().nullable(),
			permissions__view_eq_power_types: yup.boolean().nullable(),
			permissions__add_eq_power_types: yup.boolean().nullable(),
			permissions__edit_eq_power_types: yup.boolean().nullable(),
			permissions__delete_eq_power_types: yup.boolean().nullable(),
			permissions__view_eq_tire_types: yup.boolean().nullable(),
			permissions__add_eq_tire_types: yup.boolean().nullable(),
			permissions__edit_eq_tire_types: yup.boolean().nullable(),
			permissions__delete_eq_tire_types: yup.boolean().nullable(),
			permissions__view_eq_types: yup.boolean().nullable(),
			permissions__add_eq_types: yup.boolean().nullable(),
			permissions__edit_eq_types: yup.boolean().nullable(),
			permissions__delete_eq_types: yup.boolean().nullable(),
			permissions__view_eq_upright_types: yup.boolean().nullable(),
			permissions__add_eq_upright_types: yup.boolean().nullable(),
			permissions__edit_eq_upright_types: yup.boolean().nullable(),
			permissions__delete_eq_upright_types: yup.boolean().nullable(),
			permissions__view_eq_valve_types: yup.boolean().nullable(),
			permissions__add_eq_valve_types: yup.boolean().nullable(),
			permissions__edit_eq_valve_types: yup.boolean().nullable(),
			permissions__delete_eq_valve_types: yup.boolean().nullable(),
			permissions__view_eq_voltages: yup.boolean().nullable(),
			permissions__add_eq_voltages: yup.boolean().nullable(),
			permissions__edit_eq_voltages: yup.boolean().nullable(),
			permissions__delete_eq_voltages: yup.boolean().nullable(),
			permissions__view_equipment: yup.boolean().nullable(),
			permissions__add_equipment: yup.boolean().nullable(),
			permissions__edit_equipment: yup.boolean().nullable(),
			permissions__delete_equipment: yup.boolean().nullable(),
			permissions__view_equipment_pm_schedules: yup.boolean().nullable(),
			permissions__add_equipment_pm_schedules: yup.boolean().nullable(),
			permissions__edit_equipment_pm_schedules: yup.boolean().nullable(),
			permissions__delete_equipment_pm_schedules: yup.boolean().nullable(),
			permissions__view_fleets: yup.boolean().nullable(),
			permissions__add_fleets: yup.boolean().nullable(),
			permissions__edit_fleets: yup.boolean().nullable(),
			permissions__delete_fleets: yup.boolean().nullable(),
			permissions__view_groups: yup.boolean().nullable(),
			permissions__add_groups: yup.boolean().nullable(),
			permissions__edit_groups: yup.boolean().nullable(),
			permissions__delete_groups: yup.boolean().nullable(),
			permissions__view_groups_users: yup.boolean().nullable(),
			permissions__add_groups_users: yup.boolean().nullable(),
			permissions__edit_groups_users: yup.boolean().nullable(),
			permissions__delete_groups_users: yup.boolean().nullable(),
			permissions__view_import_manufacturers: yup.boolean().nullable(),
			permissions__add_import_manufacturers: yup.boolean().nullable(),
			permissions__edit_import_manufacturers: yup.boolean().nullable(),
			permissions__delete_import_manufacturers: yup.boolean().nullable(),
			permissions__view_import_manufacturers_util_process_logs: yup.boolean().nullable(),
			permissions__add_import_manufacturers_util_process_logs: yup.boolean().nullable(),
			permissions__edit_import_manufacturers_util_process_logs: yup.boolean().nullable(),
			permissions__delete_import_manufacturers_util_process_logs: yup.boolean().nullable(),
			permissions__view_import_pm_equipment: yup.boolean().nullable(),
			permissions__add_import_pm_equipment: yup.boolean().nullable(),
			permissions__edit_import_pm_equipment: yup.boolean().nullable(),
			permissions__delete_import_pm_equipment: yup.boolean().nullable(),
			permissions__view_import_pm_equipment_util_process_logs: yup.boolean().nullable(),
			permissions__add_import_pm_equipment_util_process_logs: yup.boolean().nullable(),
			permissions__edit_import_pm_equipment_util_process_logs: yup.boolean().nullable(),
			permissions__delete_import_pm_equipment_util_process_logs: yup.boolean().nullable(),
			permissions__view_import_service_centers: yup.boolean().nullable(),
			permissions__add_import_service_centers: yup.boolean().nullable(),
			permissions__edit_import_service_centers: yup.boolean().nullable(),
			permissions__delete_import_service_centers: yup.boolean().nullable(),
			permissions__view_import_service_centers_util_process_logs: yup.boolean().nullable(),
			permissions__add_import_service_centers_util_process_logs: yup.boolean().nullable(),
			permissions__edit_import_service_centers_util_process_logs: yup.boolean().nullable(),
			permissions__delete_import_service_centers_util_process_logs: yup.boolean().nullable(),
			permissions__view_import_technicians: yup.boolean().nullable(),
			permissions__add_import_technicians: yup.boolean().nullable(),
			permissions__edit_import_technicians: yup.boolean().nullable(),
			permissions__delete_import_technicians: yup.boolean().nullable(),
			permissions__view_import_technicians_util_process_logs: yup.boolean().nullable(),
			permissions__add_import_technicians_util_process_logs: yup.boolean().nullable(),
			permissions__edit_import_technicians_util_process_logs: yup.boolean().nullable(),
			permissions__delete_import_technicians_util_process_logs: yup.boolean().nullable(),
			permissions__view_import_wo_labors: yup.boolean().nullable(),
			permissions__add_import_wo_labors: yup.boolean().nullable(),
			permissions__edit_import_wo_labors: yup.boolean().nullable(),
			permissions__delete_import_wo_labors: yup.boolean().nullable(),
			permissions__view_import_wo_other_charge_types: yup.boolean().nullable(),
			permissions__add_import_wo_other_charge_types: yup.boolean().nullable(),
			permissions__edit_import_wo_other_charge_types: yup.boolean().nullable(),
			permissions__delete_import_wo_other_charge_types: yup.boolean().nullable(),
			permissions__view_import_wo_other_charge_types_util_process_logs: yup.boolean().nullable(),
			permissions__add_import_wo_other_charge_types_util_process_logs: yup.boolean().nullable(),
			permissions__edit_import_wo_other_charge_types_util_process_logs: yup.boolean().nullable(),
			permissions__delete_import_wo_other_charge_types_util_process_logs: yup.boolean().nullable(),
			permissions__view_import_wo_other_charges: yup.boolean().nullable(),
			permissions__add_import_wo_other_charges: yup.boolean().nullable(),
			permissions__edit_import_wo_other_charges: yup.boolean().nullable(),
			permissions__delete_import_wo_other_charges: yup.boolean().nullable(),
			permissions__view_import_wo_parts: yup.boolean().nullable(),
			permissions__add_import_wo_parts: yup.boolean().nullable(),
			permissions__edit_import_wo_parts: yup.boolean().nullable(),
			permissions__delete_import_wo_parts: yup.boolean().nullable(),
			permissions__view_import_work_orders: yup.boolean().nullable(),
			permissions__add_import_work_orders: yup.boolean().nullable(),
			permissions__edit_import_work_orders: yup.boolean().nullable(),
			permissions__delete_import_work_orders: yup.boolean().nullable(),
			permissions__view_import_work_orders_util_process_logs: yup.boolean().nullable(),
			permissions__add_import_work_orders_util_process_logs: yup.boolean().nullable(),
			permissions__edit_import_work_orders_util_process_logs: yup.boolean().nullable(),
			permissions__delete_import_work_orders_util_process_logs: yup.boolean().nullable(),
			permissions__view_manufacturers: yup.boolean().nullable(),
			permissions__add_manufacturers: yup.boolean().nullable(),
			permissions__edit_manufacturers: yup.boolean().nullable(),
			permissions__delete_manufacturers: yup.boolean().nullable(),
			permissions__view_meter_readings: yup.boolean().nullable(),
			permissions__add_meter_readings: yup.boolean().nullable(),
			permissions__edit_meter_readings: yup.boolean().nullable(),
			permissions__delete_meter_readings: yup.boolean().nullable(),
			permissions__view_part_types: yup.boolean().nullable(),
			permissions__add_part_types: yup.boolean().nullable(),
			permissions__edit_part_types: yup.boolean().nullable(),
			permissions__delete_part_types: yup.boolean().nullable(),
			permissions__view_parts: yup.boolean().nullable(),
			permissions__add_parts: yup.boolean().nullable(),
			permissions__edit_parts: yup.boolean().nullable(),
			permissions__delete_parts: yup.boolean().nullable(),
			permissions__view_parts_part_types: yup.boolean().nullable(),
			permissions__add_parts_part_types: yup.boolean().nullable(),
			permissions__edit_parts_part_types: yup.boolean().nullable(),
			permissions__delete_parts_part_types: yup.boolean().nullable(),
			permissions__view_parts_work_orders: yup.boolean().nullable(),
			permissions__add_parts_work_orders: yup.boolean().nullable(),
			permissions__edit_parts_work_orders: yup.boolean().nullable(),
			permissions__delete_parts_work_orders: yup.boolean().nullable(),
			permissions__view_permissions: yup.boolean().nullable(),
			permissions__add_permissions: yup.boolean().nullable(),
			permissions__edit_permissions: yup.boolean().nullable(),
			permissions__delete_permissions: yup.boolean().nullable(),
			permissions__view_pm_events: yup.boolean().nullable(),
			permissions__add_pm_events: yup.boolean().nullable(),
			permissions__edit_pm_events: yup.boolean().nullable(),
			permissions__delete_pm_events: yup.boolean().nullable(),
			permissions__view_pm_schedule_modes: yup.boolean().nullable(),
			permissions__add_pm_schedule_modes: yup.boolean().nullable(),
			permissions__edit_pm_schedule_modes: yup.boolean().nullable(),
			permissions__delete_pm_schedule_modes: yup.boolean().nullable(),
			permissions__view_pm_schedules: yup.boolean().nullable(),
			permissions__add_pm_schedules: yup.boolean().nullable(),
			permissions__edit_pm_schedules: yup.boolean().nullable(),
			permissions__delete_pm_schedules: yup.boolean().nullable(),
			permissions__view_service_centers: yup.boolean().nullable(),
			permissions__add_service_centers: yup.boolean().nullable(),
			permissions__edit_service_centers: yup.boolean().nullable(),
			permissions__delete_service_centers: yup.boolean().nullable(),
			permissions__view_tel_operators: yup.boolean().nullable(),
			permissions__add_tel_operators: yup.boolean().nullable(),
			permissions__edit_tel_operators: yup.boolean().nullable(),
			permissions__delete_tel_operators: yup.boolean().nullable(),
			permissions__view_tel_usages: yup.boolean().nullable(),
			permissions__add_tel_usages: yup.boolean().nullable(),
			permissions__edit_tel_usages: yup.boolean().nullable(),
			permissions__delete_tel_usages: yup.boolean().nullable(),
			permissions__view_users: yup.boolean().nullable(),
			permissions__add_users: yup.boolean().nullable(),
			permissions__edit_users: yup.boolean().nullable(),
			permissions__delete_users: yup.boolean().nullable(),
			permissions__view_util_crud_logs: yup.boolean().nullable(),
			permissions__add_util_crud_logs: yup.boolean().nullable(),
			permissions__edit_util_crud_logs: yup.boolean().nullable(),
			permissions__delete_util_crud_logs: yup.boolean().nullable(),
			permissions__view_util_error_codes: yup.boolean().nullable(),
			permissions__add_util_error_codes: yup.boolean().nullable(),
			permissions__edit_util_error_codes: yup.boolean().nullable(),
			permissions__delete_util_error_codes: yup.boolean().nullable(),
			permissions__view_util_error_logs: yup.boolean().nullable(),
			permissions__add_util_error_logs: yup.boolean().nullable(),
			permissions__edit_util_error_logs: yup.boolean().nullable(),
			permissions__delete_util_error_logs: yup.boolean().nullable(),
			permissions__view_util_process_logs: yup.boolean().nullable(),
			permissions__add_util_process_logs: yup.boolean().nullable(),
			permissions__edit_util_process_logs: yup.boolean().nullable(),
			permissions__delete_util_process_logs: yup.boolean().nullable(),
			permissions__view_wo_classes: yup.boolean().nullable(),
			permissions__add_wo_classes: yup.boolean().nullable(),
			permissions__edit_wo_classes: yup.boolean().nullable(),
			permissions__delete_wo_classes: yup.boolean().nullable(),
			permissions__view_wo_labor_types: yup.boolean().nullable(),
			permissions__add_wo_labor_types: yup.boolean().nullable(),
			permissions__edit_wo_labor_types: yup.boolean().nullable(),
			permissions__delete_wo_labor_types: yup.boolean().nullable(),
			permissions__view_wo_labors: yup.boolean().nullable(),
			permissions__add_wo_labors: yup.boolean().nullable(),
			permissions__edit_wo_labors: yup.boolean().nullable(),
			permissions__delete_wo_labors: yup.boolean().nullable(),
			permissions__view_wo_other_charge_types: yup.boolean().nullable(),
			permissions__add_wo_other_charge_types: yup.boolean().nullable(),
			permissions__edit_wo_other_charge_types: yup.boolean().nullable(),
			permissions__delete_wo_other_charge_types: yup.boolean().nullable(),
			permissions__view_wo_other_charges: yup.boolean().nullable(),
			permissions__add_wo_other_charges: yup.boolean().nullable(),
			permissions__edit_wo_other_charges: yup.boolean().nullable(),
			permissions__delete_wo_other_charges: yup.boolean().nullable(),
			permissions__view_work_orders: yup.boolean().nullable(),
			permissions__add_work_orders: yup.boolean().nullable(),
			permissions__edit_work_orders: yup.boolean().nullable(),
			permissions__delete_work_orders: yup.boolean().nullable()
		}),
		
		properties: [
			{ name: 'permissions__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'permissions__user_id', mapping: 'user_id', title: 'User', type: 'int', isFk: true, fkIdField: 'users__id', fkDisplayField: 'users__username', filterType: {"type":"UsersCombo","loadAfterRender":!1}, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'permissions__group_id', mapping: 'group_id', title: 'Group', type: 'int', isFk: true, fkIdField: 'groups__id', fkDisplayField: 'groups__name', filterType: {"type":"GroupsCombo","loadAfterRender":!1}, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'permissions__view_reports', mapping: 'view_reports', title: 'View Reports', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Custom Permissions', },
			{ name: 'permissions__view_quick_reports', mapping: 'view_quick_reports', title: 'View Quick Reports', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Custom Permissions', },
			{ name: 'permissions__access_fleet_app', mapping: 'access_fleet_app', title: 'Access Fleet App', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Custom Permissions', },
			{ name: 'permissions__access_pms_app', mapping: 'access_pms_app', title: 'Access Pms App', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Custom Permissions', },
			{ name: 'permissions__access_users_app', mapping: 'access_users_app', title: 'Access Users App', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Custom Permissions', },
			{ name: 'permissions__access_audit_app', mapping: 'access_audit_app', title: 'Access Audit App', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Custom Permissions', },
			{ name: 'permissions__access_data_management_app', mapping: 'access_data_management_app', title: 'Access Data Management App', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Custom Permissions', },
			{ name: 'permissions__access_lease_app', mapping: 'access_lease_app', title: 'Access Lease App', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Custom Permissions', },
			{ name: 'permissions__access_rentals_app', mapping: 'access_rentals_app', title: 'Access Rentals App', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Custom Permissions', },
			{ name: 'permissions__access_all_enterprises', mapping: 'access_all_enterprises', title: 'Access All Enterprises', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Custom Permissions', },
			{ name: 'permissions__access_whole_enterprise', mapping: 'access_whole_enterprise', title: 'Access Whole Enterprise', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Custom Permissions', },
			{ name: 'permissions__drag_drop', mapping: 'drag_drop', title: 'Drag Drop', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Custom Permissions', },
			{ name: 'permissions__access_setup_mode', mapping: 'access_setup_mode', title: 'Access Setup Mode', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'permissions__view_charts', mapping: 'view_charts', title: 'View Charts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Custom Permissions', },
			{ name: 'permissions__receive_alerts', mapping: 'receive_alerts', title: 'Receive Alerts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Custom Permissions', },
			{ name: 'permissions__view_addresses', mapping: 'view_addresses', title: 'View Addresses', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '2. Addresses', },
			{ name: 'permissions__add_addresses', mapping: 'add_addresses', title: 'Add Addresses', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '2. Addresses', },
			{ name: 'permissions__edit_addresses', mapping: 'edit_addresses', title: 'Edit Addresses', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '2. Addresses', },
			{ name: 'permissions__delete_addresses', mapping: 'delete_addresses', title: 'Delete Addresses', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '2. Addresses', },
			{ name: 'permissions__view_attachments', mapping: 'view_attachments', title: 'View Attachments', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Attachments', },
			{ name: 'permissions__add_attachments', mapping: 'add_attachments', title: 'Add Attachments', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Attachments', },
			{ name: 'permissions__edit_attachments', mapping: 'edit_attachments', title: 'Edit Attachments', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Attachments', },
			{ name: 'permissions__delete_attachments', mapping: 'delete_attachments', title: 'Delete Attachments', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '4. Attachments', },
			{ name: 'permissions__view_business_partners', mapping: 'view_business_partners', title: 'View Business Partners', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. BusinessPartners', },
			{ name: 'permissions__add_business_partners', mapping: 'add_business_partners', title: 'Add Business Partners', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. BusinessPartners', },
			{ name: 'permissions__edit_business_partners', mapping: 'edit_business_partners', title: 'Edit Business Partners', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. BusinessPartners', },
			{ name: 'permissions__delete_business_partners', mapping: 'delete_business_partners', title: 'Delete Business Partners', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. BusinessPartners', },
			{ name: 'permissions__view_business_partners_fleets', mapping: 'view_business_partners_fleets', title: 'View Business Partners Fleets', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '6. BusinessPartnersFleets', },
			{ name: 'permissions__add_business_partners_fleets', mapping: 'add_business_partners_fleets', title: 'Add Business Partners Fleets', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '6. BusinessPartnersFleets', },
			{ name: 'permissions__edit_business_partners_fleets', mapping: 'edit_business_partners_fleets', title: 'Edit Business Partners Fleets', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '6. BusinessPartnersFleets', },
			{ name: 'permissions__delete_business_partners_fleets', mapping: 'delete_business_partners_fleets', title: 'Delete Business Partners Fleets', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '6. BusinessPartnersFleets', },
			{ name: 'permissions__view_chromes', mapping: 'view_chromes', title: 'View Chromes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '3. Chromes', },
			{ name: 'permissions__add_chromes', mapping: 'add_chromes', title: 'Add Chromes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '3. Chromes', },
			{ name: 'permissions__edit_chromes', mapping: 'edit_chromes', title: 'Edit Chromes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '3. Chromes', },
			{ name: 'permissions__delete_chromes', mapping: 'delete_chromes', title: 'Delete Chromes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '3. Chromes', },
			{ name: 'permissions__view_enterprises', mapping: 'view_enterprises', title: 'View Enterprises', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '7. Enterprises', },
			{ name: 'permissions__add_enterprises', mapping: 'add_enterprises', title: 'Add Enterprises', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '7. Enterprises', },
			{ name: 'permissions__edit_enterprises', mapping: 'edit_enterprises', title: 'Edit Enterprises', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '7. Enterprises', },
			{ name: 'permissions__delete_enterprises', mapping: 'delete_enterprises', title: 'Delete Enterprises', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '7. Enterprises', },
			{ name: 'permissions__view_eq_fork_lengths', mapping: 'view_eq_fork_lengths', title: 'View Eq Fork Lengths', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '8. EqForkLengths', },
			{ name: 'permissions__add_eq_fork_lengths', mapping: 'add_eq_fork_lengths', title: 'Add Eq Fork Lengths', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '8. EqForkLengths', },
			{ name: 'permissions__edit_eq_fork_lengths', mapping: 'edit_eq_fork_lengths', title: 'Edit Eq Fork Lengths', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '8. EqForkLengths', },
			{ name: 'permissions__delete_eq_fork_lengths', mapping: 'delete_eq_fork_lengths', title: 'Delete Eq Fork Lengths', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '8. EqForkLengths', },
			{ name: 'permissions__view_eq_power_types', mapping: 'view_eq_power_types', title: 'View Eq Power Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. EqPowerTypes', },
			{ name: 'permissions__add_eq_power_types', mapping: 'add_eq_power_types', title: 'Add Eq Power Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. EqPowerTypes', },
			{ name: 'permissions__edit_eq_power_types', mapping: 'edit_eq_power_types', title: 'Edit Eq Power Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. EqPowerTypes', },
			{ name: 'permissions__delete_eq_power_types', mapping: 'delete_eq_power_types', title: 'Delete Eq Power Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '9. EqPowerTypes', },
			{ name: 'permissions__view_eq_tire_types', mapping: 'view_eq_tire_types', title: 'View Eq Tire Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '11. EqTireTypes', },
			{ name: 'permissions__add_eq_tire_types', mapping: 'add_eq_tire_types', title: 'Add Eq Tire Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '11. EqTireTypes', },
			{ name: 'permissions__edit_eq_tire_types', mapping: 'edit_eq_tire_types', title: 'Edit Eq Tire Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '11. EqTireTypes', },
			{ name: 'permissions__delete_eq_tire_types', mapping: 'delete_eq_tire_types', title: 'Delete Eq Tire Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '11. EqTireTypes', },
			{ name: 'permissions__view_eq_types', mapping: 'view_eq_types', title: 'View Eq Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '12. EqTypes', },
			{ name: 'permissions__add_eq_types', mapping: 'add_eq_types', title: 'Add Eq Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '12. EqTypes', },
			{ name: 'permissions__edit_eq_types', mapping: 'edit_eq_types', title: 'Edit Eq Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '12. EqTypes', },
			{ name: 'permissions__delete_eq_types', mapping: 'delete_eq_types', title: 'Delete Eq Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '12. EqTypes', },
			{ name: 'permissions__view_eq_upright_types', mapping: 'view_eq_upright_types', title: 'View Eq Upright Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '13. EqUprightTypes', },
			{ name: 'permissions__add_eq_upright_types', mapping: 'add_eq_upright_types', title: 'Add Eq Upright Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '13. EqUprightTypes', },
			{ name: 'permissions__edit_eq_upright_types', mapping: 'edit_eq_upright_types', title: 'Edit Eq Upright Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '13. EqUprightTypes', },
			{ name: 'permissions__delete_eq_upright_types', mapping: 'delete_eq_upright_types', title: 'Delete Eq Upright Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '13. EqUprightTypes', },
			{ name: 'permissions__view_eq_valve_types', mapping: 'view_eq_valve_types', title: 'View Eq Valve Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '14. EqValveTypes', },
			{ name: 'permissions__add_eq_valve_types', mapping: 'add_eq_valve_types', title: 'Add Eq Valve Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '14. EqValveTypes', },
			{ name: 'permissions__edit_eq_valve_types', mapping: 'edit_eq_valve_types', title: 'Edit Eq Valve Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '14. EqValveTypes', },
			{ name: 'permissions__delete_eq_valve_types', mapping: 'delete_eq_valve_types', title: 'Delete Eq Valve Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '14. EqValveTypes', },
			{ name: 'permissions__view_eq_voltages', mapping: 'view_eq_voltages', title: 'View Eq Voltages', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '15. EqVoltages', },
			{ name: 'permissions__add_eq_voltages', mapping: 'add_eq_voltages', title: 'Add Eq Voltages', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '15. EqVoltages', },
			{ name: 'permissions__edit_eq_voltages', mapping: 'edit_eq_voltages', title: 'Edit Eq Voltages', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '15. EqVoltages', },
			{ name: 'permissions__delete_eq_voltages', mapping: 'delete_eq_voltages', title: 'Delete Eq Voltages', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '15. EqVoltages', },
			{ name: 'permissions__view_equipment', mapping: 'view_equipment', title: 'View Equipment', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '16. Equipment', },
			{ name: 'permissions__add_equipment', mapping: 'add_equipment', title: 'Add Equipment', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '16. Equipment', },
			{ name: 'permissions__edit_equipment', mapping: 'edit_equipment', title: 'Edit Equipment', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '16. Equipment', },
			{ name: 'permissions__delete_equipment', mapping: 'delete_equipment', title: 'Delete Equipment', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '16. Equipment', },
			{ name: 'permissions__view_equipment_pm_schedules', mapping: 'view_equipment_pm_schedules', title: 'View Equipment Pm Schedules', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '17. EquipmentPmSchedules', },
			{ name: 'permissions__add_equipment_pm_schedules', mapping: 'add_equipment_pm_schedules', title: 'Add Equipment Pm Schedules', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '17. EquipmentPmSchedules', },
			{ name: 'permissions__edit_equipment_pm_schedules', mapping: 'edit_equipment_pm_schedules', title: 'Edit Equipment Pm Schedules', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '17. EquipmentPmSchedules', },
			{ name: 'permissions__delete_equipment_pm_schedules', mapping: 'delete_equipment_pm_schedules', title: 'Delete Equipment Pm Schedules', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '17. EquipmentPmSchedules', },
			{ name: 'permissions__view_fleets', mapping: 'view_fleets', title: 'View Fleets', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '18. Fleets', },
			{ name: 'permissions__add_fleets', mapping: 'add_fleets', title: 'Add Fleets', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '18. Fleets', },
			{ name: 'permissions__edit_fleets', mapping: 'edit_fleets', title: 'Edit Fleets', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '18. Fleets', },
			{ name: 'permissions__delete_fleets', mapping: 'delete_fleets', title: 'Delete Fleets', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '18. Fleets', },
			{ name: 'permissions__view_groups', mapping: 'view_groups', title: 'View Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '19. Groups', },
			{ name: 'permissions__add_groups', mapping: 'add_groups', title: 'Add Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '19. Groups', },
			{ name: 'permissions__edit_groups', mapping: 'edit_groups', title: 'Edit Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '19. Groups', },
			{ name: 'permissions__delete_groups', mapping: 'delete_groups', title: 'Delete Groups', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '19. Groups', },
			{ name: 'permissions__view_groups_users', mapping: 'view_groups_users', title: 'View Groups Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '20. GroupsUsers', },
			{ name: 'permissions__add_groups_users', mapping: 'add_groups_users', title: 'Add Groups Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '20. GroupsUsers', },
			{ name: 'permissions__edit_groups_users', mapping: 'edit_groups_users', title: 'Edit Groups Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '20. GroupsUsers', },
			{ name: 'permissions__delete_groups_users', mapping: 'delete_groups_users', title: 'Delete Groups Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '20. GroupsUsers', },
			{ name: 'permissions__view_import_manufacturers', mapping: 'view_import_manufacturers', title: 'View Import Manufacturers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '21. ImportManufacturers', },
			{ name: 'permissions__add_import_manufacturers', mapping: 'add_import_manufacturers', title: 'Add Import Manufacturers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '21. ImportManufacturers', },
			{ name: 'permissions__edit_import_manufacturers', mapping: 'edit_import_manufacturers', title: 'Edit Import Manufacturers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '21. ImportManufacturers', },
			{ name: 'permissions__delete_import_manufacturers', mapping: 'delete_import_manufacturers', title: 'Delete Import Manufacturers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '21. ImportManufacturers', },
			{ name: 'permissions__view_import_manufacturers_util_process_logs', mapping: 'view_import_manufacturers_util_process_logs', title: 'View Import Manufacturers Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '22. ImportManufacturersUtilProcessLogs', },
			{ name: 'permissions__add_import_manufacturers_util_process_logs', mapping: 'add_import_manufacturers_util_process_logs', title: 'Add Import Manufacturers Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '22. ImportManufacturersUtilProcessLogs', },
			{ name: 'permissions__edit_import_manufacturers_util_process_logs', mapping: 'edit_import_manufacturers_util_process_logs', title: 'Edit Import Manufacturers Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '22. ImportManufacturersUtilProcessLogs', },
			{ name: 'permissions__delete_import_manufacturers_util_process_logs', mapping: 'delete_import_manufacturers_util_process_logs', title: 'Delete Import Manufacturers Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '22. ImportManufacturersUtilProcessLogs', },
			{ name: 'permissions__view_import_pm_equipment', mapping: 'view_import_pm_equipment', title: 'View Import Pm Equipment', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '23. ImportPmEquipment', },
			{ name: 'permissions__add_import_pm_equipment', mapping: 'add_import_pm_equipment', title: 'Add Import Pm Equipment', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '23. ImportPmEquipment', },
			{ name: 'permissions__edit_import_pm_equipment', mapping: 'edit_import_pm_equipment', title: 'Edit Import Pm Equipment', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '23. ImportPmEquipment', },
			{ name: 'permissions__delete_import_pm_equipment', mapping: 'delete_import_pm_equipment', title: 'Delete Import Pm Equipment', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '23. ImportPmEquipment', },
			{ name: 'permissions__view_import_pm_equipment_util_process_logs', mapping: 'view_import_pm_equipment_util_process_logs', title: 'View Import Pm Equipment Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '24. ImportPmEquipmentUtilProcessLogs', },
			{ name: 'permissions__add_import_pm_equipment_util_process_logs', mapping: 'add_import_pm_equipment_util_process_logs', title: 'Add Import Pm Equipment Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '24. ImportPmEquipmentUtilProcessLogs', },
			{ name: 'permissions__edit_import_pm_equipment_util_process_logs', mapping: 'edit_import_pm_equipment_util_process_logs', title: 'Edit Import Pm Equipment Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '24. ImportPmEquipmentUtilProcessLogs', },
			{ name: 'permissions__delete_import_pm_equipment_util_process_logs', mapping: 'delete_import_pm_equipment_util_process_logs', title: 'Delete Import Pm Equipment Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '24. ImportPmEquipmentUtilProcessLogs', },
			{ name: 'permissions__view_import_service_centers', mapping: 'view_import_service_centers', title: 'View Import Service Centers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '25. ImportServiceCenters', },
			{ name: 'permissions__add_import_service_centers', mapping: 'add_import_service_centers', title: 'Add Import Service Centers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '25. ImportServiceCenters', },
			{ name: 'permissions__edit_import_service_centers', mapping: 'edit_import_service_centers', title: 'Edit Import Service Centers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '25. ImportServiceCenters', },
			{ name: 'permissions__delete_import_service_centers', mapping: 'delete_import_service_centers', title: 'Delete Import Service Centers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '25. ImportServiceCenters', },
			{ name: 'permissions__view_import_service_centers_util_process_logs', mapping: 'view_import_service_centers_util_process_logs', title: 'View Import Service Centers Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '26. ImportServiceCentersUtilProcessLogs', },
			{ name: 'permissions__add_import_service_centers_util_process_logs', mapping: 'add_import_service_centers_util_process_logs', title: 'Add Import Service Centers Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '26. ImportServiceCentersUtilProcessLogs', },
			{ name: 'permissions__edit_import_service_centers_util_process_logs', mapping: 'edit_import_service_centers_util_process_logs', title: 'Edit Import Service Centers Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '26. ImportServiceCentersUtilProcessLogs', },
			{ name: 'permissions__delete_import_service_centers_util_process_logs', mapping: 'delete_import_service_centers_util_process_logs', title: 'Delete Import Service Centers Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '26. ImportServiceCentersUtilProcessLogs', },
			{ name: 'permissions__view_import_technicians', mapping: 'view_import_technicians', title: 'View Import Technicians', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '27. ImportTechnicians', },
			{ name: 'permissions__add_import_technicians', mapping: 'add_import_technicians', title: 'Add Import Technicians', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '27. ImportTechnicians', },
			{ name: 'permissions__edit_import_technicians', mapping: 'edit_import_technicians', title: 'Edit Import Technicians', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '27. ImportTechnicians', },
			{ name: 'permissions__delete_import_technicians', mapping: 'delete_import_technicians', title: 'Delete Import Technicians', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '27. ImportTechnicians', },
			{ name: 'permissions__view_import_technicians_util_process_logs', mapping: 'view_import_technicians_util_process_logs', title: 'View Import Technicians Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '29. ImportTechniciansUtilProcessLogs', },
			{ name: 'permissions__add_import_technicians_util_process_logs', mapping: 'add_import_technicians_util_process_logs', title: 'Add Import Technicians Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '29. ImportTechniciansUtilProcessLogs', },
			{ name: 'permissions__edit_import_technicians_util_process_logs', mapping: 'edit_import_technicians_util_process_logs', title: 'Edit Import Technicians Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '29. ImportTechniciansUtilProcessLogs', },
			{ name: 'permissions__delete_import_technicians_util_process_logs', mapping: 'delete_import_technicians_util_process_logs', title: 'Delete Import Technicians Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '29. ImportTechniciansUtilProcessLogs', },
			{ name: 'permissions__view_import_wo_labors', mapping: 'view_import_wo_labors', title: 'View Import Wo Labors', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '30. ImportWoLabors', },
			{ name: 'permissions__add_import_wo_labors', mapping: 'add_import_wo_labors', title: 'Add Import Wo Labors', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '30. ImportWoLabors', },
			{ name: 'permissions__edit_import_wo_labors', mapping: 'edit_import_wo_labors', title: 'Edit Import Wo Labors', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '30. ImportWoLabors', },
			{ name: 'permissions__delete_import_wo_labors', mapping: 'delete_import_wo_labors', title: 'Delete Import Wo Labors', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '30. ImportWoLabors', },
			{ name: 'permissions__view_import_wo_other_charge_types', mapping: 'view_import_wo_other_charge_types', title: 'View Import Wo Other Charge Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '31. ImportWoOtherChargeTypes', },
			{ name: 'permissions__add_import_wo_other_charge_types', mapping: 'add_import_wo_other_charge_types', title: 'Add Import Wo Other Charge Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '31. ImportWoOtherChargeTypes', },
			{ name: 'permissions__edit_import_wo_other_charge_types', mapping: 'edit_import_wo_other_charge_types', title: 'Edit Import Wo Other Charge Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '31. ImportWoOtherChargeTypes', },
			{ name: 'permissions__delete_import_wo_other_charge_types', mapping: 'delete_import_wo_other_charge_types', title: 'Delete Import Wo Other Charge Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '31. ImportWoOtherChargeTypes', },
			{ name: 'permissions__view_import_wo_other_charge_types_util_process_logs', mapping: 'view_import_wo_other_charge_types_util_process_logs', title: 'View Import Wo Other Charge Types Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '32. ImportWoOtherChargeTypesUtilProcessLogs', },
			{ name: 'permissions__add_import_wo_other_charge_types_util_process_logs', mapping: 'add_import_wo_other_charge_types_util_process_logs', title: 'Add Import Wo Other Charge Types Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '32. ImportWoOtherChargeTypesUtilProcessLogs', },
			{ name: 'permissions__edit_import_wo_other_charge_types_util_process_logs', mapping: 'edit_import_wo_other_charge_types_util_process_logs', title: 'Edit Import Wo Other Charge Types Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '32. ImportWoOtherChargeTypesUtilProcessLogs', },
			{ name: 'permissions__delete_import_wo_other_charge_types_util_process_logs', mapping: 'delete_import_wo_other_charge_types_util_process_logs', title: 'Delete Import Wo Other Charge Types Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '32. ImportWoOtherChargeTypesUtilProcessLogs', },
			{ name: 'permissions__view_import_wo_other_charges', mapping: 'view_import_wo_other_charges', title: 'View Import Wo Other Charges', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '36. ImportWoOtherCharges', },
			{ name: 'permissions__add_import_wo_other_charges', mapping: 'add_import_wo_other_charges', title: 'Add Import Wo Other Charges', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '36. ImportWoOtherCharges', },
			{ name: 'permissions__edit_import_wo_other_charges', mapping: 'edit_import_wo_other_charges', title: 'Edit Import Wo Other Charges', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '36. ImportWoOtherCharges', },
			{ name: 'permissions__delete_import_wo_other_charges', mapping: 'delete_import_wo_other_charges', title: 'Delete Import Wo Other Charges', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '36. ImportWoOtherCharges', },
			{ name: 'permissions__view_import_wo_parts', mapping: 'view_import_wo_parts', title: 'View Import Wo Parts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '38. ImportWoParts', },
			{ name: 'permissions__add_import_wo_parts', mapping: 'add_import_wo_parts', title: 'Add Import Wo Parts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '38. ImportWoParts', },
			{ name: 'permissions__edit_import_wo_parts', mapping: 'edit_import_wo_parts', title: 'Edit Import Wo Parts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '38. ImportWoParts', },
			{ name: 'permissions__delete_import_wo_parts', mapping: 'delete_import_wo_parts', title: 'Delete Import Wo Parts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '38. ImportWoParts', },
			{ name: 'permissions__view_import_work_orders', mapping: 'view_import_work_orders', title: 'View Import Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '39. ImportWorkOrders', },
			{ name: 'permissions__add_import_work_orders', mapping: 'add_import_work_orders', title: 'Add Import Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '39. ImportWorkOrders', },
			{ name: 'permissions__edit_import_work_orders', mapping: 'edit_import_work_orders', title: 'Edit Import Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '39. ImportWorkOrders', },
			{ name: 'permissions__delete_import_work_orders', mapping: 'delete_import_work_orders', title: 'Delete Import Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '39. ImportWorkOrders', },
			{ name: 'permissions__view_import_work_orders_util_process_logs', mapping: 'view_import_work_orders_util_process_logs', title: 'View Import Work Orders Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '43. ImportWorkOrdersUtilProcessLogs', },
			{ name: 'permissions__add_import_work_orders_util_process_logs', mapping: 'add_import_work_orders_util_process_logs', title: 'Add Import Work Orders Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '43. ImportWorkOrdersUtilProcessLogs', },
			{ name: 'permissions__edit_import_work_orders_util_process_logs', mapping: 'edit_import_work_orders_util_process_logs', title: 'Edit Import Work Orders Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '43. ImportWorkOrdersUtilProcessLogs', },
			{ name: 'permissions__delete_import_work_orders_util_process_logs', mapping: 'delete_import_work_orders_util_process_logs', title: 'Delete Import Work Orders Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '43. ImportWorkOrdersUtilProcessLogs', },
			{ name: 'permissions__view_manufacturers', mapping: 'view_manufacturers', title: 'View Manufacturers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '10. Manufacturers', },
			{ name: 'permissions__add_manufacturers', mapping: 'add_manufacturers', title: 'Add Manufacturers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '10. Manufacturers', },
			{ name: 'permissions__edit_manufacturers', mapping: 'edit_manufacturers', title: 'Edit Manufacturers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '10. Manufacturers', },
			{ name: 'permissions__delete_manufacturers', mapping: 'delete_manufacturers', title: 'Delete Manufacturers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '10. Manufacturers', },
			{ name: 'permissions__view_meter_readings', mapping: 'view_meter_readings', title: 'View Meter Readings', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '28. MeterReadings', },
			{ name: 'permissions__add_meter_readings', mapping: 'add_meter_readings', title: 'Add Meter Readings', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '28. MeterReadings', },
			{ name: 'permissions__edit_meter_readings', mapping: 'edit_meter_readings', title: 'Edit Meter Readings', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '28. MeterReadings', },
			{ name: 'permissions__delete_meter_readings', mapping: 'delete_meter_readings', title: 'Delete Meter Readings', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '28. MeterReadings', },
			{ name: 'permissions__view_part_types', mapping: 'view_part_types', title: 'View Part Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '33. PartTypes', },
			{ name: 'permissions__add_part_types', mapping: 'add_part_types', title: 'Add Part Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '33. PartTypes', },
			{ name: 'permissions__edit_part_types', mapping: 'edit_part_types', title: 'Edit Part Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '33. PartTypes', },
			{ name: 'permissions__delete_part_types', mapping: 'delete_part_types', title: 'Delete Part Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '33. PartTypes', },
			{ name: 'permissions__view_parts', mapping: 'view_parts', title: 'View Parts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '34. Parts', },
			{ name: 'permissions__add_parts', mapping: 'add_parts', title: 'Add Parts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '34. Parts', },
			{ name: 'permissions__edit_parts', mapping: 'edit_parts', title: 'Edit Parts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '34. Parts', },
			{ name: 'permissions__delete_parts', mapping: 'delete_parts', title: 'Delete Parts', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '34. Parts', },
			{ name: 'permissions__view_parts_part_types', mapping: 'view_parts_part_types', title: 'View Parts Part Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '35. PartsPartTypes', },
			{ name: 'permissions__add_parts_part_types', mapping: 'add_parts_part_types', title: 'Add Parts Part Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '35. PartsPartTypes', },
			{ name: 'permissions__edit_parts_part_types', mapping: 'edit_parts_part_types', title: 'Edit Parts Part Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '35. PartsPartTypes', },
			{ name: 'permissions__delete_parts_part_types', mapping: 'delete_parts_part_types', title: 'Delete Parts Part Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '35. PartsPartTypes', },
			{ name: 'permissions__view_parts_work_orders', mapping: 'view_parts_work_orders', title: 'View Parts Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '37. PartsWorkOrders', },
			{ name: 'permissions__add_parts_work_orders', mapping: 'add_parts_work_orders', title: 'Add Parts Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '37. PartsWorkOrders', },
			{ name: 'permissions__edit_parts_work_orders', mapping: 'edit_parts_work_orders', title: 'Edit Parts Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '37. PartsWorkOrders', },
			{ name: 'permissions__delete_parts_work_orders', mapping: 'delete_parts_work_orders', title: 'Delete Parts Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '37. PartsWorkOrders', },
			{ name: 'permissions__view_permissions', mapping: 'view_permissions', title: 'View Permissions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '44. Permissions', },
			{ name: 'permissions__add_permissions', mapping: 'add_permissions', title: 'Add Permissions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '44. Permissions', },
			{ name: 'permissions__edit_permissions', mapping: 'edit_permissions', title: 'Edit Permissions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '44. Permissions', },
			{ name: 'permissions__delete_permissions', mapping: 'delete_permissions', title: 'Delete Permissions', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '44. Permissions', },
			{ name: 'permissions__view_pm_events', mapping: 'view_pm_events', title: 'View Pm Events', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '40. PmEvents', },
			{ name: 'permissions__add_pm_events', mapping: 'add_pm_events', title: 'Add Pm Events', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '40. PmEvents', },
			{ name: 'permissions__edit_pm_events', mapping: 'edit_pm_events', title: 'Edit Pm Events', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '40. PmEvents', },
			{ name: 'permissions__delete_pm_events', mapping: 'delete_pm_events', title: 'Delete Pm Events', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '40. PmEvents', },
			{ name: 'permissions__view_pm_schedule_modes', mapping: 'view_pm_schedule_modes', title: 'View Pm Schedule Modes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '41. PmScheduleModes', },
			{ name: 'permissions__add_pm_schedule_modes', mapping: 'add_pm_schedule_modes', title: 'Add Pm Schedule Modes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '41. PmScheduleModes', },
			{ name: 'permissions__edit_pm_schedule_modes', mapping: 'edit_pm_schedule_modes', title: 'Edit Pm Schedule Modes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '41. PmScheduleModes', },
			{ name: 'permissions__delete_pm_schedule_modes', mapping: 'delete_pm_schedule_modes', title: 'Delete Pm Schedule Modes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '41. PmScheduleModes', },
			{ name: 'permissions__view_pm_schedules', mapping: 'view_pm_schedules', title: 'View Pm Schedules', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '42. PmSchedules', },
			{ name: 'permissions__add_pm_schedules', mapping: 'add_pm_schedules', title: 'Add Pm Schedules', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '42. PmSchedules', },
			{ name: 'permissions__edit_pm_schedules', mapping: 'edit_pm_schedules', title: 'Edit Pm Schedules', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '42. PmSchedules', },
			{ name: 'permissions__delete_pm_schedules', mapping: 'delete_pm_schedules', title: 'Delete Pm Schedules', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '42. PmSchedules', },
			{ name: 'permissions__view_service_centers', mapping: 'view_service_centers', title: 'View Service Centers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '45. ServiceCenters', },
			{ name: 'permissions__add_service_centers', mapping: 'add_service_centers', title: 'Add Service Centers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '45. ServiceCenters', },
			{ name: 'permissions__edit_service_centers', mapping: 'edit_service_centers', title: 'Edit Service Centers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '45. ServiceCenters', },
			{ name: 'permissions__delete_service_centers', mapping: 'delete_service_centers', title: 'Delete Service Centers', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '45. ServiceCenters', },
			{ name: 'permissions__view_tel_operators', mapping: 'view_tel_operators', title: 'View Tel Operators', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '46. TelOperators', },
			{ name: 'permissions__add_tel_operators', mapping: 'add_tel_operators', title: 'Add Tel Operators', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '46. TelOperators', },
			{ name: 'permissions__edit_tel_operators', mapping: 'edit_tel_operators', title: 'Edit Tel Operators', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '46. TelOperators', },
			{ name: 'permissions__delete_tel_operators', mapping: 'delete_tel_operators', title: 'Delete Tel Operators', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '46. TelOperators', },
			{ name: 'permissions__view_tel_usages', mapping: 'view_tel_usages', title: 'View Tel Usages', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '47. TelUsages', },
			{ name: 'permissions__add_tel_usages', mapping: 'add_tel_usages', title: 'Add Tel Usages', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '47. TelUsages', },
			{ name: 'permissions__edit_tel_usages', mapping: 'edit_tel_usages', title: 'Edit Tel Usages', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '47. TelUsages', },
			{ name: 'permissions__delete_tel_usages', mapping: 'delete_tel_usages', title: 'Delete Tel Usages', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '47. TelUsages', },
			{ name: 'permissions__view_users', mapping: 'view_users', title: 'View Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '48. Users', },
			{ name: 'permissions__add_users', mapping: 'add_users', title: 'Add Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '48. Users', },
			{ name: 'permissions__edit_users', mapping: 'edit_users', title: 'Edit Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '48. Users', },
			{ name: 'permissions__delete_users', mapping: 'delete_users', title: 'Delete Users', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '48. Users', },
			{ name: 'permissions__view_util_crud_logs', mapping: 'view_util_crud_logs', title: 'View Util Crud Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '49. UtilCrudLogs', },
			{ name: 'permissions__add_util_crud_logs', mapping: 'add_util_crud_logs', title: 'Add Util Crud Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '49. UtilCrudLogs', },
			{ name: 'permissions__edit_util_crud_logs', mapping: 'edit_util_crud_logs', title: 'Edit Util Crud Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '49. UtilCrudLogs', },
			{ name: 'permissions__delete_util_crud_logs', mapping: 'delete_util_crud_logs', title: 'Delete Util Crud Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '49. UtilCrudLogs', },
			{ name: 'permissions__view_util_error_codes', mapping: 'view_util_error_codes', title: 'View Util Error Codes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '53. UtilErrorCodes', },
			{ name: 'permissions__add_util_error_codes', mapping: 'add_util_error_codes', title: 'Add Util Error Codes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '53. UtilErrorCodes', },
			{ name: 'permissions__edit_util_error_codes', mapping: 'edit_util_error_codes', title: 'Edit Util Error Codes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '53. UtilErrorCodes', },
			{ name: 'permissions__delete_util_error_codes', mapping: 'delete_util_error_codes', title: 'Delete Util Error Codes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '53. UtilErrorCodes', },
			{ name: 'permissions__view_util_error_logs', mapping: 'view_util_error_logs', title: 'View Util Error Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '54. UtilErrorLogs', },
			{ name: 'permissions__add_util_error_logs', mapping: 'add_util_error_logs', title: 'Add Util Error Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '54. UtilErrorLogs', },
			{ name: 'permissions__edit_util_error_logs', mapping: 'edit_util_error_logs', title: 'Edit Util Error Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '54. UtilErrorLogs', },
			{ name: 'permissions__delete_util_error_logs', mapping: 'delete_util_error_logs', title: 'Delete Util Error Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '54. UtilErrorLogs', },
			{ name: 'permissions__view_util_process_logs', mapping: 'view_util_process_logs', title: 'View Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '56. UtilProcessLogs', },
			{ name: 'permissions__add_util_process_logs', mapping: 'add_util_process_logs', title: 'Add Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '56. UtilProcessLogs', },
			{ name: 'permissions__edit_util_process_logs', mapping: 'edit_util_process_logs', title: 'Edit Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '56. UtilProcessLogs', },
			{ name: 'permissions__delete_util_process_logs', mapping: 'delete_util_process_logs', title: 'Delete Util Process Logs', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '56. UtilProcessLogs', },
			{ name: 'permissions__view_wo_classes', mapping: 'view_wo_classes', title: 'View Wo Classes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '50. WoClasses', },
			{ name: 'permissions__add_wo_classes', mapping: 'add_wo_classes', title: 'Add Wo Classes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '50. WoClasses', },
			{ name: 'permissions__edit_wo_classes', mapping: 'edit_wo_classes', title: 'Edit Wo Classes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '50. WoClasses', },
			{ name: 'permissions__delete_wo_classes', mapping: 'delete_wo_classes', title: 'Delete Wo Classes', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '50. WoClasses', },
			{ name: 'permissions__view_wo_labor_types', mapping: 'view_wo_labor_types', title: 'View Wo Labor Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '51. WoLaborTypes', },
			{ name: 'permissions__add_wo_labor_types', mapping: 'add_wo_labor_types', title: 'Add Wo Labor Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '51. WoLaborTypes', },
			{ name: 'permissions__edit_wo_labor_types', mapping: 'edit_wo_labor_types', title: 'Edit Wo Labor Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '51. WoLaborTypes', },
			{ name: 'permissions__delete_wo_labor_types', mapping: 'delete_wo_labor_types', title: 'Delete Wo Labor Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '51. WoLaborTypes', },
			{ name: 'permissions__view_wo_labors', mapping: 'view_wo_labors', title: 'View Wo Labors', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '52. WoLabors', },
			{ name: 'permissions__add_wo_labors', mapping: 'add_wo_labors', title: 'Add Wo Labors', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '52. WoLabors', },
			{ name: 'permissions__edit_wo_labors', mapping: 'edit_wo_labors', title: 'Edit Wo Labors', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '52. WoLabors', },
			{ name: 'permissions__delete_wo_labors', mapping: 'delete_wo_labors', title: 'Delete Wo Labors', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '52. WoLabors', },
			{ name: 'permissions__view_wo_other_charge_types', mapping: 'view_wo_other_charge_types', title: 'View Wo Other Charge Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '57. WoOtherChargeTypes', },
			{ name: 'permissions__add_wo_other_charge_types', mapping: 'add_wo_other_charge_types', title: 'Add Wo Other Charge Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '57. WoOtherChargeTypes', },
			{ name: 'permissions__edit_wo_other_charge_types', mapping: 'edit_wo_other_charge_types', title: 'Edit Wo Other Charge Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '57. WoOtherChargeTypes', },
			{ name: 'permissions__delete_wo_other_charge_types', mapping: 'delete_wo_other_charge_types', title: 'Delete Wo Other Charge Types', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '57. WoOtherChargeTypes', },
			{ name: 'permissions__view_wo_other_charges', mapping: 'view_wo_other_charges', title: 'View Wo Other Charges', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '55. WoOtherCharges', },
			{ name: 'permissions__add_wo_other_charges', mapping: 'add_wo_other_charges', title: 'Add Wo Other Charges', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '55. WoOtherCharges', },
			{ name: 'permissions__edit_wo_other_charges', mapping: 'edit_wo_other_charges', title: 'Edit Wo Other Charges', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '55. WoOtherCharges', },
			{ name: 'permissions__delete_wo_other_charges', mapping: 'delete_wo_other_charges', title: 'Delete Wo Other Charges', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '55. WoOtherCharges', },
			{ name: 'permissions__view_work_orders', mapping: 'view_work_orders', title: 'View Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '58. WorkOrders', },
			{ name: 'permissions__add_work_orders', mapping: 'add_work_orders', title: 'Add Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '58. WorkOrders', },
			{ name: 'permissions__edit_work_orders', mapping: 'edit_work_orders', title: 'Edit Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '58. WorkOrders', },
			{ name: 'permissions__delete_work_orders', mapping: 'delete_work_orders', title: 'Delete Work Orders', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '58. WorkOrders', },
			{ name: 'users__id', mapping: 'user.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__username', mapping: 'user.username', title: 'Username', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__email', mapping: 'user.email', title: 'Email', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password', mapping: 'user.password', title: 'Password', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__first_name', mapping: 'user.first_name', title: 'First Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_name', mapping: 'user.last_name', title: 'Last Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__employee_number', mapping: 'user.employee_number', title: 'Employee Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token', mapping: 'user.token', title: 'Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token_generated', mapping: 'user.token_generated', title: 'Token Generated', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__expo_token', mapping: 'user.expo_token', title: 'Expo Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password_reset', mapping: 'user.password_reset', title: 'Password Reset', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__full_name', mapping: 'user.full_name', title: 'Full Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_login', mapping: 'user.last_login', title: 'Last Login', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__login_count', mapping: 'user.login_count', title: 'Login Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__groups', mapping: 'user.groups', title: 'Groups', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'groups__id', mapping: 'group.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'groups__name', mapping: 'group.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'groups__description', mapping: 'group.description', title: 'Description', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Users',
				'Groups'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'permissions__user_id',
			// 'permissions__group_id',
			// 'permissions__view_reports',
			// 'permissions__view_quick_reports',
			// 'permissions__access_fleet_app',
			// 'permissions__access_pms_app',
			// 'permissions__access_users_app',
			// 'permissions__access_audit_app',
			// 'permissions__access_data_management_app',
			// 'permissions__access_lease_app',
			// 'permissions__access_rentals_app',
			// 'permissions__access_all_enterprises',
			// 'permissions__access_whole_enterprise',
			// 'permissions__drag_drop',
			// 'permissions__access_setup_mode',
			// 'permissions__view_charts',
			// 'permissions__receive_alerts',
			// 'permissions__view_addresses',
			// 'permissions__add_addresses',
			// 'permissions__edit_addresses',
			// 'permissions__delete_addresses',
			// 'permissions__view_attachments',
			// 'permissions__add_attachments',
			// 'permissions__edit_attachments',
			// 'permissions__delete_attachments',
			// 'permissions__view_business_partners',
			// 'permissions__add_business_partners',
			// 'permissions__edit_business_partners',
			// 'permissions__delete_business_partners',
			// 'permissions__view_business_partners_fleets',
			// 'permissions__add_business_partners_fleets',
			// 'permissions__edit_business_partners_fleets',
			// 'permissions__delete_business_partners_fleets',
			// 'permissions__view_chromes',
			// 'permissions__add_chromes',
			// 'permissions__edit_chromes',
			// 'permissions__delete_chromes',
			// 'permissions__view_enterprises',
			// 'permissions__add_enterprises',
			// 'permissions__edit_enterprises',
			// 'permissions__delete_enterprises',
			// 'permissions__view_eq_fork_lengths',
			// 'permissions__add_eq_fork_lengths',
			// 'permissions__edit_eq_fork_lengths',
			// 'permissions__delete_eq_fork_lengths',
			// 'permissions__view_eq_power_types',
			// 'permissions__add_eq_power_types',
			// 'permissions__edit_eq_power_types',
			// 'permissions__delete_eq_power_types',
			// 'permissions__view_eq_tire_types',
			// 'permissions__add_eq_tire_types',
			// 'permissions__edit_eq_tire_types',
			// 'permissions__delete_eq_tire_types',
			// 'permissions__view_eq_types',
			// 'permissions__add_eq_types',
			// 'permissions__edit_eq_types',
			// 'permissions__delete_eq_types',
			// 'permissions__view_eq_upright_types',
			// 'permissions__add_eq_upright_types',
			// 'permissions__edit_eq_upright_types',
			// 'permissions__delete_eq_upright_types',
			// 'permissions__view_eq_valve_types',
			// 'permissions__add_eq_valve_types',
			// 'permissions__edit_eq_valve_types',
			// 'permissions__delete_eq_valve_types',
			// 'permissions__view_eq_voltages',
			// 'permissions__add_eq_voltages',
			// 'permissions__edit_eq_voltages',
			// 'permissions__delete_eq_voltages',
			// 'permissions__view_equipment',
			// 'permissions__add_equipment',
			// 'permissions__edit_equipment',
			// 'permissions__delete_equipment',
			// 'permissions__view_equipment_pm_schedules',
			// 'permissions__add_equipment_pm_schedules',
			// 'permissions__edit_equipment_pm_schedules',
			// 'permissions__delete_equipment_pm_schedules',
			// 'permissions__view_fleets',
			// 'permissions__add_fleets',
			// 'permissions__edit_fleets',
			// 'permissions__delete_fleets',
			// 'permissions__view_groups',
			// 'permissions__add_groups',
			// 'permissions__edit_groups',
			// 'permissions__delete_groups',
			// 'permissions__view_groups_users',
			// 'permissions__add_groups_users',
			// 'permissions__edit_groups_users',
			// 'permissions__delete_groups_users',
			// 'permissions__view_import_manufacturers',
			// 'permissions__add_import_manufacturers',
			// 'permissions__edit_import_manufacturers',
			// 'permissions__delete_import_manufacturers',
			// 'permissions__view_import_manufacturers_util_process_logs',
			// 'permissions__add_import_manufacturers_util_process_logs',
			// 'permissions__edit_import_manufacturers_util_process_logs',
			// 'permissions__delete_import_manufacturers_util_process_logs',
			// 'permissions__view_import_pm_equipment',
			// 'permissions__add_import_pm_equipment',
			// 'permissions__edit_import_pm_equipment',
			// 'permissions__delete_import_pm_equipment',
			// 'permissions__view_import_pm_equipment_util_process_logs',
			// 'permissions__add_import_pm_equipment_util_process_logs',
			// 'permissions__edit_import_pm_equipment_util_process_logs',
			// 'permissions__delete_import_pm_equipment_util_process_logs',
			// 'permissions__view_import_service_centers',
			// 'permissions__add_import_service_centers',
			// 'permissions__edit_import_service_centers',
			// 'permissions__delete_import_service_centers',
			// 'permissions__view_import_service_centers_util_process_logs',
			// 'permissions__add_import_service_centers_util_process_logs',
			// 'permissions__edit_import_service_centers_util_process_logs',
			// 'permissions__delete_import_service_centers_util_process_logs',
			// 'permissions__view_import_technicians',
			// 'permissions__add_import_technicians',
			// 'permissions__edit_import_technicians',
			// 'permissions__delete_import_technicians',
			// 'permissions__view_import_technicians_util_process_logs',
			// 'permissions__add_import_technicians_util_process_logs',
			// 'permissions__edit_import_technicians_util_process_logs',
			// 'permissions__delete_import_technicians_util_process_logs',
			// 'permissions__view_import_wo_labors',
			// 'permissions__add_import_wo_labors',
			// 'permissions__edit_import_wo_labors',
			// 'permissions__delete_import_wo_labors',
			// 'permissions__view_import_wo_other_charge_types',
			// 'permissions__add_import_wo_other_charge_types',
			// 'permissions__edit_import_wo_other_charge_types',
			// 'permissions__delete_import_wo_other_charge_types',
			// 'permissions__view_import_wo_other_charge_types_util_process_logs',
			// 'permissions__add_import_wo_other_charge_types_util_process_logs',
			// 'permissions__edit_import_wo_other_charge_types_util_process_logs',
			// 'permissions__delete_import_wo_other_charge_types_util_process_logs',
			// 'permissions__view_import_wo_other_charges',
			// 'permissions__add_import_wo_other_charges',
			// 'permissions__edit_import_wo_other_charges',
			// 'permissions__delete_import_wo_other_charges',
			// 'permissions__view_import_wo_parts',
			// 'permissions__add_import_wo_parts',
			// 'permissions__edit_import_wo_parts',
			// 'permissions__delete_import_wo_parts',
			// 'permissions__view_import_work_orders',
			// 'permissions__add_import_work_orders',
			// 'permissions__edit_import_work_orders',
			// 'permissions__delete_import_work_orders',
			// 'permissions__view_import_work_orders_util_process_logs',
			// 'permissions__add_import_work_orders_util_process_logs',
			// 'permissions__edit_import_work_orders_util_process_logs',
			// 'permissions__delete_import_work_orders_util_process_logs',
			// 'permissions__view_manufacturers',
			// 'permissions__add_manufacturers',
			// 'permissions__edit_manufacturers',
			// 'permissions__delete_manufacturers',
			// 'permissions__view_meter_readings',
			// 'permissions__add_meter_readings',
			// 'permissions__edit_meter_readings',
			// 'permissions__delete_meter_readings',
			// 'permissions__view_part_types',
			// 'permissions__add_part_types',
			// 'permissions__edit_part_types',
			// 'permissions__delete_part_types',
			// 'permissions__view_parts',
			// 'permissions__add_parts',
			// 'permissions__edit_parts',
			// 'permissions__delete_parts',
			// 'permissions__view_parts_part_types',
			// 'permissions__add_parts_part_types',
			// 'permissions__edit_parts_part_types',
			// 'permissions__delete_parts_part_types',
			// 'permissions__view_parts_work_orders',
			// 'permissions__add_parts_work_orders',
			// 'permissions__edit_parts_work_orders',
			// 'permissions__delete_parts_work_orders',
			// 'permissions__view_permissions',
			// 'permissions__add_permissions',
			// 'permissions__edit_permissions',
			// 'permissions__delete_permissions',
			// 'permissions__view_pm_events',
			// 'permissions__add_pm_events',
			// 'permissions__edit_pm_events',
			// 'permissions__delete_pm_events',
			// 'permissions__view_pm_schedule_modes',
			// 'permissions__add_pm_schedule_modes',
			// 'permissions__edit_pm_schedule_modes',
			// 'permissions__delete_pm_schedule_modes',
			// 'permissions__view_pm_schedules',
			// 'permissions__add_pm_schedules',
			// 'permissions__edit_pm_schedules',
			// 'permissions__delete_pm_schedules',
			// 'permissions__view_service_centers',
			// 'permissions__add_service_centers',
			// 'permissions__edit_service_centers',
			// 'permissions__delete_service_centers',
			// 'permissions__view_tel_operators',
			// 'permissions__add_tel_operators',
			// 'permissions__edit_tel_operators',
			// 'permissions__delete_tel_operators',
			// 'permissions__view_tel_usages',
			// 'permissions__add_tel_usages',
			// 'permissions__edit_tel_usages',
			// 'permissions__delete_tel_usages',
			// 'permissions__view_users',
			// 'permissions__add_users',
			// 'permissions__edit_users',
			// 'permissions__delete_users',
			// 'permissions__view_util_crud_logs',
			// 'permissions__add_util_crud_logs',
			// 'permissions__edit_util_crud_logs',
			// 'permissions__delete_util_crud_logs',
			// 'permissions__view_util_error_codes',
			// 'permissions__add_util_error_codes',
			// 'permissions__edit_util_error_codes',
			// 'permissions__delete_util_error_codes',
			// 'permissions__view_util_error_logs',
			// 'permissions__add_util_error_logs',
			// 'permissions__edit_util_error_logs',
			// 'permissions__delete_util_error_logs',
			// 'permissions__view_util_process_logs',
			// 'permissions__add_util_process_logs',
			// 'permissions__edit_util_process_logs',
			// 'permissions__delete_util_process_logs',
			// 'permissions__view_wo_classes',
			// 'permissions__add_wo_classes',
			// 'permissions__edit_wo_classes',
			// 'permissions__delete_wo_classes',
			// 'permissions__view_wo_labor_types',
			// 'permissions__add_wo_labor_types',
			// 'permissions__edit_wo_labor_types',
			// 'permissions__delete_wo_labor_types',
			// 'permissions__view_wo_labors',
			// 'permissions__add_wo_labors',
			// 'permissions__edit_wo_labors',
			// 'permissions__delete_wo_labors',
			// 'permissions__view_wo_other_charge_types',
			// 'permissions__add_wo_other_charge_types',
			// 'permissions__edit_wo_other_charge_types',
			// 'permissions__delete_wo_other_charge_types',
			// 'permissions__view_wo_other_charges',
			// 'permissions__add_wo_other_charges',
			// 'permissions__edit_wo_other_charges',
			// 'permissions__delete_wo_other_charges',
			// 'permissions__view_work_orders',
			// 'permissions__add_work_orders',
			// 'permissions__edit_work_orders',
			// 'permissions__delete_work_orders'
		],

	},

	entity: {
		methods: {

			getUser: async function() {
				const Users = this.getAssociatedRepository('Users');
				let entity = Users.getById(this.permissions__user_id);
				if (!entity) {
					entity = await Users.getSingleEntityFromServer(this.permissions__user_id);
				}
				return entity;
			},

			getGroup: async function() {
				const Groups = this.getAssociatedRepository('Groups');
				let entity = Groups.getById(this.permissions__group_id);
				if (!entity) {
					entity = await Groups.getSingleEntityFromServer(this.permissions__group_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default Permissions;
