/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportWoServiceTypesUtilProcessLogsGridEditor from './ImportWoServiceTypesUtilProcessLogsGridEditor.js';

export default function ImportWoServiceTypesUtilProcessLogsFilteredGridEditor(props) {
	return <ImportWoServiceTypesUtilProcessLogsGridEditor
				reference="ImportWoServiceTypesUtilProcessLogsFilteredGridEditor"
				useFilters={true}

				{...props}
			/>;
}