export default [
	    {
	        "id": "import_wo_parts__woid",
	        "header": "WOID",
	        "fieldName": "import_wo_parts__woid",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_wo_parts__line_item",
	        "header": "Line Item",
	        "fieldName": "import_wo_parts__line_item",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_parts__manufacturer",
	        "header": "Manufacturer",
	        "fieldName": "import_wo_parts__manufacturer",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_wo_parts__part_number",
	        "header": "Part Number",
	        "fieldName": "import_wo_parts__part_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_wo_parts__description",
	        "header": "Description",
	        "fieldName": "import_wo_parts__description",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "import_wo_parts__qty_ordered",
	        "header": "Qty Ordered",
	        "fieldName": "import_wo_parts__qty_ordered",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_parts__qty_shipped",
	        "header": "Qty Shipped",
	        "fieldName": "import_wo_parts__qty_shipped",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_parts__unit_cost",
	        "header": "Unit Cost",
	        "fieldName": "import_wo_parts__unit_cost",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_parts__unit_price",
	        "header": "Unit Price",
	        "fieldName": "import_wo_parts__unit_price",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_parts__ext_cost",
	        "header": "Ext Cost",
	        "fieldName": "import_wo_parts__ext_cost",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_parts__ext_price",
	        "header": "Ext Price",
	        "fieldName": "import_wo_parts__ext_price",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_parts__is_imported",
	        "header": "Imported?",
	        "fieldName": "import_wo_parts__is_imported",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_parts__imported_date",
	        "header": "Imported Date",
	        "fieldName": "import_wo_parts__imported_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    }
	];