/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportManufacturersUtilProcessLogsInlineGridEditor from './ImportManufacturersUtilProcessLogsInlineGridEditor.js';

export default function ImportManufacturersUtilProcessLogsFilteredInlineGridEditor(props) {
	return <ImportManufacturersUtilProcessLogsInlineGridEditor
				reference="ImportManufacturersUtilProcessLogsFilteredInlineGridEditor"
				useFilters={true}

				{...props}
			/>;
}