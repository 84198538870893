/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportWorkOrdersEditor from '../Editor/ImportWorkOrdersEditor.js';
import ImportWorkOrdersGridColumns from './Columns/ImportWorkOrdersGridColumns.js';

export default function ImportWorkOrdersSideGridEditor(props) {
	return <SideGridEditor
				reference="ImportWorkOrdersSideGridEditor"
				model="ImportWorkOrders"
				usePermissions={true}
				isCollapsible={false}
				Editor={ImportWorkOrdersEditor}
				columnsConfig={ImportWorkOrdersGridColumns}
				
				
				{...props}
			/>;
}