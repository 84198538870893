import {
	Text,
} from 'native-base';
import CenterBox from '@onehat/ui/src/Components/Layout/CenterBox';
import { useSelector } from 'react-redux';
import {
	selectTreeSelection,
} from '../../models/Slices/AppSlice';

export default function TreeSpecific(props) {

	const
		treeSelection = useSelector(selectTreeSelection),
		hasTreeSelection = !_.isEmpty(treeSelection);

	if (!hasTreeSelection) {
		return <CenterBox {...props}>
					<Text textAlign="center">Please make a selection on the tree</Text>
				</CenterBox>;
	}

	return props.children;
}