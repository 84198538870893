/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportWorkOrdersUtilProcessLogsEditorWindowSideA from '../Window/ImportWorkOrdersUtilProcessLogsEditorWindowSideA.js';
import ImportWorkOrdersUtilProcessLogsGridColumnsSideA from './Columns/ImportWorkOrdersUtilProcessLogsGridColumnsSideA.js';

export default function ImportWorkOrdersUtilProcessLogsGridEditorSideA(props) {
	return <WindowedGridEditor
				reference="ImportWorkOrdersUtilProcessLogsGridEditorSideA"
				model="ImportWorkOrdersUtilProcessLogs"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={ImportWorkOrdersUtilProcessLogsEditorWindowSideA}
				columnsConfig={ImportWorkOrdersUtilProcessLogsGridColumnsSideA}
				canColumnsReorder={false}
				canColumnsResize={false}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}