import * as yup from 'yup'; // https://github.com/jquense/yup#string

const EqStats = {
	
	name: 'EqStats',
	
	model: {

		idProperty: 'eq_stats__id',
		displayProperty: 'eq_stats__id',
		sortProperty: 'eq_stats__id',
		
		sorters: null,

		validator: yup.object({

		}),
		
		properties: [
			{ name: 'eq_stats__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: 'General', },
			{ name: 'eq_stats__equipment_id', mapping: 'equipment_id', title: 'Equipment', type: 'int', isFk: true, fkIdField: 'equipment__id', fkDisplayField: 'equipment__unit_number', filterType: {"type":"EquipmentCombo","loadAfterRender":!1}, isEditingDisabled: true, fieldGroup: '1. General', },
			{ name: 'eq_stats__is_stale', mapping: 'is_stale', title: 'Stale?', type: 'bool', filterType: {"type":"Toggle"}, isEditingDisabled: true, fieldGroup: '1. General', },
			{ name: 'eq_stats__path', mapping: 'path', title: 'Path', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: '1. General', },
			{ name: 'eq_stats__latest_meter_reading', mapping: 'latest_meter_reading', title: 'Latest Meter Reading', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '1. General', },
			{ name: 'eq_stats__latest_meter_date', mapping: 'latest_meter_date', title: 'Latest Meter Date', type: 'date', filterType: {"type":"DateRange","mode":"DATE"}, isEditingDisabled: true, fieldGroup: '1. General', },
			{ name: 'eq_stats__latest_meter_source', mapping: 'latest_meter_source', title: 'Latest Meter Source', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '1. General', },
			{ name: 'eq_stats__latest_repair_date', mapping: 'latest_repair_date', title: 'Latest Repair Date', type: 'date', filterType: {"type":"DateRange","mode":"DATE"}, isEditingDisabled: true, fieldGroup: '1. General', },
			{ name: 'eq_stats__latest_pm_data', mapping: 'latest_pm_data', title: 'Latest Pm Data', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: '1. General', },
			{ name: 'eq_stats__mean_time_between_failures', mapping: 'mean_time_between_failures', title: 'Mean Time Between Failures', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '1. General', },
			{ name: 'eq_stats__estimated_annual_meter', mapping: 'estimated_annual_meter', title: 'Estimated Annual Meter', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '1. General', },
			{ name: 'eq_stats__is_warranty', mapping: 'is_warranty', title: 'Warranty?', type: 'bool', filterType: {"type":"Toggle"}, isEditingDisabled: true, fieldGroup: '1. General', },
			{ name: 'eq_stats__is_lease', mapping: 'is_lease', title: 'Lease?', type: 'bool', filterType: {"type":"Toggle"}, isEditingDisabled: true, fieldGroup: '1. General', },
			{ name: 'eq_stats__is_ltm', mapping: 'is_ltm', title: 'Ltm?', type: 'bool', filterType: {"type":"Toggle"}, isEditingDisabled: true, fieldGroup: '1. General', },
			{ name: 'eq_stats__due_pms', mapping: 'due_pms', title: 'Due Pms', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '2. PM', },
			{ name: 'eq_stats__next_pm_due_data', mapping: 'next_pm_due_data', title: 'Next Pm Due Data', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: '2. PM', },
			{ name: 'eq_stats__overdue_pms', mapping: 'overdue_pms', title: 'Overdue Pms', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '2. PM', },
			{ name: 'eq_stats__assigned_technician_data', mapping: 'assigned_technician_data', title: 'Assigned Technician Data', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: '2. PM', },
			{ name: 'eq_stats__lease_number', mapping: 'lease_number', title: 'Lease Number', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: '4. Lease', },
			{ name: 'eq_stats__lease_term', mapping: 'lease_term', title: 'Lease Term', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '4. Lease', },
			{ name: 'eq_stats__lease_expected_end_date', mapping: 'lease_expected_end_date', title: 'Lease Expected End Date', type: 'date', filterType: {"type":"DateRange","mode":"DATE"}, isEditingDisabled: true, fieldGroup: '4. Lease', },
			{ name: 'eq_stats__lease_status_id', mapping: 'lease_status_id', title: 'Lease Status', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '4. Lease', },
			{ name: 'eq_stats__lease_payment', mapping: 'lease_payment', title: 'Lease Payment', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '4. Lease', },
			{ name: 'eq_stats__lease_pays_remaining', mapping: 'lease_pays_remaining', title: 'Lease Pays Remaining', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '4. Lease', },
			{ name: 'eq_stats__lease_midterm_checked', mapping: 'lease_midterm_checked', title: 'Lease Midterm Checked', type: 'bool', filterType: {"type":"Toggle"}, isEditingDisabled: true, fieldGroup: '4. Lease', },
			{ name: 'eq_stats__lease_inspected', mapping: 'lease_inspected', title: 'Lease Inspected', type: 'bool', filterType: {"type":"Toggle"}, isEditingDisabled: true, fieldGroup: '4. Lease', },
			{ name: 'eq_stats__ltm_contract_number', mapping: 'ltm_contract_number', title: 'Ltm Contract Number', isFilteringDisabled: true, isEditingDisabled: true, fieldGroup: '3. LTM', },
			{ name: 'eq_stats__ltm_start_date', mapping: 'ltm_start_date', title: 'Ltm Start Date', type: 'date', filterType: {"type":"DateRange","mode":"DATE"}, isEditingDisabled: true, fieldGroup: '3. LTM', },
			{ name: 'eq_stats__ltm_end_date', mapping: 'ltm_end_date', title: 'Ltm End Date', type: 'date', filterType: {"type":"DateRange","mode":"DATE"}, isEditingDisabled: true, fieldGroup: '3. LTM', },
			{ name: 'eq_stats__ltm_elapsed_annual_meter', mapping: 'ltm_elapsed_annual_meter', title: 'Ltm Elapsed Annual Meter', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '3. LTM', },
			{ name: 'eq_stats__ltm_max_annual_meter', mapping: 'ltm_max_annual_meter', title: 'Ltm Max Annual Meter', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '3. LTM', },
			{ name: 'eq_stats__ltm_elapsed_contract_meter', mapping: 'ltm_elapsed_contract_meter', title: 'Ltm Elapsed Contract Meter', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '3. LTM', },
			{ name: 'eq_stats__ltm_max_contract_meter', mapping: 'ltm_max_contract_meter', title: 'Ltm Max Contract Meter', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '3. LTM', },
			{ name: 'eq_stats__ltm_latest_invoice_date', mapping: 'ltm_latest_invoice_date', title: 'Ltm Latest Invoice Date', type: 'date', filterType: {"type":"DateRange","mode":"DATE"}, isEditingDisabled: true, fieldGroup: '3. LTM', },
			{ name: 'eq_stats__ltm_latest_invoice_amount', mapping: 'ltm_latest_invoice_amount', title: 'Ltm Latest Invoice Amount', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '3. LTM', },
			{ name: 'eq_stats__mtd_meter', mapping: 'mtd_meter', title: 'Mtd Meter', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. MTD', },
			{ name: 'eq_stats__mtd_available_meter', mapping: 'mtd_available_meter', title: 'Mtd Available Meter', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. MTD', },
			{ name: 'eq_stats__mtd_util', mapping: 'mtd_util', title: 'Mtd Util', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. MTD', },
			{ name: 'eq_stats__mtd_maint', mapping: 'mtd_maint', title: 'Mtd Maint', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. MTD', },
			{ name: 'eq_stats__mtd_maint_preventive', mapping: 'mtd_maint_preventive', title: 'Mtd Maint Preventive', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. MTD', },
			{ name: 'eq_stats__mtd_maint_abuse', mapping: 'mtd_maint_abuse', title: 'Mtd Maint Abuse', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. MTD', },
			{ name: 'eq_stats__mtd_maint_abuseRental', mapping: 'mtd_maint_abuseRental', title: 'Mtd Maint AbuseRental', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. MTD', },
			{ name: 'eq_stats__mtd_maint_tires', mapping: 'mtd_maint_tires', title: 'Mtd Maint Tires', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. MTD', },
			{ name: 'eq_stats__mtd_maint_repair', mapping: 'mtd_maint_repair', title: 'Mtd Maint Repair', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. MTD', },
			{ name: 'eq_stats__mtd_maint_ltm', mapping: 'mtd_maint_ltm', title: 'Mtd Maint Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. MTD', },
			{ name: 'eq_stats__mtd_maint_preventive_ltm', mapping: 'mtd_maint_preventive_ltm', title: 'Mtd Maint Preventive Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. MTD', },
			{ name: 'eq_stats__mtd_maint_abuse_ltm', mapping: 'mtd_maint_abuse_ltm', title: 'Mtd Maint Abuse Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. MTD', },
			{ name: 'eq_stats__mtd_maint_tires_ltm', mapping: 'mtd_maint_tires_ltm', title: 'Mtd Maint Tires Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. MTD', },
			{ name: 'eq_stats__mtd_maint_repair_ltm', mapping: 'mtd_maint_repair_ltm', title: 'Mtd Maint Repair Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. MTD', },
			{ name: 'eq_stats__mtd_maint_combined', mapping: 'mtd_maint_combined', title: 'Mtd Maint Combined', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. MTD', },
			{ name: 'eq_stats__mtd_ltm_billing', mapping: 'mtd_ltm_billing', title: 'Mtd Ltm Billing', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. MTD', },
			{ name: 'eq_stats__mtd_lease', mapping: 'mtd_lease', title: 'Mtd Lease', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. MTD', },
			{ name: 'eq_stats__mtd_depreciation', mapping: 'mtd_depreciation', title: 'Mtd Depreciation', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. MTD', },
			{ name: 'eq_stats__mtd_cost_per_hr', mapping: 'mtd_cost_per_hr', title: 'Mtd Cost Per Hr', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. MTD', },
			{ name: 'eq_stats__mtd_cost_per_hr_customer', mapping: 'mtd_cost_per_hr_customer', title: 'Mtd Cost Per Hr Customer', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. MTD', },
			{ name: 'eq_stats__mtd_cost_per_hr_ltm', mapping: 'mtd_cost_per_hr_ltm', title: 'Mtd Cost Per Hr Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '5. MTD', },
			{ name: 'eq_stats__qtd_meter', mapping: 'qtd_meter', title: 'Qtd Meter', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '6. QTD', },
			{ name: 'eq_stats__qtd_available_meter', mapping: 'qtd_available_meter', title: 'Qtd Available Meter', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '6. QTD', },
			{ name: 'eq_stats__qtd_util', mapping: 'qtd_util', title: 'Qtd Util', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '6. QTD', },
			{ name: 'eq_stats__ytd_meter', mapping: 'ytd_meter', title: 'Ytd Meter', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '7. YTD', },
			{ name: 'eq_stats__ytd_available_meter', mapping: 'ytd_available_meter', title: 'Ytd Available Meter', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '7. YTD', },
			{ name: 'eq_stats__ytd_util', mapping: 'ytd_util', title: 'Ytd Util', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '7. YTD', },
			{ name: 'eq_stats__ytd_maint', mapping: 'ytd_maint', title: 'Ytd Maint', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '7. YTD', },
			{ name: 'eq_stats__ytd_maint_preventive', mapping: 'ytd_maint_preventive', title: 'Ytd Maint Preventive', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '7. YTD', },
			{ name: 'eq_stats__ytd_maint_abuse', mapping: 'ytd_maint_abuse', title: 'Ytd Maint Abuse', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '7. YTD', },
			{ name: 'eq_stats__ytd_maint_abuseRental', mapping: 'ytd_maint_abuseRental', title: 'Ytd Maint AbuseRental', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '7. YTD', },
			{ name: 'eq_stats__ytd_maint_tires', mapping: 'ytd_maint_tires', title: 'Ytd Maint Tires', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '7. YTD', },
			{ name: 'eq_stats__ytd_maint_repair', mapping: 'ytd_maint_repair', title: 'Ytd Maint Repair', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '7. YTD', },
			{ name: 'eq_stats__ytd_maint_ltm', mapping: 'ytd_maint_ltm', title: 'Ytd Maint Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '7. YTD', },
			{ name: 'eq_stats__ytd_maint_preventive_ltm', mapping: 'ytd_maint_preventive_ltm', title: 'Ytd Maint Preventive Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '7. YTD', },
			{ name: 'eq_stats__ytd_maint_abuse_ltm', mapping: 'ytd_maint_abuse_ltm', title: 'Ytd Maint Abuse Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '7. YTD', },
			{ name: 'eq_stats__ytd_maint_tires_ltm', mapping: 'ytd_maint_tires_ltm', title: 'Ytd Maint Tires Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '7. YTD', },
			{ name: 'eq_stats__ytd_maint_repair_ltm', mapping: 'ytd_maint_repair_ltm', title: 'Ytd Maint Repair Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '7. YTD', },
			{ name: 'eq_stats__ytd_maint_combined', mapping: 'ytd_maint_combined', title: 'Ytd Maint Combined', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '7. YTD', },
			{ name: 'eq_stats__ytd_ltm_billing', mapping: 'ytd_ltm_billing', title: 'Ytd Ltm Billing', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '7. YTD', },
			{ name: 'eq_stats__ytd_lease', mapping: 'ytd_lease', title: 'Ytd Lease', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '7. YTD', },
			{ name: 'eq_stats__ytd_depreciation', mapping: 'ytd_depreciation', title: 'Ytd Depreciation', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '7. YTD', },
			{ name: 'eq_stats__ytd_cost_per_hr', mapping: 'ytd_cost_per_hr', title: 'Ytd Cost Per Hr', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '7. YTD', },
			{ name: 'eq_stats__ytd_cost_per_hr_customer', mapping: 'ytd_cost_per_hr_customer', title: 'Ytd Cost Per Hr Customer', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '7. YTD', },
			{ name: 'eq_stats__ytd_cost_per_hr_ltm', mapping: 'ytd_cost_per_hr_ltm', title: 'Ytd Cost Per Hr Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '7. YTD', },
			{ name: 'eq_stats__rtm_meter', mapping: 'rtm_meter', title: 'Rtm Meter', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '8. RTM', },
			{ name: 'eq_stats__rtm_available_meter', mapping: 'rtm_available_meter', title: 'Rtm Available Meter', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '8. RTM', },
			{ name: 'eq_stats__rtm_util', mapping: 'rtm_util', title: 'Rtm Util', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '8. RTM', },
			{ name: 'eq_stats__rtm_maint', mapping: 'rtm_maint', title: 'Rtm Maint', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '8. RTM', },
			{ name: 'eq_stats__rtm_maint_preventive', mapping: 'rtm_maint_preventive', title: 'Rtm Maint Preventive', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '8. RTM', },
			{ name: 'eq_stats__rtm_maint_abuse', mapping: 'rtm_maint_abuse', title: 'Rtm Maint Abuse', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '8. RTM', },
			{ name: 'eq_stats__rtm_maint_abuseRental', mapping: 'rtm_maint_abuseRental', title: 'Rtm Maint AbuseRental', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '8. RTM', },
			{ name: 'eq_stats__rtm_maint_tires', mapping: 'rtm_maint_tires', title: 'Rtm Maint Tires', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '8. RTM', },
			{ name: 'eq_stats__rtm_maint_repair', mapping: 'rtm_maint_repair', title: 'Rtm Maint Repair', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '8. RTM', },
			{ name: 'eq_stats__rtm_maint_ltm', mapping: 'rtm_maint_ltm', title: 'Rtm Maint Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '8. RTM', },
			{ name: 'eq_stats__rtm_maint_preventive_ltm', mapping: 'rtm_maint_preventive_ltm', title: 'Rtm Maint Preventive Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '8. RTM', },
			{ name: 'eq_stats__rtm_maint_abuse_ltm', mapping: 'rtm_maint_abuse_ltm', title: 'Rtm Maint Abuse Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '8. RTM', },
			{ name: 'eq_stats__rtm_maint_tires_ltm', mapping: 'rtm_maint_tires_ltm', title: 'Rtm Maint Tires Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '8. RTM', },
			{ name: 'eq_stats__rtm_maint_repair_ltm', mapping: 'rtm_maint_repair_ltm', title: 'Rtm Maint Repair Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '8. RTM', },
			{ name: 'eq_stats__rtm_maint_combined', mapping: 'rtm_maint_combined', title: 'Rtm Maint Combined', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '8. RTM', },
			{ name: 'eq_stats__rtm_ltm_billing', mapping: 'rtm_ltm_billing', title: 'Rtm Ltm Billing', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '8. RTM', },
			{ name: 'eq_stats__rtm_lease', mapping: 'rtm_lease', title: 'Rtm Lease', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '8. RTM', },
			{ name: 'eq_stats__rtm_depreciation', mapping: 'rtm_depreciation', title: 'Rtm Depreciation', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '8. RTM', },
			{ name: 'eq_stats__rtm_cost_per_hr', mapping: 'rtm_cost_per_hr', title: 'Rtm Cost Per Hr', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '8. RTM', },
			{ name: 'eq_stats__rtm_cost_per_hr_customer', mapping: 'rtm_cost_per_hr_customer', title: 'Rtm Cost Per Hr Customer', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '8. RTM', },
			{ name: 'eq_stats__rtm_cost_per_hr_ltm', mapping: 'rtm_cost_per_hr_ltm', title: 'Rtm Cost Per Hr Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '8. RTM', },
			{ name: 'eq_stats__ltd_meter', mapping: 'ltd_meter', title: 'Ltd Meter', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '9. LTD', },
			{ name: 'eq_stats__ltd_available_meter', mapping: 'ltd_available_meter', title: 'Ltd Available Meter', type: 'int', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '9. LTD', },
			{ name: 'eq_stats__ltd_util', mapping: 'ltd_util', title: 'Ltd Util', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '9. LTD', },
			{ name: 'eq_stats__ltd_maint', mapping: 'ltd_maint', title: 'Ltd Maint', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '9. LTD', },
			{ name: 'eq_stats__ltd_maint_preventive', mapping: 'ltd_maint_preventive', title: 'Ltd Maint Preventive', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '9. LTD', },
			{ name: 'eq_stats__ltd_maint_abuse', mapping: 'ltd_maint_abuse', title: 'Ltd Maint Abuse', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '9. LTD', },
			{ name: 'eq_stats__ltd_maint_abuseRental', mapping: 'ltd_maint_abuseRental', title: 'Ltd Maint AbuseRental', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '9. LTD', },
			{ name: 'eq_stats__ltd_maint_tires', mapping: 'ltd_maint_tires', title: 'Ltd Maint Tires', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '9. LTD', },
			{ name: 'eq_stats__ltd_maint_repair', mapping: 'ltd_maint_repair', title: 'Ltd Maint Repair', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '9. LTD', },
			{ name: 'eq_stats__ltd_maint_ltm', mapping: 'ltd_maint_ltm', title: 'Ltd Maint Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '9. LTD', },
			{ name: 'eq_stats__ltd_maint_preventive_ltm', mapping: 'ltd_maint_preventive_ltm', title: 'Ltd Maint Preventive Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '9. LTD', },
			{ name: 'eq_stats__ltd_maint_abuse_ltm', mapping: 'ltd_maint_abuse_ltm', title: 'Ltd Maint Abuse Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '9. LTD', },
			{ name: 'eq_stats__ltd_maint_tires_ltm', mapping: 'ltd_maint_tires_ltm', title: 'Ltd Maint Tires Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '9. LTD', },
			{ name: 'eq_stats__ltd_maint_repair_ltm', mapping: 'ltd_maint_repair_ltm', title: 'Ltd Maint Repair Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '9. LTD', },
			{ name: 'eq_stats__ltd_maint_combined', mapping: 'ltd_maint_combined', title: 'Ltd Maint Combined', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '9. LTD', },
			{ name: 'eq_stats__ltd_ltm_billing', mapping: 'ltd_ltm_billing', title: 'Ltd Ltm Billing', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '9. LTD', },
			{ name: 'eq_stats__ltd_lease', mapping: 'ltd_lease', title: 'Ltd Lease', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '9. LTD', },
			{ name: 'eq_stats__ltd_depreciation', mapping: 'ltd_depreciation', title: 'Ltd Depreciation', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '9. LTD', },
			{ name: 'eq_stats__ltd_cost_per_hr', mapping: 'ltd_cost_per_hr', title: 'Ltd Cost Per Hr', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '9. LTD', },
			{ name: 'eq_stats__ltd_cost_per_hr_customer', mapping: 'ltd_cost_per_hr_customer', title: 'Ltd Cost Per Hr Customer', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '9. LTD', },
			{ name: 'eq_stats__ltd_cost_per_hr_ltm', mapping: 'ltd_cost_per_hr_ltm', title: 'Ltd Cost Per Hr Ltm', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '9. LTD', },
			{ name: 'equipment__id', mapping: 'equipment.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__enterprise_id', mapping: 'equipment.enterprise_id', title: 'Enterprise', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__fleet_id', mapping: 'equipment.fleet_id', title: 'Fleet', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_status_id', mapping: 'equipment.eq_status_id', title: 'Status', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__inactive_date', mapping: 'equipment.inactive_date', title: 'Inactive Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__year', mapping: 'equipment.year', title: 'Year', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__manufacturer_id', mapping: 'equipment.manufacturer_id', title: 'Make', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__model', mapping: 'equipment.model', title: 'Model', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__serial_number', mapping: 'equipment.serial_number', title: 'Serial #', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__unit_number', mapping: 'equipment.unit_number', title: 'Unit #', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__asset_number', mapping: 'equipment.asset_number', title: 'Asset # (Cust ID)', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__dept_number', mapping: 'equipment.dept_number', title: 'Dept #', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__business_partner_id', mapping: 'equipment.business_partner_id', title: 'Business Partner', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_type_id', mapping: 'equipment.eq_type_id', title: 'Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__tel_manufacturer_id', mapping: 'equipment.tel_manufacturer_id', title: 'Tel Manufacturer', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__tel_pid', mapping: 'equipment.tel_pid', title: 'Tel Pid', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__comments', mapping: 'equipment.comments', title: 'Comments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_misc', mapping: 'equipment.is_misc', title: 'Misc?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_pm_enable', mapping: 'equipment.is_pm_enable', title: 'Pm Enable?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__pm_schedules', mapping: 'equipment.pm_schedules', title: 'PM Schedules', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__expected_meter_per_month', mapping: 'equipment.expected_meter_per_month', title: 'Expected Meter Per Month', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__available_meter_per_week', mapping: 'equipment.available_meter_per_week', title: 'Available Meter Per Week', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__in_service_date', mapping: 'equipment.in_service_date', title: 'In Service', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__in_service_meter', mapping: 'equipment.in_service_meter', title: 'In Service Meter', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__initial_maint_expense', mapping: 'equipment.initial_maint_expense', title: 'Initial Maint $', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__initial_book_value', mapping: 'equipment.initial_book_value', title: 'Book Value $', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__purchase_price', mapping: 'equipment.purchase_price', title: 'Purchase Price', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_track_meter_enable', mapping: 'equipment.is_track_meter_enable', title: 'Track Meter Enable?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__max_meter_allowed', mapping: 'equipment.max_meter_allowed', title: 'Max Meter Allowed', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__expected_revenue_per_month', mapping: 'equipment.expected_revenue_per_month', title: 'Expected Revenue Per Month', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__warranty_expires', mapping: 'equipment.warranty_expires', title: 'Warranty Expires', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__warranty_meter', mapping: 'equipment.warranty_meter', title: 'Warranty Meter', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__capacity', mapping: 'equipment.capacity', title: 'Capacity', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_upright_type_id', mapping: 'equipment.eq_upright_type_id', title: 'Upright Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__upright_lhloh', mapping: 'equipment.upright_lhloh', title: 'LH / LoH', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_valve_type_id', mapping: 'equipment.eq_valve_type_id', title: 'Eq Valve Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_power_type_id', mapping: 'equipment.eq_power_type_id', title: 'Eq Power Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_tire_type_id', mapping: 'equipment.eq_tire_type_id', title: 'Eq Tire Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__battery_size', mapping: 'equipment.battery_size', title: 'Battery Size', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_voltage_id', mapping: 'equipment.eq_voltage_id', title: 'Eq Voltage', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_fork_length_id', mapping: 'equipment.eq_fork_length_id', title: 'Eq Fork Length', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_attachments', mapping: 'equipment.eq_attachments', title: 'Attachments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_fluorescent_stripe', mapping: 'equipment.is_fluorescent_stripe', title: 'Fluorescent Striping?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_whelen_light', mapping: 'equipment.is_whelen_light', title: 'Whelen Lights?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_blue_led_light', mapping: 'equipment.is_blue_led_light', title: 'Blue LED Spot Light?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_high_vis_seatbelt', mapping: 'equipment.is_high_vis_seatbelt', title: 'Hi-Vis Seatbelt?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_rear_handle_horn', mapping: 'equipment.is_rear_handle_horn', title: 'Rear Handle w/Horn?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_contact_decals', mapping: 'equipment.is_contact_decals', title: '3-Point of Contact Decals?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_pallet_protection', mapping: 'equipment.is_pallet_protection', title: 'Pallet Protection?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_laser_light', mapping: 'equipment.is_laser_light', title: 'Laser Light?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Equipment'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'eq_stats__equipment_id',
			// 'eq_stats__is_stale',
			// 'eq_stats__latest_meter_reading',
			// 'eq_stats__latest_meter_date',
			// 'eq_stats__latest_meter_source',
			// 'eq_stats__latest_repair_date',
			// 'eq_stats__mean_time_between_failures',
			// 'eq_stats__estimated_annual_meter',
			// 'eq_stats__is_warranty',
			// 'eq_stats__is_lease',
			// 'eq_stats__is_ltm',
			// 'eq_stats__due_pms',
			// 'eq_stats__overdue_pms',
			// 'eq_stats__lease_term',
			// 'eq_stats__lease_expected_end_date',
			// 'eq_stats__lease_status_id',
			// 'eq_stats__lease_payment',
			// 'eq_stats__lease_pays_remaining',
			// 'eq_stats__lease_midterm_checked',
			// 'eq_stats__lease_inspected',
			// 'eq_stats__ltm_start_date',
			// 'eq_stats__ltm_end_date',
			// 'eq_stats__ltm_elapsed_annual_meter',
			// 'eq_stats__ltm_max_annual_meter',
			// 'eq_stats__ltm_elapsed_contract_meter',
			// 'eq_stats__ltm_max_contract_meter',
			// 'eq_stats__ltm_latest_invoice_date',
			// 'eq_stats__ltm_latest_invoice_amount',
			// 'eq_stats__mtd_meter',
			// 'eq_stats__mtd_available_meter',
			// 'eq_stats__mtd_util',
			// 'eq_stats__mtd_maint',
			// 'eq_stats__mtd_maint_preventive',
			// 'eq_stats__mtd_maint_abuse',
			// 'eq_stats__mtd_maint_abuseRental',
			// 'eq_stats__mtd_maint_tires',
			// 'eq_stats__mtd_maint_repair',
			// 'eq_stats__mtd_maint_ltm',
			// 'eq_stats__mtd_maint_preventive_ltm',
			// 'eq_stats__mtd_maint_abuse_ltm',
			// 'eq_stats__mtd_maint_tires_ltm',
			// 'eq_stats__mtd_maint_repair_ltm',
			// 'eq_stats__mtd_maint_combined',
			// 'eq_stats__mtd_ltm_billing',
			// 'eq_stats__mtd_lease',
			// 'eq_stats__mtd_depreciation',
			// 'eq_stats__mtd_cost_per_hr',
			// 'eq_stats__mtd_cost_per_hr_customer',
			// 'eq_stats__mtd_cost_per_hr_ltm',
			// 'eq_stats__qtd_meter',
			// 'eq_stats__qtd_available_meter',
			// 'eq_stats__qtd_util',
			// 'eq_stats__ytd_meter',
			// 'eq_stats__ytd_available_meter',
			// 'eq_stats__ytd_util',
			// 'eq_stats__ytd_maint',
			// 'eq_stats__ytd_maint_preventive',
			// 'eq_stats__ytd_maint_abuse',
			// 'eq_stats__ytd_maint_abuseRental',
			// 'eq_stats__ytd_maint_tires',
			// 'eq_stats__ytd_maint_repair',
			// 'eq_stats__ytd_maint_ltm',
			// 'eq_stats__ytd_maint_preventive_ltm',
			// 'eq_stats__ytd_maint_abuse_ltm',
			// 'eq_stats__ytd_maint_tires_ltm',
			// 'eq_stats__ytd_maint_repair_ltm',
			// 'eq_stats__ytd_maint_combined',
			// 'eq_stats__ytd_ltm_billing',
			// 'eq_stats__ytd_lease',
			// 'eq_stats__ytd_depreciation',
			// 'eq_stats__ytd_cost_per_hr',
			// 'eq_stats__ytd_cost_per_hr_customer',
			// 'eq_stats__ytd_cost_per_hr_ltm',
			// 'eq_stats__rtm_meter',
			// 'eq_stats__rtm_available_meter',
			// 'eq_stats__rtm_util',
			// 'eq_stats__rtm_maint',
			// 'eq_stats__rtm_maint_preventive',
			// 'eq_stats__rtm_maint_abuse',
			// 'eq_stats__rtm_maint_abuseRental',
			// 'eq_stats__rtm_maint_tires',
			// 'eq_stats__rtm_maint_repair',
			// 'eq_stats__rtm_maint_ltm',
			// 'eq_stats__rtm_maint_preventive_ltm',
			// 'eq_stats__rtm_maint_abuse_ltm',
			// 'eq_stats__rtm_maint_tires_ltm',
			// 'eq_stats__rtm_maint_repair_ltm',
			// 'eq_stats__rtm_maint_combined',
			// 'eq_stats__rtm_ltm_billing',
			// 'eq_stats__rtm_lease',
			// 'eq_stats__rtm_depreciation',
			// 'eq_stats__rtm_cost_per_hr',
			// 'eq_stats__rtm_cost_per_hr_customer',
			// 'eq_stats__rtm_cost_per_hr_ltm',
			// 'eq_stats__ltd_meter',
			// 'eq_stats__ltd_available_meter',
			// 'eq_stats__ltd_util',
			// 'eq_stats__ltd_maint',
			// 'eq_stats__ltd_maint_preventive',
			// 'eq_stats__ltd_maint_abuse',
			// 'eq_stats__ltd_maint_abuseRental',
			// 'eq_stats__ltd_maint_tires',
			// 'eq_stats__ltd_maint_repair',
			// 'eq_stats__ltd_maint_ltm',
			// 'eq_stats__ltd_maint_preventive_ltm',
			// 'eq_stats__ltd_maint_abuse_ltm',
			// 'eq_stats__ltd_maint_tires_ltm',
			// 'eq_stats__ltd_maint_repair_ltm',
			// 'eq_stats__ltd_maint_combined',
			// 'eq_stats__ltd_ltm_billing',
			// 'eq_stats__ltd_lease',
			// 'eq_stats__ltd_depreciation',
			// 'eq_stats__ltd_cost_per_hr',
			// 'eq_stats__ltd_cost_per_hr_customer',
			// 'eq_stats__ltd_cost_per_hr_ltm'
		],

	},

	entity: {
		methods: {

			getEquipment: async function() {
				const Equipment = this.getAssociatedRepository('Equipment');
				let entity = Equipment.getById(this.eq_stats__equipment_id);
				if (!entity) {
					entity = await Equipment.getSingleEntityFromServer(this.eq_stats__equipment_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default EqStats;
