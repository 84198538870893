/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportWoServiceTypesGridEditor from './ImportWoServiceTypesGridEditor.js';

export default function ImportWoServiceTypesFilteredGridEditor(props) {
	return <ImportWoServiceTypesGridEditor
				reference="ImportWoServiceTypesFilteredGridEditor"
				useFilters={true}

				{...props}
			/>;
}