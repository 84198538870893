export default [
	    {
	        "id": "import_wo_labors__woid",
	        "header": "WOID",
	        "fieldName": "import_wo_labors__woid",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_wo_labors__line_item",
	        "header": "Line Item",
	        "fieldName": "import_wo_labors__line_item",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_labors__wo_labor_type_code",
	        "header": "Wo Labor Type Code",
	        "fieldName": "import_wo_labors__wo_labor_type_code",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_wo_labors__wo_labor_type_name",
	        "header": "Wo Labor Type Name",
	        "fieldName": "import_wo_labors__wo_labor_type_name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_wo_labors__time_type",
	        "header": "Time Type",
	        "fieldName": "import_wo_labors__time_type",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_wo_labors__employee_number",
	        "header": "Employee Number",
	        "fieldName": "import_wo_labors__employee_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_wo_labors__technician",
	        "header": "Technician",
	        "fieldName": "import_wo_labors__technician",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_wo_labors__hours",
	        "header": "Hours",
	        "fieldName": "import_wo_labors__hours",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_labors__cost",
	        "header": "Cost",
	        "fieldName": "import_wo_labors__cost",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_labors__sell",
	        "header": "Sell",
	        "fieldName": "import_wo_labors__sell",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_labors__ext_price",
	        "header": "Ext Price",
	        "fieldName": "import_wo_labors__ext_price",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_labors__is_imported",
	        "header": "Imported?",
	        "fieldName": "import_wo_labors__is_imported",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_labors__imported_date",
	        "header": "Imported Date",
	        "fieldName": "import_wo_labors__imported_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    }
	];