/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import ImportWorkOrdersEditorWindow from '../../../Window/ImportWorkOrdersEditorWindow.js';

function ImportWorkOrdersComboEditor(props) {
	return <ComboEditor
				reference="ImportWorkOrdersComboEditor"
				model="ImportWorkOrders"
				uniqueRepository={true}
				Editor={ImportWorkOrdersEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default ImportWorkOrdersComboEditor;