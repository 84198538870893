/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import ImportManufacturersEditorWindow from '../../../Window/ImportManufacturersEditorWindow.js';

function ImportManufacturersComboEditor(props) {
	return <ComboEditor
				reference="ImportManufacturersComboEditor"
				model="ImportManufacturers"
				uniqueRepository={true}
				Editor={ImportManufacturersEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default ImportManufacturersComboEditor;