/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ServiceCentersEditor from '../Editor/ServiceCentersEditor.js';
import ServiceCentersGridColumns from './Columns/ServiceCentersGridColumns.js';

export default function ServiceCentersSideGridEditor(props) {
	return <SideGridEditor
				reference="ServiceCentersSideGridEditor"
				model="ServiceCenters"
				usePermissions={true}
				isCollapsible={false}
				Editor={ServiceCentersEditor}
				columnsConfig={ServiceCentersGridColumns}
				
				
				{...props}
			/>;
}