import {
	IMPORT_STATUSES__IMPORTED,
	IMPORT_STATUSES__UPDATED,
	IMPORT_STATUSES__ALREADY_EXISTS,
	IMPORT_STATUSES__SKIPPED,
	IMPORT_STATUSES__ERROR,
} from '../../constants/ImportStatuses.js';
import DisplayField from '@onehat/ui/src/Components/Form/Field/DisplayField.js';

export default function ImportStatusesViewer(props) {
	let text = '';
	switch(props.id) {
		case IMPORT_STATUSES__IMPORTED:
			text = 'Imported';
			break;
		case IMPORT_STATUSES__UPDATED:
			text = 'Updated';
			break;
		case IMPORT_STATUSES__ALREADY_EXISTS:
			text = 'Already Exists';
			break;
		case IMPORT_STATUSES__SKIPPED:
			text = 'Skipped';
			break;
		case IMPORT_STATUSES__ERROR:
			text = 'Error';
			break;
		default:
			text = 'Unknown';
	}
	return <DisplayField text={text} />;
}