import * as yup from 'yup'; // https://github.com/jquense/yup#string
import json from '@onehat/ui/src/Functions/jsonValidator';

const WorkOrders = {
	
	name: 'WorkOrders',
	usesEnterpriseId: true,
	model: {

		idProperty: 'work_orders__id',
		displayProperty: 'work_orders__service_order',
		sortProperty: 'work_orders__service_order',
		
		sorters: null,

		validator: yup.object({
			work_orders__equipment_id: yup.number().integer().required(),
			work_orders__business_partner_id: yup.string().max(50).nullable(),
			work_orders__service_order: yup.string().max(9).nullable(),
			work_orders__segment: yup.string().max(5).nullable(),
			work_orders__invoice_number: yup.string().max(50).nullable(),
			work_orders__segment_type: yup.string().max(1).nullable(),
			work_orders__wo_number: yup.string().max(50).required(),
			work_orders__po_number: yup.string().max(25).nullable(),
			work_orders__service_center_id: yup.number().integer().nullable(),
			work_orders__open_date: yup.date().nullable(),
			work_orders__wo_service_type_id: yup.number().integer().nullable(),
			work_orders__close_date: yup.date().nullable(),
			work_orders__invoice_date: yup.date().nullable(),
			work_orders__complete_date: yup.date().nullable(),
			work_orders__time_out_of_service: yup.string().max(25).nullable(),
			work_orders__wo_mode_id: yup.number().integer().required(),
			work_orders__wo_class_id: yup.number().integer().required(),
			work_orders__pm_schedule_id: yup.number().integer().nullable(),
			work_orders__complaint: yup.string().nullable(),
			work_orders__cause: yup.string().nullable(),
			work_orders__correction: yup.string().nullable(),
			work_orders__coverage: yup.string().nullable(),
			work_orders__comments: yup.string().nullable(),
			work_orders__meter_reading: yup.number().integer().nullable(),
			work_orders__tax: yup.number().nullable(),
			work_orders__is_ltm: yup.boolean().nullable(),
			work_orders__is_scheduled: yup.boolean().nullable(),
			work_orders__is_verified: yup.boolean().nullable(),
			work_orders__repaired_by_branch: yup.string().max(128).nullable(),
			work_orders__repaired_by_company: yup.string().max(128).nullable(),
			work_orders__sold_to_business_partner_id: yup.number().integer().nullable(),
			work_orders__sold_to_name: yup.string().max(50).nullable(),
			work_orders__sold_to_address: yup.string().nullable(),
			work_orders__sold_to_city: yup.string().max(50).nullable(),
			work_orders__sold_to_state_abbr: yup.string().max(2).nullable(),
			work_orders__sold_to_zip: yup.string().max(10).nullable(),
			work_orders__bill_to_business_partner_id: yup.number().integer().nullable(),
			work_orders__bill_to_name: yup.string().max(50).nullable(),
			work_orders__ship_to_business_partner_id: yup.number().integer().nullable(),
			work_orders__ship_to_name: yup.string().max(50).nullable(),
			work_orders__ship_to_address: yup.string().nullable(),
			work_orders__ship_to_city: yup.string().max(50).nullable(),
			work_orders__ship_to_state_abbr: yup.string().max(2).nullable(),
			work_orders__ship_to_zip: yup.string().max(10).nullable()
		}),
		
		properties: [
			{ name: 'work_orders__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'work_orders__enterprise_id', mapping: 'enterprise_id', title: 'Enterprise', type: 'int', isFk: true, fkIdField: 'enterprises__id', fkDisplayField: 'enterprises__name', filterType: {"type":"EnterprisesCombo","loadAfterRender":!1}, editorType: {"type":"EnterprisesComboEditor","loadAfterRender":!1}, fieldGroup: '1. Summary', },
			{ name: 'work_orders__equipment_id', mapping: 'equipment_id', title: 'Equipment', type: 'int', isFk: true, fkIdField: 'equipment__id', fkDisplayField: 'equipment__unit_number', filterType: {"type":"EquipmentCombo","loadAfterRender":!1}, editorType: {"type":"EquipmentComboEditor","loadAfterRender":!1}, fieldGroup: '1. Summary', },
			{ name: 'work_orders__business_partner_id', mapping: 'business_partner_id', title: 'Business Partner', isFk: true, fkIdField: 'business_partners__id', fkDisplayField: 'business_partners__name', isFilteringDisabled: true, editorType: {"type":"BusinessPartnersComboEditor","loadAfterRender":!1}, fieldGroup: '1. Summary', },
			{ name: 'work_orders__service_order', mapping: 'service_order', title: 'Service Order', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Summary', },
			{ name: 'work_orders__segment', mapping: 'segment', title: 'Segment', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Summary', },
			{ name: 'work_orders__invoice_number', mapping: 'invoice_number', title: 'Invoice Number', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Summary', },
			{ name: 'work_orders__segment_type', mapping: 'segment_type', title: 'Segment Type', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'work_orders__wo_number', mapping: 'wo_number', title: 'Work Order Number', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Summary', },
			{ name: 'work_orders__po_number', mapping: 'po_number', title: 'PO Number', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Summary', },
			{ name: 'work_orders__service_center_id', mapping: 'service_center_id', title: 'Service Center', type: 'int', isFk: true, fkIdField: 'service_centers__id', fkDisplayField: 'service_centers__name', filterType: {"type":"ServiceCentersCombo","loadAfterRender":!1}, editorType: {"type":"ServiceCentersComboEditor","loadAfterRender":!1}, fieldGroup: '1. Summary', },
			{ name: 'work_orders__open_date', mapping: 'open_date', title: 'Open Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: '2. Dates', },
			{ name: 'work_orders__wo_service_type_id', mapping: 'wo_service_type_id', title: 'Wo Service Type', type: 'int', isFk: true, fkIdField: 'wo_service_types__id', fkDisplayField: 'wo_service_types__name', filterType: {"type":"WoServiceTypesCombo","loadAfterRender":!1}, editorType: {"type":"WoServiceTypesCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'work_orders__close_date', mapping: 'close_date', title: 'Close Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: '2. Dates', },
			{ name: 'work_orders__invoice_date', mapping: 'invoice_date', title: 'Invoice Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: '2. Dates', },
			{ name: 'work_orders__complete_date', mapping: 'complete_date', title: 'Complete Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: '2. Dates', },
			{ name: 'work_orders__time_out_of_service', mapping: 'time_out_of_service', title: 'Time Out Of Service', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '2. Dates', },
			{ name: 'work_orders__wo_mode_id', mapping: 'wo_mode_id', title: 'Mode', type: 'int', isFk: true, fkIdField: 'wo_modes__id', fkDisplayField: 'wo_modes__name', filterType: {"type":"WoModesCombo","loadAfterRender":!1}, editorType: {"type":"WoModesCombo","loadAfterRender":!1}, fieldGroup: '3. Specifications', defaultValue: 1, },
			{ name: 'work_orders__wo_class_id', mapping: 'wo_class_id', title: 'Class', type: 'int', isFk: true, fkIdField: 'wo_classes__id', fkDisplayField: 'wo_classes__name', filterType: {"type":"WoClassesCombo","loadAfterRender":!1}, editorType: {"type":"WoClassesComboEditor","loadAfterRender":!1}, fieldGroup: '3. Specifications', },
			{ name: 'work_orders__pm_schedule_id', mapping: 'pm_schedule_id', title: 'PM Schedule', type: 'int', isFk: true, fkIdField: 'pm_schedules__id', fkDisplayField: 'pm_schedules__name', filterType: {"type":"PmSchedulesCombo","loadAfterRender":!1}, editorType: {"type":"PmSchedulesComboEditor","loadAfterRender":!1}, fieldGroup: '3. Specifications', },
			{ name: 'work_orders__complaint', mapping: 'complaint', title: 'Complaint', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: '3. Specifications', },
			{ name: 'work_orders__cause', mapping: 'cause', title: 'Cause', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: '3. Specifications', },
			{ name: 'work_orders__correction', mapping: 'correction', title: 'Correction', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: '3. Specifications', },
			{ name: 'work_orders__coverage', mapping: 'coverage', title: 'Coverage', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: '3. Specifications', },
			{ name: 'work_orders__comments', mapping: 'comments', title: 'Comments', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: '3. Specifications', },
			{ name: 'work_orders__meter_reading', mapping: 'meter_reading', title: 'Meter Reading', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '3. Specifications', },
			{ name: 'work_orders__original_destination', mapping: 'original_destination', title: 'Original Destination', type: 'json', isFilteringDisabled: true, viewerType: {"type":"Json"}, isEditingDisabled: true, fieldGroup: '3. Specifications', },
			{ name: 'work_orders__total_parts', mapping: 'total_parts', title: 'Total Parts', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '4. Prices', },
			{ name: 'work_orders__total_labor', mapping: 'total_labor', title: 'Total Labor', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '4. Prices', },
			{ name: 'work_orders__total_other', mapping: 'total_other', title: 'Total Other', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '4. Prices', },
			{ name: 'work_orders__tax', mapping: 'tax', title: 'Tax', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '4. Prices', },
			{ name: 'work_orders__total', mapping: 'total', title: 'Total', type: 'currency', filterType: {"type":"NumberRange"}, isEditingDisabled: true, fieldGroup: '4. Prices', },
			{ name: 'work_orders__is_ltm', mapping: 'is_ltm', title: 'Ltm?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. Switches', },
			{ name: 'work_orders__is_scheduled', mapping: 'is_scheduled', title: 'Scheduled?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. Switches', },
			{ name: 'work_orders__is_verified', mapping: 'is_verified', title: 'Verified?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '5. Switches', },
			{ name: 'work_orders__repaired_by_branch', mapping: 'repaired_by_branch', title: 'Repaired By Branch', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '6. Repaired By', },
			{ name: 'work_orders__repaired_by_company', mapping: 'repaired_by_company', title: 'Repaired By Company', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '6. Repaired By', },
			{ name: 'work_orders__sold_to_business_partner_id', mapping: 'sold_to_business_partner_id', title: 'Sold To Business Partner', type: 'int', isFk: true, fkIdField: 'business_partners__id', fkDisplayField: 'business_partners__name', filterType: {"type":"BusinessPartnersCombo","loadAfterRender":!1}, editorType: {"type":"BusinessPartnersComboEditor","loadAfterRender":!1}, fieldGroup: '7. Sold To', },
			{ name: 'work_orders__sold_to_name', mapping: 'sold_to_name', title: 'Sold To Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '7. Sold To', },
			{ name: 'work_orders__sold_to_address', mapping: 'sold_to_address', title: 'Sold To Address', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: '7. Sold To', },
			{ name: 'work_orders__sold_to_city', mapping: 'sold_to_city', title: 'Sold To City', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '7. Sold To', },
			{ name: 'work_orders__sold_to_state_abbr', mapping: 'sold_to_state_abbr', title: 'Sold To State Abbr', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '7. Sold To', },
			{ name: 'work_orders__sold_to_zip', mapping: 'sold_to_zip', title: 'Sold To Zip', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '7. Sold To', },
			{ name: 'work_orders__bill_to_business_partner_id', mapping: 'bill_to_business_partner_id', title: 'Bill To Business Partner', type: 'int', isFk: true, fkIdField: 'business_partners__id', fkDisplayField: 'business_partners__name', filterType: {"type":"BusinessPartnersCombo","loadAfterRender":!1}, editorType: {"type":"BusinessPartnersComboEditor","loadAfterRender":!1}, fieldGroup: '8. Bill To', },
			{ name: 'work_orders__bill_to_name', mapping: 'bill_to_name', title: 'Bill To Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '8. Bill To', },
			{ name: 'work_orders__ship_to_business_partner_id', mapping: 'ship_to_business_partner_id', title: 'Ship To Business Partner', type: 'int', isFk: true, fkIdField: 'business_partners__id', fkDisplayField: 'business_partners__name', filterType: {"type":"BusinessPartnersCombo","loadAfterRender":!1}, editorType: {"type":"BusinessPartnersComboEditor","loadAfterRender":!1}, fieldGroup: '9. Ship To', },
			{ name: 'work_orders__ship_to_name', mapping: 'ship_to_name', title: 'Ship To Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '9. Ship To', },
			{ name: 'work_orders__ship_to_address', mapping: 'ship_to_address', title: 'Ship To Address', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: '9. Ship To', },
			{ name: 'work_orders__ship_to_city', mapping: 'ship_to_city', title: 'Ship To City', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '9. Ship To', },
			{ name: 'work_orders__ship_to_state_abbr', mapping: 'ship_to_state_abbr', title: 'Ship To State', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '9. Ship To', },
			{ name: 'work_orders__ship_to_zip', mapping: 'ship_to_zip', title: 'Ship To Zip', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '9. Ship To', },
			{ name: 'enterprises__id', mapping: 'enterprise.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__name', mapping: 'enterprise.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__is_enabled', mapping: 'enterprise.is_enabled', title: 'Enabled?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__enterprise_type_id', mapping: 'enterprise.enterprise_type_id', title: 'Enterprise Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__service_center_id', mapping: 'enterprise.service_center_id', title: 'Service Center', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__pm_mode', mapping: 'enterprise.pm_mode', title: 'PM Mode', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__exclude_before', mapping: 'enterprise.exclude_before', title: 'Exclude Before', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__is_cost_enabled', mapping: 'enterprise.is_cost_enabled', title: 'Cost Enabled?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__is_demo_mode', mapping: 'enterprise.is_demo_mode', title: 'Demo Mode?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprises__chrome_id', mapping: 'enterprise.chrome_id', title: 'Chrome', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__id', mapping: 'equipment.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__enterprise_id', mapping: 'equipment.enterprise_id', title: 'Enterprise', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__fleet_id', mapping: 'equipment.fleet_id', title: 'Fleet', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_status_id', mapping: 'equipment.eq_status_id', title: 'Status', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__inactive_date', mapping: 'equipment.inactive_date', title: 'Inactive Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__year', mapping: 'equipment.year', title: 'Year', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__manufacturer_id', mapping: 'equipment.manufacturer_id', title: 'Make', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__model', mapping: 'equipment.model', title: 'Model', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__serial_number', mapping: 'equipment.serial_number', title: 'Serial #', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__unit_number', mapping: 'equipment.unit_number', title: 'Unit #', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__asset_number', mapping: 'equipment.asset_number', title: 'Asset # (Cust ID)', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__dept_number', mapping: 'equipment.dept_number', title: 'Dept #', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__business_partner_id', mapping: 'equipment.business_partner_id', title: 'Business Partner', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_type_id', mapping: 'equipment.eq_type_id', title: 'Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__tel_manufacturer_id', mapping: 'equipment.tel_manufacturer_id', title: 'Tel Manufacturer', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__tel_pid', mapping: 'equipment.tel_pid', title: 'Tel Pid', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__comments', mapping: 'equipment.comments', title: 'Comments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_misc', mapping: 'equipment.is_misc', title: 'Misc?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_pm_enable', mapping: 'equipment.is_pm_enable', title: 'Pm Enable?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__pm_schedules', mapping: 'equipment.pm_schedules', title: 'PM Schedules', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__expected_meter_per_month', mapping: 'equipment.expected_meter_per_month', title: 'Expected Meter Per Month', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__available_meter_per_week', mapping: 'equipment.available_meter_per_week', title: 'Available Meter Per Week', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__in_service_date', mapping: 'equipment.in_service_date', title: 'In Service', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__in_service_meter', mapping: 'equipment.in_service_meter', title: 'In Service Meter', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__initial_maint_expense', mapping: 'equipment.initial_maint_expense', title: 'Initial Maint $', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__initial_book_value', mapping: 'equipment.initial_book_value', title: 'Book Value $', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__purchase_price', mapping: 'equipment.purchase_price', title: 'Purchase Price', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_track_meter_enable', mapping: 'equipment.is_track_meter_enable', title: 'Track Meter Enable?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__max_meter_allowed', mapping: 'equipment.max_meter_allowed', title: 'Max Meter Allowed', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__expected_revenue_per_month', mapping: 'equipment.expected_revenue_per_month', title: 'Expected Revenue Per Month', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__warranty_expires', mapping: 'equipment.warranty_expires', title: 'Warranty Expires', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__warranty_meter', mapping: 'equipment.warranty_meter', title: 'Warranty Meter', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__capacity', mapping: 'equipment.capacity', title: 'Capacity', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_upright_type_id', mapping: 'equipment.eq_upright_type_id', title: 'Upright Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__upright_lhloh', mapping: 'equipment.upright_lhloh', title: 'LH / LoH', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_valve_type_id', mapping: 'equipment.eq_valve_type_id', title: 'Eq Valve Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_power_type_id', mapping: 'equipment.eq_power_type_id', title: 'Eq Power Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_tire_type_id', mapping: 'equipment.eq_tire_type_id', title: 'Eq Tire Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__battery_size', mapping: 'equipment.battery_size', title: 'Battery Size', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_voltage_id', mapping: 'equipment.eq_voltage_id', title: 'Eq Voltage', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_fork_length_id', mapping: 'equipment.eq_fork_length_id', title: 'Eq Fork Length', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_attachments', mapping: 'equipment.eq_attachments', title: 'Attachments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_fluorescent_stripe', mapping: 'equipment.is_fluorescent_stripe', title: 'Fluorescent Striping?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_whelen_light', mapping: 'equipment.is_whelen_light', title: 'Whelen Lights?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_blue_led_light', mapping: 'equipment.is_blue_led_light', title: 'Blue LED Spot Light?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_high_vis_seatbelt', mapping: 'equipment.is_high_vis_seatbelt', title: 'Hi-Vis Seatbelt?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_rear_handle_horn', mapping: 'equipment.is_rear_handle_horn', title: 'Rear Handle w/Horn?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_contact_decals', mapping: 'equipment.is_contact_decals', title: '3-Point of Contact Decals?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_pallet_protection', mapping: 'equipment.is_pallet_protection', title: 'Pallet Protection?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_laser_light', mapping: 'equipment.is_laser_light', title: 'Laser Light?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'wo_modes__id', mapping: 'wo_mode.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'wo_modes__name', mapping: 'wo_mode.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'business_partners__id', mapping: 'business_partner.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'business_partners__name', mapping: 'business_partner.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'wo_classes__id', mapping: 'wo_class.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'wo_classes__name', mapping: 'wo_class.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'wo_classes__code', mapping: 'wo_class.code', title: 'Code', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_schedules__id', mapping: 'pm_schedule.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_schedules__name', mapping: 'pm_schedule.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_schedules__interval_meter', mapping: 'pm_schedule.interval_meter', title: 'Interval Meter', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_schedules__interval_days', mapping: 'pm_schedule.interval_days', title: 'Interval Days', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_schedules__pm_schedule_mode_id', mapping: 'pm_schedule.pm_schedule_mode_id', title: 'PM Schedule Mode', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_schedules__repeats', mapping: 'pm_schedule.repeats', title: 'Repeats', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_schedules__job_code', mapping: 'pm_schedule.job_code', title: 'Job Code', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'service_centers__id', mapping: 'service_center.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'service_centers__name', mapping: 'service_center.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'service_centers__code', mapping: 'service_center.code', title: 'Code', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'wo_service_types__id', mapping: 'wo_service_type.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'wo_service_types__name', mapping: 'wo_service_type.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'wo_service_types__comments', mapping: 'wo_service_type.comments', title: 'Comments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'sold_to_business_partners__id', mapping: 'sold_to_business_partner.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'sold_to_business_partners__name', mapping: 'sold_to_business_partner.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'bill_to_business_partners__id', mapping: 'bill_to_business_partner.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'bill_to_business_partners__name', mapping: 'bill_to_business_partner.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'ship_to_business_partners__id', mapping: 'ship_to_business_partner.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'ship_to_business_partners__name', mapping: 'ship_to_business_partner.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			hasMany: [
				'MeterReadings',
				'PmEvents',
				'WoLabors',
				'WoOtherCharges'
			],
			belongsTo: [
				'Enterprises',
				'Equipment',
				'WoModes',
				'BusinessPartners',
				'WoClasses',
				'PmSchedules',
				'ServiceCenters',
				'WoServiceTypes',
				'SoldToBusinessPartners',
				'BillToBusinessPartners',
				'ShipToBusinessPartners'
			],
			belongsToMany: [
				'Parts'
			],

		},

		ancillaryFilters: {
		    "parts": "Part"
		},

		defaultFilters: [
			// 'work_orders__enterprise_id',
			// 'work_orders__equipment_id',
			// 'work_orders__service_center_id',
			// 'work_orders__open_date',
			// 'work_orders__wo_service_type_id',
			// 'work_orders__close_date',
			'work_orders__invoice_date',
			// 'work_orders__complete_date',
			// 'work_orders__wo_mode_id',
			'work_orders__wo_class_id',
			// 'work_orders__pm_schedule_id',
			// 'work_orders__meter_reading',
			// 'work_orders__total_parts',
			// 'work_orders__total_labor',
			// 'work_orders__total_other',
			// 'work_orders__tax',
			// 'work_orders__total',
			// 'work_orders__is_ltm',
			// 'work_orders__is_scheduled',
			// 'work_orders__is_verified',
			// 'work_orders__sold_to_business_partner_id',
			// 'work_orders__bill_to_business_partner_id',
			// 'work_orders__ship_to_business_partner_id'
		],

	},

	entity: {
		methods: {

			getMeterReadings: function() {
				const MeterReadings = this.getAssociatedRepository('MeterReadings');
				return MeterReadings.getBy((entity) => {
					return entity.meter_readings__work_order_id === this.work_orders__id;
				});
			},

			getPmEvents: function() {
				const PmEvents = this.getAssociatedRepository('PmEvents');
				return PmEvents.getBy((entity) => {
					return entity.pm_events__work_order_id === this.work_orders__id;
				});
			},

			getEnterprise: async function() {
				const Enterprises = this.getAssociatedRepository('Enterprises');
				let entity = Enterprises.getById(this.work_orders__enterprise_id);
				if (!entity) {
					entity = await Enterprises.getSingleEntityFromServer(this.work_orders__enterprise_id);
				}
				return entity;
			},

			getEquipment: async function() {
				const Equipment = this.getAssociatedRepository('Equipment');
				let entity = Equipment.getById(this.work_orders__equipment_id);
				if (!entity) {
					entity = await Equipment.getSingleEntityFromServer(this.work_orders__equipment_id);
				}
				return entity;
			},

			getWoMode: async function() {
				const WoModes = this.getAssociatedRepository('WoModes');
				let entity = WoModes.getById(this.work_orders__wo_mode_id);
				if (!entity) {
					entity = await WoModes.getSingleEntityFromServer(this.work_orders__wo_mode_id);
				}
				return entity;
			},

			getBusinessPartner: async function() {
				const BusinessPartners = this.getAssociatedRepository('BusinessPartners');
				let entity = BusinessPartners.getById(this.work_orders__business_partner_id);
				if (!entity) {
					entity = await BusinessPartners.getSingleEntityFromServer(this.work_orders__business_partner_id);
				}
				return entity;
			},

			getWoClass: async function() {
				const WoClasses = this.getAssociatedRepository('WoClasses');
				let entity = WoClasses.getById(this.work_orders__wo_class_id);
				if (!entity) {
					entity = await WoClasses.getSingleEntityFromServer(this.work_orders__wo_class_id);
				}
				return entity;
			},

			getPmSchedule: async function() {
				const PmSchedules = this.getAssociatedRepository('PmSchedules');
				let entity = PmSchedules.getById(this.work_orders__pm_schedule_id);
				if (!entity) {
					entity = await PmSchedules.getSingleEntityFromServer(this.work_orders__pm_schedule_id);
				}
				return entity;
			},

			getWoLabors: function() {
				const WoLabors = this.getAssociatedRepository('WoLabors');
				return WoLabors.getBy((entity) => {
					return entity.wo_labors__work_order_id === this.work_orders__id;
				});
			},

			getWoOtherCharges: function() {
				const WoOtherCharges = this.getAssociatedRepository('WoOtherCharges');
				return WoOtherCharges.getBy((entity) => {
					return entity.wo_other_charges__work_order_id === this.work_orders__id;
				});
			},

			getServiceCenter: async function() {
				const ServiceCenters = this.getAssociatedRepository('ServiceCenters');
				let entity = ServiceCenters.getById(this.work_orders__service_center_id);
				if (!entity) {
					entity = await ServiceCenters.getSingleEntityFromServer(this.work_orders__service_center_id);
				}
				return entity;
			},

			getWoServiceType: async function() {
				const WoServiceTypes = this.getAssociatedRepository('WoServiceTypes');
				let entity = WoServiceTypes.getById(this.work_orders__wo_service_type_id);
				if (!entity) {
					entity = await WoServiceTypes.getSingleEntityFromServer(this.work_orders__wo_service_type_id);
				}
				return entity;
			},

			getSoldToBusinessPartner: async function() {
				const SoldToBusinessPartners = this.getAssociatedRepository('SoldToBusinessPartners');
				let entity = SoldToBusinessPartners.getById(this.work_orders__sold_to_business_partner_id);
				if (!entity) {
					entity = await SoldToBusinessPartners.getSingleEntityFromServer(this.work_orders__sold_to_business_partner_id);
				}
				return entity;
			},

			getBillToBusinessPartner: async function() {
				const BillToBusinessPartners = this.getAssociatedRepository('BillToBusinessPartners');
				let entity = BillToBusinessPartners.getById(this.work_orders__bill_to_business_partner_id);
				if (!entity) {
					entity = await BillToBusinessPartners.getSingleEntityFromServer(this.work_orders__bill_to_business_partner_id);
				}
				return entity;
			},

			getShipToBusinessPartner: async function() {
				const ShipToBusinessPartners = this.getAssociatedRepository('ShipToBusinessPartners');
				let entity = ShipToBusinessPartners.getById(this.work_orders__ship_to_business_partner_id);
				if (!entity) {
					entity = await ShipToBusinessPartners.getSingleEntityFromServer(this.work_orders__ship_to_business_partner_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": true,
	    "isAutoLoad": false
	},

};

export default WorkOrders;
