
import {
	Icon,
} from 'native-base';
import Book from '@onehat/ui/src/Components/Icons/Book';
import Button from '@onehat/ui/src/Components/Buttons/Button';
import IconButton from '@onehat/ui/src/Components/Buttons/IconButton';
import withModal from '@onehat/ui/src/Components/Hoc/withModal';

function Glossary(props) {
	const {
			isMinimized = false,
			showModal,
		} = props,
		onPress = () => {
			showModal({
				title: 'Glossary',
				h: 400,
				body: <ul>
						<li><strong>Available Hours Per Week:</strong> The number of hours per week this equipment is available for usage.</li>
						<li><strong>Avoidable Expenses:</strong> Damage to the truck caused by operator error. This repair work is usually done outside of warranty or any other maintenance contract. WO&rsquo;s can be coded with this class.</li>
						<li><strong>BV:</strong> Book Value</li>
						<li><strong>CPH:</strong> Cost Per Hour</li>
						<li><strong>Cost Per Hour (Full):</strong> Calculation = Sum of all work orders / usage hours</li>
						<li><strong>Cost Per Hour (Customer):</strong> Calculation = LTM Billing + PM work orders + Other Repair work orders (non-abuse, non-tires) / usage hours. This is the default CPH calculation.</li>
						<li><strong>Cost Per Hour (LTM):</strong> Calculation = Work orders charged to LTM account / usage hours (internal use only)</li>
						<li><strong>Enterprise:</strong> The top-level fleet.</li>
						<li><strong>EOT:</strong> End of Term</li>
						<li><strong>Expected Hours Per Month:</strong> The average number of hours per month this equipment is expected to acquire. Actual usage may vary from this. This figure is used to calculate upcoming PM dates in lieu of having actual work order / meter reading data.</li>
						<li><strong>Fleet:</strong> A grouping of equipment. Fleets within fleets are called subfleets.</li>
						<li><strong>Guaranteed Maintenance Contract:</strong> See LTM</li>
						<li><strong>In Service Date:</strong> The date the equipment was placed in service and work orders started being tracked.</li>
						<li><strong>Inactive Date:</strong> The date the equipment was made inactive. Inactive, moved, or sold equipment is not taken into consideration for fleet and enterprise statistics, or (normally) included in reports.</li>
						<li><strong>Initial Book Value:</strong> The book value of the equipment at the time the equipment was placed in service.</li>
						<li><strong>Initial Maintenance Expense:</strong> The LTD maintenance expense on equipment before being entered into this system.</li>
						 <li><strong>LTD:</strong> Life to Date. Note: The meaning of <em>Life to Date</em> varies depending on how specific equipment is set up within the system. It may mean the length of time the equipment has actually been in service since its original purchase date, or it may mean the length of time the equipment has been tracked by the application. Setting up equipment with initial hours of 0 and initial maint expense of 0 means the former. Setting up the equipment with initial hours &gt; 0, initial maint expense &gt; 0, and in-service date later than the equipment&rsquo;s actual date of first use would mean the latter.</li>
						<li><strong>LTM:</strong> Long Term Maintenance contract. Vendor agrees to do maintenance on a truck within certain limitations for a fixed rate.</li>
						<li><strong>Hours:</strong> The meter reading on equipment. In the case of replacement meters, hours will track the total acquired hours on all previous meters for a given piece of equipment.</li>
						<li><strong>Maintenance:</strong> Service work done on equipment, whether scheduled or unscheduled. Maintenance calculations are based on work orders entered into the system. Subclasses of maintenance are periodic, avoidable, tires, and general repairs.</li>
						<li><strong>Max Hours Allowed:</strong> The maximum number of hours this equipment should be allowed to acquire before being retired.</li>
						<li><strong>Meter See Hours.</strong></li>
						<li><strong>MTBF:</strong> Mean Time Between Failures. Average number of days between repairs. Measured over the life of the equipment. Excludes WO&rsquo;s which are PM&rsquo;s. [ It will calculate based on the first work order received in the system so if there are two total work orders for repair, it will be the number of days in between those two. If there are multiple, it will be the average number of days between repairs. ]</li>
						<li><strong>MTD:</strong> Month to Date</li>
						<li><strong>OEC:</strong> Original Equipment Cost</li>
						<li><strong>Ownership:</strong> The lease or depreciation expense on a given piece of equipment.</li>
						<li><strong>PM:</strong> Periodic Maintenance or Preventative Maintenance. This work is done on a regular schedule to keep the equipment in top shape. Work orders are often coded with this class.</li>
						<li><strong>PM Date:</strong> The date a PM service call should be performed.</li>
						<li><strong>PM Interval - Hours:</strong> The number of hours the equipment should be allowed to acquire between PM service calls.</li>
						<li><strong>PM Interval - Days:</strong> The number of days the equipment should be allowed to acquire between PM service calls.</li>
						<li><strong>RTM:</strong> Rolling Twelve Months. Calculations on this basis take the last twelve months of activity into account.</li>
						<li><strong>QTD:</strong> Quarter to Date</li>
						<li><strong>T&M:</strong> Time and Materials service plan</li>
						<li><strong>Utilization:</strong> Calculation = hours used / available hours</li>
						<li><strong>WO:</strong> Work Order</li>
						<li><strong>YTD:</strong> Year to Date</li>
					</ul>,
			});
		};
	return isMinimized ? 
			<IconButton
				icon={Book}
				tooltip="View the Glossary"
				onPress={onPress}
			/> :
			<Button
				text="Glossary"
				tooltip="View the Glossary"
				leftIcon={<Icon as={Book} color="#fff" />}
				m={1}
				onPress={onPress}
			/>;
};
export default withModal(Glossary);