/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Grid from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportTechniciansUtilProcessLogsGridColumns from './Columns/ImportTechniciansUtilProcessLogsGridColumns.js';

export default function ImportTechniciansUtilProcessLogsGrid(props) {
	return <Grid
				reference="ImportTechniciansUtilProcessLogsGrid"
				model="ImportTechniciansUtilProcessLogs"
				usePermissions={true}
				columnsConfig={ImportTechniciansUtilProcessLogsGridColumns}

				{...props}
			/>;
}