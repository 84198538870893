/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { useState, useEffect, useRef, } from 'react';
import {
	Column,
	Text,
} from 'native-base';
import { useSelector, useDispatch } from 'react-redux';
import {
	selectEnterpriseId,
	selectTreeSelection,
	setTreeSelection,
} from '../../models/Slices/AppSlice';
import oneHatData from '@onehat/data';
import withComponent from '@onehat/ui/src/Components/Hoc/withComponent.js';
import Panel from '@onehat/ui/src/Components/Panel/Panel.js';
import Loading from '@onehat/ui/src/Components/Messages/Loading.js';
import Tree from '@onehat/ui/src/Components/Tree/Tree.js';
import getNodeIcon from '../../functions/getNodeIcon.js';
import getDisplayTextFromFleetTreeSearchResults from '../../functions/getDisplayTextFromFleetTreeSearchResults.js';
import EnterpriseSpecific from '../Layout/EnterpriseSpecific.js';

// The FleetLitesTree is a tree of Fleets, optimized to only 
// get the necessary information to display (not edit) the tree. 
// This is largely copied from EquipmentLitesTree.js
// Only used on the EquipmentManager screen.


// TODO: Implement drag and drop functionality
// so 1+ rows from the EquipmentGrids can be dropped onto a Fleet.
// This would reassign the Equipment to the Fleet
// Maybe have a "Move to Fleet" button at bottom of tree, like in DataMgt app?

function FleetLitesTree(props) {
	const {
			self,
		} = props,
		enterpriseId = useSelector(selectEnterpriseId),
		treeSelection = useSelector(selectTreeSelection),
		dispatch = useDispatch(),
		[isReady, setIsReady] = useState(false),
		needsBuild = useRef(false),
		getNeedsBuild = () => {
			return needsBuild.current;
		},
		setNeedsBuild = (bool) => {
			needsBuild.current = bool;
		},
		FleetLites = oneHatData.getRepository('FleetLites'),
		onChangeSelection = (selection) => {
			dispatch(setTreeSelection(selection));
		},
		clearTreeSelection = () => {
			dispatch(setTreeSelection([]));
		},
		onLayout = (e) => {
			if (getNeedsBuild()) {
				const tree = self.children.Tree;
				tree.buildAndSetTreeNodeData();
			}
		},
		onTreeLoad = () => {
			const rootNode = FleetLites.getRootNodes()[0];
			if (rootNode) {
				self.children.Tree.setSelection([rootNode]);
			}
		};

	useEffect(() => {
		
		if (enterpriseId) {
			let needsLoad = false;
			if (FleetLites.getBaseParam('conditions[fleets__enterprise_id]') !== enterpriseId) {
				FleetLites.setBaseParam('conditions[fleets__enterprise_id]', enterpriseId);
				needsLoad = true;
			}
			if (FleetLites.getBaseParam('getEquipment') !== false) {
				FleetLites.setBaseParam('getEquipment', false);
				needsLoad = true;
			}
			if (FleetLites.timeout !== 15000) {
				FleetLites.timeout = 15000;
			}
			if (!FleetLites.areRootNodesLoaded) {
				needsLoad = true;
			}
			if (needsLoad) {
				FleetLites.loadRootNodes(1);
				setNeedsBuild(false);
			} else {
				setNeedsBuild(true);
			}
		}
	
		setIsReady(true);

	}, [enterpriseId]);
	
	if (!isReady) {
		return <Loading {...props} />;
	}

	return <Panel
				{...props}
				title="Fleet Tree"
				disableTitleChange={true}
			>
				<EnterpriseSpecific>
					<Tree
						parent={self}
						reference="Tree"
						disableWithData={true}
						Repository={FleetLites}
						defaultSelection={treeSelection}

						autoLoadRootNodes={false}
						getNodeIcon={getNodeIcon}
						onChangeSelection={onChangeSelection}
						onTreeLoad={onTreeLoad}
						getDisplayTextFromSearchResults={getDisplayTextFromFleetTreeSearchResults}
						onLayout={onLayout}
					/>
				</EnterpriseSpecific>
			</Panel>;
}


function withAdditionalProps(WrappedComponent) {
	return (props) => {
		return <WrappedComponent
					reference="FleetLitesTree"
					{...props}
				/>;
	};
}

export default withAdditionalProps(withComponent(FleetLitesTree));