/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Addresses from './Addresses.js';
// import Attachments from './Attachments.js';
import BusinessPartners from './BusinessPartners.js';
import BusinessPartnersFleets from './BusinessPartnersFleets.js';
import Chromes from './Chromes.js';
import EnterpriseTypes from './EnterpriseTypes.js';
import Enterprises from './Enterprises.js';
import EqForkLengths from './EqForkLengths.js';
import EqMonthlyStats from './EqMonthlyStats.js';
import EqPowerTypes from './EqPowerTypes.js';
import EqStats from './EqStats.js';
import EqStatuses from './EqStatuses.js';
import EqTireTypes from './EqTireTypes.js';
import EqTypes from './EqTypes.js';
import EqUprightTypes from './EqUprightTypes.js';
import EqValveTypes from './EqValveTypes.js';
import EqVoltages from './EqVoltages.js';
import Equipment from './Equipment.js';
import EquipmentPmSchedules from './EquipmentPmSchedules.js';
import FleetLites from './FleetLites.js';
import FleetStats from './FleetStats.js';
import Fleets from './Fleets.js';
import Groups from './Groups.js';
import GroupsUsers from './GroupsUsers.js';
import ImportManufacturers from './ImportManufacturers.js';
import ImportManufacturersUtilProcessLogs from './ImportManufacturersUtilProcessLogs.js';
import ImportPmEquipment from './ImportPmEquipment.js';
import ImportPmEquipmentUtilProcessLogs from './ImportPmEquipmentUtilProcessLogs.js';
import ImportServiceCenters from './ImportServiceCenters.js';
import ImportServiceCentersUtilProcessLogs from './ImportServiceCentersUtilProcessLogs.js';
import ImportTechnicians from './ImportTechnicians.js';
import ImportTechniciansUtilProcessLogs from './ImportTechniciansUtilProcessLogs.js';
import ImportWoLabors from './ImportWoLabors.js';
import ImportWoOtherChargeTypes from './ImportWoOtherChargeTypes.js';
import ImportWoOtherChargeTypesUtilProcessLogs from './ImportWoOtherChargeTypesUtilProcessLogs.js';
import ImportWoOtherCharges from './ImportWoOtherCharges.js';
import ImportWoParts from './ImportWoParts.js';
import ImportWoServiceTypes from './ImportWoServiceTypes.js';
import ImportWoServiceTypesUtilProcessLogs from './ImportWoServiceTypesUtilProcessLogs.js';
import ImportWorkOrders from './ImportWorkOrders.js';
import ImportWorkOrdersUtilProcessLogs from './ImportWorkOrdersUtilProcessLogs.js';
import Local from './Local.js';
import Manufacturers from './Manufacturers.js';
import MeterReadings from './MeterReadings.js';
import MeterSources from './MeterSources.js';
import PartTypes from './PartTypes.js';
import Parts from './Parts.js';
import PartsPartTypes from './PartsPartTypes.js';
import PartsWorkOrders from './PartsWorkOrders.js';
import Permissions from './Permissions.js';
import PmEventTypes from './PmEventTypes.js';
import PmEvents from './PmEvents.js';
import PmScheduleModes from './PmScheduleModes.js';
import PmSchedules from './PmSchedules.js';
import PmStatuses from './PmStatuses.js';
import Prefs from './Prefs.js';
import Secure from './Secure.js';
import ServiceCenters from './ServiceCenters.js';
import TelManufacturers from './TelManufacturers.js';
import TelOperators from './TelOperators.js';
import TelUsages from './TelUsages.js';
import UpcomingPms from './UpcomingPms.js';
import Users from './Users.js';
import UtilCrudLogs from './UtilCrudLogs.js';
import UtilErrorCodes from './UtilErrorCodes.js';
import UtilErrorLogs from './UtilErrorLogs.js';
import UtilProcessLogs from './UtilProcessLogs.js';
import WoClasses from './WoClasses.js';
import WoLaborTypes from './WoLaborTypes.js';
import WoLabors from './WoLabors.js';
import WoModes from './WoModes.js';
import WoOtherChargeTypes from './WoOtherChargeTypes.js';
import WoOtherCharges from './WoOtherCharges.js';
import WoServiceTypes from './WoServiceTypes.js';
import WorkOrders from './WorkOrders.js';

const obj = {
	Addresses,
	// Attachments,
	BusinessPartners,
	BusinessPartnersFleets,
	Chromes,
	EnterpriseTypes,
	Enterprises,
	EqForkLengths,
	EqMonthlyStats,
	EqPowerTypes,
	EqStats,
	EqStatuses,
	EqTireTypes,
	EqTypes,
	EqUprightTypes,
	EqValveTypes,
	EqVoltages,
	Equipment,
	EquipmentPmSchedules,
	FleetLites,
	FleetStats,
	Fleets,
	Groups,
	GroupsUsers,
	ImportManufacturers,
	ImportManufacturersUtilProcessLogs,
	ImportPmEquipment,
	ImportPmEquipmentUtilProcessLogs,
	ImportServiceCenters,
	ImportServiceCentersUtilProcessLogs,
	ImportTechnicians,
	ImportTechniciansUtilProcessLogs,
	ImportWoLabors,
	ImportWoOtherChargeTypes,
	ImportWoOtherChargeTypesUtilProcessLogs,
	ImportWoOtherCharges,
	ImportWoParts,
	ImportWoServiceTypes,
	ImportWoServiceTypesUtilProcessLogs,
	ImportWorkOrders,
	ImportWorkOrdersUtilProcessLogs,
	Local,
	Manufacturers,
	MeterReadings,
	MeterSources,
	PartTypes,
	Parts,
	PartsPartTypes,
	PartsWorkOrders,
	Permissions,
	PmEventTypes,
	PmEvents,
	PmScheduleModes,
	PmSchedules,
	PmStatuses,
	Prefs,
	Secure,
	ServiceCenters,
	TelManufacturers,
	TelOperators,
	TelUsages,
	UpcomingPms,
	Users,
	UtilCrudLogs,
	UtilErrorCodes,
	UtilErrorLogs,
	UtilProcessLogs,
	WoClasses,
	WoLaborTypes,
	WoLabors,
	WoModes,
	WoOtherChargeTypes,
	WoOtherCharges,
	WoServiceTypes,
	WorkOrders
};
export default obj;