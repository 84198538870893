import * as yup from 'yup'; // https://github.com/jquense/yup#string

const ImportWoLabors = {
	
	name: 'ImportWoLabors',
	
	model: {

		idProperty: 'import_wo_labors__id',
		displayProperty: 'import_wo_labors__id',
		sortProperty: 'import_wo_labors__id',
		
		sorters: null,

		validator: yup.object({
			import_wo_labors__woid: yup.string().max(255).nullable(),
			import_wo_labors__line_item: yup.number().integer().nullable(),
			import_wo_labors__wo_labor_type_code: yup.string().max(8).nullable(),
			import_wo_labors__wo_labor_type_name: yup.string().nullable(),
			import_wo_labors__time_type: yup.string().nullable(),
			import_wo_labors__employee_number: yup.string().max(6).nullable(),
			import_wo_labors__technician: yup.string().max(50).nullable(),
			import_wo_labors__hours: yup.number().nullable(),
			import_wo_labors__cost: yup.number().nullable(),
			import_wo_labors__sell: yup.number().nullable(),
			import_wo_labors__ext_price: yup.number().nullable(),
			import_wo_labors__is_imported: yup.boolean().nullable(),
			import_wo_labors__imported_date: yup.date().nullable()
		}),
		
		properties: [
			{ name: 'import_wo_labors__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'import_wo_labors__woid', mapping: 'woid', title: 'WOID', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_wo_labors__line_item', mapping: 'line_item', title: 'Line Item', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_wo_labors__wo_labor_type_code', mapping: 'wo_labor_type_code', title: 'Wo Labor Type Code', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_wo_labors__wo_labor_type_name', mapping: 'wo_labor_type_name', title: 'Wo Labor Type Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_wo_labors__time_type', mapping: 'time_type', title: 'Time Type', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_wo_labors__employee_number', mapping: 'employee_number', title: 'Employee Number', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_wo_labors__technician', mapping: 'technician', title: 'Technician', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_wo_labors__hours', mapping: 'hours', title: 'Hours', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_wo_labors__cost', mapping: 'cost', title: 'Cost', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_wo_labors__sell', mapping: 'sell', title: 'Sell', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_wo_labors__ext_price', mapping: 'ext_price', title: 'Ext Price', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_wo_labors__is_imported', mapping: 'is_imported', title: 'Imported?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'import_wo_labors__imported_date', mapping: 'imported_date', title: 'Imported Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', }
		],

		associations: {

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'import_wo_labors__line_item',
			// 'import_wo_labors__hours',
			// 'import_wo_labors__cost',
			// 'import_wo_labors__sell',
			// 'import_wo_labors__ext_price',
			// 'import_wo_labors__is_imported',
			// 'import_wo_labors__imported_date'
		],

	},



	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default ImportWoLabors;
