/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportManufacturersUtilProcessLogsEditorWindow from '../Window/ImportManufacturersUtilProcessLogsEditorWindow.js';
import ImportManufacturersUtilProcessLogsGridColumns from './Columns/ImportManufacturersUtilProcessLogsGridColumns.js';

export default function ImportManufacturersUtilProcessLogsGridEditor(props) {
	return <WindowedGridEditor
				reference="ImportManufacturersUtilProcessLogsGridEditor"
				model="ImportManufacturersUtilProcessLogs"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={ImportManufacturersUtilProcessLogsEditorWindow}
				columnsConfig={ImportManufacturersUtilProcessLogsGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}