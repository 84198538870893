import * as yup from 'yup'; // https://github.com/jquense/yup#string

const ImportPmEquipmentUtilProcessLogs = {
	
	name: 'ImportPmEquipmentUtilProcessLogs',
	
	model: {

		idProperty: 'import_pm_equipment_util_process_logs__id',
		displayProperty: 'import_pm_equipment_util_process_logs__id',
		sortProperty: 'import_pm_equipment_util_process_logs__datetime',
		sortDir: 'DESC',
		
		sorters: null,

		validator: yup.object({
			import_pm_equipment_util_process_logs__util_process_log_id: yup.number().integer().required(),
			import_pm_equipment_util_process_logs__datetime: yup.date().nullable(),
			import_pm_equipment_util_process_logs__success: yup.boolean().required(),
			import_pm_equipment_util_process_logs__status: yup.number().integer().required(),
			import_pm_equipment_util_process_logs__json: yup.string().nullable(),
			import_pm_equipment_util_process_logs__import_pm_equipment_id: yup.number().integer().nullable(),
			import_pm_equipment_util_process_logs__equipment_id: yup.number().integer().nullable()
		}),
		
		properties: [
			{ name: 'import_pm_equipment_util_process_logs__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'import_pm_equipment_util_process_logs__util_process_log_id', mapping: 'util_process_log_id', title: 'Util Process Log', type: 'int', isFk: true, fkIdField: 'util_process_logs__id', fkDisplayField: 'util_process_logs__process', filterType: {"type":"UtilProcessLogsCombo","loadAfterRender":!1}, editorType: {"type":"UtilProcessLogsCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment_util_process_logs__datetime', mapping: 'datetime', title: 'Datetime', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment_util_process_logs__success', mapping: 'success', title: 'Success', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment_util_process_logs__status', mapping: 'status', title: 'Status', type: 'int', filterType: {"type":"ImportStatusesCombo"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment_util_process_logs__json', mapping: 'json', title: 'Json', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment_util_process_logs__import_pm_equipment_id', mapping: 'import_pm_equipment_id', title: 'Import Pm Equipment', type: 'int', isFk: true, fkIdField: 'import_pm_equipment__id', fkDisplayField: 'import_pm_equipment__id', filterType: {"type":"ImportPmEquipmentCombo","loadAfterRender":!1}, editorType: {"type":"ImportPmEquipmentComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment_util_process_logs__equipment_id', mapping: 'equipment_id', title: 'Equipment', type: 'int', isFk: true, fkIdField: 'equipment__id', fkDisplayField: 'equipment__unit_number', filterType: {"type":"EquipmentCombo","loadAfterRender":!1}, editorType: {"type":"EquipmentComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment__id', mapping: 'import_pm_equipment.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_pm_equipment__business_partner', mapping: 'import_pm_equipment.business_partner', title: 'Business Partner', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_pm_equipment__customer_name', mapping: 'import_pm_equipment.customer_name', title: 'Customer Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_pm_equipment__year', mapping: 'import_pm_equipment.year', title: 'Year', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_pm_equipment__make', mapping: 'import_pm_equipment.make', title: 'Make', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_pm_equipment__model', mapping: 'import_pm_equipment.model', title: 'Model', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_pm_equipment__serial_number', mapping: 'import_pm_equipment.serial_number', title: 'Serial Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_pm_equipment__service_center', mapping: 'import_pm_equipment.service_center', title: 'Service Center', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_pm_equipment__unit_number', mapping: 'import_pm_equipment.unit_number', title: 'Unit Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_pm_equipment__asset_number', mapping: 'import_pm_equipment.asset_number', title: 'Asset Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_pm_equipment__meter_reading', mapping: 'import_pm_equipment.meter_reading', title: 'Meter Reading', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_pm_equipment__technician', mapping: 'import_pm_equipment.technician', title: 'Technician', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_pm_equipment__pm_comments', mapping: 'import_pm_equipment.pm_comments', title: 'Pm Comments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_pm_equipment__pm_status', mapping: 'import_pm_equipment.pm_status', title: 'Pm Status', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_pm_equipment__service_plan', mapping: 'import_pm_equipment.service_plan', title: 'Service Plan', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_pm_equipment__job_code', mapping: 'import_pm_equipment.job_code', title: 'Job Code', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_pm_equipment__is_imported', mapping: 'import_pm_equipment.is_imported', title: 'Imported?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_pm_equipment__imported_date', mapping: 'import_pm_equipment.imported_date', title: 'Imported Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__id', mapping: 'equipment.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__enterprise_id', mapping: 'equipment.enterprise_id', title: 'Enterprise', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__fleet_id', mapping: 'equipment.fleet_id', title: 'Fleet', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_status_id', mapping: 'equipment.eq_status_id', title: 'Status', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__inactive_date', mapping: 'equipment.inactive_date', title: 'Inactive Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__year', mapping: 'equipment.year', title: 'Year', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__manufacturer_id', mapping: 'equipment.manufacturer_id', title: 'Make', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__model', mapping: 'equipment.model', title: 'Model', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__serial_number', mapping: 'equipment.serial_number', title: 'Serial #', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__unit_number', mapping: 'equipment.unit_number', title: 'Unit #', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__asset_number', mapping: 'equipment.asset_number', title: 'Asset # (Cust ID)', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__dept_number', mapping: 'equipment.dept_number', title: 'Dept #', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__business_partner_id', mapping: 'equipment.business_partner_id', title: 'Business Partner', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_type_id', mapping: 'equipment.eq_type_id', title: 'Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__tel_manufacturer_id', mapping: 'equipment.tel_manufacturer_id', title: 'Tel Manufacturer', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__tel_pid', mapping: 'equipment.tel_pid', title: 'Tel Pid', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__comments', mapping: 'equipment.comments', title: 'Comments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_misc', mapping: 'equipment.is_misc', title: 'Misc?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_pm_enable', mapping: 'equipment.is_pm_enable', title: 'Pm Enable?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__pm_schedules', mapping: 'equipment.pm_schedules', title: 'PM Schedules', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__expected_meter_per_month', mapping: 'equipment.expected_meter_per_month', title: 'Expected Meter Per Month', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__available_meter_per_week', mapping: 'equipment.available_meter_per_week', title: 'Available Meter Per Week', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__in_service_date', mapping: 'equipment.in_service_date', title: 'In Service', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__in_service_meter', mapping: 'equipment.in_service_meter', title: 'In Service Meter', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__initial_maint_expense', mapping: 'equipment.initial_maint_expense', title: 'Initial Maint $', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__initial_book_value', mapping: 'equipment.initial_book_value', title: 'Book Value $', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__purchase_price', mapping: 'equipment.purchase_price', title: 'Purchase Price', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_track_meter_enable', mapping: 'equipment.is_track_meter_enable', title: 'Track Meter Enable?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__max_meter_allowed', mapping: 'equipment.max_meter_allowed', title: 'Max Meter Allowed', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__expected_revenue_per_month', mapping: 'equipment.expected_revenue_per_month', title: 'Expected Revenue Per Month', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__warranty_expires', mapping: 'equipment.warranty_expires', title: 'Warranty Expires', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__warranty_meter', mapping: 'equipment.warranty_meter', title: 'Warranty Meter', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__capacity', mapping: 'equipment.capacity', title: 'Capacity', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_upright_type_id', mapping: 'equipment.eq_upright_type_id', title: 'Upright Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__upright_lhloh', mapping: 'equipment.upright_lhloh', title: 'LH / LoH', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_valve_type_id', mapping: 'equipment.eq_valve_type_id', title: 'Eq Valve Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_power_type_id', mapping: 'equipment.eq_power_type_id', title: 'Eq Power Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_tire_type_id', mapping: 'equipment.eq_tire_type_id', title: 'Eq Tire Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__battery_size', mapping: 'equipment.battery_size', title: 'Battery Size', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_voltage_id', mapping: 'equipment.eq_voltage_id', title: 'Eq Voltage', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_fork_length_id', mapping: 'equipment.eq_fork_length_id', title: 'Eq Fork Length', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_attachments', mapping: 'equipment.eq_attachments', title: 'Attachments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_fluorescent_stripe', mapping: 'equipment.is_fluorescent_stripe', title: 'Fluorescent Striping?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_whelen_light', mapping: 'equipment.is_whelen_light', title: 'Whelen Lights?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_blue_led_light', mapping: 'equipment.is_blue_led_light', title: 'Blue LED Spot Light?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_high_vis_seatbelt', mapping: 'equipment.is_high_vis_seatbelt', title: 'Hi-Vis Seatbelt?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_rear_handle_horn', mapping: 'equipment.is_rear_handle_horn', title: 'Rear Handle w/Horn?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_contact_decals', mapping: 'equipment.is_contact_decals', title: '3-Point of Contact Decals?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_pallet_protection', mapping: 'equipment.is_pallet_protection', title: 'Pallet Protection?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_laser_light', mapping: 'equipment.is_laser_light', title: 'Laser Light?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__id', mapping: 'util_process_log.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__datetime', mapping: 'util_process_log.datetime', title: 'Datetime', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__process', mapping: 'util_process_log.process', title: 'Process', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__success', mapping: 'util_process_log.success', title: 'Success', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__count', mapping: 'util_process_log.count', title: 'Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__time', mapping: 'util_process_log.time', title: 'Time', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__json', mapping: 'util_process_log.json', title: 'Json', type: 'json', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'ImportPmEquipment',
				'Equipment',
				'UtilProcessLogs'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'import_pm_equipment_util_process_logs__util_process_log_id',
			// 'import_pm_equipment_util_process_logs__datetime',
			'import_pm_equipment_util_process_logs__success',
			'import_pm_equipment_util_process_logs__status',
			// 'import_pm_equipment_util_process_logs__import_pm_equipment_id',
			// 'import_pm_equipment_util_process_logs__equipment_id'
		],

	},

	entity: {
		methods: {

			getImportPmEquipment: async function() {
				const ImportPmEquipment = this.getAssociatedRepository('ImportPmEquipment');
				let entity = ImportPmEquipment.getById(this.import_pm_equipment_util_process_logs__import_pm_equipment_id);
				if (!entity) {
					entity = await ImportPmEquipment.getSingleEntityFromServer(this.import_pm_equipment_util_process_logs__import_pm_equipment_id);
				}
				return entity;
			},

			getEquipment: async function() {
				const Equipment = this.getAssociatedRepository('Equipment');
				let entity = Equipment.getById(this.import_pm_equipment_util_process_logs__equipment_id);
				if (!entity) {
					entity = await Equipment.getSingleEntityFromServer(this.import_pm_equipment_util_process_logs__equipment_id);
				}
				return entity;
			},

			getUtilProcessLog: async function() {
				const UtilProcessLogs = this.getAssociatedRepository('UtilProcessLogs');
				let entity = UtilProcessLogs.getById(this.import_pm_equipment_util_process_logs__util_process_log_id);
				if (!entity) {
					entity = await UtilProcessLogs.getSingleEntityFromServer(this.import_pm_equipment_util_process_logs__util_process_log_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default ImportPmEquipmentUtilProcessLogs;
