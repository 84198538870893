/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportManufacturersUtilProcessLogsGridEditorSideA from './ImportManufacturersUtilProcessLogsGridEditorSideA.js';

export default function ImportManufacturersUtilProcessLogsFilteredGridEditorSideA(props) {
	return <ImportManufacturersUtilProcessLogsGridEditorSideA
				reference="ImportManufacturersUtilProcessLogsFilteredGridEditorSideA"
				useFilters={true}

				{...props}
			/>;
}