/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportTechniciansInlineGridEditorPanel from './ImportTechniciansInlineGridEditor.js';

export default function ImportTechniciansFilteredInlineGridEditorPanel(props) {
	return <ImportTechniciansInlineGridEditorPanel
				reference="ImportTechniciansFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}