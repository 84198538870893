import * as yup from 'yup'; // https://github.com/jquense/yup#string

const ImportWorkOrdersUtilProcessLogs = {
	
	name: 'ImportWorkOrdersUtilProcessLogs',
	
	model: {

		idProperty: 'import_work_orders_util_process_logs__id',
		displayProperty: 'import_work_orders_util_process_logs__id',
		sortProperty: 'import_work_orders_util_process_logs__datetime',
		sortDir: 'DESC',
		
		sorters: null,

		validator: yup.object({
			import_work_orders_util_process_logs__util_process_log_id: yup.number().integer().required(),
			import_work_orders_util_process_logs__datetime: yup.date().nullable(),
			import_work_orders_util_process_logs__success: yup.boolean().required(),
			import_work_orders_util_process_logs__status: yup.number().integer().required(),
			import_work_orders_util_process_logs__json: yup.string().nullable(),
			import_work_orders_util_process_logs__import_work_order_id: yup.number().integer().nullable(),
			import_work_orders_util_process_logs__went_to_misc: yup.boolean().nullable()
		}),
		
		properties: [
			{ name: 'import_work_orders_util_process_logs__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'import_work_orders_util_process_logs__util_process_log_id', mapping: 'util_process_log_id', title: 'Util Process Log', type: 'int', isFk: true, fkIdField: 'util_process_logs__id', fkDisplayField: 'util_process_logs__process', filterType: {"type":"UtilProcessLogsCombo","loadAfterRender":!1}, editorType: {"type":"UtilProcessLogsCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'import_work_orders_util_process_logs__datetime', mapping: 'datetime', title: 'Datetime', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', },
			{ name: 'import_work_orders_util_process_logs__success', mapping: 'success', title: 'Success', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'import_work_orders_util_process_logs__status', mapping: 'status', title: 'Status', type: 'int', filterType: {"type":"ImportStatusesCombo"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_work_orders_util_process_logs__json', mapping: 'json', title: 'Json', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'import_work_orders_util_process_logs__import_work_order_id', mapping: 'import_work_order_id', title: 'Import Work Order', type: 'int', isFk: true, fkIdField: 'import_work_orders__id', fkDisplayField: 'import_work_orders__service_order', filterType: {"type":"ImportWorkOrdersCombo","loadAfterRender":!1}, editorType: {"type":"ImportWorkOrdersComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'import_work_orders_util_process_logs__went_to_misc', mapping: 'went_to_misc', title: 'Went To Misc', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'import_work_orders__id', mapping: 'import_work_order.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__woid', mapping: 'import_work_order.woid', title: 'WOID', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__business_partner', mapping: 'import_work_order.business_partner', title: 'Business Partner', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__service_order', mapping: 'import_work_order.service_order', title: 'Service Order', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__segment', mapping: 'import_work_order.segment', title: 'Segment', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__service_center', mapping: 'import_work_order.service_center', title: 'Service Center', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__po_number', mapping: 'import_work_order.po_number', title: 'Po Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__service_type', mapping: 'import_work_order.service_type', title: 'Service Type', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__open_date', mapping: 'import_work_order.open_date', title: 'Open Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__invoice_date', mapping: 'import_work_order.invoice_date', title: 'Invoice Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__wo_class', mapping: 'import_work_order.wo_class', title: 'Wo Class', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__data_source', mapping: 'import_work_order.data_source', title: 'Data Source', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__segment_type', mapping: 'import_work_order.segment_type', title: 'Segment Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__manufacturer', mapping: 'import_work_order.manufacturer', title: 'Manufacturer', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__job_code', mapping: 'import_work_order.job_code', title: 'Job Code', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__model', mapping: 'import_work_order.model', title: 'Model', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__serial_number', mapping: 'import_work_order.serial_number', title: 'Serial Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__unit_number', mapping: 'import_work_order.unit_number', title: 'Unit Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__asset_number', mapping: 'import_work_order.asset_number', title: 'Asset Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__meter_reading', mapping: 'import_work_order.meter_reading', title: 'Meter Reading', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__complaint', mapping: 'import_work_order.complaint', title: 'Complaint', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__cause', mapping: 'import_work_order.cause', title: 'Cause', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__correction', mapping: 'import_work_order.correction', title: 'Correction', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__coverage', mapping: 'import_work_order.coverage', title: 'Coverage', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__comments', mapping: 'import_work_order.comments', title: 'Comments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__total_parts', mapping: 'import_work_order.total_parts', title: 'Total Parts', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__total_labor', mapping: 'import_work_order.total_labor', title: 'Total Labor', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__total_other', mapping: 'import_work_order.total_other', title: 'Total Other', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__tax', mapping: 'import_work_order.tax', title: 'Tax', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__total', mapping: 'import_work_order.total', title: 'Total', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__sold_to_customer', mapping: 'import_work_order.sold_to_customer', title: 'Sold To Customer', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__sold_to_name', mapping: 'import_work_order.sold_to_name', title: 'Sold To Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__sold_to_address', mapping: 'import_work_order.sold_to_address', title: 'Sold To Address', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__sold_to_city', mapping: 'import_work_order.sold_to_city', title: 'Sold To City', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__sold_to_state_abbr', mapping: 'import_work_order.sold_to_state_abbr', title: 'Sold To State Abbr', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__sold_to_zip', mapping: 'import_work_order.sold_to_zip', title: 'Sold To Zip', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__bill_to_customer', mapping: 'import_work_order.bill_to_customer', title: 'Bill To Customer', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__bill_to_name', mapping: 'import_work_order.bill_to_name', title: 'Bill To Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__ship_to_customer', mapping: 'import_work_order.ship_to_customer', title: 'Ship To Customer', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__ship_to_name', mapping: 'import_work_order.ship_to_name', title: 'Ship To Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__ship_to_address', mapping: 'import_work_order.ship_to_address', title: 'Ship To Address', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__ship_to_city', mapping: 'import_work_order.ship_to_city', title: 'Ship To City', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__ship_to_state_abbr', mapping: 'import_work_order.ship_to_state_abbr', title: 'Ship To State Abbr', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__ship_to_zip', mapping: 'import_work_order.ship_to_zip', title: 'Ship To Zip', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__is_imported', mapping: 'import_work_order.is_imported', title: 'Imported?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_work_orders__imported_date', mapping: 'import_work_order.imported_date', title: 'Imported Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__id', mapping: 'util_process_log.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__datetime', mapping: 'util_process_log.datetime', title: 'Datetime', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__process', mapping: 'util_process_log.process', title: 'Process', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__success', mapping: 'util_process_log.success', title: 'Success', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__count', mapping: 'util_process_log.count', title: 'Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__time', mapping: 'util_process_log.time', title: 'Time', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__json', mapping: 'util_process_log.json', title: 'Json', type: 'json', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'ImportWorkOrders',
				'UtilProcessLogs'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'import_work_orders_util_process_logs__util_process_log_id',
			// 'import_work_orders_util_process_logs__datetime',
			'import_work_orders_util_process_logs__success',
			'import_work_orders_util_process_logs__status',
			// 'import_work_orders_util_process_logs__import_work_order_id',
			// 'import_work_orders_util_process_logs__went_to_misc'
		],

	},

	entity: {
		methods: {

			getImportWorkOrder: async function() {
				const ImportWorkOrders = this.getAssociatedRepository('ImportWorkOrders');
				let entity = ImportWorkOrders.getById(this.import_work_orders_util_process_logs__import_work_order_id);
				if (!entity) {
					entity = await ImportWorkOrders.getSingleEntityFromServer(this.import_work_orders_util_process_logs__import_work_order_id);
				}
				return entity;
			},

			getUtilProcessLog: async function() {
				const UtilProcessLogs = this.getAssociatedRepository('UtilProcessLogs');
				let entity = UtilProcessLogs.getById(this.import_work_orders_util_process_logs__util_process_log_id);
				if (!entity) {
					entity = await UtilProcessLogs.getSingleEntityFromServer(this.import_work_orders_util_process_logs__util_process_log_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default ImportWorkOrdersUtilProcessLogs;
