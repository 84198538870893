/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import ImportWoOtherChargeTypesUtilProcessLogsEditorWindow from '../../../Window/ImportWoOtherChargeTypesUtilProcessLogsEditorWindow.js';

function ImportWoOtherChargeTypesUtilProcessLogsComboEditor(props) {
	return <ComboEditor
				reference="ImportWoOtherChargeTypesUtilProcessLogsComboEditor"
				model="ImportWoOtherChargeTypesUtilProcessLogs"
				uniqueRepository={true}
				Editor={ImportWoOtherChargeTypesUtilProcessLogsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default ImportWoOtherChargeTypesUtilProcessLogsComboEditor;