/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportServiceCentersUtilProcessLogsEditorWindow from '../Window/ImportServiceCentersUtilProcessLogsEditorWindow.js';
import ImportServiceCentersUtilProcessLogsGridColumns from './Columns/ImportServiceCentersUtilProcessLogsGridColumns.js';

export default function ImportServiceCentersUtilProcessLogsGridEditor(props) {
	return <WindowedGridEditor
				reference="ImportServiceCentersUtilProcessLogsGridEditor"
				model="ImportServiceCentersUtilProcessLogs"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={ImportServiceCentersUtilProcessLogsEditorWindow}
				columnsConfig={ImportServiceCentersUtilProcessLogsGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}