import * as yup from 'yup'; // https://github.com/jquense/yup#string

const ImportTechnicians = {
	
	name: 'ImportTechnicians',
	
	model: {

		idProperty: 'import_technicians__id',
		displayProperty: 'import_technicians__name',
		sortProperty: 'import_technicians__name',
		
		sorters: null,

		validator: yup.object({
			import_technicians__name: yup.string().max(100).nullable(),
			import_technicians__employee_number: yup.string().max(6).nullable(),
			import_technicians__service_center: yup.number().integer().nullable(),
			import_technicians__is_imported: yup.boolean().nullable(),
			import_technicians__imported_date: yup.date().nullable()
		}),
		
		properties: [
			{ name: 'import_technicians__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'import_technicians__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_technicians__employee_number', mapping: 'employee_number', title: 'Employee Number', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_technicians__service_center', mapping: 'service_center', title: 'Service Center', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_technicians__is_imported', mapping: 'is_imported', title: 'Imported?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'import_technicians__imported_date', mapping: 'imported_date', title: 'Imported Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', }
		],

		associations: {

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'import_technicians__service_center',
			// 'import_technicians__is_imported',
			// 'import_technicians__imported_date'
		],

	},



	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default ImportTechnicians;
