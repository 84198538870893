/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { TagEditor } from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import ImportWoLaborsEditorWindow from '../../../Window/ImportWoLaborsEditorWindow.js';

function ImportWoLaborsTagEditor(props) {
	return <TagEditor
				reference="ImportWoLaborsTagEditor"
				model="ImportWoLabors"
				uniqueRepository={true}
				Editor={ImportWoLaborsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default ImportWoLaborsTagEditor;