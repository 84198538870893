import {
	Column,
	Icon,
	Image,
	Pressable,
	Row,
	Text,
} from 'native-base';
import testProps from '@onehat/ui/src/Functions/testProps.js';

export default function Box(props) {
	const {
			text,
			_icon,
			onPress,
		} = props,
		[containerWidth, setContainerWidth] = useState(100),
		[isRendered, setIsRendered] = useState(false),
		onLayout= (e) => {
			const containerWidth = e.nativeEvent.layout.width;
			setContainerWidth(containerWidth);
			setIsRendered(true);
		};

	let fontSize = '15px',
		iconSize = '40px';
	if (containerWidth > 200) {
		fontSize = '25px';
		iconSize = '55px';
	}
	if (containerWidth > 300) {
		fontSize = '25px';
		iconSize = '55px';
	}
	if (containerWidth > 400) {
		fontSize = '35px';
		iconSize = '65px';
	}
	if (containerWidth > 500) {
		fontSize = '25px';
		iconSize = '65px';
	}

	return <Pressable
				{...testProps(Inflector.camelize(text))}
				onLayout={onLayout} 
				onPress={onPress} 
				flex={1}
				_hover={{ bg: "primary.300" }}
				alignItems="center"
				justifyContent="center"
				flexDirection="column"
				bg="primary.700"
				p={5}
				mx={2}
				borderRadius={10}
			>
				{isRendered &&
					<>
						<Icon
							as={_icon.as}
							color="#fff"
							size={iconSize}
							minHeight={5}
							mb={2}
						/>
						<Text
							fontSize={fontSize}
							lineHeight={fontSize}
							style={{
								fontFamily: 'OpenSansCondensed_700Bold',
							}}
							color="#fff"
							textAlign="center"
						>{text}</Text>
					</>}
			</Pressable>;
}