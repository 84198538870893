export default [
	    {
	        "id": "import_wo_service_types__name",
	        "header": "Name",
	        "fieldName": "import_wo_service_types__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_wo_service_types__comments",
	        "header": "Comments",
	        "fieldName": "import_wo_service_types__comments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "import_wo_service_types__is_imported",
	        "header": "Imported?",
	        "fieldName": "import_wo_service_types__is_imported",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_service_types__imported_date",
	        "header": "Imported Date",
	        "fieldName": "import_wo_service_types__imported_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    }
	];