import {
	VIEW,
} from '@onehat/ui/src/Constants/Commands.js';
import {
	logoutThunk,
} from '../models/Slices/AppSlice';
import canUser from '@onehat/ui/src/Components/Hoc/withPermissions';
import Book from '@onehat/ui/src/Components/Icons/Book';
import Building from '@onehat/ui/src/Components/Icons/Building';
import ChartPie from '@onehat/ui/src/Components/Icons/ChartPie';
import Clipboard from '@onehat/ui/src/Components/Icons/Clipboard';
import FolderTree from '@onehat/ui/src/Components/Icons/FolderTree';
import Forklift from '../components/Icons/Forklift';
import Gauge from '@onehat/ui/src/Components/Icons/Gauge';
import Hammer from '@onehat/ui/src/Components/Icons/Hammer';
import OilCan from '../components/Icons/OilCan';
import Shop from '@onehat/ui/src/Components/Icons/Shop';
import User from '@onehat/ui/src/Components/Icons/User';
import UserGroup from '@onehat/ui/src/Components/Icons/UserGroup';
import RightFromBracket from '@onehat/ui/src/Components/Icons/RightFromBracket';
import AppGlobals from '../AppGlobals';
import Inflector from 'inflector-js';
import _ from 'lodash';

const urlPrefix = '/' + AppGlobals.urlPrefix;

// Two sets of tabs are defined below -- 'normalTabs' and 'setupTabs'.
// Then, AppSlice.loginThunk() calls calculateTabVisibility() whenever a user logs in.
// This sets 'visibleNormalTabs' and 'visibleSetupTabs', which are used in AppNavigator.js

export const normalTabs = [
	{
		title: 'Dashboard',
		_icon: { as: Gauge, },
		path: urlPrefix + Inflector.dasherize(Inflector.underscore('Dashboard')),
		screenName: 'Dashboard',
		usesEnterpriseSelector: true,
		usesEquipmentLitesTree: true,
	},
	{
		title: 'Work Orders',
		_icon: { as: Clipboard, },
		path: urlPrefix + Inflector.dasherize(Inflector.underscore('WorkOrders')),
		screenName: 'WorkOrdersManager',
		usesEnterpriseSelector: true,
		usesEquipmentLitesTree: true,
		permissions: [VIEW, 'WorkOrders'],
	},
	{
		title: 'Upcoming PMs',
		_icon: { as: OilCan, },
		path: urlPrefix + Inflector.dasherize(Inflector.underscore('Pms')),
		screenName: 'PmEventsManager',
		usesEnterpriseSelector: true,
		usesEquipmentLitesTree: true,
		permissions: [VIEW, 'PmEvents'],
	},
	// {
	// 	title: 'Reports',
	// 	_icon: { as: ChartPie, },
	// 	path: urlPrefix + Inflector.dasherize(Inflector.underscore('reports')),
	// 	screenName: 'ReportsManager',
	// 	usesEnterpriseSelector: true,
	//	usesEquipmentLitesTree: true,
	// 	permissions: [VIEW, 'Reports'],
	// },
];

export const setupTabs = [
	{
		title: 'Enterprises',
		_icon: { as: Shop, },
		path: urlPrefix + Inflector.dasherize(Inflector.underscore('Enterprises')),
		screenName: 'EnterprisesManager',
		permissions: [VIEW, 'Enterprises'],
	},
	{
		title: 'Fleets',
		_icon: { as: FolderTree, },
		path: urlPrefix + Inflector.dasherize(Inflector.underscore('Fleets')),
		screenName: 'FleetsManager',
		usesEnterpriseSelector: true,
		permissions: [VIEW, 'Fleets'],
	},
	{
		title: 'Equipment',
		_icon: { as: Forklift, },
		path: urlPrefix + Inflector.dasherize(Inflector.underscore('Equipment')),
		screenName: 'EquipmentManager',
		usesEnterpriseSelector: true,
		permissions: [VIEW, 'Equipment'],
	},
	{
		title: 'Users',
		_icon: { as: User, },
		path: urlPrefix + Inflector.dasherize(Inflector.underscore('Users')),
		screenName: 'UsersManager',
		permissions: [VIEW, 'Users'],
	},
	{
		title: 'Groups',
		_icon: { as: UserGroup, },
		path: urlPrefix + Inflector.dasherize(Inflector.underscore('Groups')),
		screenName: 'GroupsManager',
		permissions: [VIEW, 'Groups'],
	},
	{
		title: 'Logs',
		_icon: { as: Book, },
		path: urlPrefix + Inflector.dasherize(Inflector.underscore('Logs')),
		screenName: 'Logs',
		permissions: 'access_audit_app',
	},
	{
		title: 'Utilities',
		_icon: { as: Hammer, },
		path: urlPrefix + Inflector.dasherize(Inflector.underscore('Util')),
		screenName: 'Util',
	},
];

export let visibleNormalTabs = [];
export let visibleSetupTabs = [];

export function calculateTabVisibility() {
	const tabs = [normalTabs, setupTabs];
	_.each(tabs, (tabSet) => {
		_.each(tabSet, (tab) => {
			let isVisible = false;
			if (!tab.permissions) {
				isVisible = true;
			} else {
				const [permission, model] = tab.permissions;
				isVisible = canUser(permission, model);
			}
			tab.isVisible = isVisible;
		});
	});

	visibleNormalTabs = _.filter(tabs[0], (tab) => tab.isVisible);
	visibleSetupTabs = _.filter(tabs[1], (tab) => tab.isVisible);
}

calculateTabVisibility(); // set the initially visible tabs
