
import {
	Icon,
	Row,
	Text,
} from 'native-base';
import { useSelector, useDispatch } from 'react-redux';
import {
	logoutThunk,
	selectUser,
} from '../../../models/Slices/AppSlice';
import IconButton from '@onehat/ui/src/Components/Buttons/IconButton';
import RightFromBracket from '@onehat/ui/src/Components/Icons/RightFromBracket';
import User from '@onehat/ui/src/Components/Icons/User';

export default function UserIndicator(props) {
	const {
			isMinimized = false,
		} = props,
		dispatch = useDispatch(),
		user = useSelector(selectUser);
	if (!user) {
		return null;
	}
	return <Row alignItems="center">
				{!isMinimized &&
					<Icon
						as={User}
						ml={2}
						mr={1}
					/>}
				{!isMinimized &&
					<Text>{user.full_name}</Text>}
					
				<IconButton
					icon={RightFromBracket}
					onPress={() => dispatch(logoutThunk())}
					tooltip="Logout"
				/>
			</Row>;
}