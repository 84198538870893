/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportPmEquipmentGridEditor from './ImportPmEquipmentGridEditor.js';

export default function ImportPmEquipmentFilteredGridEditor(props) {
	return <ImportPmEquipmentGridEditor
				reference="ImportPmEquipmentFilteredGridEditor"
				useFilters={true}

				{...props}
			/>;
}