/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Tag from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import ImportWoOtherChargesEditorWindow from '../../../Window/ImportWoOtherChargesEditorWindow.js';

function ImportWoOtherChargesTag(props) {
	return <Tag
				reference="ImportWoOtherChargesTag"
				model="ImportWoOtherCharges"
				uniqueRepository={true}
				Editor={ImportWoOtherChargesEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default ImportWoOtherChargesTag;