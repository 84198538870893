/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function PartsEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "General",
		                "reference": "general",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "parts__part_number"
		                    },
		                    {
		                        "name": "parts__description"
		                    },
		                    {
		                        "name": "parts__manufacturer_id"
		                    },
		                    {
		                        "name": "parts__unit_cost"
		                    },
		                    {
		                        "name": "parts__unit_price"
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [
		    {
		        "title": "Part Types",
		        "type": "PartsPartTypesGridEditorSideB",
		        "selectorId": "parts_part_types__part_id"
		    },
		    {
		        "title": "Work Orders",
		        "type": "PartsWorkOrdersGridEditorSideB",
		        "selectorId": "parts_work_orders__part_id"
		    }
		],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="PartsEditor"
				title="Parts"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

