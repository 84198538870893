export default [
	    {
	        "id": "meter_readings__equipment_id",
	        "header": "Equipment",
	        "fieldName": "equipment__unit_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "meter_readings__equipment_id"
	    },
	    {
	        "id": "meter_readings__date",
	        "header": "Date",
	        "fieldName": "meter_readings__date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "meter_readings__value",
	        "header": "Value",
	        "fieldName": "meter_readings__value",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "meter_readings__delta",
	        "header": "Delta",
	        "fieldName": "meter_readings__delta",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "meter_readings__is_replacement",
	        "header": "Replacement?",
	        "fieldName": "meter_readings__is_replacement",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "meter_readings__is_bad",
	        "header": "Bad?",
	        "fieldName": "meter_readings__is_bad",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "meter_readings__is_pm_clock_reset",
	        "header": "Pm Clock Reset?",
	        "fieldName": "meter_readings__is_pm_clock_reset",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "meter_readings__comment",
	        "header": "Comment",
	        "fieldName": "meter_readings__comment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "meter_readings__meter_source_id",
	        "header": "Meter Source",
	        "fieldName": "meter_sources__source",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "meter_readings__meter_source_id"
	    },
	    {
	        "id": "meter_readings__work_order_id",
	        "header": "Work Order",
	        "fieldName": "work_orders__service_order",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "meter_readings__work_order_id"
	    },
	    {
	        "id": "meter_readings__pm_event_id",
	        "header": "PM Event",
	        "fieldName": "pm_events__id",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "meter_readings__pm_event_id"
	    },
	    {
	        "id": "meter_readings__tel_usage_id",
	        "header": "Tel Usage",
	        "fieldName": "tel_usages__id",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "meter_readings__tel_usage_id"
	    }
	];