export default [
	    {
	        "id": "util_process_logs__datetime",
	        "header": "Datetime",
	        "fieldName": "util_process_logs__datetime",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "util_process_logs__process",
	        "header": "Process",
	        "fieldName": "util_process_logs__process",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200 // MOD
	    },
	    {
	        "id": "util_process_logs__success",
	        "header": "Success",
	        "fieldName": "util_process_logs__success",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "util_process_logs__count",
	        "header": "Count",
	        "fieldName": "util_process_logs__count",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "util_process_logs__time",
	        "header": "Time",
	        "fieldName": "util_process_logs__time",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "util_process_logs__json",
	        "header": "Json",
	        "fieldName": "util_process_logs__json",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];