/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import ImportTechniciansUtilProcessLogsEditor from '../../Editor/ImportTechniciansUtilProcessLogsEditor.js';
import ImportTechniciansUtilProcessLogsGridColumns from '../../Grid/Columns/ImportTechniciansUtilProcessLogsGridColumns.js';

export default function ImportTechniciansUtilProcessLogsSideGridEditorPanel(props) {
	return <GridPanel
				reference="ImportTechniciansUtilProcessLogsSideGridEditorPanel"
				model="ImportTechniciansUtilProcessLogs"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={ImportTechniciansUtilProcessLogsEditor}
				columnsConfig={ImportTechniciansUtilProcessLogsGridColumns}
				
				
				{...props}
			/>;
}