/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportTechniciansUtilProcessLogsGridEditorSideB from './ImportTechniciansUtilProcessLogsGridEditorSideB.js';

export default function ImportTechniciansUtilProcessLogsFilteredGridEditorSideB(props) {
	return <ImportTechniciansUtilProcessLogsGridEditorSideB
				reference="ImportTechniciansUtilProcessLogsFilteredGridEditorSideB"
				useFilters={true}

				{...props}
			/>;
}