/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Tag from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import ImportManufacturersUtilProcessLogsEditorWindow from '../../../Window/ImportManufacturersUtilProcessLogsEditorWindow.js';

function ImportManufacturersUtilProcessLogsTag(props) {
	return <Tag
				reference="ImportManufacturersUtilProcessLogsTag"
				model="ImportManufacturersUtilProcessLogs"
				uniqueRepository={true}
				Editor={ImportManufacturersUtilProcessLogsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default ImportManufacturersUtilProcessLogsTag;