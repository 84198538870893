import * as yup from 'yup'; // https://github.com/jquense/yup#string

const ImportPmEquipment = {
	
	name: 'ImportPmEquipment',
	
	model: {

		idProperty: 'import_pm_equipment__id',
		displayProperty: 'import_pm_equipment__id',
		sortProperty: 'import_pm_equipment__id',
		
		sorters: null,

		validator: yup.object({
			import_pm_equipment__business_partner: yup.string().max(255).nullable(),
			import_pm_equipment__customer_name: yup.string().max(30).nullable(),
			import_pm_equipment__year: yup.number().integer().nullable(),
			import_pm_equipment__make: yup.string().max(10).nullable(),
			import_pm_equipment__model: yup.string().max(14).nullable(),
			import_pm_equipment__serial_number: yup.string().max(30).nullable(),
			import_pm_equipment__service_center: yup.string().max(10).nullable(),
			import_pm_equipment__unit_number: yup.string().max(25).nullable(),
			import_pm_equipment__asset_number: yup.string().max(25).nullable(),
			import_pm_equipment__meter_reading: yup.number().integer().nullable(),
			import_pm_equipment__technician: yup.string().max(10).nullable(),
			import_pm_equipment__pm_comments: yup.string().nullable(),
			import_pm_equipment__pm_status: yup.number().integer().nullable(),
			import_pm_equipment__service_plan: yup.string().max(15).nullable(),
			import_pm_equipment__job_code: yup.string().max(25).nullable(),
			import_pm_equipment__is_imported: yup.boolean().nullable(),
			import_pm_equipment__imported_date: yup.date().nullable()
		}),
		
		properties: [
			{ name: 'import_pm_equipment__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'import_pm_equipment__business_partner', mapping: 'business_partner', title: 'Business Partner', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment__customer_name', mapping: 'customer_name', title: 'Customer Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment__year', mapping: 'year', title: 'Year', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment__make', mapping: 'make', title: 'Make', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment__model', mapping: 'model', title: 'Model', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment__serial_number', mapping: 'serial_number', title: 'Serial Number', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment__service_center', mapping: 'service_center', title: 'Service Center', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment__unit_number', mapping: 'unit_number', title: 'Unit Number', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment__asset_number', mapping: 'asset_number', title: 'Asset Number', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment__meter_reading', mapping: 'meter_reading', title: 'Meter Reading', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment__technician', mapping: 'technician', title: 'Technician', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment__pm_comments', mapping: 'pm_comments', title: 'Pm Comments', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment__pm_status', mapping: 'pm_status', title: 'Pm Status', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment__service_plan', mapping: 'service_plan', title: 'Service Plan', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment__job_code', mapping: 'job_code', title: 'Job Code', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment__is_imported', mapping: 'is_imported', title: 'Imported?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'import_pm_equipment__imported_date', mapping: 'imported_date', title: 'Imported Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', }
		],

		associations: {

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'import_pm_equipment__year',
			// 'import_pm_equipment__meter_reading',
			// 'import_pm_equipment__pm_status',
			// 'import_pm_equipment__is_imported',
			// 'import_pm_equipment__imported_date'
		],

	},



	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default ImportPmEquipment;
