import * as yup from 'yup'; // https://github.com/jquense/yup#string

const WoLabors = {
	
	name: 'WoLabors',
	
	model: {

		idProperty: 'wo_labors__id',
		displayProperty: 'wo_labors__id',
		sortProperty: 'wo_labors__date',
		
		sorters: null,

		validator: yup.object({
			wo_labors__work_order_id: yup.number().integer().required(),
			wo_labors__wo_labor_type_id: yup.number().integer().required(),
			wo_labors__date: yup.date().nullable(),
			wo_labors__time_type: yup.string().max(10).nullable(),
			wo_labors__hours: yup.number().nullable(),
			wo_labors__description: yup.string().nullable(),
			wo_labors__pm_technician_id: yup.number().integer().nullable(),
			wo_labors__hourly_rate: yup.number().nullable(),
			wo_labors__unit_price: yup.number().nullable(),
			wo_labors__overtime_price: yup.number().nullable(),
			wo_labors__sell_price: yup.number().nullable()
		}),
		
		properties: [
			{ name: 'wo_labors__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'wo_labors__work_order_id', mapping: 'work_order_id', title: 'Work Order', type: 'int', isFk: true, fkIdField: 'work_orders__id', fkDisplayField: 'work_orders__service_order', filterType: {"type":"WorkOrdersCombo","loadAfterRender":!1}, editorType: {"type":"WorkOrdersComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'wo_labors__wo_labor_type_id', mapping: 'wo_labor_type_id', title: 'Wo Labor Type', type: 'int', isFk: true, fkIdField: 'wo_labor_types__id', fkDisplayField: 'wo_labor_types__name', filterType: {"type":"WoLaborTypesCombo","loadAfterRender":!1}, editorType: {"type":"WoLaborTypesComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'wo_labors__date', mapping: 'date', title: 'Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', },
			{ name: 'wo_labors__time_type', mapping: 'time_type', title: 'Time Type', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'wo_labors__hours', mapping: 'hours', title: 'Hours', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', defaultValue: 1, },
			{ name: 'wo_labors__description', mapping: 'description', title: 'Description', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'wo_labors__pm_technician_id', mapping: 'pm_technician_id', title: 'PM Technician', type: 'int', isFk: true, fkIdField: 'users__id', fkDisplayField: 'users__username', filterType: {"type":"UsersCombo","loadAfterRender":!1}, editorType: {"type":"UsersComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'wo_labors__hourly_rate', mapping: 'hourly_rate', title: 'Hourly Rate', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'wo_labors__unit_price', mapping: 'unit_price', title: 'Unit Price', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'wo_labors__overtime_price', mapping: 'overtime_price', title: 'Overtime Price', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'wo_labors__sell_price', mapping: 'sell_price', title: 'Sell Price', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'wo_labor_types__id', mapping: 'wo_labor_type.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'wo_labor_types__name', mapping: 'wo_labor_type.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'wo_labor_types__unit_price', mapping: 'wo_labor_type.unit_price', title: 'Unit Price', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'wo_labor_types__code', mapping: 'wo_labor_type.code', title: 'Code', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__id', mapping: 'work_order.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__enterprise_id', mapping: 'work_order.enterprise_id', title: 'Enterprise', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__equipment_id', mapping: 'work_order.equipment_id', title: 'Equipment', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__business_partner_id', mapping: 'work_order.business_partner_id', title: 'Business Partner', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__service_order', mapping: 'work_order.service_order', title: 'Service Order', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__segment', mapping: 'work_order.segment', title: 'Segment', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__invoice_number', mapping: 'work_order.invoice_number', title: 'Invoice Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__segment_type', mapping: 'work_order.segment_type', title: 'Segment Type', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__wo_number', mapping: 'work_order.wo_number', title: 'Work Order Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__po_number', mapping: 'work_order.po_number', title: 'PO Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__service_center_id', mapping: 'work_order.service_center_id', title: 'Service Center', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__open_date', mapping: 'work_order.open_date', title: 'Open Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__wo_service_type_id', mapping: 'work_order.wo_service_type_id', title: 'Wo Service Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__close_date', mapping: 'work_order.close_date', title: 'Close Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__invoice_date', mapping: 'work_order.invoice_date', title: 'Invoice Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__complete_date', mapping: 'work_order.complete_date', title: 'Complete Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__time_out_of_service', mapping: 'work_order.time_out_of_service', title: 'Time Out Of Service', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__wo_mode_id', mapping: 'work_order.wo_mode_id', title: 'Mode', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__wo_class_id', mapping: 'work_order.wo_class_id', title: 'Class', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__pm_schedule_id', mapping: 'work_order.pm_schedule_id', title: 'PM Schedule', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__complaint', mapping: 'work_order.complaint', title: 'Complaint', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__cause', mapping: 'work_order.cause', title: 'Cause', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__correction', mapping: 'work_order.correction', title: 'Correction', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__coverage', mapping: 'work_order.coverage', title: 'Coverage', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__comments', mapping: 'work_order.comments', title: 'Comments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__meter_reading', mapping: 'work_order.meter_reading', title: 'Meter Reading', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__original_destination', mapping: 'work_order.original_destination', title: 'Original Destination', type: 'json', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__total_parts', mapping: 'work_order.total_parts', title: 'Total Parts', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__total_labor', mapping: 'work_order.total_labor', title: 'Total Labor', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__total_other', mapping: 'work_order.total_other', title: 'Total Other', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__tax', mapping: 'work_order.tax', title: 'Tax', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__total', mapping: 'work_order.total', title: 'Total', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__is_ltm', mapping: 'work_order.is_ltm', title: 'Ltm?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__is_scheduled', mapping: 'work_order.is_scheduled', title: 'Scheduled?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__is_verified', mapping: 'work_order.is_verified', title: 'Verified?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__repaired_by_branch', mapping: 'work_order.repaired_by_branch', title: 'Repaired By Branch', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__repaired_by_company', mapping: 'work_order.repaired_by_company', title: 'Repaired By Company', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__sold_to_business_partner_id', mapping: 'work_order.sold_to_business_partner_id', title: 'Sold To Business Partner', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__sold_to_name', mapping: 'work_order.sold_to_name', title: 'Sold To Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__sold_to_address', mapping: 'work_order.sold_to_address', title: 'Sold To Address', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__sold_to_city', mapping: 'work_order.sold_to_city', title: 'Sold To City', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__sold_to_state_abbr', mapping: 'work_order.sold_to_state_abbr', title: 'Sold To State Abbr', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__sold_to_zip', mapping: 'work_order.sold_to_zip', title: 'Sold To Zip', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__bill_to_business_partner_id', mapping: 'work_order.bill_to_business_partner_id', title: 'Bill To Business Partner', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__bill_to_name', mapping: 'work_order.bill_to_name', title: 'Bill To Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_business_partner_id', mapping: 'work_order.ship_to_business_partner_id', title: 'Ship To Business Partner', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_name', mapping: 'work_order.ship_to_name', title: 'Ship To Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_address', mapping: 'work_order.ship_to_address', title: 'Ship To Address', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_city', mapping: 'work_order.ship_to_city', title: 'Ship To City', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_state_abbr', mapping: 'work_order.ship_to_state_abbr', title: 'Ship To State', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_zip', mapping: 'work_order.ship_to_zip', title: 'Ship To Zip', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__id', mapping: 'user.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__username', mapping: 'user.username', title: 'Username', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__email', mapping: 'user.email', title: 'Email', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password', mapping: 'user.password', title: 'Password', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__first_name', mapping: 'user.first_name', title: 'First Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_name', mapping: 'user.last_name', title: 'Last Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__employee_number', mapping: 'user.employee_number', title: 'Employee Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token', mapping: 'user.token', title: 'Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token_generated', mapping: 'user.token_generated', title: 'Token Generated', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__expo_token', mapping: 'user.expo_token', title: 'Expo Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password_reset', mapping: 'user.password_reset', title: 'Password Reset', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__full_name', mapping: 'user.full_name', title: 'Full Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_login', mapping: 'user.last_login', title: 'Last Login', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__login_count', mapping: 'user.login_count', title: 'Login Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__groups', mapping: 'user.groups', title: 'Groups', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'WoLaborTypes',
				'WorkOrders',
				'Users'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'wo_labors__work_order_id',
			// 'wo_labors__wo_labor_type_id',
			// 'wo_labors__date',
			// 'wo_labors__hours',
			// 'wo_labors__pm_technician_id',
			// 'wo_labors__hourly_rate',
			// 'wo_labors__unit_price',
			// 'wo_labors__overtime_price',
			// 'wo_labors__sell_price'
		],

	},

	entity: {
		methods: {

			getWoLaborType: async function() {
				const WoLaborTypes = this.getAssociatedRepository('WoLaborTypes');
				let entity = WoLaborTypes.getById(this.wo_labors__wo_labor_type_id);
				if (!entity) {
					entity = await WoLaborTypes.getSingleEntityFromServer(this.wo_labors__wo_labor_type_id);
				}
				return entity;
			},

			getWorkOrder: async function() {
				const WorkOrders = this.getAssociatedRepository('WorkOrders');
				let entity = WorkOrders.getById(this.wo_labors__work_order_id);
				if (!entity) {
					entity = await WorkOrders.getSingleEntityFromServer(this.wo_labors__work_order_id);
				}
				return entity;
			},

			getUser: async function() {
				const Users = this.getAssociatedRepository('Users');
				let entity = Users.getById(this.wo_labors__pm_technician_id);
				if (!entity) {
					entity = await Users.getSingleEntityFromServer(this.wo_labors__pm_technician_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default WoLabors;
