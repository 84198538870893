import * as yup from 'yup'; // https://github.com/jquense/yup#string

const WoServiceTypes = {
	
	name: 'WoServiceTypes',
	
	model: {

		idProperty: 'wo_service_types__id',
		displayProperty: 'wo_service_types__name',
		sortProperty: 'wo_service_types__name',
		
		sorters: null,

		validator: yup.object({
			wo_service_types__name: yup.string().required(),
			wo_service_types__comments: yup.string().nullable()
		}),
		
		properties: [
			{ name: 'wo_service_types__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'wo_service_types__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'wo_service_types__comments', mapping: 'comments', title: 'Comments', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', }
		],

		associations: {
			hasMany: [
				'WorkOrders',
				'ImportWoServiceTypesUtilProcessLogs'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [

		],

	},

	entity: {
		methods: {

			getWorkOrders: function() {
				const WorkOrders = this.getAssociatedRepository('WorkOrders');
				return WorkOrders.getBy((entity) => {
					return entity.work_orders__wo_service_type_id === this.wo_service_types__id;
				});
			},

			getImportWoServiceTypesUtilProcessLogs: function() {
				const ImportWoServiceTypesUtilProcessLogs = this.getAssociatedRepository('ImportWoServiceTypesUtilProcessLogs');
				return ImportWoServiceTypesUtilProcessLogs.getBy((entity) => {
					return entity.import_wo_service_types_util_process_logs__wo_service_type_id === this.wo_service_types__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default WoServiceTypes;
