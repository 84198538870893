/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportWoOtherChargeTypesUtilProcessLogsGridEditorSideB from './ImportWoOtherChargeTypesUtilProcessLogsGridEditorSideB.js';

export default function ImportWoOtherChargeTypesUtilProcessLogsFilteredGridEditorSideB(props) {
	return <ImportWoOtherChargeTypesUtilProcessLogsGridEditorSideB
				reference="ImportWoOtherChargeTypesUtilProcessLogsFilteredGridEditorSideB"
				useFilters={true}

				{...props}
			/>;
}