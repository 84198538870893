/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import Tag from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import ImportWorkOrdersUtilProcessLogsEditorWindow from '../../../Window/ImportWorkOrdersUtilProcessLogsEditorWindow.js';

function ImportWorkOrdersUtilProcessLogsTag(props) {
	return <Tag
				reference="ImportWorkOrdersUtilProcessLogsTag"
				model="ImportWorkOrdersUtilProcessLogs"
				uniqueRepository={true}
				Editor={ImportWorkOrdersUtilProcessLogsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default ImportWorkOrdersUtilProcessLogsTag;