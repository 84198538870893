export default [
	    {
	        "id": "import_work_orders__woid",
	        "header": "WOID",
	        "fieldName": "import_work_orders__woid",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__business_partner",
	        "header": "Business Partner",
	        "fieldName": "import_work_orders__business_partner",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__service_order",
	        "header": "Service Order",
	        "fieldName": "import_work_orders__service_order",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__segment",
	        "header": "Segment",
	        "fieldName": "import_work_orders__segment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_work_orders__service_center",
	        "header": "Service Center",
	        "fieldName": "import_work_orders__service_center",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__po_number",
	        "header": "Po Number",
	        "fieldName": "import_work_orders__po_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__service_type",
	        "header": "Service Type",
	        "fieldName": "import_work_orders__service_type",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__open_date",
	        "header": "Open Date",
	        "fieldName": "import_work_orders__open_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "import_work_orders__invoice_date",
	        "header": "Invoice Date",
	        "fieldName": "import_work_orders__invoice_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "import_work_orders__wo_class",
	        "header": "Wo Class",
	        "fieldName": "import_work_orders__wo_class",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__data_source",
	        "header": "Data Source",
	        "fieldName": "import_work_orders__data_source",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__segment_type",
	        "header": "Segment Type",
	        "fieldName": "import_work_orders__segment_type",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_work_orders__manufacturer",
	        "header": "Manufacturer",
	        "fieldName": "import_work_orders__manufacturer",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__job_code",
	        "header": "Job Code",
	        "fieldName": "import_work_orders__job_code",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__model",
	        "header": "Model",
	        "fieldName": "import_work_orders__model",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__serial_number",
	        "header": "Serial Number",
	        "fieldName": "import_work_orders__serial_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__unit_number",
	        "header": "Unit Number",
	        "fieldName": "import_work_orders__unit_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__asset_number",
	        "header": "Asset Number",
	        "fieldName": "import_work_orders__asset_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__meter_reading",
	        "header": "Meter Reading",
	        "fieldName": "import_work_orders__meter_reading",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_work_orders__complaint",
	        "header": "Complaint",
	        "fieldName": "import_work_orders__complaint",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "import_work_orders__cause",
	        "header": "Cause",
	        "fieldName": "import_work_orders__cause",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "import_work_orders__correction",
	        "header": "Correction",
	        "fieldName": "import_work_orders__correction",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "import_work_orders__coverage",
	        "header": "Coverage",
	        "fieldName": "import_work_orders__coverage",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "import_work_orders__comments",
	        "header": "Comments",
	        "fieldName": "import_work_orders__comments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "import_work_orders__total_parts",
	        "header": "Total Parts",
	        "fieldName": "import_work_orders__total_parts",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_work_orders__total_labor",
	        "header": "Total Labor",
	        "fieldName": "import_work_orders__total_labor",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_work_orders__total_other",
	        "header": "Total Other",
	        "fieldName": "import_work_orders__total_other",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_work_orders__tax",
	        "header": "Tax",
	        "fieldName": "import_work_orders__tax",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_work_orders__total",
	        "header": "Total",
	        "fieldName": "import_work_orders__total",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_work_orders__sold_to_customer",
	        "header": "Sold To Customer",
	        "fieldName": "import_work_orders__sold_to_customer",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__sold_to_name",
	        "header": "Sold To Name",
	        "fieldName": "import_work_orders__sold_to_name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__sold_to_address",
	        "header": "Sold To Address",
	        "fieldName": "import_work_orders__sold_to_address",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__sold_to_city",
	        "header": "Sold To City",
	        "fieldName": "import_work_orders__sold_to_city",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__sold_to_state_abbr",
	        "header": "Sold To State Abbr",
	        "fieldName": "import_work_orders__sold_to_state_abbr",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__sold_to_zip",
	        "header": "Sold To Zip",
	        "fieldName": "import_work_orders__sold_to_zip",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__bill_to_customer",
	        "header": "Bill To Customer",
	        "fieldName": "import_work_orders__bill_to_customer",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__bill_to_name",
	        "header": "Bill To Name",
	        "fieldName": "import_work_orders__bill_to_name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__ship_to_customer",
	        "header": "Ship To Customer",
	        "fieldName": "import_work_orders__ship_to_customer",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__ship_to_name",
	        "header": "Ship To Name",
	        "fieldName": "import_work_orders__ship_to_name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__ship_to_address",
	        "header": "Ship To Address",
	        "fieldName": "import_work_orders__ship_to_address",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__ship_to_city",
	        "header": "Ship To City",
	        "fieldName": "import_work_orders__ship_to_city",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__ship_to_state_abbr",
	        "header": "Ship To State Abbr",
	        "fieldName": "import_work_orders__ship_to_state_abbr",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__ship_to_zip",
	        "header": "Ship To Zip",
	        "fieldName": "import_work_orders__ship_to_zip",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_work_orders__is_imported",
	        "header": "Imported?",
	        "fieldName": "import_work_orders__is_imported",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_work_orders__imported_date",
	        "header": "Imported Date",
	        "fieldName": "import_work_orders__imported_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    }
	];