/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportTechniciansUtilProcessLogsEditor from '../Editor/ImportTechniciansUtilProcessLogsEditor.js';
import ImportTechniciansUtilProcessLogsGridColumns from './Columns/ImportTechniciansUtilProcessLogsGridColumns.js';

export default function ImportTechniciansUtilProcessLogsSideGridEditor(props) {
	return <SideGridEditor
				reference="ImportTechniciansUtilProcessLogsSideGridEditor"
				model="ImportTechniciansUtilProcessLogs"
				usePermissions={true}
				isCollapsible={false}
				Editor={ImportTechniciansUtilProcessLogsEditor}
				columnsConfig={ImportTechniciansUtilProcessLogsGridColumns}
				
				
				{...props}
			/>;
}