import * as yup from 'yup'; // https://github.com/jquense/yup#string

const ImportManufacturersUtilProcessLogs = {
	
	name: 'ImportManufacturersUtilProcessLogs',
	
	model: {

		idProperty: 'import_manufacturers_util_process_logs__id',
		displayProperty: 'import_manufacturers_util_process_logs__id',
		sortProperty: 'import_manufacturers_util_process_logs__datetime',
		sortDir: 'DESC',
		
		sorters: null,

		validator: yup.object({
			import_manufacturers_util_process_logs__util_process_log_id: yup.number().integer().required(),
			import_manufacturers_util_process_logs__datetime: yup.date().nullable(),
			import_manufacturers_util_process_logs__success: yup.boolean().required(),
			import_manufacturers_util_process_logs__status: yup.number().integer().nullable(),
			import_manufacturers_util_process_logs__json: yup.string().nullable(),
			import_manufacturers_util_process_logs__import_manufacturer_id: yup.number().integer().nullable(),
			import_manufacturers_util_process_logs__manufacturer_id: yup.number().integer().nullable()
		}),
		
		properties: [
			{ name: 'import_manufacturers_util_process_logs__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'import_manufacturers_util_process_logs__util_process_log_id', mapping: 'util_process_log_id', title: 'Util Process Log', type: 'int', isFk: true, fkIdField: 'util_process_logs__id', fkDisplayField: 'util_process_logs__process', filterType: {"type":"UtilProcessLogsCombo","loadAfterRender":!1}, editorType: {"type":"UtilProcessLogsCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'import_manufacturers_util_process_logs__datetime', mapping: 'datetime', title: 'Datetime', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', },
			{ name: 'import_manufacturers_util_process_logs__success', mapping: 'success', title: 'Success', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'import_manufacturers_util_process_logs__status', mapping: 'status', title: 'Status', type: 'int', filterType: {"type":"ImportStatusesCombo"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_manufacturers_util_process_logs__json', mapping: 'json', title: 'Json', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'import_manufacturers_util_process_logs__import_manufacturer_id', mapping: 'import_manufacturer_id', title: 'Import Manufacturer', type: 'int', isFk: true, fkIdField: 'import_manufacturers__id', fkDisplayField: 'import_manufacturers__name', filterType: {"type":"ImportManufacturersCombo","loadAfterRender":!1}, editorType: {"type":"ImportManufacturersComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'import_manufacturers_util_process_logs__manufacturer_id', mapping: 'manufacturer_id', title: 'Manufacturer', type: 'int', isFk: true, fkIdField: 'manufacturers__id', fkDisplayField: 'manufacturers__name', filterType: {"type":"ManufacturersCombo","loadAfterRender":!1}, editorType: {"type":"ManufacturersComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'import_manufacturers__id', mapping: 'import_manufacturer.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_manufacturers__name', mapping: 'import_manufacturer.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_manufacturers__abbr', mapping: 'import_manufacturer.abbr', title: 'Abbr', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_manufacturers__is_imported', mapping: 'import_manufacturer.is_imported', title: 'Imported?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_manufacturers__imported_date', mapping: 'import_manufacturer.imported_date', title: 'Imported Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__id', mapping: 'manufacturer.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__name', mapping: 'manufacturer.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__abbr', mapping: 'manufacturer.abbr', title: 'Abbr', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__fa1_eq_abbr', mapping: 'manufacturer.fa1_eq_abbr', title: 'Fa1 Eq Abbr', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__fa1_part_abbr', mapping: 'manufacturer.fa1_part_abbr', title: 'Fa1 Part Abbr', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__is_telemetry', mapping: 'manufacturer.is_telemetry', title: 'Telemetry?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__id', mapping: 'util_process_log.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__datetime', mapping: 'util_process_log.datetime', title: 'Datetime', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__process', mapping: 'util_process_log.process', title: 'Process', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__success', mapping: 'util_process_log.success', title: 'Success', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__count', mapping: 'util_process_log.count', title: 'Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__time', mapping: 'util_process_log.time', title: 'Time', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__json', mapping: 'util_process_log.json', title: 'Json', type: 'json', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'ImportManufacturers',
				'Manufacturers',
				'UtilProcessLogs'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'import_manufacturers_util_process_logs__util_process_log_id',
			// 'import_manufacturers_util_process_logs__datetime',
			'import_manufacturers_util_process_logs__success',
			'import_manufacturers_util_process_logs__status',
			// 'import_manufacturers_util_process_logs__import_manufacturer_id',
			// 'import_manufacturers_util_process_logs__manufacturer_id'
		],

	},

	entity: {
		methods: {

			getImportManufacturer: async function() {
				const ImportManufacturers = this.getAssociatedRepository('ImportManufacturers');
				let entity = ImportManufacturers.getById(this.import_manufacturers_util_process_logs__import_manufacturer_id);
				if (!entity) {
					entity = await ImportManufacturers.getSingleEntityFromServer(this.import_manufacturers_util_process_logs__import_manufacturer_id);
				}
				return entity;
			},

			getManufacturer: async function() {
				const Manufacturers = this.getAssociatedRepository('Manufacturers');
				let entity = Manufacturers.getById(this.import_manufacturers_util_process_logs__manufacturer_id);
				if (!entity) {
					entity = await Manufacturers.getSingleEntityFromServer(this.import_manufacturers_util_process_logs__manufacturer_id);
				}
				return entity;
			},

			getUtilProcessLog: async function() {
				const UtilProcessLogs = this.getAssociatedRepository('UtilProcessLogs');
				let entity = UtilProcessLogs.getById(this.import_manufacturers_util_process_logs__util_process_log_id);
				if (!entity) {
					entity = await UtilProcessLogs.getSingleEntityFromServer(this.import_manufacturers_util_process_logs__util_process_log_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default ImportManufacturersUtilProcessLogs;
