/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import ImportManufacturersUtilProcessLogsEditorWindow from '../../Window/ImportManufacturersUtilProcessLogsEditorWindow.js';
import ImportManufacturersUtilProcessLogsGridColumns from '../../Grid/Columns/ImportManufacturersUtilProcessLogsGridColumns.js';

export default function ImportManufacturersUtilProcessLogsGridEditorPanel(props) {
	return <GridPanel
				reference="ImportManufacturersUtilProcessLogsGridEditorPanel"
				model="ImportManufacturersUtilProcessLogs"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={ImportManufacturersUtilProcessLogsEditorWindow}
				columnsConfig={ImportManufacturersUtilProcessLogsGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}