import * as yup from 'yup'; // https://github.com/jquense/yup#string

const ImportWoServiceTypesUtilProcessLogs = {
	
	name: 'ImportWoServiceTypesUtilProcessLogs',
	
	model: {

		idProperty: 'import_wo_service_types_util_process_logs__id',
		displayProperty: 'import_wo_service_types_util_process_logs__status',
		sortProperty: 'import_wo_service_types_util_process_logs__status',
		
		sorters: null,

		validator: yup.object({
			import_wo_service_types_util_process_logs__util_process_log_id: yup.number().integer().required(),
			import_wo_service_types_util_process_logs__datetime: yup.date().nullable(),
			import_wo_service_types_util_process_logs__success: yup.boolean().required(),
			import_wo_service_types_util_process_logs__status: yup.number().integer().required(),
			import_wo_service_types_util_process_logs__json: yup.string().nullable(),
			import_wo_service_types_util_process_logs__import_wo_service_type_id: yup.number().integer().nullable(),
			import_wo_service_types_util_process_logs__wo_service_type_id: yup.number().integer().nullable()
		}),
		
		properties: [
			{ name: 'import_wo_service_types_util_process_logs__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'import_wo_service_types_util_process_logs__util_process_log_id', mapping: 'util_process_log_id', title: 'Util Process Log', type: 'int', isFk: true, fkIdField: 'util_process_logs__id', fkDisplayField: 'util_process_logs__process', filterType: {"type":"UtilProcessLogsCombo","loadAfterRender":!1}, editorType: {"type":"UtilProcessLogsCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'import_wo_service_types_util_process_logs__datetime', mapping: 'datetime', title: 'Datetime', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', },
			{ name: 'import_wo_service_types_util_process_logs__success', mapping: 'success', title: 'Success', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'import_wo_service_types_util_process_logs__status', mapping: 'status', title: 'Status', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_wo_service_types_util_process_logs__json', mapping: 'json', title: 'Json', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'import_wo_service_types_util_process_logs__import_wo_service_type_id', mapping: 'import_wo_service_type_id', title: 'Import Wo Service Type', type: 'int', isFk: true, fkIdField: 'import_wo_service_types__id', fkDisplayField: 'import_wo_service_types__name', filterType: {"type":"ImportWoServiceTypesCombo","loadAfterRender":!1}, editorType: {"type":"ImportWoServiceTypesCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'import_wo_service_types_util_process_logs__wo_service_type_id', mapping: 'wo_service_type_id', title: 'Wo Service Type', type: 'int', isFk: true, fkIdField: 'wo_service_types__id', fkDisplayField: 'wo_service_types__name', filterType: {"type":"WoServiceTypesCombo","loadAfterRender":!1}, editorType: {"type":"WoServiceTypesCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'util_process_logs__id', mapping: 'util_process_log.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__datetime', mapping: 'util_process_log.datetime', title: 'Datetime', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__process', mapping: 'util_process_log.process', title: 'Process', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__success', mapping: 'util_process_log.success', title: 'Success', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__count', mapping: 'util_process_log.count', title: 'Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__time', mapping: 'util_process_log.time', title: 'Time', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'util_process_logs__json', mapping: 'util_process_log.json', title: 'Json', type: 'json', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_wo_service_types__id', mapping: 'import_wo_service_type.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_wo_service_types__name', mapping: 'import_wo_service_type.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_wo_service_types__comments', mapping: 'import_wo_service_type.comments', title: 'Comments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_wo_service_types__is_imported', mapping: 'import_wo_service_type.is_imported', title: 'Imported?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'import_wo_service_types__imported_date', mapping: 'import_wo_service_type.imported_date', title: 'Imported Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'wo_service_types__id', mapping: 'wo_service_type.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'wo_service_types__name', mapping: 'wo_service_type.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'wo_service_types__comments', mapping: 'wo_service_type.comments', title: 'Comments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'UtilProcessLogs',
				'ImportWoServiceTypes',
				'WoServiceTypes'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'import_wo_service_types_util_process_logs__util_process_log_id',
			// 'import_wo_service_types_util_process_logs__datetime',
			'import_wo_service_types_util_process_logs__success',
			'import_wo_service_types_util_process_logs__status',
			// 'import_wo_service_types_util_process_logs__import_wo_service_type_id',
			// 'import_wo_service_types_util_process_logs__wo_service_type_id'
		],

	},

	entity: {
		methods: {

			getUtilProcessLog: async function() {
				const UtilProcessLogs = this.getAssociatedRepository('UtilProcessLogs');
				let entity = UtilProcessLogs.getById(this.import_wo_service_types_util_process_logs__util_process_log_id);
				if (!entity) {
					entity = await UtilProcessLogs.getSingleEntityFromServer(this.import_wo_service_types_util_process_logs__util_process_log_id);
				}
				return entity;
			},

			getImportWoServiceType: async function() {
				const ImportWoServiceTypes = this.getAssociatedRepository('ImportWoServiceTypes');
				let entity = ImportWoServiceTypes.getById(this.import_wo_service_types_util_process_logs__import_wo_service_type_id);
				if (!entity) {
					entity = await ImportWoServiceTypes.getSingleEntityFromServer(this.import_wo_service_types_util_process_logs__import_wo_service_type_id);
				}
				return entity;
			},

			getWoServiceType: async function() {
				const WoServiceTypes = this.getAssociatedRepository('WoServiceTypes');
				let entity = WoServiceTypes.getById(this.import_wo_service_types_util_process_logs__wo_service_type_id);
				if (!entity) {
					entity = await WoServiceTypes.getSingleEntityFromServer(this.import_wo_service_types_util_process_logs__wo_service_type_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default ImportWoServiceTypesUtilProcessLogs;
