/*
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import { useState, useEffect, } from 'react';
import { Linking } from 'react-native';
import {
	Column,
	Row,
} from 'native-base';
import Url from 'url-parse';
import {
	useLinkTo,
} from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { useSelector, useDispatch } from 'react-redux';
import {
	VERTICAL,
} from '@onehat/ui/src/Constants/Directions.js';
import {
	navigateTo,
	setCurrentScreen,
	selectIsEquipmentLitesTreeShown,
	selectIsSetupMode,
	selectCurrentNormalTabIx,
	selectCurrentSetupTabIx,
	selectUser,
	setCurrentNormalTabIx,
	setCurrentSetupTabIx,
	setIsSetupMode,
} from '../models/Slices/AppSlice';
import {
	setAlertMessage,
	setDebugMessage,
	setInfoMessage,
	selectDebugMessage,
	selectAlertMessage,
	selectInfoMessage,
	selectIsWaitModalShown,
	selectWaitMessage,
} from '../models/Slices/DebugSlice';
import {
	visibleNormalTabs,
	visibleSetupTabs,
} from './Tabs';
import Inflector from 'inflector-js';
import AppGlobals from '../AppGlobals';
import _ from 'lodash';

// components
import Container from '@onehat/ui/src/Components/Container/Container';
import Loading from '@onehat/ui/src/Components/Messages/Loading';
import ErrorMessage from '@onehat/ui/src/Components/Messages/ErrorMessage';
import EquipmentLitesTree from '../components/Tree/EquipmentLitesTree.js';
import TabBar from '@onehat/ui/src/Components/Tab/TabBar.js';
import TopBar from '../components/Layout/TopBar';
import WaitMessage from '@onehat/ui/src/Components/Messages/WaitMessage';

// screens
import AppPortal from '../screens/AppPortal';
import Dashboard from '../screens/Dashboard';
import EnterprisesManager from '../screens/EnterprisesManager';
import FleetsManager from '../screens/FleetsManager';
import EquipmentManager from '../screens/EquipmentManager';
import Logs from '../screens/Logs';
import Logout from '../screens/Logout';
import PmEventsManager from '../screens/PmEventsManager';
// import ReportsManager from '../screens/ReportsManager';
import UsersManager from '../screens/UsersManager';
import GroupsManager from '../screens/GroupsManager';
import WorkOrdersManager from '../screens/WorkOrdersManager';
import Util from '../screens/Util';

const
	Stack = createStackNavigator(),
	urlPrefix = '/' + AppGlobals.urlPrefix;

export default function AppNavigator() {
	const
		linkTo = useLinkTo(),
		dispatch = useDispatch(),
		isWaitModalShown = useSelector(selectIsWaitModalShown),
		debugMessage = useSelector(selectDebugMessage),
		alertMessage = useSelector(selectAlertMessage),
		infoMessage = useSelector(selectInfoMessage),
		waitMessage = useSelector(selectWaitMessage),
		isSetupMode = useSelector(selectIsSetupMode),
		isEquipmentLitesTreeShown = useSelector(selectIsEquipmentLitesTreeShown),
		user = useSelector(selectUser),
		currentNormalTabIx = useSelector(selectCurrentNormalTabIx),
		currentSetupTabIx = useSelector(selectCurrentSetupTabIx),
		[isReady, setIsReady] = useState(false),
		[initialRouteName, setInitialRouteName] = useState('Dashboard'),
		tabs = isSetupMode ? visibleSetupTabs : visibleNormalTabs,
		currentTabIx = isSetupMode ? currentSetupTabIx : currentNormalTabIx;

	useEffect(() => {
		(async () => {
			const
				initialUrl = await Linking.getInitialURL(),
				url = new Url(initialUrl),
				path = url.pathname;

			if (!user) {
				linkTo(urlPrefix + Inflector.dasherize(Inflector.underscore('Login')));
				return;
			}

			// When we go directly to a url (e.g. '/utilities'),
			// we need to find the tab that matches that path
			// and set the current tab to it, as well as isSetupMode.
			// We don't need to navigate, because we're already there.
			const
				whichNormalTab = _.find(visibleNormalTabs, (tab) => {
					return tab.path === path;
				}),
				whichNormalTabIx = whichNormalTab ? visibleNormalTabs.indexOf(whichNormalTab) : 0,
				whichSetupTab = _.find(visibleSetupTabs, (tab) => {
					return tab.path === path;
				}),
				whichSetupTabIx = whichSetupTab ? visibleSetupTabs.indexOf(whichSetupTab) : 0,
				tab = whichNormalTab || whichSetupTab,
				isSetupMode = !_.isNil(whichSetupTab);
			dispatch(setCurrentNormalTabIx(whichNormalTabIx));
			dispatch(setCurrentSetupTabIx(whichSetupTabIx));
			if (whichNormalTab) {
				setInitialRouteName(whichNormalTab.screenName);
				dispatch(setCurrentScreen(whichNormalTab.screenName));
			} else if (whichSetupTab) {
				setInitialRouteName(whichSetupTab.screenName);
				dispatch(setCurrentScreen(whichSetupTab.screenName));
			}
			dispatch(setIsSetupMode(isSetupMode));
			await dispatch(navigateTo({ tab }));

			
			setIsReady(true);
		})();
	}, []);

	if (!isReady) {
		return <Loading isScreen={true} />;
	}

	const navigatorOptions = {
		initialRouteName,
		screenOptions: ({ route }) => {
			return {
				headerShown: false,
				title: 'MhPms Admin App: ' + route.name.replace('Manager', ''),
			}
		},
	};
	
	return <Column flex={1} w="100%">
				{isWaitModalShown && <WaitMessage text={waitMessage} />}
				{!isWaitModalShown && alertMessage && <ErrorMessage text={alertMessage} onOk={() => dispatch(setAlertMessage(null))} />}
				{!isWaitModalShown && debugMessage && <ErrorMessage text={debugMessage} color="green" onOk={() => dispatch(setDebugMessage(null))} />}
				{!isWaitModalShown && infoMessage && <ErrorMessage text={infoMessage} color="#000" onOk={() => dispatch(setInfoMessage(null))} />}

				<TopBar />

				<Row w="100%" flex={1}>
					<TabBar
						direction={VERTICAL}
						tabWidth={180}
						tabs={tabs}
						currentTabIx={currentTabIx}
						onChangeCurrentTab={(tabIx) => dispatch(navigateTo({ tabIx }))}
					/>

					<Container
						reference="MainContainer"
						west={isEquipmentLitesTreeShown &&
								<EquipmentLitesTree
									title="Equipment Tree"
									isResizable={true}
									w={300}
								/>}
						center={<Stack.Navigator {...navigatorOptions}>
									<Stack.Screen name="AppPortal" component={AppPortal} />
									<Stack.Screen name="Dashboard" component={Dashboard} />
									<Stack.Screen name="EnterprisesManager" component={EnterprisesManager} />
									<Stack.Screen name="EquipmentManager" component={EquipmentManager} />
									<Stack.Screen name="FleetsManager" component={FleetsManager} />
									<Stack.Screen name="GroupsManager" component={GroupsManager} />
									<Stack.Screen name="Logs" component={Logs} />
									<Stack.Screen name="Logout" component={Logout} />
									<Stack.Screen name="PmEventsManager" component={PmEventsManager} />
									<Stack.Screen name="UsersManager" component={UsersManager} />
									<Stack.Screen name="Util" component={Util} />
									<Stack.Screen name="WorkOrdersManager" component={WorkOrdersManager} />
								</Stack.Navigator>}
					/>
				</Row>
			</Column>;
}
