export default [
	    {
	        "id": "manufacturers__name",
	        "header": "Name",
	        "fieldName": "manufacturers__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "manufacturers__abbr",
	        "header": "Abbr",
	        "fieldName": "manufacturers__abbr",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "manufacturers__fa1_eq_abbr",
	        "header": "Fa1 Eq Abbr",
	        "fieldName": "manufacturers__fa1_eq_abbr",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "manufacturers__fa1_part_abbr",
	        "header": "Fa1 Part Abbr",
	        "fieldName": "manufacturers__fa1_part_abbr",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "manufacturers__is_telemetry",
	        "header": "Telemetry?",
	        "fieldName": "manufacturers__is_telemetry",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];