/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportWoOtherChargesSideGridEditorPanel from './ImportWoOtherChargesSideGridEditor.js';


export default function ImportWoOtherChargesFilteredSideGridEditorPanel(props) {
	return <ImportWoOtherChargesSideGridEditorPanel
				reference="ImportWoOtherChargesFilteredSideGridEditorPanel"
				useFilters={true}
				
				{...props}
			/>;
}