/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportWoOtherChargesEditor from '../Editor/ImportWoOtherChargesEditor.js';
import ImportWoOtherChargesGridColumns from './Columns/ImportWoOtherChargesGridColumns.js';

export default function ImportWoOtherChargesSideGridEditor(props) {
	return <SideGridEditor
				reference="ImportWoOtherChargesSideGridEditor"
				model="ImportWoOtherCharges"
				usePermissions={true}
				isCollapsible={false}
				Editor={ImportWoOtherChargesEditor}
				columnsConfig={ImportWoOtherChargesGridColumns}
				
				
				{...props}
			/>;
}