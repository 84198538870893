import {
	Box,
	Column,
	Row,
	Text,
} from 'native-base';
import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import EnterpriseSpecific from '../components/Layout/EnterpriseSpecific.js';
import TreeSpecific from '../components/Layout/TreeSpecific';

function Panel(props) {
	return <Box
				{...props}
				borderWidth={1}
				borderColor="#ccc"
				justifyContent="center"
				alignItems="center"
			>
				<Text>Panel</Text>
			</Box>;
}

export default function Dashboard(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		isActive = currentScreen === 'Dashboard';

	if (!isActive) {
		return null;
	}

	return <ManagerScreen
				title="Dashboard"
				reference="Dashboard"
				fullModeComponent={<EnterpriseSpecific>
										<TreeSpecific>
											<Column w="100%" h="100%" justifyContent="center" alignItems="center">
												<Row w="100%" flex={1}>
													<Panel flex={1} />
													<Panel flex={1} />
													<Panel flex={1} />
												</Row>
												<Row w="100%" flex={1}>
													<Panel flex={1} />
													<Panel flex={1} />
													<Panel flex={1} />
												</Row>
											</Column>
										</TreeSpecific>
									</EnterpriseSpecific>}
			/>;
}
