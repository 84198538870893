/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportWoOtherChargeTypesEditor from '../Editor/ImportWoOtherChargeTypesEditor.js';
import ImportWoOtherChargeTypesGridColumns from './Columns/ImportWoOtherChargeTypesGridColumns.js';

export default function ImportWoOtherChargeTypesSideGridEditor(props) {
	return <SideGridEditor
				reference="ImportWoOtherChargeTypesSideGridEditor"
				model="ImportWoOtherChargeTypes"
				usePermissions={true}
				isCollapsible={false}
				Editor={ImportWoOtherChargeTypesEditor}
				columnsConfig={ImportWoOtherChargeTypesGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}