import {
	IMPORT_STATUSES__IMPORTED,
	IMPORT_STATUSES__UPDATED,
	IMPORT_STATUSES__ALREADY_EXISTS,
	IMPORT_STATUSES__SKIPPED,
	IMPORT_STATUSES__ERROR,
} from '../../../../constants/ImportStatuses.js';
import ArrayCombo from '@onehat/ui/src/Components/Form/Field/Combo/ArrayCombo.js';

const data = [
	[ IMPORT_STATUSES__IMPORTED, 'Imported', ],
	[ IMPORT_STATUSES__UPDATED, 'Updated', ],
	[ IMPORT_STATUSES__ALREADY_EXISTS, 'Already Exists', ],
	[ IMPORT_STATUSES__SKIPPED, 'Skipped', ],
	[ IMPORT_STATUSES__ERROR, 'Error', ],
];

export default function ImportStatusesCombo(props) {
	return <ArrayCombo
				data={data}
				disableDirectEntry={true}
				{...props}
			/>;
}
