/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { TagEditor } from '@onehat/ui/src/Components/Form/Field/Tag/Tag.js';
import ImportWoOtherChargeTypesUtilProcessLogsEditorWindow from '../../../Window/ImportWoOtherChargeTypesUtilProcessLogsEditorWindow.js';

function ImportWoOtherChargeTypesUtilProcessLogsTagEditor(props) {
	return <TagEditor
				reference="ImportWoOtherChargeTypesUtilProcessLogsTagEditor"
				model="ImportWoOtherChargeTypesUtilProcessLogs"
				uniqueRepository={true}
				Editor={ImportWoOtherChargeTypesUtilProcessLogsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default ImportWoOtherChargeTypesUtilProcessLogsTagEditor;