/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__WINDOWED,
} from '@onehat/ui/src/Constants/Editor.js';
import ImportWoOtherChargeTypesEditorWindow from '../../Window/ImportWoOtherChargeTypesEditorWindow.js';
import ImportWoOtherChargeTypesGridColumns from '../../Grid/Columns/ImportWoOtherChargeTypesGridColumns.js';

export default function ImportWoOtherChargeTypesGridEditorPanel(props) {
	return <GridPanel
				reference="ImportWoOtherChargeTypesGridEditorPanel"
				model="ImportWoOtherChargeTypes"
				usePermissions={true}
				isEditor={true}
				editorType={EDITOR_TYPE__WINDOWED}
				Editor={ImportWoOtherChargeTypesEditorWindow}
				columnsConfig={ImportWoOtherChargeTypesGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}