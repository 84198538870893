
import {
	Icon,
} from 'native-base';
import { useSelector, useDispatch } from 'react-redux';
import {
	selectIsSetupMode,
	toggleSetupModeThunk,
} from '../../../models/Slices/AppSlice';
import Button from '@onehat/ui/src/Components/Buttons/Button';
import IconButton from '@onehat/ui/src/Components/Buttons/IconButton';
import Gear from '@onehat/ui/src/Components/Icons/Gear';

export default function SetupButton(props) {
	const {
			isMinimized = false,
		} = props,
		dispatch = useDispatch(),
		isSetupMode = useSelector(selectIsSetupMode),
		onPress = () => dispatch(toggleSetupModeThunk());
	return isMinimized ? 
			<IconButton
				icon={Gear}
				onPress={onPress}
				tooltip="Toggle Setup Mode"
			/> : 
			<Button
				text={isSetupMode ? 'Exit Setup' : 'Setup'}
				leftIcon={<Icon as={Gear} color="#fff" />}
				m={1}
				onPress={onPress}
				tooltip="Toggle Setup Mode"
			/>;
};