import * as yup from 'yup'; // https://github.com/jquense/yup#string

const ImportWoParts = {
	
	name: 'ImportWoParts',
	
	model: {

		idProperty: 'import_wo_parts__id',
		displayProperty: 'import_wo_parts__id',
		sortProperty: 'import_wo_parts__id',
		
		sorters: null,

		validator: yup.object({
			import_wo_parts__woid: yup.string().max(50).required(),
			import_wo_parts__line_item: yup.number().integer().nullable(),
			import_wo_parts__manufacturer: yup.string().max(25).nullable(),
			import_wo_parts__part_number: yup.string().nullable(),
			import_wo_parts__description: yup.string().nullable(),
			import_wo_parts__qty_ordered: yup.number().nullable(),
			import_wo_parts__qty: yup.number().nullable(),
			import_wo_parts__qty_shipped: yup.number().nullable(),
			import_wo_parts__unit_cost: yup.number().nullable(),
			import_wo_parts__unit_price: yup.number().nullable(),
			import_wo_parts__ext_cost: yup.number().nullable(),
			import_wo_parts__ext_price: yup.number().nullable(),
			import_wo_parts__is_imported: yup.boolean().nullable(),
			import_wo_parts__imported_date: yup.date().nullable()
		}),
		
		properties: [
			{ name: 'import_wo_parts__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'import_wo_parts__woid', mapping: 'woid', title: 'WOID', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_wo_parts__line_item', mapping: 'line_item', title: 'Line Item', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_wo_parts__manufacturer', mapping: 'manufacturer', title: 'Manufacturer', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_wo_parts__part_number', mapping: 'part_number', title: 'Part Number', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'import_wo_parts__description', mapping: 'description', title: 'Description', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'import_wo_parts__qty_ordered', mapping: 'qty_ordered', title: 'Qty Ordered', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_wo_parts__qty', mapping: 'qty', title: 'Qty', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_wo_parts__qty_shipped', mapping: 'qty_shipped', title: 'Qty Shipped', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_wo_parts__unit_cost', mapping: 'unit_cost', title: 'Unit Cost', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_wo_parts__unit_price', mapping: 'unit_price', title: 'Unit Price', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_wo_parts__ext_cost', mapping: 'ext_cost', title: 'Ext Cost', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_wo_parts__ext_price', mapping: 'ext_price', title: 'Ext Price', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'import_wo_parts__is_imported', mapping: 'is_imported', title: 'Imported?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'import_wo_parts__imported_date', mapping: 'imported_date', title: 'Imported Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', }
		],

		associations: {

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'import_wo_parts__line_item',
			// 'import_wo_parts__qty_ordered',
			// 'import_wo_parts__qty',
			// 'import_wo_parts__qty_shipped',
			// 'import_wo_parts__unit_cost',
			// 'import_wo_parts__unit_price',
			// 'import_wo_parts__ext_cost',
			// 'import_wo_parts__ext_price',
			// 'import_wo_parts__is_imported',
			// 'import_wo_parts__imported_date'
		],

	},



	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default ImportWoParts;
