import * as yup from 'yup'; // https://github.com/jquense/yup#string

const MeterReadings = {
	
	name: 'MeterReadings',
	
	model: {

		idProperty: 'meter_readings__id',
		displayProperty: 'meter_readings__id',
		sortProperty: 'meter_readings__id',
		
		sorters: null,

		validator: yup.object({
			meter_readings__equipment_id: yup.number().integer().required(),
			meter_readings__date: yup.date().required(),
			meter_readings__value: yup.number().integer().required(),
			meter_readings__delta: yup.number().integer().nullable(),
			meter_readings__is_replacement: yup.boolean().required(),
			meter_readings__is_bad: yup.boolean().nullable(),
			meter_readings__is_pm_clock_reset: yup.boolean().required(),
			meter_readings__comment: yup.string().nullable(),
			meter_readings__meter_source_id: yup.number().integer().required(),
			meter_readings__work_order_id: yup.number().integer().nullable(),
			meter_readings__pm_event_id: yup.number().integer().nullable(),
			meter_readings__tel_usage_id: yup.number().integer().nullable()
		}),
		
		properties: [
			{ name: 'meter_readings__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'meter_readings__equipment_id', mapping: 'equipment_id', title: 'Equipment', type: 'int', isFk: true, fkIdField: 'equipment__id', fkDisplayField: 'equipment__unit_number', filterType: {"type":"EquipmentCombo","loadAfterRender":!1}, editorType: {"type":"EquipmentComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'meter_readings__date', mapping: 'date', title: 'Date', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', },
			{ name: 'meter_readings__value', mapping: 'value', title: 'Value', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'meter_readings__delta', mapping: 'delta', title: 'Delta', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'meter_readings__is_replacement', mapping: 'is_replacement', title: 'Replacement?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'meter_readings__is_bad', mapping: 'is_bad', title: 'Bad?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'meter_readings__is_pm_clock_reset', mapping: 'is_pm_clock_reset', title: 'Pm Clock Reset?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'meter_readings__comment', mapping: 'comment', title: 'Comment', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'meter_readings__meter_source_id', mapping: 'meter_source_id', title: 'Meter Source', type: 'int', isFk: true, fkIdField: 'meter_sources__id', fkDisplayField: 'meter_sources__source', filterType: {"type":"MeterSourcesCombo","loadAfterRender":!1}, editorType: {"type":"MeterSourcesComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'meter_readings__work_order_id', mapping: 'work_order_id', title: 'Work Order', type: 'int', isFk: true, fkIdField: 'work_orders__id', fkDisplayField: 'work_orders__service_order', filterType: {"type":"WorkOrdersCombo","loadAfterRender":!1}, editorType: {"type":"WorkOrdersComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'meter_readings__pm_event_id', mapping: 'pm_event_id', title: 'PM Event', type: 'int', isFk: true, fkIdField: 'pm_events__id', fkDisplayField: 'pm_events__id', filterType: {"type":"PmEventsCombo","loadAfterRender":!1}, editorType: {"type":"PmEventsComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'meter_readings__tel_usage_id', mapping: 'tel_usage_id', title: 'Tel Usage', type: 'int', isFk: true, fkIdField: 'tel_usages__id', fkDisplayField: 'tel_usages__id', filterType: {"type":"TelUsagesCombo","loadAfterRender":!1}, editorType: {"type":"TelUsagesCombo","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'equipment__id', mapping: 'equipment.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__enterprise_id', mapping: 'equipment.enterprise_id', title: 'Enterprise', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__fleet_id', mapping: 'equipment.fleet_id', title: 'Fleet', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_status_id', mapping: 'equipment.eq_status_id', title: 'Status', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__inactive_date', mapping: 'equipment.inactive_date', title: 'Inactive Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__year', mapping: 'equipment.year', title: 'Year', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__manufacturer_id', mapping: 'equipment.manufacturer_id', title: 'Make', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__model', mapping: 'equipment.model', title: 'Model', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__serial_number', mapping: 'equipment.serial_number', title: 'Serial #', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__unit_number', mapping: 'equipment.unit_number', title: 'Unit #', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__asset_number', mapping: 'equipment.asset_number', title: 'Asset # (Cust ID)', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__dept_number', mapping: 'equipment.dept_number', title: 'Dept #', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__business_partner_id', mapping: 'equipment.business_partner_id', title: 'Business Partner', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_type_id', mapping: 'equipment.eq_type_id', title: 'Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__tel_manufacturer_id', mapping: 'equipment.tel_manufacturer_id', title: 'Tel Manufacturer', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__tel_pid', mapping: 'equipment.tel_pid', title: 'Tel Pid', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__comments', mapping: 'equipment.comments', title: 'Comments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_misc', mapping: 'equipment.is_misc', title: 'Misc?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_pm_enable', mapping: 'equipment.is_pm_enable', title: 'Pm Enable?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__pm_schedules', mapping: 'equipment.pm_schedules', title: 'PM Schedules', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__expected_meter_per_month', mapping: 'equipment.expected_meter_per_month', title: 'Expected Meter Per Month', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__available_meter_per_week', mapping: 'equipment.available_meter_per_week', title: 'Available Meter Per Week', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__in_service_date', mapping: 'equipment.in_service_date', title: 'In Service', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__in_service_meter', mapping: 'equipment.in_service_meter', title: 'In Service Meter', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__initial_maint_expense', mapping: 'equipment.initial_maint_expense', title: 'Initial Maint $', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__initial_book_value', mapping: 'equipment.initial_book_value', title: 'Book Value $', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__purchase_price', mapping: 'equipment.purchase_price', title: 'Purchase Price', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_track_meter_enable', mapping: 'equipment.is_track_meter_enable', title: 'Track Meter Enable?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__max_meter_allowed', mapping: 'equipment.max_meter_allowed', title: 'Max Meter Allowed', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__expected_revenue_per_month', mapping: 'equipment.expected_revenue_per_month', title: 'Expected Revenue Per Month', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__warranty_expires', mapping: 'equipment.warranty_expires', title: 'Warranty Expires', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__warranty_meter', mapping: 'equipment.warranty_meter', title: 'Warranty Meter', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__capacity', mapping: 'equipment.capacity', title: 'Capacity', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_upright_type_id', mapping: 'equipment.eq_upright_type_id', title: 'Upright Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__upright_lhloh', mapping: 'equipment.upright_lhloh', title: 'LH / LoH', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_valve_type_id', mapping: 'equipment.eq_valve_type_id', title: 'Eq Valve Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_power_type_id', mapping: 'equipment.eq_power_type_id', title: 'Eq Power Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_tire_type_id', mapping: 'equipment.eq_tire_type_id', title: 'Eq Tire Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__battery_size', mapping: 'equipment.battery_size', title: 'Battery Size', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_voltage_id', mapping: 'equipment.eq_voltage_id', title: 'Eq Voltage', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_fork_length_id', mapping: 'equipment.eq_fork_length_id', title: 'Eq Fork Length', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__eq_attachments', mapping: 'equipment.eq_attachments', title: 'Attachments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_fluorescent_stripe', mapping: 'equipment.is_fluorescent_stripe', title: 'Fluorescent Striping?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_whelen_light', mapping: 'equipment.is_whelen_light', title: 'Whelen Lights?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_blue_led_light', mapping: 'equipment.is_blue_led_light', title: 'Blue LED Spot Light?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_high_vis_seatbelt', mapping: 'equipment.is_high_vis_seatbelt', title: 'Hi-Vis Seatbelt?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_rear_handle_horn', mapping: 'equipment.is_rear_handle_horn', title: 'Rear Handle w/Horn?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_contact_decals', mapping: 'equipment.is_contact_decals', title: '3-Point of Contact Decals?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_pallet_protection', mapping: 'equipment.is_pallet_protection', title: 'Pallet Protection?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'equipment__is_laser_light', mapping: 'equipment.is_laser_light', title: 'Laser Light?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'meter_sources__id', mapping: 'meter_source.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'meter_sources__source', mapping: 'meter_source.source', title: 'Source', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__id', mapping: 'work_order.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__enterprise_id', mapping: 'work_order.enterprise_id', title: 'Enterprise', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__equipment_id', mapping: 'work_order.equipment_id', title: 'Equipment', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__business_partner_id', mapping: 'work_order.business_partner_id', title: 'Business Partner', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__service_order', mapping: 'work_order.service_order', title: 'Service Order', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__segment', mapping: 'work_order.segment', title: 'Segment', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__invoice_number', mapping: 'work_order.invoice_number', title: 'Invoice Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__segment_type', mapping: 'work_order.segment_type', title: 'Segment Type', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__wo_number', mapping: 'work_order.wo_number', title: 'Work Order Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__po_number', mapping: 'work_order.po_number', title: 'PO Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__service_center_id', mapping: 'work_order.service_center_id', title: 'Service Center', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__open_date', mapping: 'work_order.open_date', title: 'Open Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__wo_service_type_id', mapping: 'work_order.wo_service_type_id', title: 'Wo Service Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__close_date', mapping: 'work_order.close_date', title: 'Close Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__invoice_date', mapping: 'work_order.invoice_date', title: 'Invoice Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__complete_date', mapping: 'work_order.complete_date', title: 'Complete Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__time_out_of_service', mapping: 'work_order.time_out_of_service', title: 'Time Out Of Service', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__wo_mode_id', mapping: 'work_order.wo_mode_id', title: 'Mode', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__wo_class_id', mapping: 'work_order.wo_class_id', title: 'Class', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__pm_schedule_id', mapping: 'work_order.pm_schedule_id', title: 'PM Schedule', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__complaint', mapping: 'work_order.complaint', title: 'Complaint', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__cause', mapping: 'work_order.cause', title: 'Cause', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__correction', mapping: 'work_order.correction', title: 'Correction', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__coverage', mapping: 'work_order.coverage', title: 'Coverage', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__comments', mapping: 'work_order.comments', title: 'Comments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__meter_reading', mapping: 'work_order.meter_reading', title: 'Meter Reading', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__original_destination', mapping: 'work_order.original_destination', title: 'Original Destination', type: 'json', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__total_parts', mapping: 'work_order.total_parts', title: 'Total Parts', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__total_labor', mapping: 'work_order.total_labor', title: 'Total Labor', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__total_other', mapping: 'work_order.total_other', title: 'Total Other', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__tax', mapping: 'work_order.tax', title: 'Tax', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__total', mapping: 'work_order.total', title: 'Total', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__is_ltm', mapping: 'work_order.is_ltm', title: 'Ltm?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__is_scheduled', mapping: 'work_order.is_scheduled', title: 'Scheduled?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__is_verified', mapping: 'work_order.is_verified', title: 'Verified?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__repaired_by_branch', mapping: 'work_order.repaired_by_branch', title: 'Repaired By Branch', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__repaired_by_company', mapping: 'work_order.repaired_by_company', title: 'Repaired By Company', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__sold_to_business_partner_id', mapping: 'work_order.sold_to_business_partner_id', title: 'Sold To Business Partner', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__sold_to_name', mapping: 'work_order.sold_to_name', title: 'Sold To Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__sold_to_address', mapping: 'work_order.sold_to_address', title: 'Sold To Address', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__sold_to_city', mapping: 'work_order.sold_to_city', title: 'Sold To City', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__sold_to_state_abbr', mapping: 'work_order.sold_to_state_abbr', title: 'Sold To State Abbr', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__sold_to_zip', mapping: 'work_order.sold_to_zip', title: 'Sold To Zip', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__bill_to_business_partner_id', mapping: 'work_order.bill_to_business_partner_id', title: 'Bill To Business Partner', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__bill_to_name', mapping: 'work_order.bill_to_name', title: 'Bill To Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_business_partner_id', mapping: 'work_order.ship_to_business_partner_id', title: 'Ship To Business Partner', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_name', mapping: 'work_order.ship_to_name', title: 'Ship To Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_address', mapping: 'work_order.ship_to_address', title: 'Ship To Address', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_city', mapping: 'work_order.ship_to_city', title: 'Ship To City', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_state_abbr', mapping: 'work_order.ship_to_state_abbr', title: 'Ship To State', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'work_orders__ship_to_zip', mapping: 'work_order.ship_to_zip', title: 'Ship To Zip', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__id', mapping: 'pm_event.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__date', mapping: 'pm_event.date', title: 'Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__pm_event_type_id', mapping: 'pm_event.pm_event_type_id', title: 'PM Event Type', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__equipment_id', mapping: 'pm_event.equipment_id', title: 'Equipment', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__pm_schedule_id', mapping: 'pm_event.pm_schedule_id', title: 'PM Schedule', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__interval', mapping: 'pm_event.interval', title: 'Interval', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__user_id', mapping: 'pm_event.user_id', title: 'Pm Technician', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__work_order_id', mapping: 'pm_event.work_order_id', title: 'Work Order', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__associated_date', mapping: 'pm_event.associated_date', title: 'Associated Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__meter_reading', mapping: 'pm_event.meter_reading', title: 'Meter Reading', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'pm_events__comments', mapping: 'pm_event.comments', title: 'Comments', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__id', mapping: 'tel_usage.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__equipment_id', mapping: 'tel_usage.equipment_id', title: 'Equipment', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__tel_operator_id', mapping: 'tel_usage.tel_operator_id', title: 'Tel Operator', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__start_date', mapping: 'tel_usage.start_date', title: 'Start Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__end_date', mapping: 'tel_usage.end_date', title: 'End Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__midpoint_date', mapping: 'tel_usage.midpoint_date', title: 'Midpoint Date', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_login_time', mapping: 'tel_usage.sess_login_time', title: 'Sess Login Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__session_identifier', mapping: 'tel_usage.session_identifier', title: 'Session Identifier', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__max_height', mapping: 'tel_usage.max_height', title: 'Max Height', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__max_weight', mapping: 'tel_usage.max_weight', title: 'Max Weight', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__max_speed', mapping: 'tel_usage.max_speed', title: 'Max Speed', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_start_fuel_level', mapping: 'tel_usage.sess_start_fuel_level', title: 'Sess Start Fuel Level', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_end_fuel_level', mapping: 'tel_usage.sess_end_fuel_level', title: 'Sess End Fuel Level', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_avg_load', mapping: 'tel_usage.sess_avg_load', title: 'Sess Avg Load', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_peak_load', mapping: 'tel_usage.sess_peak_load', title: 'Sess Peak Load', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_total_load', mapping: 'tel_usage.sess_total_load', title: 'Sess Total Load', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_weight_low', mapping: 'tel_usage.sess_weight_low', title: 'Sess Weight Low', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_weight_med', mapping: 'tel_usage.sess_weight_med', title: 'Sess Weight Med', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_weight_high', mapping: 'tel_usage.sess_weight_high', title: 'Sess Weight High', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_weight_low_time', mapping: 'tel_usage.sess_weight_low_time', title: 'Sess Weight Low Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_weight_med_time', mapping: 'tel_usage.sess_weight_med_time', title: 'Sess Weight Med Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_weight_high_time', mapping: 'tel_usage.sess_weight_high_time', title: 'Sess Weight High Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_lift_count', mapping: 'tel_usage.sess_lift_count', title: 'Sess Lift Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_height_low', mapping: 'tel_usage.sess_height_low', title: 'Sess Height Low', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_height_med', mapping: 'tel_usage.sess_height_med', title: 'Sess Height Med', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_height_high', mapping: 'tel_usage.sess_height_high', title: 'Sess Height High', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_height_low_time', mapping: 'tel_usage.sess_height_low_time', title: 'Sess Height Low Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_height_med_time', mapping: 'tel_usage.sess_height_med_time', title: 'Sess Height Med Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_height_high_time', mapping: 'tel_usage.sess_height_high_time', title: 'Sess Height High Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_avg_speed', mapping: 'tel_usage.sess_avg_speed', title: 'Sess Avg Speed', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_peak_speed', mapping: 'tel_usage.sess_peak_speed', title: 'Sess Peak Speed', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_speed_low', mapping: 'tel_usage.sess_speed_low', title: 'Sess Speed Low', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_speed_med', mapping: 'tel_usage.sess_speed_med', title: 'Sess Speed Med', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_speed_high', mapping: 'tel_usage.sess_speed_high', title: 'Sess Speed High', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_speed_low_time', mapping: 'tel_usage.sess_speed_low_time', title: 'Sess Speed Low Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_speed_med_time', mapping: 'tel_usage.sess_speed_med_time', title: 'Sess Speed Med Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_speed_high_time', mapping: 'tel_usage.sess_speed_high_time', title: 'Sess Speed High Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_speed_over_level1', mapping: 'tel_usage.sess_speed_over_level1', title: 'Sess Speed Over Level1', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_speed_over_level2', mapping: 'tel_usage.sess_speed_over_level2', title: 'Sess Speed Over Level2', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_danger', mapping: 'tel_usage.sess_danger', title: 'Sess Danger', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_danger_time', mapping: 'tel_usage.sess_danger_time', title: 'Sess Danger Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_key_time', mapping: 'tel_usage.sess_key_time', title: 'Sess Key Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_operator_time', mapping: 'tel_usage.sess_operator_time', title: 'Sess Operator Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_traction_time', mapping: 'tel_usage.sess_traction_time', title: 'Sess Traction Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_on_seat_idle_time', mapping: 'tel_usage.sess_on_seat_idle_time', title: 'Sess On Seat Idle Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_off_seat_idle_time', mapping: 'tel_usage.sess_off_seat_idle_time', title: 'Sess Off Seat Idle Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_laden_time', mapping: 'tel_usage.sess_laden_time', title: 'Sess Laden Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_laden_traction_time', mapping: 'tel_usage.sess_laden_traction_time', title: 'Sess Laden Traction Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_lift_time', mapping: 'tel_usage.sess_lift_time', title: 'Sess Lift Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_hydraulic_time', mapping: 'tel_usage.sess_hydraulic_time', title: 'Sess Hydraulic Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_forward_gear_time', mapping: 'tel_usage.sess_forward_gear_time', title: 'Sess Forward Gear Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_reverse_gear_time', mapping: 'tel_usage.sess_reverse_gear_time', title: 'Sess Reverse Gear Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_distance', mapping: 'tel_usage.sess_distance', title: 'Sess Distance', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__sess_distance_laden', mapping: 'tel_usage.sess_distance_laden', title: 'Sess Distance Laden', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__ltd_key_time', mapping: 'tel_usage.ltd_key_time', title: 'Ltd Key Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__ltd_operator_time', mapping: 'tel_usage.ltd_operator_time', title: 'Ltd Operator Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__ltd_traction_time', mapping: 'tel_usage.ltd_traction_time', title: 'Ltd Traction Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__ltd_on_seat_idle_time', mapping: 'tel_usage.ltd_on_seat_idle_time', title: 'Ltd On Seat Idle Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__ltd_off_seat_idle_time', mapping: 'tel_usage.ltd_off_seat_idle_time', title: 'Ltd Off Seat Idle Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__ltd_laden_time', mapping: 'tel_usage.ltd_laden_time', title: 'Ltd Laden Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__ltd_laden_moving_time', mapping: 'tel_usage.ltd_laden_moving_time', title: 'Ltd Laden Moving Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__ltd_lift_time', mapping: 'tel_usage.ltd_lift_time', title: 'Ltd Lift Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__ltd_hydraulic_time', mapping: 'tel_usage.ltd_hydraulic_time', title: 'Ltd Hydraulic Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__ltd_forward_gear_time', mapping: 'tel_usage.ltd_forward_gear_time', title: 'Ltd Forward Gear Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__ltd_reverse_gear_time', mapping: 'tel_usage.ltd_reverse_gear_time', title: 'Ltd Reverse Gear Time', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__ltd_distance', mapping: 'tel_usage.ltd_distance', title: 'Ltd Distance', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__ltd_distance_laden', mapping: 'tel_usage.ltd_distance_laden', title: 'Ltd Distance Laden', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__shift', mapping: 'tel_usage.shift', title: 'Shift', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'tel_usages__err_msg', mapping: 'tel_usage.err_msg', title: 'Err Msg', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Equipment',
				'MeterSources',
				'WorkOrders',
				'PmEvents',
				'TelUsages'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'meter_readings__equipment_id',
			// 'meter_readings__date',
			// 'meter_readings__value',
			// 'meter_readings__delta',
			// 'meter_readings__is_replacement',
			// 'meter_readings__is_bad',
			// 'meter_readings__is_pm_clock_reset',
			// 'meter_readings__meter_source_id',
			// 'meter_readings__work_order_id',
			// 'meter_readings__pm_event_id',
			// 'meter_readings__tel_usage_id'
		],

	},

	entity: {
		methods: {

			getEquipment: async function() {
				const Equipment = this.getAssociatedRepository('Equipment');
				let entity = Equipment.getById(this.meter_readings__equipment_id);
				if (!entity) {
					entity = await Equipment.getSingleEntityFromServer(this.meter_readings__equipment_id);
				}
				return entity;
			},

			getMeterSource: async function() {
				const MeterSources = this.getAssociatedRepository('MeterSources');
				let entity = MeterSources.getById(this.meter_readings__meter_source_id);
				if (!entity) {
					entity = await MeterSources.getSingleEntityFromServer(this.meter_readings__meter_source_id);
				}
				return entity;
			},

			getWorkOrder: async function() {
				const WorkOrders = this.getAssociatedRepository('WorkOrders');
				let entity = WorkOrders.getById(this.meter_readings__work_order_id);
				if (!entity) {
					entity = await WorkOrders.getSingleEntityFromServer(this.meter_readings__work_order_id);
				}
				return entity;
			},

			getPmEvent: async function() {
				const PmEvents = this.getAssociatedRepository('PmEvents');
				let entity = PmEvents.getById(this.meter_readings__pm_event_id);
				if (!entity) {
					entity = await PmEvents.getSingleEntityFromServer(this.meter_readings__pm_event_id);
				}
				return entity;
			},

			getTelUsage: async function() {
				const TelUsages = this.getAssociatedRepository('TelUsages');
				let entity = TelUsages.getById(this.meter_readings__tel_usage_id);
				if (!entity) {
					entity = await TelUsages.getSingleEntityFromServer(this.meter_readings__tel_usage_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default MeterReadings;
