/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import ImportManufacturersGridColumns from '../../Grid/Columns/ImportManufacturersGridColumns.js';

export default function ImportManufacturersGridPanel(props) {
	return <GridPanel
				reference="ImportManufacturersGridPanel"
				model="ImportManufacturers"
				usePermissions={true}
				columnsConfig={ImportManufacturersGridColumns}

				{...props}
			/>;
}