/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import ImportTechniciansGridColumns from '../../Grid/Columns/ImportTechniciansGridColumns.js';

export default function ImportTechniciansGridPanel(props) {
	return <GridPanel
				reference="ImportTechniciansGridPanel"
				model="ImportTechnicians"
				usePermissions={true}
				columnsConfig={ImportTechniciansGridColumns}

				{...props}
			/>;
}