/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportWoOtherChargeTypesUtilProcessLogsInlineGridEditorPanel from './ImportWoOtherChargeTypesUtilProcessLogsInlineGridEditor.js';

export default function ImportWoOtherChargeTypesUtilProcessLogsFilteredInlineGridEditorPanel(props) {
	return <ImportWoOtherChargeTypesUtilProcessLogsInlineGridEditorPanel
				reference="ImportWoOtherChargeTypesUtilProcessLogsFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}