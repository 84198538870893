/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportWoPartsEditorWindow from '../Window/ImportWoPartsEditorWindow.js';
import ImportWoPartsGridColumns from './Columns/ImportWoPartsGridColumns.js';

export default function ImportWoPartsGridEditor(props) {
	return <WindowedGridEditor
				reference="ImportWoPartsGridEditor"
				model="ImportWoParts"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={ImportWoPartsEditorWindow}
				columnsConfig={ImportWoPartsGridColumns}
				
				
				{...props}
			/>;
}