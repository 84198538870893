/*
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import { useState, } from 'react';
import {
	Row,
} from 'native-base';
import { useSelector, } from 'react-redux';
import {
	selectIsSetupMode,
	selectIsEnterpriseSelectorShown,
} from '../../models/Slices/AppSlice';
import AppLogo from './TopBar/AppLogo';
import EnterpriseSelector from './TopBar/EnterpriseSelector';
import Glossary from './TopBar/Glossary';
import PerspectiveToggle from './TopBar/PerspectiveToggle';
import SetupButton from './TopBar/SetupButton';
import UserIndicator from './TopBar/UserIndicator';

export default function TopBar() {
	const
		isEnterpriseSelectorShown = useSelector(selectIsEnterpriseSelectorShown),
		isSetupMode = useSelector(selectIsSetupMode),
		[isRendered, setIsRendered] = useState(false),
		[w, setW] = useState(0),
		onLayout = (e) => {
			setW(e.nativeEvent.layout.width);
			setIsRendered(true);
		},
		isMinimized = w < 800;
		
	return <Row
				w="100%"
				h="40px"
				bg={isSetupMode ? '#c2d1e5' : '#fff'}
				borderBottomWidth={1}
				onLayout={onLayout}
			>
				{isRendered && <>
					<Row flex={2} alignItems="center" pl={isMinimized ? 3 : 5}>
						<AppLogo />
						{isEnterpriseSelectorShown && <EnterpriseSelector isMinimized={isMinimized} />}
						<PerspectiveToggle isMinimized={isMinimized} />
					</Row>
					<Row
						flex={isMinimized ? null : 1}
						width={isMinimized ? 100 : null}
						justifyContent="flex-end"
						pr={5}
					>
						<Glossary isMinimized={isMinimized} />
						<SetupButton isMinimized={isMinimized} />
						<UserIndicator isMinimized={isMinimized} />
					</Row>
				</>}
			</Row>;
}