/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import ImportManufacturersUtilProcessLogsEditorWindow from '../../../Window/ImportManufacturersUtilProcessLogsEditorWindow.js';

function ImportManufacturersUtilProcessLogsComboEditor(props) {
	return <ComboEditor
				reference="ImportManufacturersUtilProcessLogsComboEditor"
				model="ImportManufacturersUtilProcessLogs"
				uniqueRepository={true}
				Editor={ImportManufacturersUtilProcessLogsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default ImportManufacturersUtilProcessLogsComboEditor;