/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportPmEquipmentEditorWindow from '../Window/ImportPmEquipmentEditorWindow.js';
import ImportPmEquipmentGridColumns from './Columns/ImportPmEquipmentGridColumns.js';

export default function ImportPmEquipmentGridEditor(props) {
	return <WindowedGridEditor
				reference="ImportPmEquipmentGridEditor"
				model="ImportPmEquipment"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={ImportPmEquipmentEditorWindow}
				columnsConfig={ImportPmEquipmentGridColumns}
				
				
				{...props}
			/>;
}