/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import ImportWoPartsEditor from '../../Editor/ImportWoPartsEditor.js';
import ImportWoPartsGridColumns from '../../Grid/Columns/ImportWoPartsGridColumns.js';

export default function ImportWoPartsSideGridEditorPanel(props) {
	return <GridPanel
				reference="ImportWoPartsSideGridEditorPanel"
				model="ImportWoParts"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={ImportWoPartsEditor}
				columnsConfig={ImportWoPartsGridColumns}
				
				
				{...props}
			/>;
}