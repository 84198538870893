import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
	selectEnterpriseId,
	selectEnterpriseTypeId,
	selectTreeSelection,
} from '../models/Slices/AppSlice';
import {
	ENTERPRISE_TYPES__BRANCH_PM,
} from '../constants/EnterpriseTypes';
import {
	REPORT_TYPES__EXCEL,
} from '@onehat/ui/src/Constants/ReportTypes.js';
import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import FleetsFilteredTreeEditor from '../components/Tree/FleetsFilteredTreeEditor.js';
import FleetsFilteredSideTreeEditor from '../components/Tree/FleetsFilteredSideTreeEditor.js';
import EnterpriseSpecific from '../components/Layout/EnterpriseSpecific.js';
import getReport from '@onehat/ui/src/Functions/getReport.js';
import Fleets from '../components/Icons/Fleets.js';
import _ from 'lodash';

export default function FleetsManager(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		enterpriseId = useSelector(selectEnterpriseId),
		enterpriseTypeId = useSelector(selectEnterpriseTypeId),
		treeSelection = useSelector(selectTreeSelection),
		isActive = currentScreen === 'FleetsManager';

	if (!isActive) {
		return null;
	}

	const
		useUploadDownload = enterpriseTypeId !== ENTERPRISE_TYPES__BRANCH_PM,
		baseParams = {};
	if (treeSelection) {
		if (treeSelection.length === 1) {
			baseParams['conditions[fleet_id]'] = treeSelection[0].fleets__fleet_id;
		} else {
			baseParams['conditions[0]'] = '1=2'; // >1 Fleet selected, hide all
		}
	}

	const
		buttons = [
			{
				key: 'listFleetsBtn',
				text: 'List Fleets by Enterprise',
				icon: Fleets,
				handler: () => getReport({
					reportId: 85,
					data: {
						enterpriseId,
					},
				}),
			},
		];

	return <ManagerScreen
				title="Fleets"
				reference="FleetsManager"
				fullModeComponent={<EnterpriseSpecific>
										<FleetsFilteredTreeEditor
											reference="FleetsTreeEditor"
											// useRemoteDuplicate={true}
											useUploadDownload={useUploadDownload}
											baseParams={baseParams}
											uploadParams={baseParams}
											downloadParams={baseParams}
											additionalToolbarButtons={buttons}
										/>
									</EnterpriseSpecific>}
				sideModeComponent={<EnterpriseSpecific>
										<FleetsFilteredSideTreeEditor
											reference="FleetsTreeEditor"
											// useRemoteDuplicate={true}
											useUploadDownload={useUploadDownload}
											baseParams={baseParams}
											uploadParams={baseParams}
											downloadParams={baseParams}
											additionalToolbarButtons={buttons}
										/>
									</EnterpriseSpecific>}
			/>;
}
