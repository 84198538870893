/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import WoServiceTypesInlineGridEditorPanel from './WoServiceTypesInlineGridEditor.js';

export default function WoServiceTypesFilteredInlineGridEditorPanel(props) {
	return <WoServiceTypesInlineGridEditorPanel
				reference="WoServiceTypesFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}