/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import EquipmentEditorWindow from '../Window/EquipmentEditorWindow.js';
import EquipmentGridColumns from './Columns/EquipmentGridColumns.js';
import { useSelector } from 'react-redux';
import {
	selectEnterpriseId,
	selectTreeSelection,
} from '../../models/Slices/AppSlice';
import _ from 'lodash';

export default function EquipmentGridEditor(props) {
	const
		enterpriseId = useSelector(selectEnterpriseId),
		treeSelection = useSelector(selectTreeSelection),
		fleetId = treeSelection[0].id;

	return <WindowedGridEditor
				reference="EquipmentGridEditor"
				model="Equipment"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={EquipmentEditorWindow}
				columnsConfig={EquipmentGridColumns}

				selectorId="equipment__fleet_id"
				selectorSelected={treeSelection[0]}
				defaultValues={{ 
					equipment__enterprise_id: enterpriseId,
					equipment__fleet_id: fleetId,
				}}
				
				
				{...props}
			/>;
}