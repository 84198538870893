export default [
	    {
	        "id": "fleet_stats__is_stale",
	        "header": "Stale?",
	        "fieldName": "fleet_stats__is_stale",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__path",
	        "header": "Path",
	        "fieldName": "fleet_stats__path",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "fleet_stats__total_active_equipment",
	        "header": "Total Active Equipment",
	        "fieldName": "fleet_stats__total_active_equipment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__total_inactive_equipment",
	        "header": "Total Inactive Equipment",
	        "fieldName": "fleet_stats__total_inactive_equipment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__latest_repair_date",
	        "header": "Latest Repair Date",
	        "fieldName": "fleet_stats__latest_repair_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__due_pms",
	        "header": "Due Pms",
	        "fieldName": "fleet_stats__due_pms",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__overdue_pms",
	        "header": "Overdue Pms",
	        "fieldName": "fleet_stats__overdue_pms",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__overdue_pm_equipment",
	        "header": "Overdue Pm Equipment",
	        "fieldName": "fleet_stats__overdue_pm_equipment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__pm_equipment_hy",
	        "header": "PM Equipment Hy",
	        "fieldName": "fleet_stats__pm_equipment_hy",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__pm_equipment_ya",
	        "header": "PM Equipment Ya",
	        "fieldName": "fleet_stats__pm_equipment_ya",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__pm_equipment_other",
	        "header": "PM Equipment Other",
	        "fieldName": "fleet_stats__pm_equipment_other",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__latest_pm_date",
	        "header": "Latest Pm Date",
	        "fieldName": "fleet_stats__latest_pm_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__total_pm_equipment",
	        "header": "Total Pm Equipment",
	        "fieldName": "fleet_stats__total_pm_equipment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "fleet_stats__total_pm_will_call_equipment",
	        "header": "Total Pm Will Call Equipment",
	        "fieldName": "fleet_stats__total_pm_will_call_equipment",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];