/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import ImportServiceCentersEditor from '../../Editor/ImportServiceCentersEditor.js';
import ImportServiceCentersGridColumns from '../../Grid/Columns/ImportServiceCentersGridColumns.js';

export default function ImportServiceCentersSideGridEditorPanel(props) {
	return <GridPanel
				reference="ImportServiceCentersSideGridEditorPanel"
				model="ImportServiceCenters"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={ImportServiceCentersEditor}
				columnsConfig={ImportServiceCentersGridColumns}
				
				
				{...props}
			/>;
}