/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { ComboEditor } from '@onehat/ui/src/Components/Form/Field/Combo/Combo.js';
import ImportWoLaborsEditorWindow from '../../../Window/ImportWoLaborsEditorWindow.js';

function ImportWoLaborsComboEditor(props) {
	return <ComboEditor
				reference="ImportWoLaborsComboEditor"
				model="ImportWoLabors"
				uniqueRepository={true}
				Editor={ImportWoLaborsEditorWindow}
				usePermissions={true}
				{...props}
			/>;
}

export default ImportWoLaborsComboEditor;