/*
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
	selectEnterpriseId,
	selectEnterpriseTypeId,
	selectTreeSelection,
} from '../models/Slices/AppSlice';
import {
	ENTERPRISE_TYPES__BRANCH_PM,
} from '../constants/EnterpriseTypes';
import Container from '@onehat/ui/src/Components/Container/Container.js';
import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import EquipmentFilteredGridEditor from '../components/Grid/EquipmentFilteredGridEditor.js';
import EquipmentFilteredSideGridEditor from '../components/Grid/EquipmentFilteredSideGridEditor.js';
import FleetLitesTree from '../components/Tree/FleetLitesTree.js';
import EnterpriseSpecific from '../components/Layout/EnterpriseSpecific.js';
import TreeSpecific from '../components/Layout/TreeSpecific';
import _ from 'lodash';

export default function EquipmentManager(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		enterpriseId = useSelector(selectEnterpriseId),
		enterpriseTypeId = useSelector(selectEnterpriseTypeId),
		treeSelection = useSelector(selectTreeSelection),
		isActive = currentScreen === 'EquipmentManager';

	if (!isActive) {
		return null;
	}

	const
		useUploadDownload = true,//enterpriseTypeId !== ENTERPRISE_TYPES__BRANCH_PM, // TODO: Restore this line
		baseParams = {
			'conditions[enterprise_id]': enterpriseId,
		};
	if (treeSelection) {
		if (treeSelection.length === 1) {
			baseParams['conditions[fleet_id]'] = treeSelection[0].fleets__fleet_id;
		} else {
			baseParams['conditions[0]'] = '1=2'; // >1 Fleet selected, hide all
		}
	}

	return <Container
				reference="EquipmentManagerScreen"
				west={<FleetLitesTree
							isResizable={true}
							w={300}
						/>}
				center={<ManagerScreen
							title="Equipment"
							reference="EquipmentManager"
							fullModeComponent={<EnterpriseSpecific>
													<TreeSpecific>
														<EquipmentFilteredGridEditor
															reference="EquipmentFilteredGridEditor"
															useRemoteDuplicate={true}
															useUploadDownload={useUploadDownload}
															baseParams={baseParams}
															uploadParams={baseParams}
															downloadParams={baseParams}
														/>
													</TreeSpecific>
												</EnterpriseSpecific>}
							sideModeComponent={<EnterpriseSpecific>
													<TreeSpecific>
														<EquipmentFilteredSideGridEditor
															reference="EquipmentFilteredGridEditor"
															useRemoteDuplicate={true}
															useUploadDownload={useUploadDownload}
															baseParams={baseParams}
															uploadParams={baseParams}
															downloadParams={baseParams}
														/>
													</TreeSpecific>
												</EnterpriseSpecific>}
						/>}
			/>;
}
