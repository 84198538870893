import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
	selectEnterpriseId,
	selectTreeSelection,
} from '../models/Slices/AppSlice';
import {
	ENTERPRISE_TYPES__BRANCH_PM,
} from '../constants/EnterpriseTypes';
import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import PmEventsFilteredGridEditor from '../components/Grid/PmEventsFilteredGridEditor.js';
import PmEventsFilteredSideGridEditor from '../components/Grid/PmEventsFilteredSideGridEditor.js';
import TabBar from '@onehat/ui/src/Components/Tab/TabBar.js';
import EnterpriseSpecific from '../components/Layout/EnterpriseSpecific.js';
import TreeSpecific from '../components/Layout/TreeSpecific.js';
import UpcomingPmsGrid from '../components/Grid/UpcomingPmsGrid.js';
import Bell from '@onehat/ui/src/Components/Icons/Bell.js';
import ClockRegular from '@onehat/ui/src/Components/Icons/ClockRegular.js';
import _ from 'lodash';

export default function PmEventsManager(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		enterpriseId = useSelector(selectEnterpriseId),
		treeSelection = useSelector(selectTreeSelection),
		isActive = currentScreen === 'PmEventsManager';

	if (!isActive) {
		return null;
	}

	const
		defaultFilters = [
			'pm_events__equipment_id',
			'pm_events__date',
			'pm_events__pm_event_type_id',
		],
		baseParams = {
			'conditions[Equipment.enterprise_id]': enterpriseId,
		};
	if (treeSelection) {
		if (treeSelection.length === 1) {
			baseParams['conditions[fleet_id]'] = treeSelection[0].fleets__fleet_id;
		} else {
			baseParams['conditions[0]'] = '1=2'; // >1 Fleet selected, hide all
		}
	}

	return <TabBar
				tabs={[
					{
						title: 'Upcoming PMs',
						_icon: { as: Bell },
						content: <EnterpriseSpecific>
									<TreeSpecific>
										<UpcomingPmsGrid reference="UpcomingPmsGrid" />,
									</TreeSpecific>
								</EnterpriseSpecific>,
					},
					{
						title: 'PM Events',
						_icon: { as: ClockRegular },
						content: <ManagerScreen
									title="PmEvents"
									reference="PmEventsManager"
									fullModeComponent={<EnterpriseSpecific>
															<TreeSpecific>
																<PmEventsFilteredGridEditor
																	reference="PmEventsFilteredGridEditor"
																	defaultFilters={defaultFilters}
																	baseParams={baseParams}
																/>
															</TreeSpecific>
														</EnterpriseSpecific>}
									sideModeComponent={<EnterpriseSpecific>
															<TreeSpecific>
																<PmEventsFilteredSideGridEditor
																	reference="PmEventsFilteredGridEditor"
																	defaultFilters={defaultFilters}
																	baseParams={baseParams}
																/>
															</TreeSpecific>
														</EnterpriseSpecific>}
								/>,
					},
				]}
			/>;
}
