/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportTechniciansUtilProcessLogsEditorWindow from '../Window/ImportTechniciansUtilProcessLogsEditorWindow.js';
import ImportTechniciansUtilProcessLogsGridColumns from './Columns/ImportTechniciansUtilProcessLogsGridColumns.js';

export default function ImportTechniciansUtilProcessLogsGridEditor(props) {
	return <WindowedGridEditor
				reference="ImportTechniciansUtilProcessLogsGridEditor"
				model="ImportTechniciansUtilProcessLogs"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={ImportTechniciansUtilProcessLogsEditorWindow}
				columnsConfig={ImportTechniciansUtilProcessLogsGridColumns}
				
				
				{...props}
			/>;
}