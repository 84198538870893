import * as yup from 'yup'; // https://github.com/jquense/yup#string
import json from '@onehat/ui/src/Functions/jsonValidator';

const UtilCrudLogs = {
	
	name: 'UtilCrudLogs',
	
	model: {

		idProperty: 'util_crud_logs__id',
		displayProperty: 'util_crud_logs__id',
		sortProperty: 'util_crud_logs__datetime',
		sortDir: 'DESC',
		
		sorters: null,

		validator: yup.object({
			util_crud_logs__datetime: yup.date().required(),
			util_crud_logs__model: yup.string().max(25).required(),
			util_crud_logs__modelid: yup.number().integer().required(),
			util_crud_logs__operation: yup.string().max(25).required(),
			util_crud_logs__display_value: yup.string().nullable(),
			util_crud_logs__user_id: yup.number().integer().nullable(),
			util_crud_logs__json: json().nullable()
		}),
		
		properties: [
			{ name: 'util_crud_logs__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'util_crud_logs__datetime', mapping: 'datetime', title: 'Datetime', type: 'datetime', filterType: {"type":"DateRange","mode":"DATETIME"}, editorType: {"type":"Date","mode":"DATETIME"}, fieldGroup: 'General', },
			{ name: 'util_crud_logs__model', mapping: 'model', title: 'Model', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			// adjust filterType for modelid
			{ name: 'util_crud_logs__modelid', mapping: 'modelid', title: 'Modelid', type: 'int', filterType: {"type":"CrudModelsCombo"}, editorType: {"type":"CrudModelsCombo"}, fieldGroup: 'General', },
			{ name: 'util_crud_logs__operation', mapping: 'operation', title: 'Operation', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'util_crud_logs__display_value', mapping: 'display_value', title: 'Display Value', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'util_crud_logs__user_id', mapping: 'user_id', title: 'User', type: 'int', isFk: true, fkIdField: 'users__id', fkDisplayField: 'users__username', filterType: {"type":"UsersCombo","loadAfterRender":!1}, editorType: {"type":"UsersComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'util_crud_logs__json', mapping: 'json', title: 'Json', type: 'json', isFilteringDisabled: true, viewerType: {"type":"Json"}, editorType: {"type":"Json"}, fieldGroup: 'General', },
			{ name: 'users__id', mapping: 'user.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__username', mapping: 'user.username', title: 'Username', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__email', mapping: 'user.email', title: 'Email', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password', mapping: 'user.password', title: 'Password', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__first_name', mapping: 'user.first_name', title: 'First Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_name', mapping: 'user.last_name', title: 'Last Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__employee_number', mapping: 'user.employee_number', title: 'Employee Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token', mapping: 'user.token', title: 'Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__token_generated', mapping: 'user.token_generated', title: 'Token Generated', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__expo_token', mapping: 'user.expo_token', title: 'Expo Token', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__password_reset', mapping: 'user.password_reset', title: 'Password Reset', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__full_name', mapping: 'user.full_name', title: 'Full Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__last_login', mapping: 'user.last_login', title: 'Last Login', type: 'datetime', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__login_count', mapping: 'user.login_count', title: 'Login Count', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'users__groups', mapping: 'user.groups', title: 'Groups', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Users'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			'util_crud_logs__datetime',
			'util_crud_logs__modelid',
			'util_crud_logs__user_id'
		],

	},

	entity: {
		methods: {

			getUser: async function() {
				const Users = this.getAssociatedRepository('Users');
				let entity = Users.getById(this.util_crud_logs__user_id);
				if (!entity) {
					entity = await Users.getSingleEntityFromServer(this.util_crud_logs__user_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default UtilCrudLogs;
