import { useState, } from 'react';
import {
	Column,
	Icon,
	Image,
	Pressable,
	Row,
	Text,
} from 'native-base';
import {
	useLinkTo,
} from '@react-navigation/native';
import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
} from '../models/Slices/AppSlice';
import withPermissions from '@onehat/ui/src/Components/Hoc/withPermissions';
import chunkArray from '../functions/chunkArray';
import testProps from '@onehat/ui/src/Functions/testProps.js';
import IconBox from '../components/Layout/TopBar/IconBox';
import appPng from '../components/Images/MhPmsLogo.png';
import Gauge from '@onehat/ui/src/Components/Icons/Gauge';
import Truck from '@onehat/ui/src/Components/Icons/Truck';
import Book from '@onehat/ui/src/Components/Icons/Book';
import _ from 'lodash';


const
	apps = [
		{
			title: 'Fleet App',
			_icon: { as: Truck, },
			path: '/mh-fleet',
		},
		{
			title: 'MH PMs',
			_icon: { as: Gauge, },
			path: '/mh-pms',
		},
		{
			title: 'Audit Tool',
			_icon: { as: Book, },
			path: '/audit-tool',
		},
	];

function AppPortal(props) {
	const
		linkTo = useLinkTo(),
		currentScreen = useSelector(selectCurrentScreen),
		isActive = isSetup ? currentScreen === 'SetupAppPortal' : currentScreen === 'AppPortal',
		[containerWidth, setContainerWidth] = useState(500),
		[isRendered, setIsRendered] = useState(false),
		onLayout= (e) => {
			const containerWidth = e.nativeEvent.layout.width;
			setContainerWidth(containerWidth);
			setIsRendered(true);
		},
		onPress = (app) => {
			linkTo(app.path); // navigate & change URL
		};

	if (!isActive) {
		return null;
	}

	const
		rows = [],
		items = [];

	_.each(apps, (app, ix) => {
		if (!ix) {
			return; // skip 'AppPortal' app
		}
		const {
				title,
				_icon,
				path,
			} = app;
		items.push(<IconBox
			key={ix}
			url={path}
			text={title}
			_icon={_icon}
			onPress={() => onPress(app)} 
		/>);
	});
	
	let itemsPerRow = 1;
	if (containerWidth > 400) {
		itemsPerRow = 2;
	}
	if (containerWidth > 600) {
		itemsPerRow = 3;
	}
	if (containerWidth > 800) {
		itemsPerRow = 4;
	}

	const chunks = chunkArray(items, itemsPerRow);
	_.each(chunks, (items, ix) => {
		rows.push(<Row
						key={'row' + ix}
						flex={1}
						justifyContent="space-between"
						mb={3}
					>{items}</Row>);
	});
	
	return <Column flex={1} w="100%" p={10} onLayout={onLayout} >
				{isRendered ? rows : null}
			</Column>;

}

export default withPermissions(AppPortal, true);