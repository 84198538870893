/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ServiceCentersSideGridEditor from './ServiceCentersSideGridEditor.js';

export default function ServiceCentersFilteredSideGridEditor(props) {
	return <ServiceCentersSideGridEditor
				reference="ServiceCentersFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}