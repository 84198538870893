/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import ImportTechniciansEditor from '../../Editor/ImportTechniciansEditor.js';
import ImportTechniciansGridColumns from '../../Grid/Columns/ImportTechniciansGridColumns.js';

export default function ImportTechniciansSideGridEditorPanel(props) {
	return <GridPanel
				reference="ImportTechniciansSideGridEditorPanel"
				model="ImportTechnicians"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={ImportTechniciansEditor}
				columnsConfig={ImportTechniciansGridColumns}
				
				
				{...props}
			/>;
}