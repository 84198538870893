import {
	Text,
} from 'native-base';
import CenterBox from '@onehat/ui/src/Components/Layout/CenterBox';
import { useSelector } from 'react-redux';
import {
	selectEnterpriseId,
} from '../../models/Slices/AppSlice';

export default function EnterpriseSpecific(props) {

	const
		enterpriseId = useSelector(selectEnterpriseId),
		hasEnterpriseId = !!enterpriseId;

	if (!hasEnterpriseId) {
		return <CenterBox {...props}>
					<Text>No enterprise selected</Text>
				</CenterBox>;
	}

	return props.children;
}