import * as yup from 'yup'; // https://github.com/jquense/yup#string

const WoOtherChargeTypes = {
	
	name: 'WoOtherChargeTypes',
	
	model: {

		idProperty: 'wo_other_charge_types__id',
		displayProperty: 'wo_other_charge_types__name',
		sortProperty: 'wo_other_charge_types__name',
		
		sorters: null,

		validator: yup.object({
			wo_other_charge_types__name: yup.string().required(),
			wo_other_charge_types__code: yup.string().max(8).nullable()
		}),
		
		properties: [
			{ name: 'wo_other_charge_types__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'wo_other_charge_types__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'wo_other_charge_types__code', mapping: 'code', title: 'Code', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', }
		],

		associations: {
			hasMany: [
				'WoOtherCharges'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [

		],

	},

	entity: {
		methods: {

			getWoOtherCharges: function() {
				const WoOtherCharges = this.getAssociatedRepository('WoOtherCharges');
				return WoOtherCharges.getBy((entity) => {
					return entity.wo_other_charges__wo_other_charge_type_id === this.wo_other_charge_types__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default WoOtherChargeTypes;
