/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportTechniciansInlineGridEditor from './ImportTechniciansInlineGridEditor.js';

export default function ImportTechniciansFilteredInlineGridEditor(props) {
	return <ImportTechniciansInlineGridEditor
				reference="ImportTechniciansFilteredInlineGridEditor"
				useFilters={true}

				{...props}
			/>;
}