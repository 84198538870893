// NOTE: This model is a reduced version of the Fleet model, making an optimized version of Fleets for the FleetTree.
// It utilizes the "FleetLites" controller, which auto-adds the reduced fields and contains to the request.

const FleetLites = {
	
	name: 'FleetLites',
	usesEnterpriseId: false, // it actually DOES, but being a tree we need to handle it differently
	model: {

		idProperty: 'fleets__id',
		displayProperty: 'fleets__name',
		sortProperty: 'fleets__name',
		isTree: true,
		parentIdProperty: 'parentId',
		depthProperty: 'depth',
		hasChildrenProperty: 'hasChildren',
		
		sorters: null,

		validator: null,
		
		properties: [
			{ name: 'parentId', mapping: 'parentId', type: 'uuid', isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'hasChildren', mapping: 'hasChildren', type: 'bool', isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'depth', mapping: 'depth', type: 'int', isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'nodeType', mapping: 'nodeType', title: 'Node Type', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'actualId', mapping: 'actualId', title: 'Actual Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },

			// Fleets nodes
			{ name: 'fleets__id', mapping: 'id', title: 'Id', type: 'uuid', isFilteringDisabled: true, fieldGroup: 'General', },
			// { name: 'fleets__enterprise_id', mapping: 'enterprise_id', title: 'Enterprise', type: 'int', isFk: true, fkIdField: 'enterprises__id', fkDisplayField: 'enterprises__name', filterType: {"type":"EnterprisesCombo","loadAfterRender":!1}, editorType: {"type":"EnterprisesComboEditor","loadAfterRender":!1}, fieldGroup: '1. Details', },
			{ name: 'fleets__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Details', },
			{ name: 'fleets__business_partners', mapping: 'business_partners', title: 'Business Partners', filterType: {"type":"BusinessPartnersTag"}, viewerType: {"type":"BusinessPartnersTag"}, editorType: {"type":"BusinessPartnersTagEditor","loadAfterRender":!1}, fieldGroup: '1. Details', },
			{ name: 'fleet_stats__is_stale', mapping: 'fleet_stat.is_stale', title: 'Stale?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleet_stats__path', mapping: 'fleet_stat.path', title: 'Path', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleet_stats__total_active_equipment', mapping: 'fleet_stat.total_active_equipment', title: 'Total Active Equipment', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleet_stats__total_inactive_equipment', mapping: 'fleet_stat.total_inactive_equipment', title: 'Total Inactive Equipment', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleet_stats__latest_repair_date', mapping: 'fleet_stat.latest_repair_date', title: 'Latest Repair Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleet_stats__due_pms', mapping: 'fleet_stat.due_pms', title: 'Due Pms', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleet_stats__overdue_pms', mapping: 'fleet_stat.overdue_pms', title: 'Overdue Pms', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleet_stats__overdue_pm_equipment', mapping: 'fleet_stat.overdue_pm_equipment', title: 'Overdue Pm Equipment', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleet_stats__pm_equipment_hy', mapping: 'fleet_stat.pm_equipment_hy', title: 'PM Equipment Hy', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleet_stats__pm_equipment_ya', mapping: 'fleet_stat.pm_equipment_ya', title: 'PM Equipment Ya', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleet_stats__pm_equipment_other', mapping: 'fleet_stat.pm_equipment_other', title: 'PM Equipment Other', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleet_stats__latest_pm_date', mapping: 'fleet_stat.latest_pm_date', title: 'Latest Pm Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleet_stats__total_pm_equipment', mapping: 'fleet_stat.total_pm_equipment', title: 'Total Pm Equipment', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleet_stats__total_pm_will_call_equipment', mapping: 'fleet_stat.total_pm_will_call_equipment', title: 'Total Pm Will Call Equipment', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },

			// Equipment nodes
			{ name: 'equipment__id', mapping: 'id', title: 'Id', type: 'uuid', isFilteringDisabled: true, fieldGroup: 'General', },
			// { name: 'equipment__enterprise_id', mapping: 'enterprise_id', title: 'Enterprise', type: 'int', isFk: true, fkIdField: 'enterprises__id', fkDisplayField: 'enterprises__name', filterType: {"type":"EnterprisesCombo","loadAfterRender":!1}, editorType: {"type":"EnterprisesComboEditor","loadAfterRender":!1}, fieldGroup: '1. Equipment Details', },
			{ name: 'equipment__fleet_id', mapping: 'fleet_id', title: 'Fleet', type: 'int', isFk: true, fkIdField: 'fleets__id', fkDisplayField: 'fleets__name', filterType: {"type":"FleetsCombo","loadAfterRender":!1}, editorType: {"type":"FleetsComboEditor","loadAfterRender":!1}, fieldGroup: '1. Equipment Details', },
			{ name: 'equipment__eq_status_id', mapping: 'eq_status_id', title: 'Status', type: 'int', isFk: true, fkIdField: 'eq_statuses__id', fkDisplayField: 'eq_statuses__name', filterType: {"type":"EqStatusesCombo","loadAfterRender":!1}, editorType: {"type":"EqStatusesCombo","loadAfterRender":!1}, fieldGroup: '1. Equipment Details', defaultValue: 1, },
			{ name: 'equipment__inactive_date', mapping: 'inactive_date', title: 'Inactive Date', type: 'date', filterType: {"type":"DateRange","mode":"DATE"}, isEditingDisabled: true, fieldGroup: '1. Equipment Details', },
			{ name: 'equipment__year', mapping: 'year', title: 'Year', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Equipment Details', },
			{ name: 'equipment__manufacturer_id', mapping: 'manufacturer_id', title: 'Make', type: 'int', isFk: true, fkIdField: 'manufacturers__id', fkDisplayField: 'manufacturers__name', filterType: {"type":"ManufacturersCombo","loadAfterRender":!1}, editorType: {"type":"ManufacturersComboEditor","loadAfterRender":!1}, fieldGroup: '1. Equipment Details', },
			{ name: 'equipment__model', mapping: 'model', title: 'Model', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Equipment Details', },
			{ name: 'equipment__serial_number', mapping: 'serial_number', title: 'Serial #', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Equipment Details', },
			{ name: 'equipment__unit_number', mapping: 'unit_number', title: 'Unit #', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Equipment Details', },
			{ name: 'equipment__asset_number', mapping: 'asset_number', title: 'Asset # (Cust ID)', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Equipment Details', },
			{ name: 'equipment__dept_number', mapping: 'dept_number', title: 'Dept #', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. Equipment Details', },
			{ name: 'equipment__business_partner_id', mapping: 'business_partner_id', title: 'Business Partner', type: 'int', isFk: true, fkIdField: 'business_partners__id', fkDisplayField: 'business_partners__name', filterType: {"type":"BusinessPartnersCombo","loadAfterRender":!1}, editorType: {"type":"BusinessPartnersComboEditor","loadAfterRender":!1}, fieldGroup: '1. Equipment Details', },
			{ name: 'equipment__eq_type_id', mapping: 'eq_type_id', title: 'Type', type: 'int', isFk: true, fkIdField: 'eq_types__id', fkDisplayField: 'eq_types__name', filterType: {"type":"EqTypesCombo","loadAfterRender":!1}, editorType: {"type":"EqTypesComboEditor","loadAfterRender":!1}, fieldGroup: '1. Equipment Details', },
			{ name: 'equipment__is_misc', mapping: 'is_misc', title: 'Misc?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. Equipment Details', },
			{ name: 'equipment__is_pm_enable', mapping: 'is_pm_enable', title: 'Pm Enable?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '2. PM Setup', },
			{ name: 'equipment__pm_schedules', mapping: 'pm_schedules', title: 'PM Schedules', filterType: {"type":"PmSchedulesTag"}, viewerType: {"type":"PmSchedulesTag"}, editorType: {"type":"PmSchedulesTagEditor","loadAfterRender":!1}, fieldGroup: '2. PM Setup', },
			{ name: 'eq_stats__is_stale', mapping: 'eq_stat.is_stale', title: 'Stale?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__path', mapping: 'eq_stat.path', title: 'Path', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__latest_meter_reading', mapping: 'eq_stat.latest_meter_reading', title: 'Latest Meter Reading', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__latest_meter_date', mapping: 'eq_stat.latest_meter_date', title: 'Latest Meter Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__latest_meter_source', mapping: 'eq_stat.latest_meter_source', title: 'Latest Meter Source', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__latest_repair_date', mapping: 'eq_stat.latest_repair_date', title: 'Latest Repair Date', type: 'date', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__latest_pm_data', mapping: 'eq_stat.latest_pm_data', title: 'Latest Pm Data', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__due_pms', mapping: 'eq_stat.due_pms', title: 'Due Pms', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__next_pm_due_data', mapping: 'eq_stat.next_pm_due_data', title: 'Next Pm Due Data', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__overdue_pms', mapping: 'eq_stat.overdue_pms', title: 'Overdue Pms', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_stats__assigned_technician_data', mapping: 'eq_stat.assigned_technician_data', title: 'Assigned Technician Data', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__name', mapping: 'manufacturer.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__abbr', mapping: 'manufacturer.abbr', title: 'Abbr', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_statuses__name', mapping: 'eq_status.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'business_partners__name', mapping: 'business_partner.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'eq_types__name', mapping: 'eq_type.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
		],

	},

	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false,
		canAdd: false,
		canEdit: false,
		canDelete: false,
	},

};

export default FleetLites;
