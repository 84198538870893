/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function EnterprisesEditor(props) {

	const
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "General",
		                "reference": "general",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "enterprises__name"
		                    },
		                    {
		                        "name": "enterprises__is_enabled"
		                    },
		                    {
		                        "name": "enterprises__enterprise_type_id"
		                    },
		                    {
		                        "name": "enterprises__service_center_id"
		                    },
		                    {
		                        "name": "enterprises__exclude_before"
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [
		    // {
		    //     "title": "Equipment",
		    //     "type": "EquipmentGridEditor",
		    //     "selectorId": "equipment__enterprise_id"
		    // },
		    // {
		    //     "title": "Fleets",
		    //     "type": "FleetsGridEditor",
		    //     "selectorId": "fleets__enterprise_id"
		    // },
		    // {
		    //     "title": "Tel Operators",
		    //     "type": "TelOperatorsGridEditor",
		    //     "selectorId": "tel_operators__enterprise_id"
		    // },
		    // {
		    //     "title": "Work Orders",
		    //     "type": "WorkOrdersGridEditor",
		    //     "selectorId": "work_orders__enterprise_id"
		    // }
		],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		};
	return <Editor
				reference="EnterprisesEditor"
				title="Enterprises"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

