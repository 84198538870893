/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import UtilProcessLogsEditorWindow from '../Window/UtilProcessLogsEditorWindow.js';
import UtilProcessLogsGridColumns from './Columns/UtilProcessLogsGridColumns.js';

export default function UtilProcessLogsGridEditor(props) {
	return <WindowedGridEditor
				reference="UtilProcessLogsGridEditor"
				model="UtilProcessLogs"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={UtilProcessLogsEditorWindow}
				columnsConfig={UtilProcessLogsGridColumns}
				getRowProps={(item) => {
					const props = {
						borderBottomWidth: 1,
						borderBottomColor: 'trueGray.500',
					};
					if (!item.properties.util_process_logs__success.submitValue) {
						props.bg = '#ffd1d1';
					}
					return props;
				}}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}