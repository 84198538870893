export default function getNodeText(item) {
	let text;
	switch(item.nodeType) {
		case 'Fleets':
			text = item.fleets__name;
			break;
		case 'Equipment':
			if (item.equipment__is_misc) {
				text = 'Misc Catch-All';
			} else {
				// TODO: allow use to select the field to display
				text = item.equipment__serial_number;
			}
			break;
	}
	return text;
}