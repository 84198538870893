import { useSelector } from 'react-redux';
import {
	selectCurrentScreen,
	selectEnterpriseId,
	selectEnterpriseTypeId,
	selectTreeSelection,
} from '../models/Slices/AppSlice';
import {
	ENTERPRISE_TYPES__BRANCH_PM,
} from '../constants/EnterpriseTypes';
import ManagerScreen from '@onehat/ui/src/Components/Screens/Manager.js';
import WorkOrdersFilteredGridEditor from '../components/Grid/WorkOrdersFilteredGridEditor.js';
import WorkOrdersFilteredSideGridEditor from '../components/Grid/WorkOrdersFilteredSideGridEditor.js';
import EnterpriseSpecific from '../components/Layout/EnterpriseSpecific.js';
import TreeSpecific from '../components/Layout/TreeSpecific.js';

export default function WorkOrdersManager(props) {
	const
		currentScreen = useSelector(selectCurrentScreen),
		enterpriseId = useSelector(selectEnterpriseId),
		enterpriseTypeId = useSelector(selectEnterpriseTypeId),
		treeSelection = useSelector(selectTreeSelection),
		isActive = currentScreen === 'WorkOrdersManager';

	if (!isActive) {
		return null;
	}

	const
		defaultFilters = [
			// 'equipment',
			// 'work_orders__wo_class_id',
			// 'work_orders__open_date',
			// 'work_orders__customer_id',
		],
		useUploadDownload = true,//enterpriseTypeId !== ENTERPRISE_TYPES__BRANCH_PM, // TODO: restore this line
		baseParams = {
			'conditions[WorkOrders.enterprise_id]': enterpriseId,
		};
	if (treeSelection) {
		if (treeSelection.length === 1) {
			switch(treeSelection[0].nodeType) {
				case 'Fleets':
					baseParams['conditions[WorkOrders.fleet_id]'] = treeSelection[0].actualId;
					break;
				case 'Equipment':
					baseParams['conditions[WorkOrders.equipment_id]'] = treeSelection[0].actualId;
					break;
			}
		} else {
			baseParams['conditions[0]'] = '1=2'; // >1 Fleet selected, hide all
		}
	}

	return <ManagerScreen
				title="WorkOrders"
				reference="WorkOrdersManager"
				fullModeComponent={<EnterpriseSpecific>
										<TreeSpecific>
											<WorkOrdersFilteredGridEditor
												reference="WorkOrdersFilteredGridEditor"
												useRemoteDuplicate={true}
												defaultFilters={defaultFilters}
												useUploadDownload={useUploadDownload}
												baseParams={baseParams}
												uploadParams={baseParams}
												downloadParams={baseParams}
											/>
										</TreeSpecific>
									</EnterpriseSpecific>}
				sideModeComponent={<EnterpriseSpecific>
										<TreeSpecific>
											<WorkOrdersFilteredSideGridEditor
												reference="WorkOrdersFilteredGridEditor"
												useRemoteDuplicate={true}
												defaultFilters={defaultFilters}
												useUploadDownload={useUploadDownload}
												baseParams={baseParams}
												uploadParams={baseParams}
												downloadParams={baseParams}
											/>
										</TreeSpecific>
									</EnterpriseSpecific>}
			/>;
}
