/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import {
	EDITOR_TYPE__SIDE,
} from '@onehat/ui/src/Constants/Editor.js';
import ImportServiceCentersUtilProcessLogsEditor from '../../Editor/ImportServiceCentersUtilProcessLogsEditor.js';
import ImportServiceCentersUtilProcessLogsGridColumns from '../../Grid/Columns/ImportServiceCentersUtilProcessLogsGridColumns.js';

export default function ImportServiceCentersUtilProcessLogsSideGridEditorPanel(props) {
	return <GridPanel
				reference="ImportServiceCentersUtilProcessLogsSideGridEditorPanel"
				model="ImportServiceCentersUtilProcessLogs"
				usePermissions={true}
				isCollapsible={false}
				isEditor={true}
				editorType={EDITOR_TYPE__SIDE}
				Editor={ImportServiceCentersUtilProcessLogsEditor}
				columnsConfig={ImportServiceCentersUtilProcessLogsGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}