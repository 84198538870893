/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportWoLaborsInlineGridEditorPanel from './ImportWoLaborsInlineGridEditor.js';

export default function ImportWoLaborsFilteredInlineGridEditorPanel(props) {
	return <ImportWoLaborsInlineGridEditorPanel
				reference="ImportWoLaborsFilteredInlineGridEditorPanel"
				useFilters={true}

				{...props}
			/>;
}