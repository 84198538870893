import { useState, useEffect, useRef } from 'react';
import {
	Button,
	Column,
	Icon,
	Image,
	Pressable,
	Row,
} from 'native-base';
import { useSelector, useDispatch } from 'react-redux';
import {
	loginThunk,
} from '../models/Slices/AppSlice';
import {
	setAlertMessage,
	setDebugMessage,
	setInfoMessage,
	selectDebugMessage,
	selectAlertMessage,
	selectInfoMessage,
	selectIsWaitModalShown,
	selectWaitMessage,
} from '../models/Slices/DebugSlice';
import oneHatData from '@onehat/data';
import getSecure from '@onehat/ui/src/Functions/getSecure';
import Input from '@onehat/ui/src/Components/Form/Field/Input';
import CenterBox from '@onehat/ui/src/Components/Layout/CenterBox.js';
import Eye from '@onehat/ui/src/Components/Icons/Eye.js';
import EyeSlash from '@onehat/ui/src/Components/Icons/EyeSlash.js';
import RightToBracket from '@onehat/ui/src/Components/Icons/RightToBracket.js';
import ChevronRight from '@onehat/ui/src/Components/Icons/ChevronRight.js';
import User from '@onehat/ui/src/Components/Icons/User.js';
import testProps from '@onehat/ui/src/Functions/testProps.js';
import WaitMessage from '@onehat/ui/src/Components/Messages/WaitMessage';
import ErrorMessage from '@onehat/ui/src/Components/Messages/ErrorMessage';
import Loading from '@onehat/ui/src/Components/Messages/Loading.js';
import AppGlobals from '../AppGlobals.js';
import appPng from '../components/Images/MhPmsLogo.png';
import _ from 'lodash';

const 
	USERNAME = null, // 'skote', // 
	PASSWORD = null; // 'biglens47'; // 
	
export default function Login() {
	const
		dispatch = useDispatch(),
		debugMessage = useSelector(selectDebugMessage),
		alertMessage = useSelector(selectAlertMessage),
		infoMessage = useSelector(selectInfoMessage),
		isWaitModalShown = useSelector(selectIsWaitModalShown),
		waitMessage = useSelector(selectWaitMessage),
		passwordRef = useRef(),
		loginIdRef = useRef(),
		[isReady, setIsReady] = useState(false),
		[loginId, setLoginId] = useState(USERNAME),
		[isPasswordPlaintext, setIsPasswordPlaintext] = useState(false),
		[password, setPassword] = useState(PASSWORD),
		Users = oneHatData.getRepository('Users'),
		isValid = !!(loginId && password),
		applyDefaultLoginId = () => {
			getSecure('loginId').then((loginId) => {
				if (loginId) {
					setLoginId(loginId);
				}
			});
		},
		login = () => {
			setPassword(); // Clear the password field

			dispatch(loginThunk({ loginId, password, isValid, }));
		};
		// login = async () => {
		// 	try {
		// 		if (!isValid) {
		// 			dispatch(setAlertMessage('Please completely fill out the form first.'));
		// 			return;
		// 		}

		// 		dispatch(setIsWaitModalShown(true));

		// 		Keyboard.dismiss();

		// 		// Login user
		// 		let isError = false;
		// 		const params = {};
		// 		params[AppGlobals.loginIdField] = loginId;
		// 		params.password = password;
		// 		const loginResult = await Users.login(params)
		// 			.catch(function (error) {
		// 				dispatch(setAlertMessage(error.message));
		// 				isError = true;
		// 			});
		// 		if (isError || !loginResult) {
		// 			dispatch(setIsWaitModalShown(false));
		// 			return;
		// 		}

		// 		await dispatch(loginThunk(loginResult));

		// 		setPassword(); // Clear the password field

		// 		navigation.replace('AppNavigator');

		// 	} catch(error) {
		// 		dispatch(setAlertMessage(error?.message));
		// 	}
		// 	dispatch(setIsWaitModalShown(false));
		// };
		
	useEffect(() => {
		if (Users.isDestroyed) {
			return; // for auto-reload of Expo
		}

		applyDefaultLoginId();
		
		setIsReady(true);

	}, [Users]);

	if (!isReady) {
		return <Loading isScreen={true} />;
	}
	
	const
		inputProps = {
			w: '100%',
			borderWidth: 1,
			borderColor: 'trueGray.400',
			mb: 2,
		};
	
	return <CenterBox bg="trueGray.300">
				{isWaitModalShown && <WaitMessage text={waitMessage} />}
				{!isWaitModalShown && alertMessage && <ErrorMessage text={alertMessage} onOk={() => dispatch(setAlertMessage(null))} />}
				{!isWaitModalShown && debugMessage && <ErrorMessage text={debugMessage} color="green" onOk={() => dispatch(setDebugMessage(null))} />}
				{!isWaitModalShown && infoMessage && <ErrorMessage text={infoMessage} color="#000" onOk={() => dispatch(setInfoMessage(null))} />}
				<Column
					justifyContent="center"
					alignItems="center"
					h={300}
					w={400}
					borderRadius={20}
					p={5}
					bg="#fff"
				>
					<Image
						{...testProps('AppImg')}
						source={appPng}
						resizeMode="contain"
						alt="App Logo"
						h={150}
						w="90%"
						mb={3}
					/>
					<Input
						{...testProps('loginId')}
						ref={loginIdRef}
						placeholder={AppGlobals.loginIdField === 'username' ? 'Username' : 'Email'}
						value={loginId}
						onChangeText={setLoginId}
						InputLeftElement={<Pressable onPress={() => {
												loginIdRef.current.focus();
											}}>
												<Icon
													as={User}
													size={5}
													ml="2"
													color="muted.400"
												/>
											</Pressable>}
						onSubmitEditing={login}
						variant="form"
						{...inputProps}
					/>
					<Input
						{...testProps('password')}
						ref={passwordRef}
						placeholder="Password"
						value={password}
						onChangeText={setPassword}
						onSubmitEditing={login}
						secureTextEntry={!isPasswordPlaintext}
						type={isPasswordPlaintext ? 'text' : 'password'}
						overflow="visible"
						InputRightElement={<Pressable onPress={() => {
												passwordRef.current.focus();
												setIsPasswordPlaintext(!isPasswordPlaintext);
											}}>
												<Icon
													as={isPasswordPlaintext ? EyeSlash : Eye}
													size={5}
													mr="2"
													color="trueGray.400"
												/>
											</Pressable>}
						variant="form"
						// onBlur={(e) => {
						// 	Keyboard.dismiss();
						// }}
						{...inputProps}
					/>

					<Button
						{...testProps('loginBtn')}
						leftIcon={<Icon as={RightToBracket} color="#fff" />}
						isDisabled={!isValid}
						onPress={ login }
						bg="primary.600"
					>
						Sign In
					</Button>
				</Column>
			</CenterBox>;
}
