/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportWoServiceTypesUtilProcessLogsGrid from './ImportWoServiceTypesUtilProcessLogsGrid.js';

export default function ImportWoServiceTypesUtilProcessLogsFilteredGrid(props) {
	return <ImportWoServiceTypesUtilProcessLogsGrid
				reference="ImportWoServiceTypesUtilProcessLogsFilteredGrid"
				useFilters={true}
				
				{...props}
			/>;
}