export default [
	    {
	        "id": "parts__part_number",
	        "header": "Part Number",
	        "fieldName": "parts__part_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "parts__description",
	        "header": "Description",
	        "fieldName": "parts__description",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "parts__manufacturer_id",
	        "header": "Manufacturer",
	        "fieldName": "manufacturers__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "parts__manufacturer_id"
	    },
	    {
	        "id": "parts__unit_cost",
	        "header": "Unit Cost",
	        "fieldName": "parts__unit_cost",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "parts__unit_price",
	        "header": "Unit Price",
	        "fieldName": "parts__unit_price",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    }
	];