import EquipmentPmSchedules from './EquipmentPmSchedules.js';
import _ from 'lodash';

// NOTE: This repository is essentially a repurposed version of
// the EquipmentPmSchedules repository. We change the sorting
// and keep it distinct, so it won't interfere with normal operations
// of EquipmentPmSchedules.

const UpcomingPms = _.cloneDeep(EquipmentPmSchedules);

UpcomingPms.name = 'UpcomingPms';
UpcomingPms.model.sortProperty = 'equipment_pm_schedules__next_pm_due',
UpcomingPms.model.sorters = [
	{
		name: 'equipment_pm_schedules__next_pm_due',
		direction: 'DESC',
	},
];
UpcomingPms.repository.api = {
	get: EquipmentPmSchedules.name + '/get',
	add: EquipmentPmSchedules.name + '/add',
	edit: EquipmentPmSchedules.name + '/edit',
	delete: EquipmentPmSchedules.name + '/delete',
	batchAdd: EquipmentPmSchedules.name + '/batchAdd',
	batchEdit: EquipmentPmSchedules.name + '/batchEdit',
	batchDelete: EquipmentPmSchedules.name + '/batchDelete',
};


export default UpcomingPms;