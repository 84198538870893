import ImportStatusesViewer from '../../Viewer/ImportStatusesViewer.js';
import TimeViewer from '../../Viewer/TimeViewer.js';

export default [
	    {
	        "id": "import_service_centers_util_process_logs__datetime",
	        "header": "Time", // MOD
	        "fieldName": "import_service_centers_util_process_logs__datetime",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100, // MOD
			renderer: (entity) => {
				return <TimeViewer moment={entity.properties.import_service_centers_util_process_logs__datetime?.getMoment()} />;
			},
	    },
		{
			id: "import_service_centers__name",
			header: "Service Center",
			fieldName: "import_service_centers__name",
			isSortable: false,
			isEditable: false,
			isReorderable: true,
			isResizable: true,
			w: 250
		},
		{
			id: "import_service_centers__code",
			header: "CODE",
			fieldName: "import_service_centers__code",
			isSortable: false,
			isEditable: false,
			isReorderable: true,
			isResizable: true,
			w: 100
		},
	    {
	        "id": "import_service_centers_util_process_logs__success",
	        "header": "Success",
	        "fieldName": "import_service_centers_util_process_logs__success",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_service_centers_util_process_logs__status",
	        "header": "Status",
	        "fieldName": "import_service_centers_util_process_logs__status",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
			renderer: (entity) => {
				return <ImportStatusesViewer id={entity.import_service_centers_util_process_logs__status} />;
			},
	    },
	    {
	        "id": "import_service_centers_util_process_logs__json",
	        "header": "Json",
	        "fieldName": "import_service_centers_util_process_logs__json",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    // {
	    //     "id": "import_service_centers_util_process_logs__import_service_center_id",
	    //     "header": "Import Service Center",
	    //     "fieldName": "import_service_centers__name",
	    //     "isSortable": true,
	    //     "isEditable": true,
	    //     "isReorderable": true,
	    //     "isResizable": true,
	    //     "editField": "import_service_centers_util_process_logs__import_service_center_id",
	    //     "flex": 1,
	    //     "minWidth": 150
	    // },
	    // {
	    //     "id": "import_service_centers_util_process_logs__service_center_id",
	    //     "header": "Service Center",
	    //     "fieldName": "service_centers__name",
	    //     "isSortable": true,
	    //     "isEditable": true,
	    //     "isReorderable": true,
	    //     "isResizable": true,
	    //     "w": 100,
	    //     "editField": "import_service_centers_util_process_logs__service_center_id"
	    // }
	];