import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Parts = {
	
	name: 'Parts',
	
	model: {

		idProperty: 'parts__id',
		displayProperty: 'parts__part_number',
		sortProperty: 'parts__part_number',
		
		sorters: null,

		validator: yup.object({
			parts__part_number: yup.string().nullable(),
			parts__description: yup.string().nullable(),
			parts__manufacturer_id: yup.number().integer().nullable(),
			parts__unit_cost: yup.number().nullable(),
			parts__unit_price: yup.number().nullable()
		}),
		
		properties: [
			{ name: 'parts__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'parts__part_number', mapping: 'part_number', title: 'Part Number', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'parts__description', mapping: 'description', title: 'Description', isFilteringDisabled: true, editorType: {"type":"TextArea"}, fieldGroup: 'General', },
			{ name: 'parts__manufacturer_id', mapping: 'manufacturer_id', title: 'Manufacturer', type: 'int', isFk: true, fkIdField: 'manufacturers__id', fkDisplayField: 'manufacturers__name', filterType: {"type":"ManufacturersCombo","loadAfterRender":!1}, editorType: {"type":"ManufacturersComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'parts__unit_cost', mapping: 'unit_cost', title: 'Unit Cost', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'parts__unit_price', mapping: 'unit_price', title: 'Unit Price', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: 'General', },
			{ name: 'manufacturers__id', mapping: 'manufacturer.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__name', mapping: 'manufacturer.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__abbr', mapping: 'manufacturer.abbr', title: 'Abbr', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__fa1_eq_abbr', mapping: 'manufacturer.fa1_eq_abbr', title: 'Fa1 Eq Abbr', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__fa1_part_abbr', mapping: 'manufacturer.fa1_part_abbr', title: 'Fa1 Part Abbr', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'manufacturers__is_telemetry', mapping: 'manufacturer.is_telemetry', title: 'Telemetry?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Manufacturers'
			],
			belongsToMany: [
				'PartTypes',
				'WorkOrders'
			],

		},

		ancillaryFilters: {
		    "part_types": "PartType",
		    "work_orders": "WorkOrder"
		},

		defaultFilters: [
			// 'parts__manufacturer_id',
			// 'parts__unit_cost',
			// 'parts__unit_price'
		],

	},

	entity: {
		methods: {

			getManufacturer: async function() {
				const Manufacturers = this.getAssociatedRepository('Manufacturers');
				let entity = Manufacturers.getById(this.parts__manufacturer_id);
				if (!entity) {
					entity = await Manufacturers.getSingleEntityFromServer(this.parts__manufacturer_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": true,
	    "isAutoLoad": false
	},

};

export default Parts;
