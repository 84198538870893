/*
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */
import env from '../env';
import {
	Platform,
} from 'react-native';
import Constants from 'expo-constants';
import moment from 'moment';

const
	manifest = Constants.manifest || Constants.manifest2,
	extra = manifest?.extra?.expoClient?.extra || manifest?.extra, 
	PROCESS_ENV = process.env,
	gitCommit = PROCESS_ENV?.GIT_COMMIT,
	// subdomain = PROCESS_ENV?.SUBDOMAIN || 'testing',
	experienceId = extra?.experienceId;

let subdomain = null;
if (location.hostname.match(/localhost|127|192/)) {
	subdomain = 'localhost';
} else if (location.hostname.match(/(.*)\.mhfleet\.com/)) {
	subdomain = location.hostname.match(/(.*)\.mhfleet\.com/)[1];
} else if (location.hostname.match(/(.*)\.guardianfleet\.net/)) {
	subdomain = location.hostname.match(/(.*)\.guardianfleet\.net/)[1];
}

const Globals = {
	debugMode: false, //__DEV__, // Auto-sets to false for production
	showProgrammerErrorMessages: true,
	useDebugStack: false,
	baseURL: __DEV__ ? 'http://' + env.ip + '/MhFleet/' : 'https://' + subdomain + '.guardianfleet.net/',
	// baseURL: 'http://' + env.ip + '/MhFleet/',
	// baseURL: 'https://' + subdomain + '.MhFleet.work/',
	loginIdField: 'username',
	urlPrefix: null,
	redux: null,
	appState: false,
	gitCommit,
	experienceId, // for expo-notifications
	retryInterval: (Platform.OS === 'ios' ? 2 * 60 : 20) * 1000, // ms
	clearLocalData: false,
	clearLocalDataBefore: moment('2024-01-17 18:00:00'),
	clearSessionData: false,
	clearSecureData: false,
	clearAllNotifications: true,
	autoAdjustPageSizeToHeight: true,
	disableSavedColumnsConfig: true, // temp
	DEFAULT_ENTERPRISE_ID: 8,

	// stayInEditModeOnSelectionChange: true, // Jarrod preference
	// isSideEditorAlwaysEditMode: true, // Jarrod preference
	
	dates: {
		today: moment().format('YYYY-MM-DD'),
		todayDatetime: moment().format('YYYY-MM-DD HH:mm:ss'),
		yesterday: moment().subtract(1, 'days').format('YYYY-MM-DD'),
		tomorrow: moment().add(1, 'days').format('YYYY-MM-DD'),
		oneMonthAgo: moment().subtract(1, 'months').format('YYYY-MM-DD'),
		oneYearAgo: moment().subtract(1, 'years').format('YYYY-MM-DD'),
		startOfThisMonth: moment().startOf('months').format('YYYY-MM-DD'),
		endOfLastMonth: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
		twoMonthsAgo: moment().subtract(2, 'months').format('YYYY-MM-DD'),
		sixMonthsAgo: moment().subtract(6, 'months').format('YYYY-MM-DD'),
		oneMonthFromNow: moment().add(1, 'months').format('YYYY-MM-DD'),
	},
};

export default Globals;

