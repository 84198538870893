/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportWoOtherChargesGridPanel from './ImportWoOtherChargesGrid.js';

export default function ImportWoOtherChargesFilteredGridPanel(props) {
	return <ImportWoOtherChargesGridPanel
				reference="ImportWoOtherChargesFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}