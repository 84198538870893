/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportWoServiceTypesSideGridEditor from './ImportWoServiceTypesSideGridEditor.js';

export default function ImportWoServiceTypesFilteredSideGridEditor(props) {
	return <ImportWoServiceTypesSideGridEditor
				reference="ImportWoServiceTypesFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}