/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportManufacturersUtilProcessLogsGrid from './ImportManufacturersUtilProcessLogsGrid.js';

export default function ImportManufacturersUtilProcessLogsFilteredGrid(props) {
	return <ImportManufacturersUtilProcessLogsGrid
				reference="ImportManufacturersUtilProcessLogsFilteredGrid"
				useFilters={true}
				
				{...props}
			/>;
}