
import {
	Icon,
} from 'native-base';
import { useSelector, useDispatch } from 'react-redux';
import {
	selectIsCustomerPerspective,
	setIsCustomerPerspective
} from '../../../models/Slices/AppSlice';
import Toggle from '@onehat/ui/src/Components/Form/Field/Toggle';

export default function SetupButton(props) {
	const {
			isMinimized = false,
		} = props,
		dispatch = useDispatch(),
		isCustomerPerspective = useSelector(selectIsCustomerPerspective),
		onChangeValue = () => {
			dispatch(setIsCustomerPerspective(!isCustomerPerspective));
		},
		minimizedProps = {};
	if (isMinimized) {
		minimizedProps.onText = null;
		minimizedProps.offText = null;
	}
	return <Toggle
				onChangeValue={onChangeValue}
				tooltip="Perspective"
				value={isCustomerPerspective}
				onText="Customer"
				offText="Internal"
				mx={2}
				{...minimizedProps}
			/>;
};