/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportWorkOrdersUtilProcessLogsEditorWindowSideB from '../Window/ImportWorkOrdersUtilProcessLogsEditorWindowSideB.js';
import ImportWorkOrdersUtilProcessLogsGridColumnsSideB from './Columns/ImportWorkOrdersUtilProcessLogsGridColumnsSideB.js';

export default function ImportWorkOrdersUtilProcessLogsGridEditorSideB(props) {
	return <WindowedGridEditor
				reference="ImportWorkOrdersUtilProcessLogsGridEditorSideB"
				model="ImportWorkOrdersUtilProcessLogs"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={ImportWorkOrdersUtilProcessLogsEditorWindowSideB}
				columnsConfig={ImportWorkOrdersUtilProcessLogsGridColumnsSideB}
				canColumnsReorder={false}
				canColumnsResize={false}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}