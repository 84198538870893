export default [
	    {
	        "id": "groups__name",
	        "header": "Name",
	        "fieldName": "groups__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 250 // MOD
	    },
	    {
	        "id": "groups__description",
	        "header": "Description",
	        "fieldName": "groups__description",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 350 // MOD
	    }
	];