export default [
	    {
	        "id": "equipment__eq_status_id",
	        "header": "Status",
	        "fieldName": "eq_statuses__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "equipment__eq_status_id"
	    },
	    {
	        "id": "equipment__inactive_date",
	        "header": "Inactive Date",
	        "fieldName": "equipment__inactive_date",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "equipment__year",
	        "header": "Year",
	        "fieldName": "equipment__year",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "equipment__manufacturer_id",
	        "header": "Make",
	        "fieldName": "manufacturers__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "equipment__manufacturer_id"
	    },
	    {
	        "id": "equipment__model",
	        "header": "Model",
	        "fieldName": "equipment__model",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "equipment__serial_number",
	        "header": "Serial #",
	        "fieldName": "equipment__serial_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "equipment__unit_number",
	        "header": "Unit #",
	        "fieldName": "equipment__unit_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "equipment__asset_number",
	        "header": "Asset # (Cust ID)",
	        "fieldName": "equipment__asset_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "equipment__dept_number",
	        "header": "Dept #",
	        "fieldName": "equipment__dept_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "equipment__business_partner_id",
	        "header": "Business Partner",
	        "fieldName": "business_partners__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	        "editField": "equipment__business_partner_id"
	    },
	    {
	        "id": "equipment__eq_type_id",
	        "header": "Type",
	        "fieldName": "eq_types__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200, // MOD
	        "editField": "equipment__eq_type_id"
	    },
	    {
	        "id": "equipment__tel_manufacturer_id",
	        "header": "Tel Manufacturer",
	        "fieldName": "tel_manufacturers__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150, // MOD
	        "editField": "equipment__tel_manufacturer_id"
	    },
	    {
	        "id": "equipment__tel_pid",
	        "header": "Tel Pid",
	        "fieldName": "equipment__tel_pid",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "equipment__comments",
	        "header": "Comments",
	        "fieldName": "equipment__comments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "equipment__is_misc",
	        "header": "Misc?",
	        "fieldName": "equipment__is_misc",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "equipment__is_pm_enable",
	        "header": "Pm Enable?",
	        "fieldName": "equipment__is_pm_enable",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "equipment__pm_schedules",
	        "header": "PM Schedules",
	        "fieldName": "equipment__pm_schedules",
	        "isSortable": false,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "equipment__expected_meter_per_month",
	        "header": "Exp. Meter/Mo", // MOD
	        "fieldName": "equipment__expected_meter_per_month",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "equipment__available_meter_per_week",
	        "header": "Avl. Meter/Wk", // MOD
	        "fieldName": "equipment__available_meter_per_week",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "equipment__in_service_date",
	        "header": "In Service",
	        "fieldName": "equipment__in_service_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    },
	    {
	        "id": "equipment__in_service_meter",
	        "header": "In Service Meter",
	        "fieldName": "equipment__in_service_meter",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150 // MOD
	    }
	];