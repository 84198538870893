/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import {
	SELECTION_MODE_SINGLE,
} from '@onehat/ui/src/Constants/Selection.js';
import { WindowedGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportPmEquipmentUtilProcessLogsEditorWindowSideA from '../Window/ImportPmEquipmentUtilProcessLogsEditorWindowSideA.js';
import ImportPmEquipmentUtilProcessLogsGridColumnsSideA from './Columns/ImportPmEquipmentUtilProcessLogsGridColumnsSideA.js';

export default function ImportPmEquipmentUtilProcessLogsGridEditorSideA(props) {
	return <WindowedGridEditor
				reference="ImportPmEquipmentUtilProcessLogsGridEditorSideA"
				model="ImportPmEquipmentUtilProcessLogs"
				usePermissions={true}
				selectionMode={SELECTION_MODE_SINGLE}
				Editor={ImportPmEquipmentUtilProcessLogsEditorWindowSideA}
				columnsConfig={ImportPmEquipmentUtilProcessLogsGridColumnsSideA}
				canColumnsReorder={false}
				canColumnsResize={false}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}