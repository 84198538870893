/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportWoServiceTypesEditor from '../Editor/ImportWoServiceTypesEditor.js';
import ImportWoServiceTypesGridColumns from './Columns/ImportWoServiceTypesGridColumns.js';

export default function ImportWoServiceTypesSideGridEditor(props) {
	return <SideGridEditor
				reference="ImportWoServiceTypesSideGridEditor"
				model="ImportWoServiceTypes"
				usePermissions={true}
				isCollapsible={false}
				Editor={ImportWoServiceTypesEditor}
				columnsConfig={ImportWoServiceTypesGridColumns}
				
				disableAdd={true}
				disableEdit={true}
				disableDelete={true}
				{...props}
			/>;
}