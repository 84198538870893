import * as yup from 'yup'; // https://github.com/jquense/yup#string

const Enterprises = {
	
	name: 'Enterprises',
	
	model: {

		idProperty: 'enterprises__id',
		displayProperty: 'enterprises__name',
		sortProperty: 'enterprises__name',
		
		sorters: null,

		validator: yup.object({
			enterprises__name: yup.string().required(),
			enterprises__is_enabled: yup.boolean().required(),
			enterprises__enterprise_type_id: yup.number().integer().required(),
			enterprises__service_center_id: yup.number().integer().nullable(),
			enterprises__exclude_before: yup.date().nullable()
		}),
		
		properties: [
			{ name: 'enterprises__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'enterprises__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', },
			{ name: 'enterprises__is_enabled', mapping: 'is_enabled', title: 'Enabled?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', defaultValue: 1, },
			{ name: 'enterprises__enterprise_type_id', mapping: 'enterprise_type_id', title: 'Enterprise Type', type: 'int', isFk: true, fkIdField: 'enterprise_types__id', fkDisplayField: 'enterprise_types__name', filterType: {"type":"EnterpriseTypesCombo","loadAfterRender":!1}, editorType: {"type":"EnterpriseTypesCombo","loadAfterRender":!1}, fieldGroup: 'General', defaultValue: 1, },
			{ name: 'enterprises__service_center_id', mapping: 'service_center_id', title: 'Service Center', type: 'int', isFk: true, fkIdField: 'service_centers__id', fkDisplayField: 'service_centers__name', filterType: {"type":"ServiceCentersCombo","loadAfterRender":!1}, editorType: {"type":"ServiceCentersComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'enterprises__pm_mode', mapping: 'pm_mode', title: 'PM Mode', isFilteringDisabled: true, editorType: {"type":"ArrayCombo","data":[['Standalone','Standalone'],['Master','Master'],['Slave','Slave']]}, fieldGroup: 'General', defaultValue: 'Standalone', },
			{ name: 'enterprises__exclude_before', mapping: 'exclude_before', title: 'Exclude Before', type: 'date', filterType: {"type":"DateRange","mode":"DATE"}, editorType: {"type":"Date","mode":"DATE"}, fieldGroup: 'General', },
			{ name: 'enterprises__is_cost_enabled', mapping: 'is_cost_enabled', title: 'Cost Enabled?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', defaultValue: 1, },
			{ name: 'enterprises__is_demo_mode', mapping: 'is_demo_mode', title: 'Demo Mode?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: 'General', },
			{ name: 'enterprises__chrome_id', mapping: 'chrome_id', title: 'Chrome', type: 'int', isFk: true, fkIdField: 'chromes__id', fkDisplayField: 'chromes__name', filterType: {"type":"ChromesCombo","loadAfterRender":!1}, editorType: {"type":"ChromesCombo","loadAfterRender":!1}, fieldGroup: 'General', defaultValue: 2, },
			{ name: 'enterprise_types__id', mapping: 'enterprise_type.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'enterprise_types__name', mapping: 'enterprise_type.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'chromes__id', mapping: 'chrome.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'chromes__name', mapping: 'chrome.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'chromes__url', mapping: 'chrome.url', title: 'Url', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'chromes__abbr', mapping: 'chrome.abbr', title: 'Abbr', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'chromes__is_enabled', mapping: 'chrome.is_enabled', title: 'Enabled?', type: 'bool', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'service_centers__id', mapping: 'service_center.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'service_centers__name', mapping: 'service_center.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'service_centers__code', mapping: 'service_center.code', title: 'Code', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			hasMany: [
				'Equipment',
				'Fleets',
				'TelOperators',
				'WorkOrders'
			],
			belongsTo: [
				'EnterpriseTypes',
				'Chromes',
				'ServiceCenters'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			'enterprises__is_enabled',
			'enterprises__enterprise_type_id',
			// 'enterprises__service_center_id',
			// 'enterprises__exclude_before',
			// 'enterprises__is_cost_enabled',
			// 'enterprises__is_demo_mode',
			// 'enterprises__chrome_id'
		],

	},

	entity: {
		methods: {

			getEquipment: function() {
				const Equipment = this.getAssociatedRepository('Equipment');
				return Equipment.getBy((entity) => {
					return entity.equipment__enterprise_id === this.enterprises__id;
				});
			},

			getFleets: function() {
				const Fleets = this.getAssociatedRepository('Fleets');
				return Fleets.getBy((entity) => {
					return entity.fleets__enterprise_id === this.enterprises__id;
				});
			},

			getTelOperators: function() {
				const TelOperators = this.getAssociatedRepository('TelOperators');
				return TelOperators.getBy((entity) => {
					return entity.tel_operators__enterprise_id === this.enterprises__id;
				});
			},

			getWorkOrders: function() {
				const WorkOrders = this.getAssociatedRepository('WorkOrders');
				return WorkOrders.getBy((entity) => {
					return entity.work_orders__enterprise_id === this.enterprises__id;
				});
			},

			getEnterpriseType: async function() {
				const EnterpriseTypes = this.getAssociatedRepository('EnterpriseTypes');
				let entity = EnterpriseTypes.getById(this.enterprises__enterprise_type_id);
				if (!entity) {
					entity = await EnterpriseTypes.getSingleEntityFromServer(this.enterprises__enterprise_type_id);
				}
				return entity;
			},

			getChrome: async function() {
				const Chromes = this.getAssociatedRepository('Chromes');
				let entity = Chromes.getById(this.enterprises__chrome_id);
				if (!entity) {
					entity = await Chromes.getSingleEntityFromServer(this.enterprises__chrome_id);
				}
				return entity;
			},

			getServiceCenter: async function() {
				const ServiceCenters = this.getAssociatedRepository('ServiceCenters');
				let entity = ServiceCenters.getById(this.enterprises__service_center_id);
				if (!entity) {
					entity = await ServiceCenters.getSingleEntityFromServer(this.enterprises__service_center_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": true,
	    "isAutoLoad": false
	},

};

export default Enterprises;
