/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */
import Editor from '@onehat/ui/src/Components/Editor/Editor.js';
import _ from 'lodash';

export default function UtilProcessLogsEditor(props) {

	const {
			selection,
		} = props,
		utilProcessLog = selection[0],
		items = [
		    {
		        "type": "Column",
		        "flex": 1,
		        "defaults": {},
		        "items": [
		            {
		                "type": "FieldSet",
		                "title": "General",
		                "reference": "general",
		                "defaults": {},
		                "items": [
		                    {
		                        "name": "util_process_logs__datetime",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "util_process_logs__process",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "util_process_logs__success",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "util_process_logs__count",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "util_process_logs__time",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    },
		                    {
		                        "name": "util_process_logs__json",
		                        "isEditable": false,
		                        "isEditingEnabledInPlainEditor": true
		                    }
		                ]
		            }
		        ]
		    }
		],
		ancillaryItems = [],
		columnDefaults = { // defaults for each column defined in 'items', for use in Form amd Viewer
		},
		ancillaryProps = {
			disableTitleChange: true,
			title: 'Rows',
			h: 600,
		};

	switch(utilProcessLog?.util_process_logs__process) {
		case 'ImportManufacturers':
			ancillaryItems.push({
				type: 'ImportManufacturersUtilProcessLogsFilteredGridEditorSideA',
				selectorId: 'import_manufacturers_util_process_logs__util_process_log_id',
				...ancillaryProps,
			});
			break;
		case 'ImportPmEquipment':
			ancillaryItems.push({
				type: 'ImportPmEquipmentUtilProcessLogsFilteredGridEditorSideA',
				selectorId: 'import_pm_equipment_util_process_logs__util_process_log_id',
				...ancillaryProps,
			});
			break;
		case 'ImportServiceCenters':
			ancillaryItems.push({
				type: 'ImportServiceCentersUtilProcessLogsFilteredGridEditorSideA',
				selectorId: 'import_service_centers_util_process_logs__util_process_log_id',
				...ancillaryProps,
			});
			break;
		case 'ImportTechnicians':
			ancillaryItems.push({
				type: 'ImportTechniciansUtilProcessLogsFilteredGridEditorSideA',
				selectorId: 'import_technicians_util_process_logs__util_process_log_id',
				...ancillaryProps,
			});
			break;
		case 'ImportWoOtherChargeTypes':
			ancillaryItems.push({
				type: 'ImportWoOtherChargeTypesUtilProcessLogsFilteredGridEditorSideA',
				selectorId: 'import_wo_other_charge_types_util_process_logs__util_process_log_id',
				...ancillaryProps,
			});
			break;
		case 'ImportWorkOrders':
			ancillaryItems.push({
				type: 'ImportWorkOrdersUtilProcessLogsFilteredGridEditorSideA',
				selectorId: 'import_work_orders_util_process_logs__util_process_log_id',
				...ancillaryProps,
			});
			break;
	}

	return <Editor
				reference="UtilProcessLogsEditor"
				title="UtilProcessLogs"
				items={items}
				ancillaryItems={ancillaryItems}
				columnDefaults={columnDefaults}
				{...props}
			/>;
}

