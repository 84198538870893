export default [
	    {
	        "id": "import_pm_equipment__business_partner",
	        "header": "Business Partner",
	        "fieldName": "import_pm_equipment__business_partner",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_pm_equipment__customer_name",
	        "header": "Customer Name",
	        "fieldName": "import_pm_equipment__customer_name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_pm_equipment__year",
	        "header": "Year",
	        "fieldName": "import_pm_equipment__year",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_pm_equipment__make",
	        "header": "Make",
	        "fieldName": "import_pm_equipment__make",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_pm_equipment__model",
	        "header": "Model",
	        "fieldName": "import_pm_equipment__model",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_pm_equipment__serial_number",
	        "header": "Serial Number",
	        "fieldName": "import_pm_equipment__serial_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_pm_equipment__service_center",
	        "header": "Service Center",
	        "fieldName": "import_pm_equipment__service_center",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_pm_equipment__unit_number",
	        "header": "Unit Number",
	        "fieldName": "import_pm_equipment__unit_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_pm_equipment__asset_number",
	        "header": "Asset Number",
	        "fieldName": "import_pm_equipment__asset_number",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_pm_equipment__meter_reading",
	        "header": "Meter Reading",
	        "fieldName": "import_pm_equipment__meter_reading",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_pm_equipment__technician",
	        "header": "Technician",
	        "fieldName": "import_pm_equipment__technician",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_pm_equipment__pm_comments",
	        "header": "Pm Comments",
	        "fieldName": "import_pm_equipment__pm_comments",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "import_pm_equipment__pm_status",
	        "header": "Pm Status",
	        "fieldName": "import_pm_equipment__pm_status",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_pm_equipment__service_plan",
	        "header": "Service Plan",
	        "fieldName": "import_pm_equipment__service_plan",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_pm_equipment__job_code",
	        "header": "Job Code",
	        "fieldName": "import_pm_equipment__job_code",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_pm_equipment__is_imported",
	        "header": "Imported?",
	        "fieldName": "import_pm_equipment__is_imported",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_pm_equipment__imported_date",
	        "header": "Imported Date",
	        "fieldName": "import_pm_equipment__imported_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    }
	];