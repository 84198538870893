/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportWoServiceTypesUtilProcessLogsGridPanel from './ImportWoServiceTypesUtilProcessLogsGrid.js';

export default function ImportWoServiceTypesUtilProcessLogsFilteredGridPanel(props) {
	return <ImportWoServiceTypesUtilProcessLogsGridPanel
				reference="ImportWoServiceTypesUtilProcessLogsFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}