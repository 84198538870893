import * as yup from 'yup'; // https://github.com/jquense/yup#string

const FleetStats = {
	
	name: 'FleetStats',
	
	model: {

		idProperty: 'fleet_stats__id',
		displayProperty: 'fleet_stats__id',
		sortProperty: 'fleet_stats__id',
		
		sorters: null,

		validator: yup.object({
			fleet_stats__is_stale: yup.boolean().required(),
			fleet_stats__path: yup.string().nullable(),
			fleet_stats__total_active_equipment: yup.number().integer().required(),
			fleet_stats__total_inactive_equipment: yup.number().integer().required(),
			fleet_stats__latest_repair_date: yup.date().nullable(),
			fleet_stats__due_pms: yup.number().integer().required(),
			fleet_stats__overdue_pms: yup.number().integer().required(),
			fleet_stats__overdue_pm_equipment: yup.number().integer().required(),
			fleet_stats__pm_equipment_hy: yup.number().integer().required(),
			fleet_stats__pm_equipment_ya: yup.number().integer().required(),
			fleet_stats__pm_equipment_other: yup.number().integer().required(),
			fleet_stats__latest_pm_date: yup.date().nullable(),
			fleet_stats__total_pm_equipment: yup.number().integer().required(),
			fleet_stats__total_pm_will_call_equipment: yup.number().integer().required()
		}),
		
		properties: [
			{ name: 'fleet_stats__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'fleet_stats__fleet_id', mapping: 'fleet_id', title: 'Fleet', type: 'int', isFk: true, fkIdField: 'fleets__id', fkDisplayField: 'fleets__name', filterType: {"type":"FleetsCombo","loadAfterRender":!1}, editorType: {"type":"FleetsComboEditor","loadAfterRender":!1}, fieldGroup: '1. General', },
			{ name: 'fleet_stats__is_stale', mapping: 'is_stale', title: 'Stale?', type: 'bool', filterType: {"type":"Toggle"}, editorType: {"type":"Toggle"}, fieldGroup: '1. General', },
			{ name: 'fleet_stats__path', mapping: 'path', title: 'Path', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: '1. General', },
			{ name: 'fleet_stats__total_active_equipment', mapping: 'total_active_equipment', title: 'Total Active Equipment', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '1. General', },
			{ name: 'fleet_stats__total_inactive_equipment', mapping: 'total_inactive_equipment', title: 'Total Inactive Equipment', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '1. General', },
			{ name: 'fleet_stats__mtbf', mapping: 'mtbf', title: 'Mtbf', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '1. General', },
			{ name: 'fleet_stats__mtbf_count', mapping: 'mtbf_count', title: 'Mtbf Count', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '1. General', },
			{ name: 'fleet_stats__latest_repair_date', mapping: 'latest_repair_date', title: 'Latest Repair Date', type: 'date', filterType: {"type":"DateRange","mode":"DATE"}, editorType: {"type":"Date","mode":"DATE"}, fieldGroup: '1. General', },
			{ name: 'fleet_stats__due_pms', mapping: 'due_pms', title: 'Due Pms', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '2. PM', },
			{ name: 'fleet_stats__overdue_pms', mapping: 'overdue_pms', title: 'Overdue Pms', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '2. PM', },
			{ name: 'fleet_stats__overdue_pm_equipment', mapping: 'overdue_pm_equipment', title: 'Overdue Pm Equipment', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '2. PM', },
			{ name: 'fleet_stats__pm_equipment_hy', mapping: 'pm_equipment_hy', title: 'PM Equipment Hy', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '2. PM', },
			{ name: 'fleet_stats__pm_equipment_ya', mapping: 'pm_equipment_ya', title: 'PM Equipment Ya', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '2. PM', },
			{ name: 'fleet_stats__pm_equipment_other', mapping: 'pm_equipment_other', title: 'PM Equipment Other', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '2. PM', },
			{ name: 'fleet_stats__latest_pm_date', mapping: 'latest_pm_date', title: 'Latest Pm Date', type: 'date', filterType: {"type":"DateRange","mode":"DATE"}, editorType: {"type":"Date","mode":"DATE"}, fieldGroup: '2. PM', },
			{ name: 'fleet_stats__total_pm_equipment', mapping: 'total_pm_equipment', title: 'Total Pm Equipment', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '2. PM', },
			{ name: 'fleet_stats__total_pm_will_call_equipment', mapping: 'total_pm_will_call_equipment', title: 'Total Pm Will Call Equipment', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '2. PM', },
			{ name: 'fleet_stats__latest_invoice_date_rental', mapping: 'latest_invoice_date_rental', title: 'Latest Invoice Date Rental', type: 'date', filterType: {"type":"DateRange","mode":"DATE"}, editorType: {"type":"Date","mode":"DATE"}, fieldGroup: '3. Rental', },
			{ name: 'fleet_stats__total_eq_coming_off_rent', mapping: 'total_eq_coming_off_rent', title: 'Total Eq Coming Off Rent', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '3. Rental', },
			{ name: 'fleet_stats__total_eq_overdue_rent', mapping: 'total_eq_overdue_rent', title: 'Total Eq Overdue Rent', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '3. Rental', },
			{ name: 'fleet_stats__total_eq_on_rent', mapping: 'total_eq_on_rent', title: 'Total Eq OnRent', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '3. Rental', },
			{ name: 'fleet_stats__leases_active', mapping: 'leases_active', title: 'Leases Active', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '4. Lease', },
			{ name: 'fleet_stats__leases_extended', mapping: 'leases_extended', title: 'Leases Extended', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '4. Lease', },
			{ name: 'fleet_stats__leases_percent_extended', mapping: 'leases_percent_extended', title: 'Leases Percent Extended', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '4. Lease', },
			{ name: 'fleet_stats__lease_total_monthly_payment', mapping: 'lease_total_monthly_payment', title: 'Lease Total Monthly Payment', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '4. Lease', },
			{ name: 'fleet_stats__lease_avg_months_to_end', mapping: 'lease_avg_months_to_end', title: 'Lease Avg Months To End', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '4. Lease', },
			{ name: 'fleet_stats__lease_avg_term', mapping: 'lease_avg_term', title: 'Lease Avg Term', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '4. Lease', },
			{ name: 'fleet_stats__mtd_meter', mapping: 'mtd_meter', title: 'Mtd Meter', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__mtd_available_meter', mapping: 'mtd_available_meter', title: 'Mtd Available Meter', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__mtd_util', mapping: 'mtd_util', title: 'Mtd Util', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__mtd_maint', mapping: 'mtd_maint', title: 'Mtd Maint', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__mtd_maint_preventive', mapping: 'mtd_maint_preventive', title: 'Mtd Maint Preventive', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__mtd_maint_abuse', mapping: 'mtd_maint_abuse', title: 'Mtd Maint Abuse', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__mtd_maint_abuse_rental', mapping: 'mtd_maint_abuse_rental', title: 'Mtd Maint Abuse Rental', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__mtd_maint_tires', mapping: 'mtd_maint_tires', title: 'Mtd Maint Tires', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__mtd_maint_repair', mapping: 'mtd_maint_repair', title: 'Mtd Maint Repair', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__mtd_maint_ltm', mapping: 'mtd_maint_ltm', title: 'Mtd Maint Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__mtd_maint_preventive_ltm', mapping: 'mtd_maint_preventive_ltm', title: 'Mtd Maint Preventive Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__mtd_maint_abuse_ltm', mapping: 'mtd_maint_abuse_ltm', title: 'Mtd Maint Abuse Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__mtd_maint_tires_ltm', mapping: 'mtd_maint_tires_ltm', title: 'Mtd Maint Tires Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__mtd_maint_repair_ltm', mapping: 'mtd_maint_repair_ltm', title: 'Mtd Maint Repair Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__mtd_maint_combined', mapping: 'mtd_maint_combined', title: 'Mtd Maint Combined', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__mtd_ltm_billing', mapping: 'mtd_ltm_billing', title: 'Mtd Ltm Billing', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__mtd_lease', mapping: 'mtd_lease', title: 'Mtd Lease', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__mtd_depreciation', mapping: 'mtd_depreciation', title: 'Mtd Depreciation', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__mtd_cost_per_hr', mapping: 'mtd_cost_per_hr', title: 'Mtd Cost Per Hr', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__mtd_cost_per_hr_customer', mapping: 'mtd_cost_per_hr_customer', title: 'Mtd Cost Per Hr Customer', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__mtd_cost_per_hr_ltm', mapping: 'mtd_cost_per_hr_ltm', title: 'Mtd Cost Per Hr Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__mtd_rental', mapping: 'mtd_rental', title: 'Mtd Rental', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '5. MTD', },
			{ name: 'fleet_stats__qtd_meter', mapping: 'qtd_meter', title: 'Qtd Meter', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '6. QTD', },
			{ name: 'fleet_stats__qtd_available_meter', mapping: 'qtd_available_meter', title: 'Qtd Available Meter', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '6. QTD', },
			{ name: 'fleet_stats__qtd_util', mapping: 'qtd_util', title: 'Qtd Util', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '6. QTD', },
			{ name: 'fleet_stats__ytd_meter', mapping: 'ytd_meter', title: 'Ytd Meter', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__ytd_available_meter', mapping: 'ytd_available_meter', title: 'Ytd Available Meter', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__ytd_util', mapping: 'ytd_util', title: 'Ytd Util', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__ytd_maint', mapping: 'ytd_maint', title: 'Ytd Maint', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__ytd_maint_preventive', mapping: 'ytd_maint_preventive', title: 'Ytd Maint Preventive', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__ytd_maint_abuse', mapping: 'ytd_maint_abuse', title: 'Ytd Maint Abuse', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__ytd_maint_abuse_rental', mapping: 'ytd_maint_abuse_rental', title: 'Ytd Maint Abuse Rental', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__ytd_maint_tires', mapping: 'ytd_maint_tires', title: 'Ytd Maint Tires', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__ytd_maint_repair', mapping: 'ytd_maint_repair', title: 'Ytd Maint Repair', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__ytd_maint_ltm', mapping: 'ytd_maint_ltm', title: 'Ytd Maint Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__ytd_maint_preventive_ltm', mapping: 'ytd_maint_preventive_ltm', title: 'Ytd Maint Preventive Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__ytd_maint_abuse_ltm', mapping: 'ytd_maint_abuse_ltm', title: 'Ytd Maint Abuse Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__ytd_maint_tires_ltm', mapping: 'ytd_maint_tires_ltm', title: 'Ytd Maint Tires Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__ytd_maint_repair_ltm', mapping: 'ytd_maint_repair_ltm', title: 'Ytd Maint Repair Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__ytd_maint_combined', mapping: 'ytd_maint_combined', title: 'Ytd Maint Combined', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__ytd_ltm_billing', mapping: 'ytd_ltm_billing', title: 'Ytd Ltm Billing', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__ytd_lease', mapping: 'ytd_lease', title: 'Ytd Lease', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__ytd_depreciation', mapping: 'ytd_depreciation', title: 'Ytd Depreciation', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__ytd_cost_per_hr', mapping: 'ytd_cost_per_hr', title: 'Ytd Cost Per Hr', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__ytd_cost_per_hr_customer', mapping: 'ytd_cost_per_hr_customer', title: 'Ytd Cost Per Hr Customer', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__ytd_cost_per_hr_ltm', mapping: 'ytd_cost_per_hr_ltm', title: 'Ytd Cost Per Hr Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__ytd_rental', mapping: 'ytd_rental', title: 'Ytd Rental', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '7. YTD', },
			{ name: 'fleet_stats__rtm_meter', mapping: 'rtm_meter', title: 'Rtm Meter', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__rtm_available_meter', mapping: 'rtm_available_meter', title: 'Rtm Available Meter', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__rtm_util', mapping: 'rtm_util', title: 'Rtm Util', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__rtm_maint', mapping: 'rtm_maint', title: 'Rtm Maint', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__rtm_maint_preventive', mapping: 'rtm_maint_preventive', title: 'Rtm Maint Preventive', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__rtm_maint_abuse', mapping: 'rtm_maint_abuse', title: 'Rtm Maint Abuse', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__rtm_maint_abuse_rental', mapping: 'rtm_maint_abuse_rental', title: 'Rtm Maint Abuse Rental', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__rtm_maint_tires', mapping: 'rtm_maint_tires', title: 'Rtm Maint Tires', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__rtm_maint_repair', mapping: 'rtm_maint_repair', title: 'Rtm Maint Repair', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__rtm_maint_ltm', mapping: 'rtm_maint_ltm', title: 'Rtm Maint Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__rtm_maint_preventive_ltm', mapping: 'rtm_maint_preventive_ltm', title: 'Rtm Maint Preventive Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__rtm_maint_abuse_ltm', mapping: 'rtm_maint_abuse_ltm', title: 'Rtm Maint Abuse Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__rtm_maint_tires_ltm', mapping: 'rtm_maint_tires_ltm', title: 'Rtm Maint Tires Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__rtm_maint_repair_ltm', mapping: 'rtm_maint_repair_ltm', title: 'Rtm Maint Repair Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__rtm_maint_combined', mapping: 'rtm_maint_combined', title: 'Rtm Maint Combined', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__rtm_ltm_billing', mapping: 'rtm_ltm_billing', title: 'Rtm Ltm Billing', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__rtm_lease', mapping: 'rtm_lease', title: 'Rtm Lease', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__rtm_depreciation', mapping: 'rtm_depreciation', title: 'Rtm Depreciation', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__rtm_cost_per_hr', mapping: 'rtm_cost_per_hr', title: 'Rtm Cost Per Hr', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__rtm_cost_per_hr_customer', mapping: 'rtm_cost_per_hr_customer', title: 'Rtm Cost Per Hr Customer', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__rtm_cost_per_hr_ltm', mapping: 'rtm_cost_per_hr_ltm', title: 'Rtm Cost Per Hr Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__rtm_rental', mapping: 'rtm_rental', title: 'Rtm Rental', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '8. RTM', },
			{ name: 'fleet_stats__ltd_meter', mapping: 'ltd_meter', title: 'Ltd Meter', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleet_stats__ltd_available_meter', mapping: 'ltd_available_meter', title: 'Ltd Available Meter', type: 'int', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleet_stats__ltd_util', mapping: 'ltd_util', title: 'Ltd Util', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleet_stats__ltd_maint', mapping: 'ltd_maint', title: 'Ltd Maint', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleet_stats__ltd_maint_preventive', mapping: 'ltd_maint_preventive', title: 'Ltd Maint Preventive', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleet_stats__ltd_maint_abuse', mapping: 'ltd_maint_abuse', title: 'Ltd Maint Abuse', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleet_stats__ltd_maint_abuse_rental', mapping: 'ltd_maint_abuse_rental', title: 'Ltd Maint Abuse Rental', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleet_stats__ltd_maint_tires', mapping: 'ltd_maint_tires', title: 'Ltd Maint Tires', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleet_stats__ltd_maint_repair', mapping: 'ltd_maint_repair', title: 'Ltd Maint Repair', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleet_stats__ltd_maint_ltm', mapping: 'ltd_maint_ltm', title: 'Ltd Maint Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleet_stats__ltd_maint_preventive_ltm', mapping: 'ltd_maint_preventive_ltm', title: 'Ltd Maint Preventive Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleet_stats__ltd_maint_abuse_ltm', mapping: 'ltd_maint_abuse_ltm', title: 'Ltd Maint Abuse Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleet_stats__ltd_maint_tires_ltm', mapping: 'ltd_maint_tires_ltm', title: 'Ltd Maint Tires Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleet_stats__ltd_maint_repair_ltm', mapping: 'ltd_maint_repair_ltm', title: 'Ltd Maint Repair Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleet_stats__ltd_maint_combined', mapping: 'ltd_maint_combined', title: 'Ltd Maint Combined', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleet_stats__ltd_ltm_billing', mapping: 'ltd_ltm_billing', title: 'Ltd Ltm Billing', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleet_stats__ltd_lease', mapping: 'ltd_lease', title: 'Ltd Lease', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleet_stats__ltd_depreciation', mapping: 'ltd_depreciation', title: 'Ltd Depreciation', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleet_stats__ltd_cost_per_hr', mapping: 'ltd_cost_per_hr', title: 'Ltd Cost Per Hr', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleet_stats__ltd_cost_per_hr_customer', mapping: 'ltd_cost_per_hr_customer', title: 'Ltd Cost Per Hr Customer', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleet_stats__ltd_cost_per_hr_ltm', mapping: 'ltd_cost_per_hr_ltm', title: 'Ltd Cost Per Hr Ltm', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleet_stats__ltd_rental', mapping: 'ltd_rental', title: 'Ltd Rental', type: 'currency', filterType: {"type":"NumberRange"}, editorType: {"type":"Number"}, fieldGroup: '9. LTD', },
			{ name: 'fleets__id', mapping: 'fleet.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__enterprise_id', mapping: 'fleet.enterprise_id', title: 'Enterprise', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__name', mapping: 'fleet.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__business_partners', mapping: 'fleet.business_partners', title: 'Business Partners', type: 'tag', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__shift_start_1', mapping: 'fleet.shift_start_1', title: 'Shift Start 1', type: 'time', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__shift_start_2', mapping: 'fleet.shift_start_2', title: 'Shift Start 2', type: 'time', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'fleets__shift_start_3', mapping: 'fleet.shift_start_3', title: 'Shift Start 3', type: 'time', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'Fleets'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'fleet_stats__fleet_id',
			// 'fleet_stats__is_stale',
			// 'fleet_stats__total_active_equipment',
			// 'fleet_stats__total_inactive_equipment',
			// 'fleet_stats__mtbf',
			// 'fleet_stats__mtbf_count',
			// 'fleet_stats__latest_repair_date',
			// 'fleet_stats__due_pms',
			// 'fleet_stats__overdue_pms',
			// 'fleet_stats__overdue_pm_equipment',
			// 'fleet_stats__pm_equipment_hy',
			// 'fleet_stats__pm_equipment_ya',
			// 'fleet_stats__pm_equipment_other',
			// 'fleet_stats__latest_pm_date',
			// 'fleet_stats__total_pm_equipment',
			// 'fleet_stats__total_pm_will_call_equipment',
			// 'fleet_stats__latest_invoice_date_rental',
			// 'fleet_stats__total_eq_coming_off_rent',
			// 'fleet_stats__total_eq_overdue_rent',
			// 'fleet_stats__total_eq_on_rent',
			// 'fleet_stats__leases_active',
			// 'fleet_stats__leases_extended',
			// 'fleet_stats__leases_percent_extended',
			// 'fleet_stats__lease_total_monthly_payment',
			// 'fleet_stats__lease_avg_months_to_end',
			// 'fleet_stats__lease_avg_term',
			// 'fleet_stats__mtd_meter',
			// 'fleet_stats__mtd_available_meter',
			// 'fleet_stats__mtd_util',
			// 'fleet_stats__mtd_maint',
			// 'fleet_stats__mtd_maint_preventive',
			// 'fleet_stats__mtd_maint_abuse',
			// 'fleet_stats__mtd_maint_abuse_rental',
			// 'fleet_stats__mtd_maint_tires',
			// 'fleet_stats__mtd_maint_repair',
			// 'fleet_stats__mtd_maint_ltm',
			// 'fleet_stats__mtd_maint_preventive_ltm',
			// 'fleet_stats__mtd_maint_abuse_ltm',
			// 'fleet_stats__mtd_maint_tires_ltm',
			// 'fleet_stats__mtd_maint_repair_ltm',
			// 'fleet_stats__mtd_maint_combined',
			// 'fleet_stats__mtd_ltm_billing',
			// 'fleet_stats__mtd_lease',
			// 'fleet_stats__mtd_depreciation',
			// 'fleet_stats__mtd_cost_per_hr',
			// 'fleet_stats__mtd_cost_per_hr_customer',
			// 'fleet_stats__mtd_cost_per_hr_ltm',
			// 'fleet_stats__mtd_rental',
			// 'fleet_stats__qtd_meter',
			// 'fleet_stats__qtd_available_meter',
			// 'fleet_stats__qtd_util',
			// 'fleet_stats__ytd_meter',
			// 'fleet_stats__ytd_available_meter',
			// 'fleet_stats__ytd_util',
			// 'fleet_stats__ytd_maint',
			// 'fleet_stats__ytd_maint_preventive',
			// 'fleet_stats__ytd_maint_abuse',
			// 'fleet_stats__ytd_maint_abuse_rental',
			// 'fleet_stats__ytd_maint_tires',
			// 'fleet_stats__ytd_maint_repair',
			// 'fleet_stats__ytd_maint_ltm',
			// 'fleet_stats__ytd_maint_preventive_ltm',
			// 'fleet_stats__ytd_maint_abuse_ltm',
			// 'fleet_stats__ytd_maint_tires_ltm',
			// 'fleet_stats__ytd_maint_repair_ltm',
			// 'fleet_stats__ytd_maint_combined',
			// 'fleet_stats__ytd_ltm_billing',
			// 'fleet_stats__ytd_lease',
			// 'fleet_stats__ytd_depreciation',
			// 'fleet_stats__ytd_cost_per_hr',
			// 'fleet_stats__ytd_cost_per_hr_customer',
			// 'fleet_stats__ytd_cost_per_hr_ltm',
			// 'fleet_stats__ytd_rental',
			// 'fleet_stats__rtm_meter',
			// 'fleet_stats__rtm_available_meter',
			// 'fleet_stats__rtm_util',
			// 'fleet_stats__rtm_maint',
			// 'fleet_stats__rtm_maint_preventive',
			// 'fleet_stats__rtm_maint_abuse',
			// 'fleet_stats__rtm_maint_abuse_rental',
			// 'fleet_stats__rtm_maint_tires',
			// 'fleet_stats__rtm_maint_repair',
			// 'fleet_stats__rtm_maint_ltm',
			// 'fleet_stats__rtm_maint_preventive_ltm',
			// 'fleet_stats__rtm_maint_abuse_ltm',
			// 'fleet_stats__rtm_maint_tires_ltm',
			// 'fleet_stats__rtm_maint_repair_ltm',
			// 'fleet_stats__rtm_maint_combined',
			// 'fleet_stats__rtm_ltm_billing',
			// 'fleet_stats__rtm_lease',
			// 'fleet_stats__rtm_depreciation',
			// 'fleet_stats__rtm_cost_per_hr',
			// 'fleet_stats__rtm_cost_per_hr_customer',
			// 'fleet_stats__rtm_cost_per_hr_ltm',
			// 'fleet_stats__rtm_rental',
			// 'fleet_stats__ltd_meter',
			// 'fleet_stats__ltd_available_meter',
			// 'fleet_stats__ltd_util',
			// 'fleet_stats__ltd_maint',
			// 'fleet_stats__ltd_maint_preventive',
			// 'fleet_stats__ltd_maint_abuse',
			// 'fleet_stats__ltd_maint_abuse_rental',
			// 'fleet_stats__ltd_maint_tires',
			// 'fleet_stats__ltd_maint_repair',
			// 'fleet_stats__ltd_maint_ltm',
			// 'fleet_stats__ltd_maint_preventive_ltm',
			// 'fleet_stats__ltd_maint_abuse_ltm',
			// 'fleet_stats__ltd_maint_tires_ltm',
			// 'fleet_stats__ltd_maint_repair_ltm',
			// 'fleet_stats__ltd_maint_combined',
			// 'fleet_stats__ltd_ltm_billing',
			// 'fleet_stats__ltd_lease',
			// 'fleet_stats__ltd_depreciation',
			// 'fleet_stats__ltd_cost_per_hr',
			// 'fleet_stats__ltd_cost_per_hr_customer',
			// 'fleet_stats__ltd_cost_per_hr_ltm',
			// 'fleet_stats__ltd_rental'
		],

	},

	entity: {
		methods: {

			getFleet: async function() {
				const Fleets = this.getAssociatedRepository('Fleets');
				let entity = Fleets.getById(this.fleet_stats__fleet_id);
				if (!entity) {
					entity = await Fleets.getSingleEntityFromServer(this.fleet_stats__fleet_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default FleetStats;
