import {
	Column,
	Image,
	Row,
} from 'native-base';
import appPng from '../../Images/MhPmsLogo.png';

export default function AppLogo(props) {
	return <Column h="40px" w="40px">
				<Image
					source={appPng}
					margin="auto"
					resizeMode="contain"
					alt="MH PMs Logo"
					flex={1}
					w="100%"
				/>
			</Column>;
}