export default [
	    {
	        "id": "import_wo_other_charge_types_util_process_logs__util_process_log_id",
	        "header": "Util Process Log",
	        "fieldName": "util_process_logs__process",
	        "isSortable": true,
	        "isEditable": false,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "import_wo_other_charge_types_util_process_logs__util_process_log_id"
	    },
	    {
	        "id": "import_wo_other_charge_types_util_process_logs__datetime",
	        "header": "Datetime",
	        "fieldName": "import_wo_other_charge_types_util_process_logs__datetime",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "import_wo_other_charge_types_util_process_logs__success",
	        "header": "Success",
	        "fieldName": "import_wo_other_charge_types_util_process_logs__success",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_other_charge_types_util_process_logs__status",
	        "header": "Status",
	        "fieldName": "import_wo_other_charge_types_util_process_logs__status",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_other_charge_types_util_process_logs__json",
	        "header": "Json",
	        "fieldName": "import_wo_other_charge_types_util_process_logs__json",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "import_wo_other_charge_types_util_process_logs__import_wo_other_charge_type_id",
	        "header": "Import Wo Other Charge Type",
	        "fieldName": "import_wo_other_charge_types__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "import_wo_other_charge_types_util_process_logs__import_wo_other_charge_type_id"
	    },
	    {
	        "id": "import_wo_other_charge_types_util_process_logs__wo_other_charge_type_id",
	        "header": "Wo Other Charge Type",
	        "fieldName": "wo_other_charge_types__name",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
	        "editField": "import_wo_other_charge_types_util_process_logs__wo_other_charge_type_id"
	    }
	];