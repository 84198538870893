/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportWoPartsSideGridEditor from './ImportWoPartsSideGridEditor.js';

export default function ImportWoPartsFilteredSideGridEditor(props) {
	return <ImportWoPartsSideGridEditor
				reference="ImportWoPartsFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}