/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import GridPanel from '@onehat/ui/src/Components/Panel/GridPanel.js';
import WoServiceTypesGridColumns from '../../Grid/Columns/WoServiceTypesGridColumns.js';

export default function WoServiceTypesGridPanel(props) {
	return <GridPanel
				reference="WoServiceTypesGridPanel"
				model="WoServiceTypes"
				usePermissions={true}
				columnsConfig={WoServiceTypesGridColumns}

				{...props}
			/>;
}