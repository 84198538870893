/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportWorkOrdersSideGridEditor from './ImportWorkOrdersSideGridEditor.js';

export default function ImportWorkOrdersFilteredSideGridEditor(props) {
	return <ImportWorkOrdersSideGridEditor
				reference="ImportWorkOrdersFilteredSideGridEditor"
				useFilters={true}

				{...props}
			/>;
}