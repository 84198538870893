import ImportStatusesViewer from '../../Viewer/ImportStatusesViewer.js';
import TimeViewer from '../../Viewer/TimeViewer.js';

export default [
	    {
	        "id": "import_work_orders_util_process_logs__datetime",
	        "header": "Time", // MOD
	        "fieldName": "import_work_orders_util_process_logs__datetime",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100, // MOD
			renderer: (entity) => {
				return <TimeViewer moment={entity.properties.import_work_orders_util_process_logs__datetime?.getMoment()} />;
			},
	    },
		{
			id: "import_work_orders__service_order",
			header: "Service Order",
			fieldName: "import_work_orders__service_order",
			isSortable: false,
			isEditable: false,
			isReorderable: true,
			isResizable: true,
			w: 150
		},
		{
			id: "import_work_orders__segment",
			header: "Sgmt",
			fieldName: "import_work_orders__segment",
			isSortable: false,
			isEditable: false,
			isReorderable: true,
			isResizable: true,
			w: 60
		},
		{
			id: "import_work_orders__manufacturer",
			header: "Make",
			fieldName: "import_work_orders__manufacturer",
			isSortable: false,
			isEditable: false,
			isReorderable: true,
			isResizable: true,
			w: 100
		},
		{
			id: "import_work_orders__serial_number",
			header: "Serial",
			fieldName: "import_work_orders__serial_number",
			isSortable: false,
			isEditable: false,
			isReorderable: true,
			isResizable: true,
			w: 150
		},
	    {
	        "id": "import_work_orders_util_process_logs__success",
	        "header": "Success",
	        "fieldName": "import_work_orders_util_process_logs__success",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_work_orders_util_process_logs__status",
	        "header": "Status",
	        "fieldName": "import_work_orders_util_process_logs__status",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100,
			renderer: (entity) => {
				return <ImportStatusesViewer id={entity.import_work_orders_util_process_logs__status} />;
			},
	    },
	    // {
	    //     "id": "import_work_orders_util_process_logs__import_work_order_id",
	    //     "header": "Import Work Order",
	    //     "fieldName": "import_work_orders__id",
	    //     "isSortable": true,
	    //     "isEditable": true,
	    //     "isReorderable": true,
	    //     "isResizable": true,
	    //     "editField": "import_work_orders_util_process_logs__import_work_order_id",
	    //     "flex": 1,
	    //     "minWidth": 150
	    // },
	    {
	        "id": "import_work_orders_util_process_logs__went_to_misc",
	        "header": "Misc", // MOD
	        "fieldName": "import_work_orders_util_process_logs__went_to_misc",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_work_orders_util_process_logs__json",
	        "header": "Json",
	        "fieldName": "import_work_orders_util_process_logs__json",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	];