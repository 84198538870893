/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import ImportWorkOrdersUtilProcessLogsGridPanel from './ImportWorkOrdersUtilProcessLogsGrid.js';

export default function ImportWorkOrdersUtilProcessLogsFilteredGridPanel(props) {
	return <ImportWorkOrdersUtilProcessLogsGridPanel
				reference="ImportWorkOrdersUtilProcessLogsFilteredGridPanel"
				useFilters={true}

				{...props}
			/>;
}