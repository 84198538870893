import * as yup from 'yup'; // https://github.com/jquense/yup#string

const PartsPartTypes = {
	
	name: 'PartsPartTypes',
	
	model: {

		idProperty: 'parts_part_types__id',
		displayProperty: 'parts_part_types__id',
		sortProperty: 'parts_part_types__id',
		
		sorters: null,

		validator: yup.object({
			parts_part_types__part_id: yup.number().integer().required(),
			parts_part_types__part_type_id: yup.number().integer().required()
		}),
		
		properties: [
			{ name: 'parts_part_types__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'parts_part_types__part_id', mapping: 'part_id', title: 'Part', type: 'int', isFk: true, fkIdField: 'parts__id', fkDisplayField: 'parts__part_number', filterType: {"type":"PartsCombo","loadAfterRender":!1}, editorType: {"type":"PartsComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'parts_part_types__part_type_id', mapping: 'part_type_id', title: 'Part Type', type: 'int', isFk: true, fkIdField: 'part_types__id', fkDisplayField: 'part_types__name', filterType: {"type":"PartTypesCombo","loadAfterRender":!1}, editorType: {"type":"PartTypesComboEditor","loadAfterRender":!1}, fieldGroup: 'General', },
			{ name: 'part_types__id', mapping: 'part_type.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'part_types__name', mapping: 'part_type.name', title: 'Name', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__id', mapping: 'part.id', title: 'Id', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__part_number', mapping: 'part.part_number', title: 'Part Number', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__description', mapping: 'part.description', title: 'Description', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__manufacturer_id', mapping: 'part.manufacturer_id', title: 'Manufacturer', type: 'int', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__unit_cost', mapping: 'part.unit_cost', title: 'Unit Cost', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, },
			{ name: 'parts__unit_price', mapping: 'part.unit_price', title: 'Unit Price', type: 'currency', isForeignModel: true, isEditingDisabled: true, isFilteringDisabled: true, }
		],

		associations: {
			belongsTo: [
				'PartTypes',
				'Parts'
			],

		},

		ancillaryFilters: [],

		defaultFilters: [
			// 'parts_part_types__part_id',
			// 'parts_part_types__part_type_id'
		],

	},

	entity: {
		methods: {

			getPartType: async function() {
				const PartTypes = this.getAssociatedRepository('PartTypes');
				let entity = PartTypes.getById(this.parts_part_types__part_type_id);
				if (!entity) {
					entity = await PartTypes.getSingleEntityFromServer(this.parts_part_types__part_type_id);
				}
				return entity;
			},

			getPart: async function() {
				const Parts = this.getAssociatedRepository('Parts');
				let entity = Parts.getById(this.parts_part_types__part_id);
				if (!entity) {
					entity = await Parts.getSingleEntityFromServer(this.parts_part_types__part_id);
				}
				return entity;
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default PartsPartTypes;
