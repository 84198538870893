export default [
	    {
	        "id": "import_wo_other_charges__woid",
	        "header": "WOID",
	        "fieldName": "import_wo_other_charges__woid",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_wo_other_charges__line_item",
	        "header": "Line Item",
	        "fieldName": "import_wo_other_charges__line_item",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_other_charges__other_charge_type",
	        "header": "Other Charge Type",
	        "fieldName": "import_wo_other_charges__other_charge_type",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 150
	    },
	    {
	        "id": "import_wo_other_charges__qty_ordered",
	        "header": "Qty Ordered",
	        "fieldName": "import_wo_other_charges__qty_ordered",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_other_charges__qty_shipped",
	        "header": "Qty Shipped",
	        "fieldName": "import_wo_other_charges__qty_shipped",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_other_charges__ext_price",
	        "header": "Ext Price",
	        "fieldName": "import_wo_other_charges__ext_price",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_other_charges__description",
	        "header": "Description",
	        "fieldName": "import_wo_other_charges__description",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    },
	    {
	        "id": "import_wo_other_charges__is_imported",
	        "header": "Imported?",
	        "fieldName": "import_wo_other_charges__is_imported",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 100
	    },
	    {
	        "id": "import_wo_other_charges__imported_date",
	        "header": "Imported Date",
	        "fieldName": "import_wo_other_charges__imported_date",
	        "isSortable": true,
	        "isEditable": true,
	        "isReorderable": true,
	        "isResizable": true,
	        "w": 200
	    }
	];