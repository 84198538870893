/**
 * COPYRIGHT NOTICE
 * This file is categorized as "Custom Source Code"
 * and is subject to the terms and conditions defined in the
 * "LICENSE.txt" file, which is part of this source code package.
 */

import { SideGridEditor } from '@onehat/ui/src/Components/Grid/Grid.js';
import ImportServiceCentersEditor from '../Editor/ImportServiceCentersEditor.js';
import ImportServiceCentersGridColumns from './Columns/ImportServiceCentersGridColumns.js';

export default function ImportServiceCentersSideGridEditor(props) {
	return <SideGridEditor
				reference="ImportServiceCentersSideGridEditor"
				model="ImportServiceCenters"
				usePermissions={true}
				isCollapsible={false}
				Editor={ImportServiceCentersEditor}
				columnsConfig={ImportServiceCentersGridColumns}
				
				
				{...props}
			/>;
}