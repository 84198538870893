import * as yup from 'yup'; // https://github.com/jquense/yup#string

const BusinessPartners = {
	
	name: 'BusinessPartners',
	
	model: {

		idProperty: 'business_partners__id',
		displayProperty: 'business_partners__name',
		sortProperty: 'business_partners__name',
		
		sorters: null,

		validator: yup.object({
			business_partners__name: yup.string().required()
		}),
		
		properties: [
			{ name: 'business_partners__id', mapping: 'id', title: 'Id', type: 'int', isFilteringDisabled: true, fieldGroup: 'General', },
			{ name: 'business_partners__name', mapping: 'name', title: 'Name', isFilteringDisabled: true, editorType: {"type":"Input"}, fieldGroup: 'General', }
		],

		associations: {
			hasMany: [
				'Equipment',
				'WorkOrders',
				'SoldToWorkOrders',
				'BillToWorkOrders',
				'ShipToWorkOrders'
			],
			belongsToMany: [
				'Fleets'
			],

		},

		ancillaryFilters: {
		    "fleets": "Fleet"
		},

		defaultFilters: [

		],

	},

	entity: {
		methods: {

			getEquipment: function() {
				const Equipment = this.getAssociatedRepository('Equipment');
				return Equipment.getBy((entity) => {
					return entity.equipment__business_partner_id === this.business_partners__id;
				});
			},

			getWorkOrders: function() {
				const WorkOrders = this.getAssociatedRepository('WorkOrders');
				return WorkOrders.getBy((entity) => {
					return entity.work_orders__business_partner_id === this.business_partners__id;
				});
			},

			getSoldToWorkOrders: function() {
				const SoldToWorkOrders = this.getAssociatedRepository('SoldToWorkOrders');
				return SoldToWorkOrders.getBy((entity) => {
					return entity.sold_to_work_orders__sold_to_business_partner_id === this.business_partners__id;
				});
			},

			getBillToWorkOrders: function() {
				const BillToWorkOrders = this.getAssociatedRepository('BillToWorkOrders');
				return BillToWorkOrders.getBy((entity) => {
					return entity.bill_to_work_orders__bill_to_business_partner_id === this.business_partners__id;
				});
			},

			getShipToWorkOrders: function() {
				const ShipToWorkOrders = this.getAssociatedRepository('ShipToWorkOrders');
				return ShipToWorkOrders.getBy((entity) => {
					return entity.ship_to_work_orders__ship_to_business_partner_id === this.business_partners__id;
				});
			},

		},
	},


	repository: {
	    "type": "onebuild",
	    "isRemotePhantomMode": false,
	    "isAutoLoad": false
	},

};

export default BusinessPartners;
